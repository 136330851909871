// eslint-disable-next-line
// @ts-nocheck
import {
  useMutation,
  useQuery,
  useSuspenseQuery,
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { nextFetcher } from '../next-fetcher'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
}

export type AddBundleProductsToCartInput = {
  cart_id: Scalars['String']['input']
  cart_items: Array<InputMaybe<BundleProductCartItemInput>>
}

export type AddBundleProductsToCartOutput = {
  __typename?: 'AddBundleProductsToCartOutput'
  cart: Cart
}

export type AddConfigurableProductsToCartInput = {
  cart_id: Scalars['String']['input']
  cart_items: Array<InputMaybe<ConfigurableProductCartItemInput>>
}

export type AddConfigurableProductsToCartOutput = {
  __typename?: 'AddConfigurableProductsToCartOutput'
  cart: Cart
  messages?: Maybe<Array<Maybe<MessageType>>>
}

export type AddDownloadableProductsToCartInput = {
  cart_id: Scalars['String']['input']
  cart_items: Array<InputMaybe<DownloadableProductCartItemInput>>
}

export type AddDownloadableProductsToCartOutput = {
  __typename?: 'AddDownloadableProductsToCartOutput'
  cart: Cart
}

export type AddGiftRegistryItemInput = {
  /** An array of options the customer has entered */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** A brief note about the item */
  note?: InputMaybe<Scalars['String']['input']>
  /** For complex product types, the SKU of the parent product */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The quantity of the product to add */
  quantity: Scalars['Float']['input']
  /** An array of strings corresponding to options the customer has selected */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The SKU of the product to add to the gift registry */
  sku: Scalars['String']['input']
}

export type AddGiftRegistryRegistrantInput = {
  /** Additional attributes specified as a code-value pair. */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<GiftRegistryDynamicAttributeInput>>
  >
  /** The email address of the registrant */
  email: Scalars['String']['input']
  /** The first name of the registrant */
  firstname: Scalars['String']['input']
  /** The last name of the registrant */
  lastname: Scalars['String']['input']
}

export type AddGiftRegistryRegistrantsOutput = {
  __typename?: 'AddGiftRegistryRegistrantsOutput'
  /** The gift registry after adding registrants */
  gift_registry?: Maybe<GiftRegistry>
}

export type AddProductsToCartOutput = {
  __typename?: 'AddProductsToCartOutput'
  /** The cart after products have been added */
  cart: Cart
  /** An error encountered while adding an item to the cart. */
  user_errors: Array<Maybe<CartUserInputError>>
}

export type AddProductsToCompareListInput = {
  /** An array of product IDs to add to the compare list */
  products: Array<InputMaybe<Scalars['ID']['input']>>
  /** The unique identifier of the compare list to modify */
  uid: Scalars['ID']['input']
}

/** Output of the request to add products to a requisition list */
export type AddProductsToRequisitionListOutput = {
  __typename?: 'AddProductsToRequisitionListOutput'
  /** The requisition list after adding products */
  requisition_list?: Maybe<RequisitionList>
}

/** Contains the customer's wish list and any errors encountered */
export type AddProductsToWishlistOutput = {
  __typename?: 'AddProductsToWishlistOutput'
  /** An array of errors encountered while adding products to a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with all items that were successfully added */
  wishlist: Wishlist
}

export type AddPromoItemInput = {
  cart_id: Scalars['String']['input']
  cart_items?: InputMaybe<Array<InputMaybe<ConfigurableProductCartItemInput>>>
}

export type AddPromoItemOutput = {
  __typename?: 'AddPromoItemOutput'
  cart?: Maybe<Cart>
}

export type AddRequisitionListItemToCartUserError = {
  __typename?: 'AddRequisitionListItemToCartUserError'
  /** A description of the error */
  message: Scalars['String']['output']
  type: AddRequisitionListItemToCartUserErrorType
}

export enum AddRequisitionListItemToCartUserErrorType {
  LowQuantity = 'LOW_QUANTITY',
  OptionsUpdated = 'OPTIONS_UPDATED',
  OutOfStock = 'OUT_OF_STOCK',
  UnavailableSku = 'UNAVAILABLE_SKU',
}

/** Output of the request to add items in a requisition list to the cart */
export type AddRequisitionListItemsToCartOutput = {
  __typename?: 'AddRequisitionListItemsToCartOutput'
  /** Indicates why the attempt to add items to the requistion list was not successful */
  add_requisition_list_items_to_cart_user_errors: Array<
    Maybe<AddRequisitionListItemToCartUserError>
  >
  /** The cart after adding requisition list items */
  cart?: Maybe<Cart>
  /** Indicates whether the attempt to add items to the requisition list was successful */
  status: Scalars['Boolean']['output']
}

export type AddReturnCommentInput = {
  /** The text added to the return request */
  comment_text: Scalars['String']['input']
  /** The unique ID for a `Return` object */
  return_uid: Scalars['ID']['input']
}

export type AddReturnCommentOutput = {
  __typename?: 'AddReturnCommentOutput'
  /** Contains details about the modified return */
  return?: Maybe<Return>
}

export type AddReturnTrackingInput = {
  /** The unique ID for a `ReturnShippingCarrier` object */
  carrier_uid: Scalars['ID']['input']
  /** The unique ID for a `Returns` object */
  return_uid: Scalars['ID']['input']
  /** The shipping tracking number for this return request */
  tracking_number: Scalars['String']['input']
}

export type AddReturnTrackingOutput = {
  __typename?: 'AddReturnTrackingOutput'
  /** Contains details about the modified return */
  return?: Maybe<Return>
  /** Contains details about shipping for a return */
  return_shipping_tracking?: Maybe<ReturnShippingTracking>
}

export type AddSimpleProductsToCartInput = {
  cart_id: Scalars['String']['input']
  cart_items: Array<InputMaybe<SimpleProductCartItemInput>>
}

export type AddSimpleProductsToCartOutput = {
  __typename?: 'AddSimpleProductsToCartOutput'
  cart: Cart
}

export type AddVirtualProductsToCartInput = {
  cart_id: Scalars['String']['input']
  cart_items: Array<InputMaybe<VirtualProductCartItemInput>>
}

export type AddVirtualProductsToCartOutput = {
  __typename?: 'AddVirtualProductsToCartOutput'
  cart: Cart
}

export type AddWishlistItemsToCartOutput = {
  __typename?: 'AddWishlistItemsToCartOutput'
  /** An array of errors encountered while adding products to the customer's cart */
  add_wishlist_items_to_cart_user_errors: Array<
    Maybe<WishlistCartUserInputError>
  >
  /** Indicates whether the attempt to add items to the customer's cart was successful */
  status: Scalars['Boolean']['output']
  /** Contains the wish list with all items that were successfully added */
  wishlist: Wishlist
}

export type AdyenAdditionalDataBoleto = {
  /** Type of Boleto. */
  boleto_type?: InputMaybe<Scalars['String']['input']>
  /** Customer Firstname. */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** Customer Lastname. */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** Social security number. */
  social_security_number?: InputMaybe<Scalars['String']['input']>
}

export type AdyenAdditionalDataCc = {
  /** Credit card brand. */
  cc_type?: InputMaybe<Scalars['String']['input']>
  /** Debit or Credit card. */
  combo_card_type?: InputMaybe<Scalars['String']['input']>
  /** Email address if customer is guest. */
  guestEmail?: InputMaybe<Scalars['String']['input']>
  /** If combo_card_type is credit, Number of installments for the payment. */
  number_of_installments?: InputMaybe<Scalars['Int']['input']>
  /** Recurring processing model to tokenize the payment method. */
  recurringProcessingModel?: InputMaybe<Scalars['String']['input']>
  /** The URL to return to in case of a redirection. The format depends on the channel. This URL can have a maximum of 1024 characters. It can include a placeholder `:merchantReference` to identify the order e.g. `https://your-company.com/checkout?shopperOrder=:merchantReference`. */
  returnUrl?: InputMaybe<Scalars['String']['input']>
  /** JSON string of filled fields. */
  stateData?: InputMaybe<Scalars['String']['input']>
}

export type AdyenAdditionalDataHpp = {
  /** Type of HPP payment. */
  brand_code: Scalars['String']['input']
  /** Ratepay device identification token. */
  df_value?: InputMaybe<Scalars['String']['input']>
  /** Email address if customer is guest. */
  guestEmail?: InputMaybe<Scalars['String']['input']>
  /** Recurring processing model to tokenize the payment method. */
  recurringProcessingModel?: InputMaybe<Scalars['String']['input']>
  /** The URL to return to in case of a redirection. The format depends on the channel. This URL can have a maximum of 1024 characters. It can include a placeholder `:merchantReference` to identify the order e.g. `https://your-company.com/checkout?shopperOrder=:merchantReference`. */
  returnUrl?: InputMaybe<Scalars['String']['input']>
  /** JSON string of filled fields. */
  stateData: Scalars['String']['input']
}

export type AdyenAdditionalDataOneclick = {
  /** Number of installments for the payment. */
  number_of_installments?: InputMaybe<Scalars['Int']['input']>
  /** The URL to return to in case of a redirection. The format depends on the channel. This URL can have a maximum of 1024 characters. It can include a placeholder `:merchantReference` to identify the order e.g. `https://your-company.com/checkout?shopperOrder=:merchantReference`. */
  returnUrl?: InputMaybe<Scalars['String']['input']>
  /** JSON string of filled fields. */
  stateData?: InputMaybe<Scalars['String']['input']>
}

export type AdyenAdditionalDataPosCloud = {
  /** Number of installments for the payment. */
  number_of_installments?: InputMaybe<Scalars['Int']['input']>
  /** Terminal ID of selected terminal. */
  terminal_id?: InputMaybe<Scalars['String']['input']>
}

export type AdyenPaymentMethodIcon = {
  __typename?: 'AdyenPaymentMethodIcon'
  /** Height of the icon in pixels. */
  height?: Maybe<Scalars['Int']['output']>
  /** URL of the icon. */
  url?: Maybe<Scalars['String']['output']>
  /** Width of the icon in pixels. */
  width?: Maybe<Scalars['Int']['output']>
}

export type AdyenPaymentMethods = {
  __typename?: 'AdyenPaymentMethods'
  /** Payment method's additional details. */
  paymentMethodsExtraDetails?: Maybe<
    Array<Maybe<AdyenPaymentMethodsExtraDetails>>
  >
  /** API response from Adyen with payment methods. */
  paymentMethodsResponse?: Maybe<AdyenPaymentMethodsResponse>
}

export type AdyenPaymentMethodsArray = {
  __typename?: 'AdyenPaymentMethodsArray'
  /** Brand for the selected gift card. For example: plastix, hmclub. */
  brand?: Maybe<Scalars['String']['output']>
  /** List of possible brands. For example: visa, mc. */
  brands?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The configuration of the payment method. */
  configuration?: Maybe<AdyenPaymentMethodsConfiguration>
  /** All input details to be provided to complete the payment with this payment method. */
  details?: Maybe<Array<Maybe<AdyenPaymentMethodsDetails>>>
  /** Payment method issuer list. */
  issuers?: Maybe<Array<Maybe<AdyenPaymentMethodsIssuers>>>
  /** The displayable name of this payment method. */
  name?: Maybe<Scalars['String']['output']>
  /** The unique payment method code. */
  type?: Maybe<Scalars['String']['output']>
}

export type AdyenPaymentMethodsConfiguration = {
  __typename?: 'AdyenPaymentMethodsConfiguration'
  /** Name of the merchant for Google Pay. */
  gatewayMerchantId?: Maybe<Scalars['String']['output']>
  /** ID of the merchant. */
  merchantId?: Maybe<Scalars['String']['output']>
  /** Name of the merchant. */
  merchantName?: Maybe<Scalars['String']['output']>
}

export type AdyenPaymentMethodsDetails = {
  __typename?: 'AdyenPaymentMethodsDetails'
  /** The items to choose from in case that the payment method includes a selection list. */
  items?: Maybe<Array<Maybe<AdyenPaymentMethodsDetailsItems>>>
  /** The value to provide in the result. */
  key?: Maybe<Scalars['String']['output']>
  /** True if this input is optional. */
  optional?: Maybe<Scalars['String']['output']>
  /** The type of the required input. */
  type?: Maybe<Scalars['String']['output']>
  /** The value can be pre-filled, if available. */
  value?: Maybe<Scalars['String']['output']>
}

export type AdyenPaymentMethodsDetailsItems = {
  __typename?: 'AdyenPaymentMethodsDetailsItems'
  /** The value to provide in the result. */
  id?: Maybe<Scalars['String']['output']>
  /** The display name. */
  name?: Maybe<Scalars['String']['output']>
}

export type AdyenPaymentMethodsExtraDetails = {
  __typename?: 'AdyenPaymentMethodsExtraDetails'
  /** Extra configuration settings. */
  configuration?: Maybe<AdyenPaymentMethodsExtraDetailsConfiguration>
  /** Icon for the payment method. */
  icon?: Maybe<AdyenPaymentMethodIcon>
  /** True if the payment method is Open Invoice. */
  isOpenInvoice?: Maybe<Scalars['Boolean']['output']>
  /** The unique payment method code. */
  type?: Maybe<Scalars['String']['output']>
}

export type AdyenPaymentMethodsExtraDetailsConfiguration = {
  __typename?: 'AdyenPaymentMethodsExtraDetailsConfiguration'
  /** Current order amount in minor units. */
  amount?: Maybe<Money>
  /** Current order currency. */
  currency?: Maybe<Scalars['String']['output']>
}

export type AdyenPaymentMethodsIssuers = {
  __typename?: 'AdyenPaymentMethodsIssuers'
  /** Issuer ID. */
  id?: Maybe<Scalars['String']['output']>
  /** Issuer name. */
  name?: Maybe<Scalars['String']['output']>
}

export type AdyenPaymentMethodsResponse = {
  __typename?: 'AdyenPaymentMethodsResponse'
  paymentMethods?: Maybe<Array<Maybe<AdyenPaymentMethodsArray>>>
  storedPaymentMethods?: Maybe<Array<Maybe<AdyenStoredPaymentMethodsArray>>>
}

export type AdyenPaymentStatus = {
  __typename?: 'AdyenPaymentStatus'
  /** Object containing information about the payment's next step. */
  action?: Maybe<Scalars['String']['output']>
  /** Additional data required for the next step in the payment process. */
  additionalData?: Maybe<Scalars['String']['output']>
  /** If True, no further action is required and customer should be redirect to success page. */
  isFinal?: Maybe<Scalars['Boolean']['output']>
  /** Current state of the order in Adyen. */
  resultCode?: Maybe<Scalars['String']['output']>
}

export type AdyenStoredPaymentMethodsArray = {
  __typename?: 'AdyenStoredPaymentMethodsArray'
  /** The brand of the card. */
  brand?: Maybe<Scalars['String']['output']>
  /** The month the card expires. */
  expiryMonth?: Maybe<Scalars['String']['output']>
  /** The year the card expires. */
  expiryYear?: Maybe<Scalars['String']['output']>
  /** The unique payment method code. */
  holderName?: Maybe<Scalars['String']['output']>
  /** The IBAN of the bank account. */
  iban?: Maybe<Scalars['String']['output']>
  /** A unique identifier of this stored payment method. */
  id?: Maybe<Scalars['String']['output']>
  /** The last four digits of the PAN. */
  lastFour?: Maybe<Scalars['String']['output']>
  /** The display name of the stored payment method. */
  name?: Maybe<Scalars['String']['output']>
  /** Returned in the response if you are not tokenizing with Adyen and are using the Merchant-initiated transactions (MIT) framework from Mastercard or Visa. This contains either the Mastercard Trace ID or the Visa Transaction ID. */
  networkTxReference?: Maybe<Scalars['String']['output']>
  /** The name of the bank account holder. */
  ownerName?: Maybe<Scalars['String']['output']>
  /** The shopper’s email address. */
  shopperEmail?: Maybe<Scalars['String']['output']>
  /** The supported shopper interactions for this stored payment method. */
  supportedShopperInteractions?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >
  /** The type of payment method. */
  type?: Maybe<Scalars['String']['output']>
}

/** A bucket that contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export type Aggregation = {
  __typename?: 'Aggregation'
  /** Attribute code of the aggregation group. */
  attribute_code: Scalars['String']['output']
  /** The number of options in the aggregation group. */
  count?: Maybe<Scalars['Int']['output']>
  /** The aggregation display name. */
  label?: Maybe<Scalars['String']['output']>
  /** Array of options for the aggregation. */
  options?: Maybe<Array<Maybe<AggregationOption>>>
  /** The relative position of the attribute in a layered navigation block */
  position?: Maybe<Scalars['Int']['output']>
}

export type AggregationOption = AggregationOptionInterface & {
  __typename?: 'AggregationOption'
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']['output']>
  /** Aggregation option display label. */
  label?: Maybe<Scalars['String']['output']>
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']['output']
}

export type AggregationOptionInterface = {
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']['output']>
  /** Aggregation option display label. */
  label?: Maybe<Scalars['String']['output']>
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']['output']
}

export type AllergensInfoInterface = {
  __typename?: 'AllergensInfoInterface'
  allergens?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  traces?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/** Label info */
export type AmLabel = {
  __typename?: 'AmLabel'
  /** Label customer group */
  customer_group_ids?: Maybe<Scalars['String']['output']>
  /** Label image. */
  image?: Maybe<Scalars['String']['output']>
  /** Is label visible */
  is_visible?: Maybe<Scalars['Boolean']['output']>
  /** Label id. */
  label_id?: Maybe<Scalars['Int']['output']>
  /** Label name. */
  name?: Maybe<Scalars['String']['output']>
  /** Label position. */
  position?: Maybe<Scalars['String']['output']>
  /** Product id. */
  product_id?: Maybe<Scalars['Int']['output']>
  /** Label image size. Percent of the product image. */
  size?: Maybe<Scalars['String']['output']>
  /** Label css style. */
  style?: Maybe<Scalars['String']['output']>
  /** A settings for label tooltip */
  tooltip_settings?: Maybe<AmLabelTooltip>
  /** Label text. */
  txt?: Maybe<Scalars['String']['output']>
}

export type AmLabelList = {
  __typename?: 'AmLabelList'
  /** Labels. */
  items?: Maybe<Array<Maybe<AmLabel>>>
}

/** Label Mode */
export enum AmLabelMode {
  Category = 'CATEGORY',
  Product = 'PRODUCT',
}

export type AmLabelSetting = {
  __typename?: 'AmLabelSetting'
  /** Category Page Label Container. */
  category_container?: Maybe<Scalars['String']['output']>
  /** Labels Alignment. */
  labels_alignment?: Maybe<Scalars['Int']['output']>
  /** Margin between labels, px. */
  margin_between?: Maybe<Scalars['Int']['output']>
  /** Max Number of Labels on One Product. */
  max_labels?: Maybe<Scalars['Int']['output']>
  /** Product Page Label Container. */
  product_container?: Maybe<Scalars['String']['output']>
  /** Show Several Labels on the Same Place. */
  show_several_on_place?: Maybe<Scalars['Int']['output']>
}

export type AmLabelTooltip = {
  __typename?: 'AmLabelTooltip'
  /** Tooltip color */
  color?: Maybe<Scalars['String']['output']>
  /** Tooltip status */
  status?: Maybe<Scalars['Int']['output']>
  /** Tooltip text */
  text?: Maybe<Scalars['String']['output']>
  /** Tooltip text color */
  text_color?: Maybe<Scalars['String']['output']>
}

export type AmxnotifSubscribeInput = {
  /** Email of logged-in or logged-out User */
  email?: InputMaybe<Scalars['String']['input']>
  /** ID of a Product to be watched */
  product_id?: InputMaybe<Scalars['String']['input']>
  /** UID of a Product to be watched */
  product_uid?: InputMaybe<Scalars['String']['input']>
}

export type AmxnotifSubscribeOutput = {
  __typename?: 'AmxnotifSubscribeOutput'
  message?: Maybe<Scalars['String']['output']>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type AppliedCoupon = {
  __typename?: 'AppliedCoupon'
  code: Scalars['String']['output']
}

/** Contains the applied gift card with applied and remaining balance */
export type AppliedGiftCard = {
  __typename?: 'AppliedGiftCard'
  /** Applied balance to the current cart */
  applied_balance?: Maybe<Money>
  /** Gift card account code */
  code?: Maybe<Scalars['String']['output']>
  /** Current balance remaining on gift card */
  current_balance?: Maybe<Money>
  /** Gift card expiration date */
  expiration_date?: Maybe<Scalars['String']['output']>
}

/** Applied and current balance */
export type AppliedStoreCredit = {
  __typename?: 'AppliedStoreCredit'
  /** Applied store credit balance to the current cart */
  applied_balance?: Maybe<Money>
  /** Current balance remaining on store credit */
  current_balance?: Maybe<Money>
  /** Indicates whether store credits are enabled. If the feature is disabled, then the current balance will not be returned */
  enabled?: Maybe<Scalars['Boolean']['output']>
}

export type ApplyCouponToCartInput = {
  cart_id: Scalars['String']['input']
  coupon_code: Scalars['String']['input']
}

export type ApplyCouponToCartOutput = {
  __typename?: 'ApplyCouponToCartOutput'
  cart: Cart
}

/** Defines the input required to run the applyGiftCardToCart mutation */
export type ApplyGiftCardToCartInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
  /** The gift card code to be applied to the cart */
  gift_card_code: Scalars['String']['input']
}

/** Defines the possible output for the applyGiftCardToCart mutation */
export type ApplyGiftCardToCartOutput = {
  __typename?: 'ApplyGiftCardToCartOutput'
  /** Describes the contents of the specified shopping cart */
  cart: Cart
}

export type ApplyRewardPointsToCartOutput = {
  __typename?: 'ApplyRewardPointsToCartOutput'
  /** The customer cart after reward points are applied */
  cart: Cart
}

/** Defines the input required to run the applyStoreCreditToCart mutation */
export type ApplyStoreCreditToCartInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
}

/** Defines the possible output for the applyStoreCreditToCart mutation */
export type ApplyStoreCreditToCartOutput = {
  __typename?: 'ApplyStoreCreditToCartOutput'
  /** Describes the contents of the specified shopping cart */
  cart: Cart
}

/** AreaInput defines the parameters which will be used for filter by specified location. */
export type AreaInput = {
  /** The radius for the search in KM. */
  radius: Scalars['Int']['input']
  /** The country code where search must be performed. Required parameter together with region, city or postcode. */
  search_term: Scalars['String']['input']
}

export type ArukeresoType = {
  __typename?: 'ArukeresoType'
  is_active?: Maybe<Scalars['Boolean']['output']>
  script_src?: Maybe<Scalars['String']['output']>
  web_api_key?: Maybe<Scalars['String']['output']>
}

export type AssignCompareListToCustomerOutput = {
  __typename?: 'AssignCompareListToCustomerOutput'
  /** The contents of the customer's compare list */
  compare_list?: Maybe<CompareList>
  result: Scalars['Boolean']['output']
}

/** Attribute contains the attribute_type of the specified attribute_code and entity_type */
export type Attribute = {
  __typename?: 'Attribute'
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']['output']>
  /** Attribute options list. */
  attribute_options?: Maybe<Array<Maybe<AttributeOption>>>
  /** The data type of the attribute */
  attribute_type?: Maybe<Scalars['String']['output']>
  /** The type of entity that defines the attribute */
  entity_type?: Maybe<Scalars['String']['output']>
  /** The frontend input type of the attribute */
  input_type?: Maybe<Scalars['String']['output']>
  /** Contains details about the storefront properties configured for the attribute */
  storefront_properties?: Maybe<StorefrontProperties>
}

/** AttributeInput specifies the attribute_code and entity_type to search */
export type AttributeInput = {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: InputMaybe<Scalars['String']['input']>
  /** The type of entity that defines the attribute */
  entity_type?: InputMaybe<Scalars['String']['input']>
}

/** Attribute option. */
export type AttributeOption = {
  __typename?: 'AttributeOption'
  /** Attribute option label. */
  label?: Maybe<Scalars['String']['output']>
  /** Attribute option value. */
  value?: Maybe<Scalars['String']['output']>
}

export type AvailablePaymentMethod = {
  __typename?: 'AvailablePaymentMethod'
  /** The payment method code */
  code: Scalars['String']['output']
  /** The payment method title. */
  title: Scalars['String']['output']
}

export type AvailableShippingMethod = {
  __typename?: 'AvailableShippingMethod'
  amount: Money
  available: Scalars['Boolean']['output']
  /** @deprecated The field should not be used on the storefront */
  base_amount?: Maybe<Money>
  carrier_code: Scalars['String']['output']
  carrier_title: Scalars['String']['output']
  error_message?: Maybe<Scalars['String']['output']>
  /** Could be null if method is not available */
  method_code?: Maybe<Scalars['String']['output']>
  /** Could be null if method is not available */
  method_title?: Maybe<Scalars['String']['output']>
  price_excl_tax: Money
  price_incl_tax: Money
}

export type BankAccountDetails = {
  __typename?: 'BankAccountDetails'
  /** Bank Account Number */
  account_number?: Maybe<Scalars['String']['output']>
  /** IBAN */
  iban?: Maybe<Scalars['String']['output']>
  /** SWIFT code */
  swift?: Maybe<Scalars['String']['output']>
}

export type BannerSliderConfig = {
  __typename?: 'BannerSliderConfig'
  autoplay?: Maybe<Scalars['Boolean']['output']>
  autoplay_speed?: Maybe<Scalars['Int']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
  infinite_loop?: Maybe<Scalars['Boolean']['output']>
  show_dots?: Maybe<Scalars['Boolean']['output']>
}

export type BannerSliderItem = {
  __typename?: 'BannerSliderItem'
  active_from?: Maybe<Scalars['String']['output']>
  active_to?: Maybe<Scalars['String']['output']>
  desktop_image?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  mobile_image?: Maybe<Scalars['String']['output']>
  mobile_route?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['Boolean']['output']>
  store_id?: Maybe<Scalars['Int']['output']>
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type BillingAddressInput = {
  address?: InputMaybe<CartAddressInput>
  customer_address_id?: InputMaybe<Scalars['Int']['input']>
  /** Set billing address same as shipping */
  same_as_shipping?: InputMaybe<Scalars['Boolean']['input']>
  /** Deprecated: use `same_as_shipping` field instead */
  use_for_shipping?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingCartAddress = CartAddressInterface & {
  __typename?: 'BillingCartAddress'
  city: Scalars['String']['output']
  company?: Maybe<Scalars['String']['output']>
  country: CartAddressCountry
  /** @deprecated The field is used only in shipping address */
  customer_notes?: Maybe<Scalars['String']['output']>
  firstname: Scalars['String']['output']
  lastname: Scalars['String']['output']
  postcode?: Maybe<Scalars['String']['output']>
  region?: Maybe<CartAddressRegion>
  street: Array<Maybe<Scalars['String']['output']>>
  telephone: Scalars['String']['output']
}

export type BlogPostInterface = {
  __typename?: 'BlogPostInterface'
  /** The date and time the Blog Post was created */
  created_at?: Maybe<Scalars['String']['output']>
  /** The excerpt or short description of the Blog Post */
  excerpt?: Maybe<Scalars['String']['output']>
  /** The absolute URL of the Blog post */
  img?: Maybe<Scalars['String']['output']>
  /** The Title of the Blog Post */
  title?: Maybe<Scalars['String']['output']>
  /** The date and time the Blog Post was updated */
  updated_at?: Maybe<Scalars['String']['output']>
  /** The absolute URL of the Blog Post */
  url?: Maybe<Scalars['String']['output']>
}

export type BlogPosts = {
  __typename?: 'BlogPosts'
  /** An array of latest Blog Posts for a specific Product and Store */
  items?: Maybe<Array<Maybe<BlogPostInterface>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>
  /** The total number of Blog Posts available at the specified Store */
  total_count?: Maybe<Scalars['Int']['output']>
}

/** Breadcrumb item. */
export type Breadcrumb = {
  __typename?: 'Breadcrumb'
  /**
   * Category ID.
   * @deprecated Use the `category_uid` argument instead.
   */
  category_id?: Maybe<Scalars['Int']['output']>
  /** Category level. */
  category_level?: Maybe<Scalars['Int']['output']>
  /** Category name. */
  category_name?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Breadcrumb` object. */
  category_uid: Scalars['ID']['output']
  /** Category URL key. */
  category_url_key?: Maybe<Scalars['String']['output']>
  /** Category URL path. */
  category_url_path?: Maybe<Scalars['String']['output']>
}

export type BreadcrumbType = {
  __typename?: 'BreadcrumbType'
  name?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type BundleCartItem = CartItemInterface & {
  __typename?: 'BundleCartItem'
  /** The list of available gift wrapping options for the cart item */
  available_gift_wrapping: Array<Maybe<GiftWrapping>>
  bundle_options: Array<Maybe<SelectedBundleOption>>
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>
  /** The selected gift wrapping for the cart item */
  gift_wrapping?: Maybe<GiftWrapping>
  /** @deprecated Use `uid` instead */
  id: Scalars['String']['output']
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']['output']
  rule_id?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID']['output']
}

export type BundleCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'BundleCreditMemoItem'
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
}

export type BundleInvoiceItem = InvoiceItemInterface & {
  __typename?: 'BundleInvoiceItem'
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID']['output']
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
}

/** BundleItem defines an individual item in a bundle product. */
export type BundleItem = {
  __typename?: 'BundleItem'
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** An array of additional options for this bundle item. */
  options?: Maybe<Array<Maybe<BundleItemOption>>>
  /** he relative position of this item compared to the other bundle items. */
  position?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the item must be included in the bundle. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The SKU of the bundle product. */
  sku?: Maybe<Scalars['String']['output']>
  /** The display name of the item. */
  title?: Maybe<Scalars['String']['output']>
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `BundleItem` object. */
  uid?: Maybe<Scalars['ID']['output']>
}

/** BundleItemOption defines characteristics and options for a specific bundle item. */
export type BundleItemOption = {
  __typename?: 'BundleItemOption'
  /** Indicates whether the customer can change the number of items for this option. */
  can_change_quantity?: Maybe<Scalars['Boolean']['output']>
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether this option is the default option. */
  is_default?: Maybe<Scalars['Boolean']['output']>
  /** The text that identifies the bundled item option. */
  label?: Maybe<Scalars['String']['output']>
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position?: Maybe<Scalars['Int']['output']>
  /** The price of the selected option. */
  price?: Maybe<Scalars['Float']['output']>
  /** One of FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** Contains details about this product option. */
  product?: Maybe<ProductInterface>
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated The `qty` is deprecated. Use `quantity` instead.
   */
  qty?: Maybe<Scalars['Float']['output']>
  /** Indicates the quantity of this specific bundle item. */
  quantity?: Maybe<Scalars['Float']['output']>
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID']['output']
}

export type BundleOptionInput = {
  id: Scalars['Int']['input']
  quantity: Scalars['Float']['input']
  value: Array<InputMaybe<Scalars['String']['input']>>
}

export type BundleOrderItem = OrderItemInterface & {
  __typename?: 'BundleOrderItem'
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** Indicates whether the order item is eligible is eligible to be in a return request */
  eligible_for_return?: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift wrapping for the order item */
  gift_wrapping?: Maybe<GiftWrapping>
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  is_saleable?: Maybe<Scalars['Boolean']['output']>
  parent_sku?: Maybe<Scalars['String']['output']>
  product_image?: Maybe<Scalars['String']['output']>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']['output']>
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']['output']>
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']['output']>
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']['output']>
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item */
  status?: Maybe<Scalars['String']['output']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems */
export type BundleProduct = CustomizableProductInterface &
  PhysicalProductInterface &
  ProductInterface &
  RoutableInterface & {
    __typename?: 'BundleProduct'
    allergen_celery?: Maybe<Scalars['Int']['output']>
    allergen_crustaceans?: Maybe<Scalars['Int']['output']>
    allergen_eggs?: Maybe<Scalars['Int']['output']>
    allergen_fish?: Maybe<Scalars['Int']['output']>
    allergen_gluten?: Maybe<Scalars['Int']['output']>
    allergen_lupin?: Maybe<Scalars['Int']['output']>
    allergen_milk?: Maybe<Scalars['Int']['output']>
    allergen_molluscs?: Maybe<Scalars['Int']['output']>
    allergen_mustard?: Maybe<Scalars['Int']['output']>
    allergen_peanuts?: Maybe<Scalars['Int']['output']>
    allergen_sesame?: Maybe<Scalars['Int']['output']>
    allergen_soya?: Maybe<Scalars['Int']['output']>
    allergen_sulphites?: Maybe<Scalars['Int']['output']>
    allergen_tree_nuts?: Maybe<Scalars['Int']['output']>
    application?: Maybe<Scalars['Int']['output']>
    artificial_sweetener_free?: Maybe<Scalars['Int']['output']>
    aspartame_free?: Maybe<Scalars['Int']['output']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attribute_set_id?: Maybe<Scalars['Int']['output']>
    available_for_sdd?: Maybe<Scalars['String']['output']>
    bag_volume_range?: Maybe<Scalars['Int']['output']>
    bio?: Maybe<Scalars['Int']['output']>
    blend?: Maybe<Scalars['Int']['output']>
    bottom?: Maybe<Scalars['String']['output']>
    breadcrumb?: Maybe<Array<Maybe<BreadcrumbType>>>
    breadcrumb_en?: Maybe<Scalars['String']['output']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']['output']>
    capsules?: Maybe<Scalars['String']['output']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    color?: Maybe<Scalars['String']['output']>
    colors?: Maybe<Scalars['String']['output']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']['output']>
    coupon_amount?: Maybe<Scalars['Int']['output']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    created_at?: Maybe<Scalars['String']['output']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    default_category?: Maybe<Scalars['String']['output']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    /** Indicates whether the bundle product has a dynamic price. */
    dynamic_price?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the bundle product has a dynamic SK. */
    dynamic_sku?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the bundle product has a dynamically calculated weight. */
    dynamic_weight?: Maybe<Scalars['Boolean']['output']>
    ean?: Maybe<Scalars['String']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    estimated_date_of_arrival?: Maybe<EstimatedDateOfArrivalType>
    expected_arrival_date?: Maybe<Scalars['String']['output']>
    farba_oblecenia?: Maybe<Scalars['String']['output']>
    farba_sejkera?: Maybe<Scalars['String']['output']>
    fit?: Maybe<Scalars['String']['output']>
    fitness_goal?: Maybe<Scalars['String']['output']>
    flavor?: Maybe<Scalars['String']['output']>
    form?: Maybe<Scalars['Int']['output']>
    /** Product Labels list */
    gb_product_labels?: Maybe<Array<Maybe<Label>>>
    gender_segmentation?: Maybe<Scalars['String']['output']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']['output']>
    glutenfree?: Maybe<Scalars['Int']['output']>
    gmo_free?: Maybe<Scalars['Int']['output']>
    heureka_name?: Maybe<Scalars['String']['output']>
    hodnota_kuponu?: Maybe<Scalars['String']['output']>
    hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']['output']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** Indicates whether the product can be returned */
    is_returnable?: Maybe<Scalars['String']['output']>
    /** An array containing information about individual bundle items. */
    items?: Maybe<Array<Maybe<BundleItem>>>
    lactosefree?: Maybe<Scalars['Int']['output']>
    /** The latest Related Blog Posts for a specific Product and Store */
    latest_related_blog_posts?: Maybe<LatestRelatedBlogPosts>
    legal_category_of_product?: Maybe<Scalars['Int']['output']>
    long_term_unavailable?: Maybe<Scalars['Int']['output']>
    main_ingredient?: Maybe<Scalars['Int']['output']>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']['output']>
    /** Manufacturer's info. */
    manufacturer_info?: Maybe<ManufacturerInfoInterface>
    manufacturer_model?: Maybe<Scalars['String']['output']>
    mass_grams_g?: Maybe<Scalars['String']['output']>
    mass_mililiter_ml?: Maybe<Scalars['Int']['output']>
    material?: Maybe<Scalars['Int']['output']>
    maximum_duration?: Maybe<Scalars['String']['output']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']['output']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']['output']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']['output']>
    method_of_protein_processing?: Maybe<Scalars['Int']['output']>
    mgs_brand?: Maybe<Scalars['Int']['output']>
    mgs_j360?: Maybe<Scalars['Int']['output']>
    milimeter_mm?: Maybe<Scalars['Int']['output']>
    mininum_order_quantity?: Maybe<Scalars['String']['output']>
    /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
    msrp_price?: Maybe<Scalars['Float']['output']>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']['output']>
    new_from?: Maybe<Scalars['String']['output']>
    /**
     * The beginning date for new product listings, and determines if the product is featured as a new product.
     * @deprecated The field should not be used on the storefront.
     */
    new_from_date?: Maybe<Scalars['String']['output']>
    /**
     * The end date for new product listings.
     * @deprecated The field should not be used on the storefront.
     */
    new_to_date?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_batch?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_package_o?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_pallette?: Maybe<Scalars['String']['output']>
    objem?: Maybe<Scalars['String']['output']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']['output']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']['output']>
    other_models?: Maybe<Array<Maybe<OtherModelsValues>>>
    plastic_packaging_free?: Maybe<Scalars['Int']['output']>
    preparation?: Maybe<Scalars['String']['output']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    /** One of PRICE_RANGE or AS_LOW_AS. */
    price_view?: Maybe<PriceViewEnum>
    prichut?: Maybe<Scalars['String']['output']>
    /** The list of products questions. */
    productQuestions: ProductQuestions
    /** The collection of Product labels for this Product */
    product_labels?: Maybe<Array<Maybe<AmLabel>>>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    product_sort_coefficient?: Maybe<Scalars['String']['output']>
    protein_content_in_product?: Maybe<Scalars['String']['output']>
    protein_sourcee?: Maybe<Scalars['Int']['output']>
    purchasing_category?: Maybe<Scalars['Int']['output']>
    /** The average of all the ratings given to the product. */
    rating_summary: Scalars['Float']['output']
    recycling_codes?: Maybe<Scalars['String']['output']>
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
    redirect_code: Scalars['Int']['output']
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
    relative_url?: Maybe<Scalars['String']['output']>
    /** The total count of all the reviews given to the product. */
    review_count: Scalars['Int']['output']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** A short description of the product. Its use depends on the theme. */
    sales_description?: Maybe<ComplexTextValue>
    seasonality?: Maybe<Scalars['Int']['output']>
    /** Indicates whether to ship bundle items together or individually. */
    ship_bundle_items?: Maybe<ShipBundleItemsEnum>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size_of_clothing?: Maybe<Scalars['String']['output']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']['output']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    special_from_date?: Maybe<Scalars['String']['output']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']['output']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']['output']>
    staged: Scalars['Boolean']['output']
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    supplier_internal_code?: Maybe<Scalars['String']['output']>
    sw_featured?: Maybe<Scalars['Int']['output']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']['output']>
    tablets?: Maybe<Scalars['String']['output']>
    tea_package?: Maybe<Scalars['String']['output']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']['output']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    top?: Maybe<Scalars['String']['output']>
    top_in_category?: Maybe<Scalars['String']['output']>
    tops?: Maybe<Scalars['Int']['output']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']['output']>
    type_of_fish?: Maybe<Scalars['Int']['output']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']['output']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updated_at?: Maybe<Scalars['String']['output']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']['output']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']['output']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']['output']>
    variants?: Maybe<Scalars['String']['output']>
    vegan?: Maybe<Scalars['Int']['output']>
    vegetarian?: Maybe<Scalars['Int']['output']>
    velkost_oblecenia?: Maybe<Scalars['String']['output']>
    velkost_sejkera?: Maybe<Scalars['String']['output']>
    visible_attributes?: Maybe<Array<Maybe<VisibleAttributes>>>
    vydanie?: Maybe<Scalars['String']['output']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']['output']>
    weight_kilograms_kg?: Maybe<Scalars['Int']['output']>
  }

/** Defines basic features of a bundle product and contains multiple BundleItems */
export type BundleProductProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines basic features of a bundle product and contains multiple BundleItems */
export type BundleProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type BundleProductCartItemInput = {
  bundle_options: Array<InputMaybe<BundleOptionInput>>
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  data: CartItemInput
}

/** Contains details about bundle products added to a requisition list */
export type BundleRequisitionListItem = RequisitionListItemInterface & {
  __typename?: 'BundleRequisitionListItem'
  /** An array of selected options for a bundle product */
  bundle_options: Array<Maybe<SelectedBundleOption>>
  /** Selected custom options for an item in the requisition list */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The product model of the requisition list item */
  product: ProductInterface
  /** The quantity of the product added to the requisition list */
  quantity: Scalars['Float']['output']
  /** The unique ID of an item in a requisition list */
  uid: Scalars['ID']['output']
}

export type BundleShipmentItem = ShipmentItemInterface & {
  __typename?: 'BundleShipmentItem'
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID']['output']
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** Sale price for the base product */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** Number of shipped items */
  quantity_shipped: Scalars['Float']['output']
}

export type BundleWishlistItem = WishlistItemInterface & {
  __typename?: 'BundleWishlistItem'
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String']['output']
  /** An array containing information about the selected bundle items */
  bundle_options?: Maybe<Array<Maybe<SelectedBundleOption>>>
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID']['output']
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  quantity: Scalars['Float']['output']
}

export type Cart = {
  __typename?: 'Cart'
  /**
   * An array of coupons that have been applied to the cart
   * @deprecated Use applied_coupons instead
   */
  applied_coupon?: Maybe<AppliedCoupon>
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code */
  applied_coupons?: Maybe<Array<Maybe<AppliedCoupon>>>
  /** Contains the code attribute, which specifies the applied gift card codes */
  applied_gift_cards?: Maybe<Array<Maybe<AppliedGiftCard>>>
  /** The amount of reward points applied to the cart */
  applied_reward_points?: Maybe<RewardPointsAmount>
  /** Contains store credit information applied on the cart */
  applied_store_credit?: Maybe<AppliedStoreCredit>
  /** The list of available gift wrapping options for the cart */
  available_gift_wrappings: Array<Maybe<GiftWrapping>>
  /** Available payment methods */
  available_payment_methods?: Maybe<Array<Maybe<AvailablePaymentMethod>>>
  billing_address?: Maybe<BillingCartAddress>
  cart_price_rules?: Maybe<Array<Maybe<CartPriceRule>>>
  email?: Maybe<Scalars['String']['output']>
  /** The entered gift message for the cart */
  gift_message?: Maybe<GiftMessage>
  /** Wether customer requested gift receipt for the cart */
  gift_receipt_included: Scalars['Boolean']['output']
  /** The selected gift wrapping for the cart */
  gift_wrapping?: Maybe<GiftWrapping>
  /** The unique ID for a `Cart` object */
  id: Scalars['ID']['output']
  is_virtual: Scalars['Boolean']['output']
  items?: Maybe<Array<Maybe<CartItemInterface>>>
  prices?: Maybe<CartPrices>
  /** Wether customer requested printed card for the cart */
  printed_card_included: Scalars['Boolean']['output']
  selected_payment_method?: Maybe<SelectedPaymentMethod>
  shipping_addresses: Array<Maybe<ShippingCartAddress>>
  total_quantity: Scalars['Float']['output']
}

export type CartAddressCountry = {
  __typename?: 'CartAddressCountry'
  code: Scalars['String']['output']
  label: Scalars['String']['output']
}

export type CartAddressInput = {
  city: Scalars['String']['input']
  company?: InputMaybe<Scalars['String']['input']>
  country_code: Scalars['String']['input']
  firstname: Scalars['String']['input']
  lastname: Scalars['String']['input']
  postcode?: InputMaybe<Scalars['String']['input']>
  region?: InputMaybe<Scalars['String']['input']>
  region_id?: InputMaybe<Scalars['Int']['input']>
  /** Determines whether to save the address in the customer's address book. The default value is true */
  save_in_address_book?: InputMaybe<Scalars['Boolean']['input']>
  street: Array<InputMaybe<Scalars['String']['input']>>
  telephone: Scalars['String']['input']
}

export type CartAddressInterface = {
  city: Scalars['String']['output']
  company?: Maybe<Scalars['String']['output']>
  country: CartAddressCountry
  firstname: Scalars['String']['output']
  lastname: Scalars['String']['output']
  postcode?: Maybe<Scalars['String']['output']>
  region?: Maybe<CartAddressRegion>
  street: Array<Maybe<Scalars['String']['output']>>
  telephone: Scalars['String']['output']
}

export type CartAddressRegion = {
  __typename?: 'CartAddressRegion'
  code?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
  region_id?: Maybe<Scalars['Int']['output']>
}

export type CartDiscount = {
  __typename?: 'CartDiscount'
  amount: Money
  label: Array<Maybe<Scalars['String']['output']>>
}

export type CartItemInput = {
  /** An array of entered options for the base product, such as personalization text */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** For child products, the SKU of its parent product */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  quantity: Scalars['Float']['input']
  rule_id?: InputMaybe<Scalars['String']['input']>
  /** The selected options for the base product, such as color or size with  unique ID for a `CustomizableRadioOption`, `CustomizableDropDownOption`, `ConfigurableProductOptionsValues`, etc. objects */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  sku: Scalars['String']['input']
}

export type CartItemInterface = {
  /** @deprecated Use `uid` instead */
  id: Scalars['String']['output']
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']['output']
  rule_id?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID']['output']
}

export type CartItemPrices = {
  __typename?: 'CartItemPrices'
  /** An array of discounts to be applied to the cart item */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** Applied FPT to the cart item. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>
  price: Money
  price_including_tax: Money
  row_total: Money
  row_total_including_tax: Money
  /** The total of all discounts applied to the item */
  total_item_discount?: Maybe<Money>
}

/** Deprecated: `cart_items` field of `ShippingCartAddress` returns now  `CartItemInterface` instead of `CartItemQuantity` */
export type CartItemQuantity = {
  __typename?: 'CartItemQuantity'
  /** @deprecated `cart_items` field of `ShippingCartAddress` returns now `CartItemInterface` instead of `CartItemQuantity` */
  cart_item_id: Scalars['Int']['output']
  /** @deprecated `cart_items` field of `ShippingCartAddress` returns now `CartItemInterface` instead of `CartItemQuantity` */
  quantity: Scalars['Float']['output']
}

export type CartItemSelectedOptionValuePrice = {
  __typename?: 'CartItemSelectedOptionValuePrice'
  type: PriceTypeEnum
  units: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type CartItemUpdateInput = {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  /** The unique ID for a `CartItemInterface` object */
  cart_item_uid?: InputMaybe<Scalars['ID']['input']>
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  /** Gift message details for the cart item */
  gift_message?: InputMaybe<GiftMessageInput>
  /** The unique ID for a `GiftWrapping` object to be used for the cart item */
  gift_wrapping_id?: InputMaybe<Scalars['ID']['input']>
  quantity?: InputMaybe<Scalars['Float']['input']>
}

export type CartPriceRule = {
  __typename?: 'CartPriceRule'
  is_applied?: Maybe<Scalars['Boolean']['output']>
  items_sku?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  rule_id?: Maybe<Scalars['Int']['output']>
  rule_type?: Maybe<Scalars['String']['output']>
}

export type CartPrices = {
  __typename?: 'CartPrices'
  applied_taxes?: Maybe<Array<Maybe<CartTaxItem>>>
  /** @deprecated Use discounts instead  */
  discount?: Maybe<CartDiscount>
  /** An array containing cart rule discounts, store credit and gift cards applied to the cart. */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The list of prices for the selected gift options */
  gift_options?: Maybe<GiftOptionsPrices>
  grand_total?: Maybe<Money>
  subtotal_excluding_tax?: Maybe<Money>
  subtotal_including_tax?: Maybe<Money>
  subtotal_with_discount_excluding_tax?: Maybe<Money>
}

export type CartTaxItem = {
  __typename?: 'CartTaxItem'
  amount: Money
  label: Scalars['String']['output']
}

/** An error encountered while adding an item to the the cart. */
export type CartUserInputError = {
  __typename?: 'CartUserInputError'
  /** Cart-specific error code */
  code: CartUserInputErrorType
  /** A localized error message */
  message: Scalars['String']['output']
}

export enum CartUserInputErrorType {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED',
}

export type CatalogSearchCategories = {
  __typename?: 'CatalogSearchCategories'
  name?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type CategoryByGoal = {
  __typename?: 'CategoryByGoal'
  category?: Maybe<CategoryInterface>
  custom_name?: Maybe<Scalars['String']['output']>
}

/** CategoryFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type CategoryFilterInput = {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the display name of the category. */
  name?: InputMaybe<FilterMatchTypeInput>
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the part of the URL that identifies the category. */
  url_key?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the URL path for the category. */
  url_path?: InputMaybe<FilterEqualTypeInput>
}

/** a */
export type CategoryFilters = {
  __typename?: 'CategoryFilters'
  code?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export type CategoryInterface = {
  allegro_type?: Maybe<Scalars['String']['output']>
  am_exclude_from_filter?: Maybe<Scalars['Int']['output']>
  automatic_sorting?: Maybe<Scalars['String']['output']>
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Breadcrumbs, parent categories info. */
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbType>>>
  canonical?: Maybe<Scalars['String']['output']>
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled */
  canonical_url?: Maybe<Scalars['String']['output']>
  cate_landing_img?: Maybe<Scalars['String']['output']>
  /** Search for products in a Category using Indexed data (faster than regular search) */
  categorySearch?: Maybe<CategorySearchProducts>
  category_full_width?: Maybe<Scalars['Int']['output']>
  children_count?: Maybe<Scalars['String']['output']>
  /** Category CMS Block. */
  cms_block?: Maybe<CmsBlock>
  /**
   * Timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']['output']>
  custom_layout_update_file?: Maybe<Scalars['String']['output']>
  custom_url?: Maybe<Scalars['String']['output']>
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']['output']>
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']['output']>
  display_main_navigation?: Maybe<Scalars['Int']['output']>
  display_mode?: Maybe<Scalars['String']['output']>
  display_side_navigation?: Maybe<Scalars['Int']['output']>
  filter_price_range?: Maybe<Scalars['Float']['output']>
  hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use the `uid` argument instead.
   */
  id?: Maybe<Scalars['Int']['output']>
  image?: Maybe<Scalars['String']['output']>
  import_list?: Maybe<Scalars['String']['output']>
  include_in_menu?: Maybe<Scalars['Int']['output']>
  indexable_attributes?: Maybe<Scalars['String']['output']>
  is_anchor?: Maybe<Scalars['Int']['output']>
  is_builder_account?: Maybe<Scalars['Int']['output']>
  is_landing?: Maybe<Scalars['Int']['output']>
  landing_page?: Maybe<Scalars['Int']['output']>
  lang?: Maybe<Scalars['String']['output']>
  /** Indicates the depth of the category within the tree. */
  level?: Maybe<Scalars['Int']['output']>
  meta_description?: Maybe<Scalars['String']['output']>
  meta_keywords?: Maybe<Scalars['String']['output']>
  meta_robots?: Maybe<Scalars['String']['output']>
  meta_title?: Maybe<Scalars['String']['output']>
  mgs_megamenu_item_background?: Maybe<Scalars['String']['output']>
  mgs_megamenu_item_label?: Maybe<Scalars['String']['output']>
  mgs_thumbnail?: Maybe<Scalars['String']['output']>
  minimum_product_margin?: Maybe<Scalars['Float']['output']>
  /** The display name of the category. */
  name?: Maybe<Scalars['String']['output']>
  og_properties?: Maybe<Array<Maybe<OgProperties>>>
  /** Category Path. */
  path?: Maybe<Scalars['String']['output']>
  /** Category path in store. */
  path_in_store?: Maybe<Scalars['String']['output']>
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']['output']>
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  product_count?: Maybe<Scalars['Int']['output']>
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>
  rel_navigation?: Maybe<RelNavigation>
  short_description?: Maybe<Scalars['String']['output']>
  staged: Scalars['Boolean']['output']
  thumbnail?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  /** The url key assigned to the category. */
  url_key?: Maybe<Scalars['String']['output']>
  /** The url path assigned to the category. */
  url_path?: Maybe<Scalars['String']['output']>
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']['output']>
  visible_tiktok?: Maybe<Scalars['Boolean']['output']>
}

/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceCategorySearchArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<CategorySearchFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<CategorySearchSort>
}

/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceProductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<ProductAttributeSortInput>
}

/** The category products object returned in the Category query. */
export type CategoryProducts = {
  __typename?: 'CategoryProducts'
  /** An array of products that are assigned to the category. */
  items?: Maybe<Array<Maybe<ProductInterface>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  total_count?: Maybe<Scalars['Int']['output']>
}

/** A collection of CategoryTree objects and pagination information. */
export type CategoryResult = {
  __typename?: 'CategoryResult'
  /** A list of categories that match the filter criteria. */
  items?: Maybe<Array<Maybe<CategoryTree>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>
  /** The total number of categories that match the criteria. */
  total_count?: Maybe<Scalars['Int']['output']>
}

export type CategorySearchAmLabel = {
  __typename?: 'CategorySearchAmLabel'
  image?: Maybe<Scalars['String']['output']>
  image_size?: Maybe<Scalars['String']['output']>
  label_text?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  style?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['Int']['output']>
}

export type CategorySearchFilter = {
  __typename?: 'CategorySearchFilter'
  code?: Maybe<Scalars['String']['output']>
  display_mode?: Maybe<Scalars['Int']['output']>
  global_name?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  options?: Maybe<Array<Maybe<CategorySearchFilterOption>>>
  position?: Maybe<Scalars['Int']['output']>
  range?: Maybe<PriceRange>
  type?: Maybe<Scalars['String']['output']>
}

/** a */
export type CategorySearchFilterInput = {
  /** Filter items by 'is_saleable' item attribute */
  is_saleable?: InputMaybe<Scalars['Boolean']['input']>
  /** Filter by option code and value */
  options?: InputMaybe<Array<InputMaybe<CategorySearchFilterInputItem>>>
  /** Filter by price range */
  price?: InputMaybe<FilterRangeTypeInput>
}

/** b */
export type CategorySearchFilterInputItem = {
  /** Filter by option code and value */
  code?: InputMaybe<Scalars['String']['input']>
  /** Filter by price range */
  value?: InputMaybe<FilterEqualTypeInput>
}

export type CategorySearchFilterOption = {
  __typename?: 'CategorySearchFilterOption'
  count?: Maybe<Scalars['Int']['output']>
  indexable?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  used?: Maybe<Scalars['Boolean']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type CategorySearchProduct = {
  __typename?: 'CategorySearchProduct'
  am_labels?: Maybe<Array<Maybe<CategorySearchAmLabel>>>
  /** Breadcrumbs to product. */
  breadcrumbs_en?: Maybe<Scalars['String']['output']>
  configurable_sku?: Maybe<Scalars['String']['output']>
  default_category?: Maybe<IndexedEntity>
  formatted_price?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  image?: Maybe<Scalars['String']['output']>
  /** Determinate if 'From Price' can be shown */
  is_price_range?: Maybe<Scalars['Boolean']['output']>
  is_saleable?: Maybe<Scalars['String']['output']>
  is_single_variant?: Maybe<Scalars['String']['output']>
  manufacturer?: Maybe<IndexedEntity>
  name?: Maybe<Scalars['String']['output']>
  price?: Maybe<Scalars['Float']['output']>
  /** Product Labels list */
  product_labels?: Maybe<Array<Maybe<Label>>>
  product_url?: Maybe<Scalars['String']['output']>
  rating_summary?: Maybe<Scalars['String']['output']>
  reviews_count?: Maybe<Scalars['String']['output']>
  single_variant_sku?: Maybe<Scalars['String']['output']>
  skus?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  small_image?: Maybe<Scalars['String']['output']>
  thumbnail?: Maybe<Scalars['String']['output']>
}

export type CategorySearchProducts = {
  __typename?: 'CategorySearchProducts'
  /** An array of filters that can be used to filter the search results. */
  availableFilters?: Maybe<Array<Maybe<CategorySearchFilter>>>
  /** Category Breadcrumbs */
  breadcrumbs?: Maybe<Scalars['String']['output']>
  /** Full text search Categoires result */
  categories?: Maybe<Array<Maybe<CatalogSearchCategories>>>
  description?: Maybe<Scalars['String']['output']>
  filters?: Maybe<Array<Maybe<CategoryFilters>>>
  /** An array of products that match the specified filters. */
  items?: Maybe<Array<Maybe<CategorySearchProduct>>>
  meta?: Maybe<MetaData>
  name?: Maybe<Scalars['String']['output']>
  /** Pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>
  params?: Maybe<Array<Maybe<CategoryFilters>>>
  short_description?: Maybe<Scalars['String']['output']>
  total_count?: Maybe<Scalars['Int']['output']>
}

/** Sort by */
export type CategorySearchSort = {
  /** Sort by bestseller */
  bestseller?: InputMaybe<SortEnum>
  /** Sort by date added */
  new?: InputMaybe<SortEnum>
  /** Sort by price */
  price?: InputMaybe<SortEnum>
  /** Sort by rating summary */
  rating_summary?: InputMaybe<SortEnum>
  /** Sort by reviews count */
  reviews_count?: InputMaybe<SortEnum>
  /** Sort by saving */
  saving?: InputMaybe<SortEnum>
}

/** Category tree implementation */
export type CategoryTree = CategoryInterface &
  RoutableInterface & {
    __typename?: 'CategoryTree'
    allegro_type?: Maybe<Scalars['String']['output']>
    am_exclude_from_filter?: Maybe<Scalars['Int']['output']>
    automatic_sorting?: Maybe<Scalars['String']['output']>
    available_sort_by?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** Breadcrumbs, parent categories info. */
    breadcrumbs?: Maybe<Array<Maybe<BreadcrumbType>>>
    canonical?: Maybe<Scalars['String']['output']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled */
    canonical_url?: Maybe<Scalars['String']['output']>
    cate_landing_img?: Maybe<Scalars['String']['output']>
    /** Search for products in a Category using Indexed data (faster than regular search) */
    categorySearch?: Maybe<CategorySearchProducts>
    category_full_width?: Maybe<Scalars['Int']['output']>
    /** Child categories tree. */
    children?: Maybe<Array<Maybe<CategoryTree>>>
    children_count?: Maybe<Scalars['String']['output']>
    /** Category CMS Block. */
    cms_block?: Maybe<CmsBlock>
    /**
     * Timestamp indicating when the category was created.
     * @deprecated The field should not be used on the storefront.
     */
    created_at?: Maybe<Scalars['String']['output']>
    custom_layout_update_file?: Maybe<Scalars['String']['output']>
    custom_url?: Maybe<Scalars['String']['output']>
    /** The attribute to use for sorting. */
    default_sort_by?: Maybe<Scalars['String']['output']>
    /** An optional description of the category. */
    description?: Maybe<Scalars['String']['output']>
    display_main_navigation?: Maybe<Scalars['Int']['output']>
    display_mode?: Maybe<Scalars['String']['output']>
    display_side_navigation?: Maybe<Scalars['Int']['output']>
    filter_price_range?: Maybe<Scalars['Float']['output']>
    hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
    /**
     * An ID that uniquely identifies the category.
     * @deprecated Use the `uid` argument instead.
     */
    id?: Maybe<Scalars['Int']['output']>
    image?: Maybe<Scalars['String']['output']>
    import_list?: Maybe<Scalars['String']['output']>
    include_in_menu?: Maybe<Scalars['Int']['output']>
    indexable_attributes?: Maybe<Scalars['String']['output']>
    is_anchor?: Maybe<Scalars['Int']['output']>
    is_builder_account?: Maybe<Scalars['Int']['output']>
    is_landing?: Maybe<Scalars['Int']['output']>
    landing_page?: Maybe<Scalars['Int']['output']>
    lang?: Maybe<Scalars['String']['output']>
    /** Indicates the depth of the category within the tree. */
    level?: Maybe<Scalars['Int']['output']>
    meta_description?: Maybe<Scalars['String']['output']>
    meta_keywords?: Maybe<Scalars['String']['output']>
    meta_robots?: Maybe<Scalars['String']['output']>
    meta_title?: Maybe<Scalars['String']['output']>
    mgs_megamenu_item_background?: Maybe<Scalars['String']['output']>
    mgs_megamenu_item_label?: Maybe<Scalars['String']['output']>
    mgs_thumbnail?: Maybe<Scalars['String']['output']>
    minimum_product_margin?: Maybe<Scalars['Float']['output']>
    /** The display name of the category. */
    name?: Maybe<Scalars['String']['output']>
    og_properties?: Maybe<Array<Maybe<OgProperties>>>
    /** Category Path. */
    path?: Maybe<Scalars['String']['output']>
    /** Category path in store. */
    path_in_store?: Maybe<Scalars['String']['output']>
    /** The position of the category relative to other categories at the same level in tree. */
    position?: Maybe<Scalars['Int']['output']>
    /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
    product_count?: Maybe<Scalars['Int']['output']>
    /** The list of products assigned to the category. */
    products?: Maybe<CategoryProducts>
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
    redirect_code: Scalars['Int']['output']
    rel_navigation?: Maybe<RelNavigation>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
    relative_url?: Maybe<Scalars['String']['output']>
    short_description?: Maybe<Scalars['String']['output']>
    staged: Scalars['Boolean']['output']
    thumbnail?: Maybe<Scalars['String']['output']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<UrlRewriteEntityTypeEnum>
    /** The unique ID for a `CategoryInterface` object. */
    uid: Scalars['ID']['output']
    /**
     * Timestamp indicating when the category was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updated_at?: Maybe<Scalars['String']['output']>
    url?: Maybe<Scalars['String']['output']>
    /** The url key assigned to the category. */
    url_key?: Maybe<Scalars['String']['output']>
    /** The url path assigned to the category. */
    url_path?: Maybe<Scalars['String']['output']>
    /** The part of the category URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']['output']>
    visible_tiktok?: Maybe<Scalars['Boolean']['output']>
  }

/** Category tree implementation */
export type CategoryTreeCategorySearchArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<CategorySearchFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<CategorySearchSort>
}

/** Category tree implementation */
export type CategoryTreeProductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<ProductAttributeSortInput>
}

/** Defines all Checkout Agreement information */
export type CheckoutAgreement = {
  __typename?: 'CheckoutAgreement'
  /** Checkout Agreement identifier */
  agreement_id: Scalars['Int']['output']
  /** Checkout Agreement checkbox text */
  checkbox_text: Scalars['String']['output']
  /** Checkout Agreement content */
  content: Scalars['String']['output']
  /** Checkout Agreement content height */
  content_height?: Maybe<Scalars['String']['output']>
  /** Is Checkout Agreement content in HTML format */
  is_html: Scalars['Boolean']['output']
  mode: CheckoutAgreementMode
  /** Checkout Agreement name */
  name: Scalars['String']['output']
}

export enum CheckoutAgreementMode {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

export type CheckoutCurrency = {
  __typename?: 'CheckoutCurrency'
  format: Scalars['String']['output']
  rate: Scalars['Float']['output']
}

/** An error encountered while adding an item the the cart. */
export type CheckoutUserInputError = {
  __typename?: 'CheckoutUserInputError'
  /** Checkout-specific error code */
  code: CheckoutUserInputErrorCodes
  /** Localized error message */
  message: Scalars['String']['output']
  /** Path to the input field that caused an error. See the GraphQL specification about path errors for details: http://spec.graphql.org/draft/#sec-Errors */
  path: Array<Maybe<Scalars['String']['output']>>
}

export enum CheckoutUserInputErrorCodes {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  ReorderNotAvailable = 'REORDER_NOT_AVAILABLE',
  Undefined = 'UNDEFINED',
}

export type ClearCustomerCartOutput = {
  __typename?: 'ClearCustomerCartOutput'
  /** The cart after clearing items */
  cart?: Maybe<Cart>
  /** Indicates whether cart was cleared */
  status: Scalars['Boolean']['output']
}

export type CloseNegotiableQuotesInput = {
  /** A list of unique IDs from `NegotiableQuote` objects */
  quote_uids: Array<InputMaybe<Scalars['ID']['input']>>
}

export type CloseNegotiableQuotesOutput = {
  __typename?: 'CloseNegotiableQuotesOutput'
  /** An array containing the negotiable quotes that were just closed */
  closed_quotes?: Maybe<Array<Maybe<NegotiableQuote>>>
  /** A list of negotiable quotes that can be viewed by the logged-in customer */
  negotiable_quotes?: Maybe<NegotiableQuotesOutput>
}

export type CloseNegotiableQuotesOutputNegotiable_QuotesArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<NegotiableQuoteFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<NegotiableQuoteSortInput>
}

/** CMS block defines all CMS block information */
export type CmsBlock = {
  __typename?: 'CmsBlock'
  /** CMS block content */
  content?: Maybe<Scalars['String']['output']>
  /** CMS block identifier */
  identifier?: Maybe<Scalars['String']['output']>
  /** CMS block title */
  title?: Maybe<Scalars['String']['output']>
}

/** CMS blocks information */
export type CmsBlocks = {
  __typename?: 'CmsBlocks'
  /** An array of CMS blocks */
  items?: Maybe<Array<Maybe<CmsBlock>>>
}

/** CMS page defines all CMS page information */
export type CmsPage = RoutableInterface & {
  __typename?: 'CmsPage'
  canonical?: Maybe<Scalars['String']['output']>
  /** CMS page content */
  content?: Maybe<Scalars['String']['output']>
  /** CMS page content heading */
  content_heading?: Maybe<Scalars['String']['output']>
  hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
  /** Identifier of the CMS page */
  identifier?: Maybe<Scalars['String']['output']>
  /** CMS page meta description */
  meta_description?: Maybe<Scalars['String']['output']>
  /** CMS page meta keywords */
  meta_keywords?: Maybe<Scalars['String']['output']>
  /** CMS page meta title */
  meta_title?: Maybe<Scalars['String']['output']>
  /** CMS page content heading */
  page_layout?: Maybe<Scalars['String']['output']>
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']['output']>
  /** CMS page title */
  title?: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>
  /** URL key of CMS page */
  url_key?: Maybe<Scalars['String']['output']>
}

export type ColorSwatchData = SwatchDataInterface & {
  __typename?: 'ColorSwatchData'
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']['output']>
}

/** Defines the output schema for a company */
export type Company = {
  __typename?: 'Company'
  /** Returns the list of all resources defined within the company */
  acl_resources?: Maybe<Array<Maybe<CompanyAclResource>>>
  /** An object containing information about the company administrator */
  company_admin?: Maybe<Customer>
  /** Company credit balance */
  credit: CompanyCredit
  /** Company credit operations history */
  credit_history: CompanyCreditHistory
  /** The email address of the company contact */
  email?: Maybe<Scalars['String']['output']>
  /** The unique ID of a `Company` object */
  id: Scalars['ID']['output']
  /** The address where the company is registered to conduct business */
  legal_address?: Maybe<CompanyLegalAddress>
  /** The full legal name of the company */
  legal_name?: Maybe<Scalars['String']['output']>
  /** The name of the company */
  name?: Maybe<Scalars['String']['output']>
  /** The list of payment methods available to a company */
  payment_methods?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The resale number that is assigned to the company for tax reporting purposes */
  reseller_id?: Maybe<Scalars['String']['output']>
  /** Returns a company role filtered by the unique ID for a `CompanyRole` object */
  role?: Maybe<CompanyRole>
  /** Returns the list of company roles */
  roles: CompanyRoles
  /** An object containing information about the company sales representative */
  sales_representative?: Maybe<CompanySalesRepresentative>
  /** Returns the company structure of teams and customers in depth-first order */
  structure?: Maybe<CompanyStructure>
  /** Returns company team data filtered by the unique ID for a `CompanyTeam` object */
  team?: Maybe<CompanyTeam>
  /** Returns a company user filtered by the unique ID for a `Customer` object */
  user?: Maybe<Customer>
  /** Returns a list of company users based on activity status */
  users?: Maybe<CompanyUsers>
  /** The value-added tax number that is assigned to the company by some jurisdictions for tax reporting purposes */
  vat_tax_id?: Maybe<Scalars['String']['output']>
}

/** Defines the output schema for a company */
export type CompanyCredit_HistoryArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<CompanyCreditHistoryFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the output schema for a company */
export type CompanyRoleArgs = {
  id: Scalars['ID']['input']
}

/** Defines the output schema for a company */
export type CompanyRolesArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the output schema for a company */
export type CompanyStructureArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>
  rootId?: InputMaybe<Scalars['ID']['input']>
}

/** Defines the output schema for a company */
export type CompanyTeamArgs = {
  id: Scalars['ID']['input']
}

/** Defines the output schema for a company */
export type CompanyUserArgs = {
  id: Scalars['ID']['input']
}

/** Defines the output schema for a company */
export type CompanyUsersArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<CompanyUsersFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains details about a `CompanyAclResource` object */
export type CompanyAclResource = {
  __typename?: 'CompanyAclResource'
  /** An array of sub-resources */
  children?: Maybe<Array<Maybe<CompanyAclResource>>>
  /** The unique ID for a `CompanyAclResource` object */
  id: Scalars['ID']['output']
  /** The sort order of an ACL resource */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The label assigned to the ACL resource */
  text?: Maybe<Scalars['String']['output']>
}

/** Contains details about the company administrator */
export type CompanyAdmin = {
  __typename?: 'CompanyAdmin'
  /** The email address of the company administrator */
  email?: Maybe<Scalars['String']['output']>
  /** The company administrator's first name */
  firstname?: Maybe<Scalars['String']['output']>
  /** The company administrator's gender (Male - 1, Female - 2, Not Specified - 3) */
  gender?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CompanyAdmin` object */
  id: Scalars['ID']['output']
  /** The job title of the company administrator */
  job_title?: Maybe<Scalars['String']['output']>
  /** The company administrator's last name */
  lastname?: Maybe<Scalars['String']['output']>
}

/** Defines the input schema for creating a company administrator */
export type CompanyAdminInput = {
  /** The email address of the company administrator */
  email: Scalars['String']['input']
  /** The company administrator's first name */
  firstname: Scalars['String']['input']
  /** The company administrator's gender (Male - 1, Female - 2, Not Specified - 3) */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** The job title of the company administrator */
  job_title?: InputMaybe<Scalars['String']['input']>
  /** The company administrator's last name */
  lastname: Scalars['String']['input']
}

/** Defines the input schema for creating a new company */
export type CompanyCreateInput = {
  /** Defines the company administrator */
  company_admin: CompanyAdminInput
  /** The email address of the company contact */
  company_email: Scalars['String']['input']
  /** The name of the company to create */
  company_name: Scalars['String']['input']
  /** Defines legal address data of the company */
  legal_address: CompanyLegalAddressCreateInput
  /** The full legal name of the company */
  legal_name?: InputMaybe<Scalars['String']['input']>
  /** The resale number that is assigned to the company for tax reporting purposes */
  reseller_id?: InputMaybe<Scalars['String']['input']>
  /** The value-added tax number that is assigned to the company by some jurisdictions for tax reporting purposes */
  vat_tax_id?: InputMaybe<Scalars['String']['input']>
}

export type CompanyCredit = {
  __typename?: 'CompanyCredit'
  /** Available company credit */
  available_credit: Money
  /** Company credit limit */
  credit_limit: Money
  /** Outstanding company credit */
  outstanding_balance: Money
}

export type CompanyCreditHistory = {
  __typename?: 'CompanyCreditHistory'
  /** An array of company credit operations */
  items: Array<Maybe<CompanyCreditOperation>>
  /** Metadata for pagination rendering */
  page_info: SearchResultPageInfo
  /** The number of the company credit operations matching the specified filter */
  total_count?: Maybe<Scalars['Int']['output']>
}

export type CompanyCreditHistoryFilterInput = {
  /** Free text filter by the purchase order number associated with the company credit operation */
  custom_reference_number?: InputMaybe<Scalars['String']['input']>
  /** Enum filter by the type of the company credit operation */
  operation_type?: InputMaybe<CompanyCreditOperationType>
  /** Free text filter by the name of the person submitting the company credit operation */
  updated_by?: InputMaybe<Scalars['String']['input']>
}

export type CompanyCreditOperation = {
  __typename?: 'CompanyCreditOperation'
  /** The amount of the company credit operation */
  amount?: Maybe<Money>
  /** Credit balance after the company credit operation */
  balance: CompanyCredit
  /** Purchase order number associated with the company credit operation */
  custom_reference_number?: Maybe<Scalars['String']['output']>
  /** The date the operation was performed */
  date: Scalars['String']['output']
  /** The type of the company credit operation */
  type: CompanyCreditOperationType
  /** The company user submitting the company credit operation */
  updated_by: CompanyCreditOperationUser
}

export enum CompanyCreditOperationType {
  Allocation = 'ALLOCATION',
  Purchase = 'PURCHASE',
  Refund = 'REFUND',
  Reimbursement = 'REIMBURSEMENT',
  Revert = 'REVERT',
  Update = 'UPDATE',
}

export type CompanyCreditOperationUser = {
  __typename?: 'CompanyCreditOperationUser'
  /** The name of the company user submitting the company credit operation */
  name: Scalars['String']['output']
  /** The type of the company user submitting the company credit operation */
  type: CompanyCreditOperationUserType
}

export enum CompanyCreditOperationUserType {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
}

/** Contains details about the address where the company is registered to conduct business */
export type CompanyLegalAddress = {
  __typename?: 'CompanyLegalAddress'
  /** The city where the company is registered to conduct business */
  city?: Maybe<Scalars['String']['output']>
  /** The country code of the company's legal address */
  country_code?: Maybe<CountryCodeEnum>
  /** The company's postal code */
  postcode?: Maybe<Scalars['String']['output']>
  /** An object containing region data for the company */
  region?: Maybe<CustomerAddressRegion>
  /** An array of strings that define the company's street address */
  street?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The company's phone number */
  telephone?: Maybe<Scalars['String']['output']>
}

/** Defines the input schema for defining a company's legal address */
export type CompanyLegalAddressCreateInput = {
  /** The city where the company is registered to conduct business */
  city: Scalars['String']['input']
  /** The company's country ID. Use the 'countries' query to get this value */
  country_id: CountryCodeEnum
  /** The postal code of the company */
  postcode: Scalars['String']['input']
  /** An object containing the region name and/or region ID where the company is registered to conduct business */
  region: CustomerAddressRegionInput
  /** An array of strings that define the street address where the company is registered to conduct business */
  street: Array<InputMaybe<Scalars['String']['input']>>
  /** The primary phone number of the company */
  telephone: Scalars['String']['input']
}

/** Defines the input schema for updating a company's legal address */
export type CompanyLegalAddressUpdateInput = {
  /** CThe city where the company is registered to conduct business */
  city?: InputMaybe<Scalars['String']['input']>
  /** The unique ID for a `Country` object */
  country_id?: InputMaybe<CountryCodeEnum>
  /** The postal code of the company */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An object containing the region name and/or region ID where the company is registered to conduct business */
  region?: InputMaybe<CustomerAddressRegionInput>
  /** An array of strings that define the street address where the company is registered to conduct business */
  street?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The primary phone number of the company */
  telephone?: InputMaybe<Scalars['String']['input']>
}

/** Contails details about a single role */
export type CompanyRole = {
  __typename?: 'CompanyRole'
  /** The unique ID for a `CompanyRole` object */
  id: Scalars['ID']['output']
  /** The name assigned to the role */
  name?: Maybe<Scalars['String']['output']>
  /** A list of permission resources defined for a role */
  permissions?: Maybe<Array<Maybe<CompanyAclResource>>>
  /** The total number of users assigned the specified role */
  users_count?: Maybe<Scalars['Int']['output']>
}

/** Defines the input schema for creating a company role */
export type CompanyRoleCreateInput = {
  /** The name of the role to create */
  name: Scalars['String']['input']
  /** A list of resources the role can access */
  permissions: Array<InputMaybe<Scalars['String']['input']>>
}

/** Defines the input schema for updating a ompany role */
export type CompanyRoleUpdateInput = {
  /** The unique ID for a `CompanyRole` object */
  id: Scalars['ID']['input']
  /** The name of the role to update */
  name?: InputMaybe<Scalars['String']['input']>
  /** A list of resources the role can access */
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Contains an array of roles */
export type CompanyRoles = {
  __typename?: 'CompanyRoles'
  /** A list of company roles that match the specified filter criteria */
  items: Array<Maybe<CompanyRole>>
  /** Pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>
  /** The total number of objects matching the specified filter */
  total_count: Scalars['Int']['output']
}

/** Contains details about a company sales representative */
export type CompanySalesRepresentative = {
  __typename?: 'CompanySalesRepresentative'
  /** The email address of the company sales representative */
  email?: Maybe<Scalars['String']['output']>
  /** The company sales representative's first name */
  firstname?: Maybe<Scalars['String']['output']>
  /** The company sales representative's last name */
  lastname?: Maybe<Scalars['String']['output']>
}

/** Contains an array of the individual nodes that comprise the company structure */
export type CompanyStructure = {
  __typename?: 'CompanyStructure'
  /** An array of elements in a company structure */
  items?: Maybe<Array<Maybe<CompanyStructureItem>>>
}

export type CompanyStructureEntity = CompanyTeam | Customer

/** Defines an individual node in the company structure */
export type CompanyStructureItem = {
  __typename?: 'CompanyStructureItem'
  /** A union of `CompanyTeam` and `Customer` objects */
  entity?: Maybe<CompanyStructureEntity>
  /** The unique ID for a `CompanyStructureItem` object */
  id: Scalars['ID']['output']
  /** The ID of the parent item in the company hierarchy */
  parent_id?: Maybe<Scalars['ID']['output']>
}

/** Defines the input schema for updating the company structure */
export type CompanyStructureUpdateInput = {
  /** The ID of a company that will be the new parent */
  parent_tree_id: Scalars['ID']['input']
  /** The ID of the company team that is being moved to another parent */
  tree_id: Scalars['ID']['input']
}

/** Describes a company team */
export type CompanyTeam = {
  __typename?: 'CompanyTeam'
  /** An optional description of the team */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CompanyTeam` object */
  id: Scalars['ID']['output']
  /** The display name of the team */
  name?: Maybe<Scalars['String']['output']>
}

/** Defines the input schema for creating a company team */
export type CompanyTeamCreateInput = {
  /** An optional description of the team */
  description?: InputMaybe<Scalars['String']['input']>
  /** The display name of the team */
  name: Scalars['String']['input']
  /** The ID of a node within a company's structure. This ID will be the parent of the created team */
  target_id?: InputMaybe<Scalars['ID']['input']>
}

/** Defines the input schema for updating a company team */
export type CompanyTeamUpdateInput = {
  /** An optional description of the team */
  description?: InputMaybe<Scalars['String']['input']>
  /** The unique ID of the `CompanyTeam` object to update */
  id: Scalars['ID']['input']
  /** The display name of the team */
  name?: InputMaybe<Scalars['String']['input']>
}

/** Defines the Company input schema for updating a company */
export type CompanyUpdateInput = {
  /** The email address of the company contact */
  company_email?: InputMaybe<Scalars['String']['input']>
  /** The name of the company to update */
  company_name?: InputMaybe<Scalars['String']['input']>
  /** Defines the legal address data of the company */
  legal_address?: InputMaybe<CompanyLegalAddressUpdateInput>
  /** The full legal name of the company */
  legal_name?: InputMaybe<Scalars['String']['input']>
  /** The resale number that is assigned to the company for tax reporting purposes */
  reseller_id?: InputMaybe<Scalars['String']['input']>
  /** The value-added tax number that is assigned to the company by some jurisdictions for tax reporting purposes */
  vat_tax_id?: InputMaybe<Scalars['String']['input']>
}

/** Defines the input schema for creating a company user */
export type CompanyUserCreateInput = {
  /** The company user's email address */
  email: Scalars['String']['input']
  /** The company user's first name */
  firstname: Scalars['String']['input']
  /** The company user's job title or function */
  job_title: Scalars['String']['input']
  /** The company user's last name */
  lastname: Scalars['String']['input']
  /** The unique ID for a `CompanyRole` object */
  role_id: Scalars['ID']['input']
  /** Indicates whether the company user is ACTIVE or INACTIVE */
  status: CompanyUserStatusEnum
  /** The ID of a node within a company's structure. This ID will be the parent of the created company user */
  target_id?: InputMaybe<Scalars['ID']['input']>
  /** The company user's phone number */
  telephone: Scalars['String']['input']
}

/** Defines the list of company user status values */
export enum CompanyUserStatusEnum {
  /** Only active users */
  Active = 'ACTIVE',
  /** Only inactive users */
  Inactive = 'INACTIVE',
}

/** Defines the input schema for updating a company user */
export type CompanyUserUpdateInput = {
  /** The company user's email address */
  email?: InputMaybe<Scalars['String']['input']>
  /** The company user's first name */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The unique ID of a `Customer` object */
  id: Scalars['ID']['input']
  /** The company user's job title or function */
  job_title?: InputMaybe<Scalars['String']['input']>
  /** The company user's last name */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The unique ID for a `CompanyRole` object */
  role_id?: InputMaybe<Scalars['ID']['input']>
  /** Indicates whether the company user is ACTIVE or INACTIVE */
  status?: InputMaybe<CompanyUserStatusEnum>
  /** The company user's phone number */
  telephone?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about company users */
export type CompanyUsers = {
  __typename?: 'CompanyUsers'
  /** An array of 'CompanyUser' objects that match the specified filter criteria */
  items: Array<Maybe<Customer>>
  /** Pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>
  /** The number of objects returned */
  total_count: Scalars['Int']['output']
}

/** Defines the filter for returning a list of company users */
export type CompanyUsersFilterInput = {
  /** Filters company users by their activity status */
  status?: InputMaybe<CompanyUserStatusEnum>
}

export type ComparableAttribute = {
  __typename?: 'ComparableAttribute'
  /** An attribute code that is enabled for product comparisons */
  code: Scalars['String']['output']
  /** The label of the attribute code */
  label: Scalars['String']['output']
}

export type ComparableItem = {
  __typename?: 'ComparableItem'
  /** An array of product attributes that can be used to compare products */
  attributes: Array<Maybe<ProductAttribute>>
  /** Contains details about a product in a compare list */
  product: ProductInterface
  /** The unique ID of an item in a compare list */
  uid: Scalars['ID']['output']
}

export type CompareList = {
  __typename?: 'CompareList'
  /** An array of attributes that can be used for comparing products */
  attributes?: Maybe<Array<Maybe<ComparableAttribute>>>
  /** The number of items in the compare list */
  item_count: Scalars['Int']['output']
  /** An array of products to compare */
  items?: Maybe<Array<Maybe<ComparableItem>>>
  /** The unique ID assigned to the compare list */
  uid: Scalars['ID']['output']
}

export type CompariType = {
  __typename?: 'CompariType'
  api_key?: Maybe<Scalars['String']['output']>
  is_active?: Maybe<Scalars['Boolean']['output']>
  script_src?: Maybe<Scalars['String']['output']>
}

export type ComplexTextValue = {
  __typename?: 'ComplexTextValue'
  /** HTML format */
  html?: Maybe<Scalars['String']['output']>
}

/** ConfigurableAttributeOption contains the value_index (and other related information) assigned to a configurable product option */
export type ConfigurableAttributeOption = {
  __typename?: 'ConfigurableAttributeOption'
  /** The ID assigned to the attribute */
  code?: Maybe<Scalars['String']['output']>
  /** A string that describes the configurable attribute option */
  label?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ConfigurableAttributeOption` object */
  uid: Scalars['ID']['output']
  /** A unique index number assigned to the configurable product option */
  value_index?: Maybe<Scalars['Int']['output']>
}

export type ConfigurableCartItem = CartItemInterface & {
  __typename?: 'ConfigurableCartItem'
  /** The list of available gift wrapping options for the cart item */
  available_gift_wrapping: Array<Maybe<GiftWrapping>>
  configurable_options: Array<Maybe<SelectedConfigurableOption>>
  /** Product details of the cart item */
  configured_variant: ProductInterface
  customizable_options?: Maybe<Array<Maybe<SelectedCustomizableOption>>>
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>
  /** The selected gift wrapping for the cart item */
  gift_wrapping?: Maybe<GiftWrapping>
  /** @deprecated Use `uid` instead */
  id: Scalars['String']['output']
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']['output']
  rule_id?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID']['output']
}

/** Configurable option available for further selection based on current selection. */
export type ConfigurableOptionAvailableForSelection = {
  __typename?: 'ConfigurableOptionAvailableForSelection'
  /** Attribute code that uniquely identifies configurable option. */
  attribute_code: Scalars['String']['output']
  /** Configurable option values available for further selection. */
  option_value_uids: Array<Maybe<Scalars['ID']['output']>>
}

/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProduct = CustomizableProductInterface &
  PhysicalProductInterface &
  ProductInterface &
  RoutableInterface & {
    __typename?: 'ConfigurableProduct'
    allergen_celery?: Maybe<Scalars['Int']['output']>
    allergen_crustaceans?: Maybe<Scalars['Int']['output']>
    allergen_eggs?: Maybe<Scalars['Int']['output']>
    allergen_fish?: Maybe<Scalars['Int']['output']>
    allergen_gluten?: Maybe<Scalars['Int']['output']>
    allergen_lupin?: Maybe<Scalars['Int']['output']>
    allergen_milk?: Maybe<Scalars['Int']['output']>
    allergen_molluscs?: Maybe<Scalars['Int']['output']>
    allergen_mustard?: Maybe<Scalars['Int']['output']>
    allergen_peanuts?: Maybe<Scalars['Int']['output']>
    allergen_sesame?: Maybe<Scalars['Int']['output']>
    allergen_soya?: Maybe<Scalars['Int']['output']>
    allergen_sulphites?: Maybe<Scalars['Int']['output']>
    allergen_tree_nuts?: Maybe<Scalars['Int']['output']>
    application?: Maybe<Scalars['Int']['output']>
    artificial_sweetener_free?: Maybe<Scalars['Int']['output']>
    aspartame_free?: Maybe<Scalars['Int']['output']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attribute_set_id?: Maybe<Scalars['Int']['output']>
    available_for_sdd?: Maybe<Scalars['String']['output']>
    bag_volume_range?: Maybe<Scalars['Int']['output']>
    bio?: Maybe<Scalars['Int']['output']>
    blend?: Maybe<Scalars['Int']['output']>
    bottom?: Maybe<Scalars['String']['output']>
    breadcrumb?: Maybe<Array<Maybe<BreadcrumbType>>>
    breadcrumb_en?: Maybe<Scalars['String']['output']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']['output']>
    capsules?: Maybe<Scalars['String']['output']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    color?: Maybe<Scalars['String']['output']>
    colors?: Maybe<Scalars['String']['output']>
    /** An array of linked simple product items */
    configurable_options?: Maybe<Array<Maybe<ConfigurableProductOptions>>>
    /** Specified configurable product options selection */
    configurable_product_options_selection?: Maybe<ConfigurableProductOptionsSelection>
    /** An array of variants of products */
    configurable_variants?: Maybe<Array<Maybe<ConfigurableVariant>>>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']['output']>
    coupon_amount?: Maybe<Scalars['Int']['output']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    created_at?: Maybe<Scalars['String']['output']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    default_category?: Maybe<Scalars['String']['output']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    ean?: Maybe<Scalars['String']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    estimated_date_of_arrival?: Maybe<EstimatedDateOfArrivalType>
    expected_arrival_date?: Maybe<Scalars['String']['output']>
    farba_oblecenia?: Maybe<Scalars['String']['output']>
    farba_sejkera?: Maybe<Scalars['String']['output']>
    fit?: Maybe<Scalars['String']['output']>
    fitness_goal?: Maybe<Scalars['String']['output']>
    flavor?: Maybe<Scalars['String']['output']>
    form?: Maybe<Scalars['Int']['output']>
    /** Product Labels list */
    gb_product_labels?: Maybe<Array<Maybe<Label>>>
    gender_segmentation?: Maybe<Scalars['String']['output']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']['output']>
    glutenfree?: Maybe<Scalars['Int']['output']>
    gmo_free?: Maybe<Scalars['Int']['output']>
    heureka_name?: Maybe<Scalars['String']['output']>
    hodnota_kuponu?: Maybe<Scalars['String']['output']>
    hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']['output']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** Indicates whether the product can be returned */
    is_returnable?: Maybe<Scalars['String']['output']>
    lactosefree?: Maybe<Scalars['Int']['output']>
    /** The latest Related Blog Posts for a specific Product and Store */
    latest_related_blog_posts?: Maybe<LatestRelatedBlogPosts>
    legal_category_of_product?: Maybe<Scalars['Int']['output']>
    long_term_unavailable?: Maybe<Scalars['Int']['output']>
    main_ingredient?: Maybe<Scalars['Int']['output']>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']['output']>
    /** Manufacturer's info. */
    manufacturer_info?: Maybe<ManufacturerInfoInterface>
    manufacturer_model?: Maybe<Scalars['String']['output']>
    mass_grams_g?: Maybe<Scalars['String']['output']>
    mass_mililiter_ml?: Maybe<Scalars['Int']['output']>
    material?: Maybe<Scalars['Int']['output']>
    maximum_duration?: Maybe<Scalars['String']['output']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']['output']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']['output']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']['output']>
    method_of_protein_processing?: Maybe<Scalars['Int']['output']>
    mgs_brand?: Maybe<Scalars['Int']['output']>
    mgs_j360?: Maybe<Scalars['Int']['output']>
    milimeter_mm?: Maybe<Scalars['Int']['output']>
    mininum_order_quantity?: Maybe<Scalars['String']['output']>
    /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
    msrp_price?: Maybe<Scalars['Float']['output']>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']['output']>
    new_from?: Maybe<Scalars['String']['output']>
    /**
     * The beginning date for new product listings, and determines if the product is featured as a new product.
     * @deprecated The field should not be used on the storefront.
     */
    new_from_date?: Maybe<Scalars['String']['output']>
    /**
     * The end date for new product listings.
     * @deprecated The field should not be used on the storefront.
     */
    new_to_date?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_batch?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_package_o?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_pallette?: Maybe<Scalars['String']['output']>
    objem?: Maybe<Scalars['String']['output']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']['output']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']['output']>
    other_models?: Maybe<Array<Maybe<OtherModelsValues>>>
    plastic_packaging_free?: Maybe<Scalars['Int']['output']>
    preparation?: Maybe<Scalars['String']['output']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    prichut?: Maybe<Scalars['String']['output']>
    /** The list of products questions. */
    productQuestions: ProductQuestions
    /** The collection of Product labels for this Product */
    product_labels?: Maybe<Array<Maybe<AmLabel>>>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    product_sort_coefficient?: Maybe<Scalars['String']['output']>
    protein_content_in_product?: Maybe<Scalars['String']['output']>
    protein_sourcee?: Maybe<Scalars['Int']['output']>
    purchasing_category?: Maybe<Scalars['Int']['output']>
    /** The average of all the ratings given to the product. */
    rating_summary: Scalars['Float']['output']
    recommended_products_v2?: Maybe<Array<Maybe<SimpleProduct>>>
    recycling_codes?: Maybe<Scalars['String']['output']>
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
    redirect_code: Scalars['Int']['output']
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
    relative_url?: Maybe<Scalars['String']['output']>
    /** The total count of all the reviews given to the product. */
    review_count: Scalars['Int']['output']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** A short description of the product. Its use depends on the theme. */
    sales_description?: Maybe<ComplexTextValue>
    seasonality?: Maybe<Scalars['Int']['output']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size_of_clothing?: Maybe<Scalars['String']['output']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']['output']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    special_from_date?: Maybe<Scalars['String']['output']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']['output']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']['output']>
    staged: Scalars['Boolean']['output']
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    store_video_attribute?: Maybe<ComplexTextValue>
    supplier_internal_code?: Maybe<Scalars['String']['output']>
    sw_featured?: Maybe<Scalars['Int']['output']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']['output']>
    tablets?: Maybe<Scalars['String']['output']>
    tea_package?: Maybe<Scalars['String']['output']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']['output']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    top?: Maybe<Scalars['String']['output']>
    top_in_category?: Maybe<Scalars['String']['output']>
    tops?: Maybe<Scalars['Int']['output']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']['output']>
    type_of_fish?: Maybe<Scalars['Int']['output']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']['output']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updated_at?: Maybe<Scalars['String']['output']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']['output']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']['output']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']['output']>
    variants?: Maybe<Scalars['String']['output']>
    vegan?: Maybe<Scalars['Int']['output']>
    vegetarian?: Maybe<Scalars['Int']['output']>
    velkost_oblecenia?: Maybe<Scalars['String']['output']>
    velkost_sejkera?: Maybe<Scalars['String']['output']>
    visible_attributes?: Maybe<Array<Maybe<VisibleAttributes>>>
    vydanie?: Maybe<Scalars['String']['output']>
    warning_messages?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']['output']>
    weight_kilograms_kg?: Maybe<Scalars['Int']['output']>
  }

/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProductConfigurable_Product_Options_SelectionArgs = {
  configurableOptionValueUids?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProductProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProductRecommended_Products_V2Args = {
  count?: InputMaybe<Scalars['Int']['input']>
}

/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export type ConfigurableProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type ConfigurableProductCartItemInput = {
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  data: CartItemInput
  /** Configurable product SKU. */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** Deprecated. Use CartItemInput.sku instead. */
  variant_sku?: InputMaybe<Scalars['String']['input']>
}

export type ConfigurableProductOption = {
  __typename?: 'ConfigurableProductOption'
  attribute_code: Scalars['String']['output']
  label: Scalars['String']['output']
  uid: Scalars['ID']['output']
  values?: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>
}

export type ConfigurableProductOptionValue = {
  __typename?: 'ConfigurableProductOptionValue'
  is_available: Scalars['Boolean']['output']
  is_use_default: Scalars['Boolean']['output']
  label: Scalars['String']['output']
  swatch?: Maybe<SwatchDataInterface>
  uid: Scalars['ID']['output']
}

/** ConfigurableProductOptions defines configurable attributes for the specified product */
export type ConfigurableProductOptions = {
  __typename?: 'ConfigurableProductOptions'
  /** A string that identifies the attribute */
  attribute_code?: Maybe<Scalars['String']['output']>
  /**
   * The ID assigned to the attribute
   * @deprecated Use attribute_uid instead
   */
  attribute_id?: Maybe<Scalars['String']['output']>
  /**
   * The ID assigned to the attribute
   * @deprecated Use attribute_uid instead
   */
  attribute_id_v2?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `Attribute` object */
  attribute_uid: Scalars['ID']['output']
  /**
   * The configurable option ID number assigned by the system
   * @deprecated Use uid instead
   */
  id?: Maybe<Scalars['Int']['output']>
  /** A string that describes the configurable product option, which is displayed on the UI */
  label?: Maybe<Scalars['String']['output']>
  /** A number that indicates the order in which the attribute is displayed */
  position?: Maybe<Scalars['Int']['output']>
  /**
   * This is the same as a product's id field
   * @deprecated `product_id` is not needed and can be obtained from it's parent
   */
  product_id?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `ConfigurableProductOptions` object */
  uid: Scalars['ID']['output']
  /** Indicates whether the option is the default */
  use_default?: Maybe<Scalars['Boolean']['output']>
  /** An array that defines the value_index codes assigned to the configurable product */
  values?: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>
}

/** Metadata corresponding to the configurable options selection. */
export type ConfigurableProductOptionsSelection = {
  __typename?: 'ConfigurableProductOptionsSelection'
  /** Configurable options available for further selection based on current selection. */
  configurable_options?: Maybe<Array<Maybe<ConfigurableProductOption>>>
  /** Product images and videos corresponding to the specified configurable options selection. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /** Configurable options available for further selection based on current selection. */
  options_available_for_selection?: Maybe<
    Array<Maybe<ConfigurableOptionAvailableForSelection>>
  >
  /** Variant represented by the specified configurable options selection. It is expected to be null, until selections are made for each configurable option. */
  variant?: Maybe<SimpleProduct>
}

/** ConfigurableProductOptionsValues contains the index number assigned to a configurable product option */
export type ConfigurableProductOptionsValues = {
  __typename?: 'ConfigurableProductOptionsValues'
  /** The label of the product on the default store */
  default_label?: Maybe<Scalars['String']['output']>
  /** The label of the product */
  label?: Maybe<Scalars['String']['output']>
  /** The label of the product on the current store */
  store_label?: Maybe<Scalars['String']['output']>
  /** Swatch data for configurable product option */
  swatch_data?: Maybe<SwatchDataInterface>
  /** The unique ID for a `ConfigurableProductOptionsValues` object */
  uid?: Maybe<Scalars['ID']['output']>
  /** Indicates whether to use the default_label */
  use_default_value?: Maybe<Scalars['Boolean']['output']>
  /**
   * A unique index number assigned to the configurable product option
   * @deprecated Use `uid` instead
   */
  value_index?: Maybe<Scalars['Int']['output']>
}

/** Contains details about configurable products added to a requisition list */
export type ConfigurableRequisitionListItem = RequisitionListItemInterface & {
  __typename?: 'ConfigurableRequisitionListItem'
  /** Selected configurable options for an item in the requisition list */
  configurable_options?: Maybe<Array<Maybe<SelectedConfigurableOption>>>
  /** Selected custom options for an item in the requisition list */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The product model of the requisition list item */
  product: ProductInterface
  /** The quantity of the product added to the requisition list */
  quantity: Scalars['Float']['output']
  /** The unique ID of an item in a requisition list */
  uid: Scalars['ID']['output']
}

/** An array containing all the simple product variants of a configurable product */
export type ConfigurableVariant = {
  __typename?: 'ConfigurableVariant'
  attributes?: Maybe<Array<Maybe<ConfigurableAttributeOption>>>
  is_bestseller?: Maybe<Scalars['Boolean']['output']>
  product?: Maybe<SimpleProduct>
}

/** A configurable product wish list item */
export type ConfigurableWishlistItem = WishlistItemInterface & {
  __typename?: 'ConfigurableWishlistItem'
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String']['output']
  /** The SKU of the simple product corresponding to a set of selected configurable options */
  child_sku: Scalars['String']['output']
  /** An array of selected configurable options */
  configurable_options?: Maybe<Array<Maybe<SelectedConfigurableOption>>>
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID']['output']
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  quantity: Scalars['Float']['output']
}

export type CopyItemsBetweenRequisitionListsInput = {
  /** An array of IDs representing products copied from one requisition list to another */
  requisitionListItemUids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Output of the request to copy items to the destination requisition list */
export type CopyItemsFromRequisitionListsOutput = {
  __typename?: 'CopyItemsFromRequisitionListsOutput'
  /** The destination requisition list after the items were copied */
  requisition_list?: Maybe<RequisitionList>
}

export type CopyProductsBetweenWishlistsOutput = {
  __typename?: 'CopyProductsBetweenWishlistsOutput'
  /** The destination wish list containing the copied products */
  destination_wishlist: Wishlist
  /** The wish list that the products were copied from */
  source_wishlist: Wishlist
  /** An array of errors encountered while copying products in a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>
}

export type Country = {
  __typename?: 'Country'
  available_regions?: Maybe<Array<Maybe<Region>>>
  full_name_english?: Maybe<Scalars['String']['output']>
  full_name_locale?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Country` object. */
  id?: Maybe<Scalars['String']['output']>
  three_letter_abbreviation?: Maybe<Scalars['String']['output']>
  two_letter_abbreviation?: Maybe<Scalars['String']['output']>
}

/** The list of countries codes */
export enum CountryCodeEnum {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua & Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Netherlands Antilles */
  An = 'AN',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia & Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** St. Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo-Kinshasa */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo-Brazzaville */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d’Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cape Verde */
  Cv = 'CV',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands */
  Fk = 'FK',
  /** Micronesia */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia & South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong SAR China */
  Hk = 'HK',
  /** Heard &amp; McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** St. Kitts & Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** St. Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** St. Martin */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar (Burma) */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macau SAR China */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** St. Pierre & Miquelon */
  Pm = 'PM',
  /** Pitcairn Islands */
  Pn = 'PN',
  /** Palestinian Territories */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** St. Helena */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard & Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** São Tomé & Príncipe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Syria */
  Sy = 'SY',
  /** Swaziland */
  Sz = 'SZ',
  /** Turks & Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad & Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** U.S. Outlying Islands */
  Um = 'UM',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vatican City */
  Va = 'VA',
  /** St. Vincent & Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** British Virgin Islands */
  Vg = 'VG',
  /** U.S. Virgin Islands */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis & Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

/** Contains the response to the request to create a company */
export type CreateCompanyOutput = {
  __typename?: 'CreateCompanyOutput'
  /** The new company instance */
  company: Company
}

/** Contains the response to the request to create a company role */
export type CreateCompanyRoleOutput = {
  __typename?: 'CreateCompanyRoleOutput'
  /** The new company role instance */
  role: CompanyRole
}

/** Contains the response to the request to create a company team */
export type CreateCompanyTeamOutput = {
  __typename?: 'CreateCompanyTeamOutput'
  /** The new company team instance */
  team: CompanyTeam
}

/** Contains the response to the request to create a company user */
export type CreateCompanyUserOutput = {
  __typename?: 'CreateCompanyUserOutput'
  /** The new company user instance */
  user: Customer
}

export type CreateCompareListInput = {
  /** An array of product IDs to add to the compare list */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type CreateGiftRegistryInput = {
  /** Additional attributes specified as a code-value pair */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<GiftRegistryDynamicAttributeInput>>
  >
  /** The name of the event */
  event_name: Scalars['String']['input']
  /** The ID of the selected event type */
  gift_registry_type_uid: Scalars['ID']['input']
  /** A message describing the event */
  message: Scalars['String']['input']
  /** Indicates whether the registry is PRIVATE or PUBLIC */
  privacy_settings: GiftRegistryPrivacySettings
  /** The list of people who receive notifications about the registry */
  registrants: Array<InputMaybe<AddGiftRegistryRegistrantInput>>
  /** The shipping address for all gift registry items */
  shipping_address?: InputMaybe<GiftRegistryShippingAddressInput>
  /** Indicates whether the registry is ACTIVE or INACTIVE */
  status: GiftRegistryStatus
}

export type CreateGiftRegistryOutput = {
  __typename?: 'CreateGiftRegistryOutput'
  /** The newly-created gift registry */
  gift_registry?: Maybe<GiftRegistry>
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type CreatePayflowProTokenOutput = {
  __typename?: 'CreatePayflowProTokenOutput'
  response_message: Scalars['String']['output']
  result: Scalars['Int']['output']
  result_code: Scalars['Int']['output']
  secure_token: Scalars['String']['output']
  secure_token_id: Scalars['String']['output']
}

export type CreateProductAnswerInput = {
  answer_text: Scalars['String']['input']
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  question_uid: Scalars['String']['input']
}

export type CreateProductAnswerOutput = {
  __typename?: 'CreateProductAnswerOutput'
  uid?: Maybe<Scalars['String']['output']>
}

export type CreateProductQuestionInput = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  notification: Scalars['Boolean']['input']
  question_text: Scalars['String']['input']
  sku: Scalars['String']['input']
}

export type CreateProductQuestionOutput = {
  __typename?: 'CreateProductQuestionOutput'
  uid?: Maybe<Scalars['String']['output']>
}

export type CreateProductReviewInput = {
  /** The customer's nickname. Defaults to the customer name, if logged in */
  nickname: Scalars['String']['input']
  /** Ratings details by category. e.g price: 5, quality: 4 etc */
  ratings: Array<InputMaybe<ProductReviewRatingInput>>
  /** The SKU of the reviewed product */
  sku: Scalars['String']['input']
  /** The summary (title) of the review */
  summary: Scalars['String']['input']
  /** The review text. */
  text: Scalars['String']['input']
}

export type CreateProductReviewOutput = {
  __typename?: 'CreateProductReviewOutput'
  /** Contains the completed product review */
  review: ProductReview
}

export type CreateRequisitionListInput = {
  /** An option description of the requisition list */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name assigned to the requisition list */
  name: Scalars['String']['input']
}

/** Output of the request to create a requisition list */
export type CreateRequisitionListOutput = {
  __typename?: 'CreateRequisitionListOutput'
  /** The created requisition list */
  requisition_list?: Maybe<RequisitionList>
}

export type CreateWishlistInput = {
  /** The name of the new wish list */
  name: Scalars['String']['input']
  /** Indicates whether the wish list is public or private */
  visibility: WishlistVisibilityEnum
}

export type CreateWishlistOutput = {
  __typename?: 'CreateWishlistOutput'
  /** The newly-created wish list */
  wishlist: Wishlist
}

/** Required fields for Payflow Pro and Payments Pro credit card payments */
export type CreditCardDetailsInput = {
  /** Credit card expiration month */
  cc_exp_month: Scalars['Int']['input']
  /** Credit card expiration year */
  cc_exp_year: Scalars['Int']['input']
  /** Last 4 digits of the credit card */
  cc_last_4: Scalars['Int']['input']
  /** Credit card type */
  cc_type: Scalars['String']['input']
}

/** Credit memo details */
export type CreditMemo = {
  __typename?: 'CreditMemo'
  /** Comments on the credit memo */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `CreditMemo` object */
  id: Scalars['ID']['output']
  /** An array containing details about refunded items */
  items?: Maybe<Array<Maybe<CreditMemoItemInterface>>>
  /** The sequential credit memo number */
  number: Scalars['String']['output']
  /** Contains details about the total refunded amount */
  total?: Maybe<CreditMemoTotal>
}

export type CreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'CreditMemoItem'
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
}

/** Credit memo item details */
export type CreditMemoItemInterface = {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
}

/** Credit memo price details */
export type CreditMemoTotal = {
  __typename?: 'CreditMemoTotal'
  /** An adjustment manually applied to the order */
  adjustment: Money
  /** The final base grand total amount in the base currency */
  base_grand_total: Money
  /** The applied discounts to the credit memo */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money
  /** Contains details about the shipping and handling costs for the credit memo */
  shipping_handling?: Maybe<ShippingHandling>
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes */
  subtotal: Money
  /** The credit memo tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>
  /** The shipping amount for the credit memo */
  total_shipping: Money
  /** The amount of tax applied to the credit memo */
  total_tax: Money
}

export type Currency = {
  __typename?: 'Currency'
  available_currency_codes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  base_currency_code?: Maybe<Scalars['String']['output']>
  base_currency_symbol?: Maybe<Scalars['String']['output']>
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  default_display_currecy_code?: Maybe<Scalars['String']['output']>
  /** @deprecated Symbol was missed. Use `default_display_currency_symbol`. */
  default_display_currecy_symbol?: Maybe<Scalars['String']['output']>
  default_display_currency_code?: Maybe<Scalars['String']['output']>
  default_display_currency_symbol?: Maybe<Scalars['String']['output']>
  exchange_rates?: Maybe<Array<Maybe<ExchangeRate>>>
}

/** The list of available currency codes */
export enum CurrencyEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azm = 'AZM',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Buk = 'BUK',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gek = 'GEK',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gqe = 'GQE',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lsm = 'LSM',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nic = 'NIC',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rhd = 'RHD',
  Rol = 'ROL',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Ytl = 'YTL',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD',
}

/** CustomAttributeMetadata defines an array of attribute_codes and entity_types */
export type CustomAttributeMetadata = {
  __typename?: 'CustomAttributeMetadata'
  /** An array of attributes */
  items?: Maybe<Array<Maybe<Attribute>>>
}

/** Customer defines the customer name and address and other details */
export type Customer = {
  __typename?: 'Customer'
  /** An array containing the customer's shipping and billing addresses */
  addresses?: Maybe<Array<Maybe<CustomerAddress>>>
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance: Scalars['Boolean']['output']
  /** URL to log-in customer and redirect to checkout, valid for 1minute */
  checkout_url?: Maybe<Scalars['String']['output']>
  /** The contents of the customer's compare list */
  compare_list?: Maybe<CompareList>
  /** Timestamp indicating when the account was created */
  created_at?: Maybe<Scalars['String']['output']>
  customer_token?: Maybe<Scalars['String']['output']>
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']['output']>
  /** The ID assigned to the billing address */
  default_billing?: Maybe<Scalars['String']['output']>
  /** The ID assigned to the shipping address */
  default_shipping?: Maybe<Scalars['String']['output']>
  /**
   * The customer's date of birth
   * @deprecated Use `date_of_birth` instead
   */
  dob?: Maybe<Scalars['String']['output']>
  /** The customer's email address. Required */
  email?: Maybe<Scalars['String']['output']>
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']['output']>
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']['output']>
  /** Details about all of the customer's gift registries */
  gift_registries?: Maybe<Array<Maybe<GiftRegistry>>>
  /** Details about a specific gift registry */
  gift_registry?: Maybe<GiftRegistry>
  group_code?: Maybe<Scalars['String']['output']>
  /** @deprecated Customer group should not be exposed in the storefront scenarios */
  group_id?: Maybe<Scalars['Int']['output']>
  /**
   * The ID assigned to the customer
   * @deprecated id is not needed as part of Customer because on server side it can be identified based on customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether requested customer is a business-to-business customer */
  is_b2b?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']['output']>
  /** The job title of a company user */
  job_title?: Maybe<Scalars['String']['output']>
  /** The customer's family name */
  lastname?: Maybe<Scalars['String']['output']>
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']['output']>
  orders?: Maybe<CustomerOrders>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']['output']>
  /** Contains the customer's requisition lists */
  requisition_lists?: Maybe<RequisitionLists>
  /** Retrieves details about the specified return request from the unique ID for a `Return` object */
  return?: Maybe<Return>
  /** Information about the customer's return requests. */
  returns?: Maybe<Returns>
  /** Contains the customer's product reviews */
  reviews: ProductReviews
  /** Customer reward points details */
  reward_points?: Maybe<RewardPoints>
  /** The role name and permissions assigned to the company user */
  role?: Maybe<CompanyRole>
  /** Indicates whether the company user is ACTIVE or INACTIVE */
  status?: Maybe<CompanyUserStatusEnum>
  /** Contains the store credit information applied for the logged in customer */
  store_credit?: Maybe<CustomerStoreCredit>
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']['output']>
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']['output']>
  /** The team the company user is assigned to */
  team?: Maybe<CompanyTeam>
  /** The phone number of the company user */
  telephone?: Maybe<Scalars['String']['output']>
  /**
   * Contains a customer's wish lists
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2`
   */
  wishlist: Wishlist
  /** Retrieve the specified wish list identified by the unique ID for a `Wishlist` object */
  wishlist_v2?: Maybe<Wishlist>
  /** An array of wishlists. In Magento Open Source, customers are limited to one wish list. The number of wish lists is configurable for Magento Commerce */
  wishlists: Array<Maybe<Wishlist>>
}

/** Customer defines the customer name and address and other details */
export type CustomerGift_RegistryArgs = {
  giftRegistryUid: Scalars['ID']['input']
}

/** Customer defines the customer name and address and other details */
export type CustomerOrdersArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<CustomerOrdersFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Customer defines the customer name and address and other details */
export type CustomerRequisition_ListsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<RequisitionListFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Customer defines the customer name and address and other details */
export type CustomerReturnArgs = {
  uid: Scalars['ID']['input']
}

/** Customer defines the customer name and address and other details */
export type CustomerReturnsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Customer defines the customer name and address and other details */
export type CustomerReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Customer defines the customer name and address and other details */
export type CustomerWishlist_V2Args = {
  id: Scalars['ID']['input']
}

/** Customer defines the customer name and address and other details */
export type CustomerWishlistsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** CustomerAddress contains detailed information about a customer's billing and shipping addresses */
export type CustomerAddress = {
  __typename?: 'CustomerAddress'
  /** The city or town */
  city?: Maybe<Scalars['String']['output']>
  /** The customer's company */
  company?: Maybe<Scalars['String']['output']>
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>
  /**
   * The customer's country
   * @deprecated Use `country_code` instead.
   */
  country_id?: Maybe<Scalars['String']['output']>
  /** @deprecated Custom attributes should not be put into container */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>
  /**
   * The customer ID
   * @deprecated customer_id is not needed as part of CustomerAddress, address ID (id) is unique identifier for the addresses.
   */
  customer_id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the address is the default billing address */
  default_billing?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the address is the default shipping address */
  default_shipping?: Maybe<Scalars['Boolean']['output']>
  /** Address extension attributes */
  extension_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>
  /** The fax number */
  fax?: Maybe<Scalars['String']['output']>
  /** The first name of the person associated with the shipping/billing address */
  firstname?: Maybe<Scalars['String']['output']>
  /** The ID assigned to the address object */
  id?: Maybe<Scalars['Int']['output']>
  /** The family name of the person associated with the shipping/billing address */
  lastname?: Maybe<Scalars['String']['output']>
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']['output']>
  /** The customer's ZIP or postal code */
  postcode?: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']['output']>
  /** An object containing the region name, region code, and region ID */
  region?: Maybe<CustomerAddressRegion>
  /** The unique ID for a pre-defined region */
  region_id?: Maybe<Scalars['Int']['output']>
  /** An array of strings that define the street number and name */
  street?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']['output']>
  /** The telephone number */
  telephone?: Maybe<Scalars['String']['output']>
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']['output']>
}

export type CustomerAddressAttribute = {
  __typename?: 'CustomerAddressAttribute'
  /** Attribute code */
  attribute_code?: Maybe<Scalars['String']['output']>
  /** Attribute value */
  value?: Maybe<Scalars['String']['output']>
}

export type CustomerAddressAttributeInput = {
  /** Attribute code */
  attribute_code: Scalars['String']['input']
  /** Attribute value */
  value: Scalars['String']['input']
}

export type CustomerAddressInput = {
  /** The city or town */
  city?: InputMaybe<Scalars['String']['input']>
  /** The customer's company */
  company?: InputMaybe<Scalars['String']['input']>
  /** The customer's country */
  country_code?: InputMaybe<CountryCodeEnum>
  /** Deprecated: use `country_code` instead. */
  country_id?: InputMaybe<CountryCodeEnum>
  /** Deprecated: Custom attributes should not be put into container. */
  custom_attributes?: InputMaybe<
    Array<InputMaybe<CustomerAddressAttributeInput>>
  >
  /** Indicates whether the address is the default billing address */
  default_billing?: InputMaybe<Scalars['Boolean']['input']>
  /** Indicates whether the address is the default shipping address */
  default_shipping?: InputMaybe<Scalars['Boolean']['input']>
  /** The fax number */
  fax?: InputMaybe<Scalars['String']['input']>
  /** The first name of the person associated with the shipping/billing address */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The family name of the person associated with the shipping/billing address */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's ZIP or postal code */
  postcode?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** An object containing the region name, region code, and region ID */
  region?: InputMaybe<CustomerAddressRegionInput>
  /** An array of strings that define the street number and name */
  street?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** A value such as Sr., Jr., or III */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The telephone number */
  telephone?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers) */
  vat_id?: InputMaybe<Scalars['String']['input']>
}

/** CustomerAddressRegion defines the customer's state or province */
export type CustomerAddressRegion = {
  __typename?: 'CustomerAddressRegion'
  /** The state or province name */
  region?: Maybe<Scalars['String']['output']>
  /** The address region code */
  region_code?: Maybe<Scalars['String']['output']>
  /** The unique ID for a pre-defined region */
  region_id?: Maybe<Scalars['Int']['output']>
}

/** CustomerAddressRegionInput defines the customer's state or province */
export type CustomerAddressRegionInput = {
  /** The state or province name */
  region?: InputMaybe<Scalars['String']['input']>
  /** The address region code */
  region_code?: InputMaybe<Scalars['String']['input']>
  /** The unique ID for a pre-defined region */
  region_id?: InputMaybe<Scalars['Int']['input']>
}

export type CustomerCreateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's date of birth */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's email address. Required for customer creation */
  email: Scalars['String']['input']
  /** The customer's first name */
  firstname: Scalars['String']['input']
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name */
  lastname: Scalars['String']['input']
  /** The customer's middle name */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's password */
  password?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

export type CustomerDownloadableProduct = {
  __typename?: 'CustomerDownloadableProduct'
  date?: Maybe<Scalars['String']['output']>
  download_url?: Maybe<Scalars['String']['output']>
  order_increment_id?: Maybe<Scalars['String']['output']>
  remaining_downloads?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type CustomerDownloadableProducts = {
  __typename?: 'CustomerDownloadableProducts'
  /** List of purchased downloadable items */
  items?: Maybe<Array<Maybe<CustomerDownloadableProduct>>>
}

export type CustomerInput = {
  /** The customer's date of birth */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's email address. Required for customer creation */
  email?: InputMaybe<Scalars['String']['input']>
  /** The customer's first name */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The customer's middle name */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** The customer's password */
  password?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

/** Contains details about each of the customer's orders */
export type CustomerOrder = {
  __typename?: 'CustomerOrder'
  /** The billing address for the order */
  billing_address?: Maybe<OrderAddress>
  /** The shipping carrier for the order delivery */
  carrier?: Maybe<Scalars['String']['output']>
  /** Comments about the order */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>
  /** @deprecated Use the order_date attribute instead */
  created_at?: Maybe<Scalars['String']['output']>
  /** A list of credit memos */
  credit_memos?: Maybe<Array<Maybe<CreditMemo>>>
  /** The entered gift message for the order */
  gift_message?: Maybe<GiftMessage>
  /** Whether customer requested gift receipt for the order */
  gift_receipt_included: Scalars['Boolean']['output']
  /** The selected gift wrapping for the order */
  gift_wrapping?: Maybe<GiftWrapping>
  /** @deprecated Use the totals.grand_total attribute instead */
  grand_total?: Maybe<Scalars['Float']['output']>
  /** The unique ID for a `CustomerOrder` object */
  id: Scalars['ID']['output']
  /** @deprecated Use the id attribute instead */
  increment_id?: Maybe<Scalars['String']['output']>
  /** Custom order ID changeable by customer */
  internal_order_id?: Maybe<Scalars['String']['output']>
  /** A list of invoices for the order */
  invoices: Array<Maybe<Invoice>>
  /** An array containing the items purchased in this order */
  items?: Maybe<Array<Maybe<OrderItemInterface>>>
  /** A list of order items eligible to be in a return request */
  items_eligible_for_return?: Maybe<Array<Maybe<OrderItemInterface>>>
  /** The order number */
  number: Scalars['String']['output']
  /** The date the order was placed */
  order_date: Scalars['String']['output']
  order_id?: Maybe<Scalars['String']['output']>
  /** @deprecated Use the number attribute instead */
  order_number: Scalars['String']['output']
  /** Payment details for the order */
  payment_methods?: Maybe<Array<Maybe<OrderPaymentMethod>>>
  /** Whether customer requested printed card for the order */
  printed_card_included: Scalars['Boolean']['output']
  reorder_eligible?: Maybe<Scalars['Boolean']['output']>
  /** Return requests associated with this order. */
  returns?: Maybe<Returns>
  /** A list of shipments for the order */
  shipments?: Maybe<Array<Maybe<OrderShipment>>>
  /** The shipping address for the order */
  shipping_address?: Maybe<OrderAddress>
  /** The delivery method for the order */
  shipping_method?: Maybe<Scalars['String']['output']>
  /** Is order archive or active */
  state: Scalars['String']['output']
  /** The current status of the order */
  status: Scalars['String']['output']
  status_en?: Maybe<Scalars['String']['output']>
  timestamps?: Maybe<Timestamps>
  /** Contains details about the calculated totals for this order */
  total?: Maybe<OrderTotal>
  /** Order tracking URL */
  tracking_url?: Maybe<Scalars['String']['output']>
}

/** Contains details about each of the customer's orders */
export type CustomerOrderReturnsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** The collection of orders that match the conditions defined in the filter */
export type CustomerOrders = {
  __typename?: 'CustomerOrders'
  /** An array of customer orders */
  items: Array<Maybe<CustomerOrder>>
  /** An object that includes the current_page, page_info, and page_size values specified in the query */
  page_info?: Maybe<SearchResultPageInfo>
  /** The total count of customer orders */
  total_count?: Maybe<Scalars['Int']['output']>
}

/** Identifies the filter to use for filtering orders. */
export type CustomerOrdersFilterInput = {
  /** Filters by order number. */
  number?: InputMaybe<FilterStringTypeInput>
  /** Filters by order order_id */
  order_id?: InputMaybe<FilterIntTypeInput>
  /** Filter orders by their state, either ACTIVE or ARCHIVE */
  state?: InputMaybe<FilterStringTypeInput>
}

export type CustomerOutput = {
  __typename?: 'CustomerOutput'
  customer: Customer
}

export type CustomerPaymentTokens = {
  __typename?: 'CustomerPaymentTokens'
  /** An array of payment tokens */
  items: Array<Maybe<PaymentToken>>
}

/** Contains store credit information with balance and history */
export type CustomerStoreCredit = {
  __typename?: 'CustomerStoreCredit'
  /** Customer Store credit balance history. If the history or store credit feature is disabled, then a null value will be returned. */
  balance_history?: Maybe<CustomerStoreCreditHistory>
  /** Current balance on store credit */
  current_balance?: Maybe<Money>
  /** Indicates whether store credits are enabled. If the feature is disabled, then the balance will not be returned */
  enabled?: Maybe<Scalars['Boolean']['output']>
}

/** Contains store credit information with balance and history */
export type CustomerStoreCreditBalance_HistoryArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Lists changes to the amount of store credit available to the customer. */
export type CustomerStoreCreditHistory = {
  __typename?: 'CustomerStoreCreditHistory'
  /** An array containing information about changes to the store credit available to the customer. */
  items?: Maybe<Array<Maybe<CustomerStoreCreditHistoryItem>>>
  /** An object that includes the current_page page_info and page_size values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>
  /** The number of items returned. */
  total_count?: Maybe<Scalars['Int']['output']>
}

/** Defines store credit history information */
export type CustomerStoreCreditHistoryItem = {
  __typename?: 'CustomerStoreCreditHistoryItem'
  /** Action that was made on the store credit */
  action?: Maybe<Scalars['String']['output']>
  /** The store credit available to the customer as a result of this action.  */
  actual_balance?: Maybe<Money>
  /** The amount added to or subtracted from the store credit as a result of this action. */
  balance_change?: Maybe<Money>
  /** Date and time when the store credit change was made */
  date_time_changed?: Maybe<Scalars['String']['output']>
}

export type CustomerToken = {
  __typename?: 'CustomerToken'
  /** The customer token */
  token?: Maybe<Scalars['String']['output']>
}

export type CustomerUpdateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's date of birth */
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  /** Deprecated: Use `date_of_birth` instead */
  dob?: InputMaybe<Scalars['String']['input']>
  /** The customer's first name */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: InputMaybe<Scalars['Int']['input']>
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: InputMaybe<Scalars['Boolean']['input']>
  /** The customer's family name */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** The customer's middle name */
  middlename?: InputMaybe<Scalars['String']['input']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>
  /** A value such as Sr., Jr., or III */
  suffix?: InputMaybe<Scalars['String']['input']>
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: InputMaybe<Scalars['String']['input']>
}

/** CustomizableAreaOption contains information about a text area that is defined as part of a customizable option. */
export type CustomizableAreaOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableAreaOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a text area. */
  value?: Maybe<CustomizableAreaValue>
}

/** CustomizableAreaValue defines the price and sku of a product whose page contains a customized text area. */
export type CustomizableAreaValue = {
  __typename?: 'CustomizableAreaValue'
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID']['output']
}

/** CustomizableCheckbbixOption contains information about a set of checkbox values that are defined as part of a customizable option. */
export type CustomizableCheckboxOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableCheckboxOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines a set of checkbox values. */
  value?: Maybe<Array<Maybe<CustomizableCheckboxValue>>>
}

/** CustomizableCheckboxValue defines the price and sku of a product whose page contains a customized set of checkbox values. */
export type CustomizableCheckboxValue = {
  __typename?: 'CustomizableCheckboxValue'
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']['output']>
  /** The order in which the checkbox value is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID']['output']
}

/** CustomizableDateOption contains information about a date picker that is defined as part of a customizable option. */
export type CustomizableDateOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDateOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a date field in a customizable option. */
  value?: Maybe<CustomizableDateValue>
}

/** This enumeration customizable date type. */
export enum CustomizableDateTypeEnum {
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME',
}

/** CustomizableDateValue defines the price and sku of a product whose page contains a customized date picker. */
export type CustomizableDateValue = {
  __typename?: 'CustomizableDateValue'
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']['output']>
  /** DATE, DATE_TIME or TIME */
  type?: Maybe<CustomizableDateTypeEnum>
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID']['output']
}

/** CustomizableDropDownOption contains information about a drop down menu that is defined as part of a customizable option. */
export type CustomizableDropDownOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDropDownOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines the set of options for a drop down menu. */
  value?: Maybe<Array<Maybe<CustomizableDropDownValue>>>
}

/** CustomizableDropDownValue defines the price and sku of a product whose page contains a customized drop down menu. */
export type CustomizableDropDownValue = {
  __typename?: 'CustomizableDropDownValue'
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID']['output']
}

/** CustomizableFieldOption contains information about a text field that is defined as part of a customizable option. */
export type CustomizableFieldOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFieldOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a text field. */
  value?: Maybe<CustomizableFieldValue>
}

/** CustomizableFieldValue defines the price and sku of a product whose page contains a customized text field. */
export type CustomizableFieldValue = {
  __typename?: 'CustomizableFieldValue'
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']['output']>
  /** The price of the custom value. */
  price?: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID']['output']
}

/** CustomizableFileOption contains information about a file picker that is defined as part of a customizable option. */
export type CustomizableFileOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFileOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An object that defines a file value. */
  value?: Maybe<CustomizableFileValue>
}

/** CustomizableFileValue defines the price and sku of a product whose page contains a customized file picker. */
export type CustomizableFileValue = {
  __typename?: 'CustomizableFileValue'
  /** The file extension to accept. */
  file_extension?: Maybe<Scalars['String']['output']>
  /** The maximum width of an image. */
  image_size_x?: Maybe<Scalars['Int']['output']>
  /** The maximum height of an image. */
  image_size_y?: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID']['output']
}

/** CustomizableMultipleOption contains information about a multiselect that is defined as part of a customizable option. */
export type CustomizableMultipleOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableMultipleOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines the set of options for a multiselect. */
  value?: Maybe<Array<Maybe<CustomizableMultipleValue>>>
}

/** CustomizableMultipleValue defines the price and sku of a product whose page contains a customized multiselect. */
export type CustomizableMultipleValue = {
  __typename?: 'CustomizableMultipleValue'
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID']['output']
}

export type CustomizableOptionInput = {
  /** The customizable option id of the product */
  id?: InputMaybe<Scalars['Int']['input']>
  /** The string value of the option */
  value_string: Scalars['String']['input']
}

/** The CustomizableOptionInterface contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export type CustomizableOptionInterface = {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
}

/** CustomizableProductInterface contains information about customizable product options. */
export type CustomizableProductInterface = {
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
}

/** CustomizableRadioOption contains information about a set of radio buttons that are defined as part of a customizable option. */
export type CustomizableRadioOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableRadioOption'
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']['output']>
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output']
  /** An array that defines a set of radio buttons. */
  value?: Maybe<Array<Maybe<CustomizableRadioValue>>>
}

/** CustomizableRadioValue defines the price and sku of a product whose page contains a customized set of radio buttons. */
export type CustomizableRadioValue = {
  __typename?: 'CustomizableRadioValue'
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']['output']>
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']['output']>
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']['output']>
  /** The order in which the radio button is displayed. */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name for this option. */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID']['output']
}

export type DeactivateAccountCustomer = {
  __typename?: 'DeactivateAccountCustomer'
  deactivated_at?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
}

export type DeactivateAccountOutput = {
  __typename?: 'DeactivateAccountOutput'
  customer?: Maybe<DeactivateAccountCustomer>
}

/** Contains the response to the request to delete the company role */
export type DeleteCompanyRoleOutput = {
  __typename?: 'DeleteCompanyRoleOutput'
  /** SIndicates whether the company role has been deleted successfully (`true` or `false`) */
  success: Scalars['Boolean']['output']
}

/** Contains the status of the request to delete a company team */
export type DeleteCompanyTeamOutput = {
  __typename?: 'DeleteCompanyTeamOutput'
  /** Indicates whether the delete operation succeeded */
  success: Scalars['Boolean']['output']
}

/** Contains the response to the request to delete the company user */
export type DeleteCompanyUserOutput = {
  __typename?: 'DeleteCompanyUserOutput'
  /** Indicates whether the company user has been deactivated successfully (`true` or `false`) */
  success: Scalars['Boolean']['output']
}

export type DeleteCompareListOutput = {
  __typename?: 'DeleteCompareListOutput'
  /** Indicates whether the compare list was successfully deleted */
  result: Scalars['Boolean']['output']
}

export type DeleteNegotiableQuotesInput = {
  /** A list of unique IDs for `NegotiableQuote` objects to delete */
  quote_uids: Array<InputMaybe<Scalars['ID']['input']>>
}

export type DeleteNegotiableQuotesOutput = {
  __typename?: 'DeleteNegotiableQuotesOutput'
  /** A list of negotiable quotes that the customer can view */
  negotiable_quotes?: Maybe<NegotiableQuotesOutput>
}

export type DeleteNegotiableQuotesOutputNegotiable_QuotesArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<NegotiableQuoteFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<NegotiableQuoteSortInput>
}

export type DeletePaymentTokenOutput = {
  __typename?: 'DeletePaymentTokenOutput'
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>
  result: Scalars['Boolean']['output']
}

/** Output of the request to remove items from the requisition list */
export type DeleteRequisitionListItemsOutput = {
  __typename?: 'DeleteRequisitionListItemsOutput'
  /** The requisition list after removing items */
  requisition_list?: Maybe<RequisitionList>
}

/** Indicates whether the request to delete the requisition list was successful */
export type DeleteRequisitionListOutput = {
  __typename?: 'DeleteRequisitionListOutput'
  /** Contains the customer's requisition lists after removing a list */
  requisition_lists?: Maybe<RequisitionLists>
  /** Indicates whether the request to delete the requisition list was successful */
  status: Scalars['Boolean']['output']
}

export type DeleteWishlistOutput = {
  __typename?: 'DeleteWishlistOutput'
  /** Indicates whether the wish list was deleted */
  status: Scalars['Boolean']['output']
  /** A list of undeleted wish lists */
  wishlists: Array<Maybe<Wishlist>>
}

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export type Discount = {
  __typename?: 'Discount'
  /** The amount of the discount */
  amount: Money
  /** A description of the discount */
  label: Scalars['String']['output']
}

export type DognetS2SType = {
  __typename?: 'DognetS2SType'
  account_id?: Maybe<Scalars['String']['output']>
  cookie_consent?: Maybe<Scalars['Boolean']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
}

export type DognetType = {
  __typename?: 'DognetType'
  account_id?: Maybe<Scalars['String']['output']>
  allowed_customer_groups?: Maybe<Scalars['String']['output']>
  is_active?: Maybe<Scalars['Boolean']['output']>
  script_id?: Maybe<Scalars['String']['output']>
  script_src?: Maybe<Scalars['String']['output']>
}

/** Downloadable Cart Item */
export type DownloadableCartItem = CartItemInterface & {
  __typename?: 'DownloadableCartItem'
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** @deprecated Use `uid` instead */
  id: Scalars['String']['output']
  /** An array containing information about the links for the added to cart downloadable product */
  links?: Maybe<Array<Maybe<DownloadableProductLinks>>>
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']['output']
  rule_id?: Maybe<Scalars['Int']['output']>
  /** DownloadableProductSamples defines characteristics of a downloadable product */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID']['output']
}

export type DownloadableCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'DownloadableCreditMemoItem'
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are refunded from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
}

export enum DownloadableFileTypeEnum {
  /** @deprecated `sample_url` serves to get the downloadable sample */
  File = 'FILE',
  /** @deprecated `sample_url` serves to get the downloadable sample */
  Url = 'URL',
}

export type DownloadableInvoiceItem = InvoiceItemInterface & {
  __typename?: 'DownloadableInvoiceItem'
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are invoiced from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID']['output']
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
}

/** DownloadableProductLinks defines characteristics of a downloadable product */
export type DownloadableItemsLinks = {
  __typename?: 'DownloadableItemsLinks'
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name of the link */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID']['output']
}

export type DownloadableOrderItem = OrderItemInterface & {
  __typename?: 'DownloadableOrderItem'
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** A list of downloadable links that are ordered from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>
  /** Indicates whether the order item is eligible is eligible to be in a return request */
  eligible_for_return?: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift wrapping for the order item */
  gift_wrapping?: Maybe<GiftWrapping>
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  is_saleable?: Maybe<Scalars['Boolean']['output']>
  parent_sku?: Maybe<Scalars['String']['output']>
  product_image?: Maybe<Scalars['String']['output']>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']['output']>
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']['output']>
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']['output']>
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']['output']>
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item */
  status?: Maybe<Scalars['String']['output']>
}

/** DownloadableProduct defines a product that the shopper downloads */
export type DownloadableProduct = CustomizableProductInterface &
  ProductInterface &
  RoutableInterface & {
    __typename?: 'DownloadableProduct'
    allergen_celery?: Maybe<Scalars['Int']['output']>
    allergen_crustaceans?: Maybe<Scalars['Int']['output']>
    allergen_eggs?: Maybe<Scalars['Int']['output']>
    allergen_fish?: Maybe<Scalars['Int']['output']>
    allergen_gluten?: Maybe<Scalars['Int']['output']>
    allergen_lupin?: Maybe<Scalars['Int']['output']>
    allergen_milk?: Maybe<Scalars['Int']['output']>
    allergen_molluscs?: Maybe<Scalars['Int']['output']>
    allergen_mustard?: Maybe<Scalars['Int']['output']>
    allergen_peanuts?: Maybe<Scalars['Int']['output']>
    allergen_sesame?: Maybe<Scalars['Int']['output']>
    allergen_soya?: Maybe<Scalars['Int']['output']>
    allergen_sulphites?: Maybe<Scalars['Int']['output']>
    allergen_tree_nuts?: Maybe<Scalars['Int']['output']>
    application?: Maybe<Scalars['Int']['output']>
    artificial_sweetener_free?: Maybe<Scalars['Int']['output']>
    aspartame_free?: Maybe<Scalars['Int']['output']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attribute_set_id?: Maybe<Scalars['Int']['output']>
    available_for_sdd?: Maybe<Scalars['String']['output']>
    bag_volume_range?: Maybe<Scalars['Int']['output']>
    bio?: Maybe<Scalars['Int']['output']>
    blend?: Maybe<Scalars['Int']['output']>
    bottom?: Maybe<Scalars['String']['output']>
    breadcrumb?: Maybe<Array<Maybe<BreadcrumbType>>>
    breadcrumb_en?: Maybe<Scalars['String']['output']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']['output']>
    capsules?: Maybe<Scalars['String']['output']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    color?: Maybe<Scalars['String']['output']>
    colors?: Maybe<Scalars['String']['output']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']['output']>
    coupon_amount?: Maybe<Scalars['Int']['output']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    created_at?: Maybe<Scalars['String']['output']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    default_category?: Maybe<Scalars['String']['output']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    /** An array containing information about the links for this downloadable product */
    downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinks>>>
    /** An array containing information about samples of this downloadable product. */
    downloadable_product_samples?: Maybe<
      Array<Maybe<DownloadableProductSamples>>
    >
    ean?: Maybe<Scalars['String']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    estimated_date_of_arrival?: Maybe<EstimatedDateOfArrivalType>
    expected_arrival_date?: Maybe<Scalars['String']['output']>
    farba_oblecenia?: Maybe<Scalars['String']['output']>
    farba_sejkera?: Maybe<Scalars['String']['output']>
    fit?: Maybe<Scalars['String']['output']>
    fitness_goal?: Maybe<Scalars['String']['output']>
    flavor?: Maybe<Scalars['String']['output']>
    form?: Maybe<Scalars['Int']['output']>
    /** Product Labels list */
    gb_product_labels?: Maybe<Array<Maybe<Label>>>
    gender_segmentation?: Maybe<Scalars['String']['output']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']['output']>
    glutenfree?: Maybe<Scalars['Int']['output']>
    gmo_free?: Maybe<Scalars['Int']['output']>
    heureka_name?: Maybe<Scalars['String']['output']>
    hodnota_kuponu?: Maybe<Scalars['String']['output']>
    hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']['output']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** Indicates whether the product can be returned */
    is_returnable?: Maybe<Scalars['String']['output']>
    lactosefree?: Maybe<Scalars['Int']['output']>
    /** The latest Related Blog Posts for a specific Product and Store */
    latest_related_blog_posts?: Maybe<LatestRelatedBlogPosts>
    legal_category_of_product?: Maybe<Scalars['Int']['output']>
    /** A value of 1 indicates that each link in the array must be purchased separately */
    links_purchased_separately?: Maybe<Scalars['Int']['output']>
    /** The heading above the list of downloadable products */
    links_title?: Maybe<Scalars['String']['output']>
    long_term_unavailable?: Maybe<Scalars['Int']['output']>
    main_ingredient?: Maybe<Scalars['Int']['output']>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']['output']>
    /** Manufacturer's info. */
    manufacturer_info?: Maybe<ManufacturerInfoInterface>
    manufacturer_model?: Maybe<Scalars['String']['output']>
    mass_grams_g?: Maybe<Scalars['String']['output']>
    mass_mililiter_ml?: Maybe<Scalars['Int']['output']>
    material?: Maybe<Scalars['Int']['output']>
    maximum_duration?: Maybe<Scalars['String']['output']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']['output']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']['output']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']['output']>
    method_of_protein_processing?: Maybe<Scalars['Int']['output']>
    mgs_brand?: Maybe<Scalars['Int']['output']>
    mgs_j360?: Maybe<Scalars['Int']['output']>
    milimeter_mm?: Maybe<Scalars['Int']['output']>
    mininum_order_quantity?: Maybe<Scalars['String']['output']>
    /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
    msrp_price?: Maybe<Scalars['Float']['output']>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']['output']>
    new_from?: Maybe<Scalars['String']['output']>
    /**
     * The beginning date for new product listings, and determines if the product is featured as a new product.
     * @deprecated The field should not be used on the storefront.
     */
    new_from_date?: Maybe<Scalars['String']['output']>
    /**
     * The end date for new product listings.
     * @deprecated The field should not be used on the storefront.
     */
    new_to_date?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_batch?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_package_o?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_pallette?: Maybe<Scalars['String']['output']>
    objem?: Maybe<Scalars['String']['output']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']['output']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']['output']>
    other_models?: Maybe<Array<Maybe<OtherModelsValues>>>
    plastic_packaging_free?: Maybe<Scalars['Int']['output']>
    preparation?: Maybe<Scalars['String']['output']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    prichut?: Maybe<Scalars['String']['output']>
    /** The list of products questions. */
    productQuestions: ProductQuestions
    /** The collection of Product labels for this Product */
    product_labels?: Maybe<Array<Maybe<AmLabel>>>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    product_sort_coefficient?: Maybe<Scalars['String']['output']>
    protein_content_in_product?: Maybe<Scalars['String']['output']>
    protein_sourcee?: Maybe<Scalars['Int']['output']>
    purchasing_category?: Maybe<Scalars['Int']['output']>
    /** The average of all the ratings given to the product. */
    rating_summary: Scalars['Float']['output']
    recycling_codes?: Maybe<Scalars['String']['output']>
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
    redirect_code: Scalars['Int']['output']
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
    relative_url?: Maybe<Scalars['String']['output']>
    /** The total count of all the reviews given to the product. */
    review_count: Scalars['Int']['output']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** A short description of the product. Its use depends on the theme. */
    sales_description?: Maybe<ComplexTextValue>
    seasonality?: Maybe<Scalars['Int']['output']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size_of_clothing?: Maybe<Scalars['String']['output']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']['output']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    special_from_date?: Maybe<Scalars['String']['output']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']['output']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']['output']>
    staged: Scalars['Boolean']['output']
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    supplier_internal_code?: Maybe<Scalars['String']['output']>
    sw_featured?: Maybe<Scalars['Int']['output']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']['output']>
    tablets?: Maybe<Scalars['String']['output']>
    tea_package?: Maybe<Scalars['String']['output']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']['output']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    top?: Maybe<Scalars['String']['output']>
    top_in_category?: Maybe<Scalars['String']['output']>
    tops?: Maybe<Scalars['Int']['output']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']['output']>
    type_of_fish?: Maybe<Scalars['Int']['output']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']['output']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updated_at?: Maybe<Scalars['String']['output']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']['output']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']['output']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']['output']>
    variants?: Maybe<Scalars['String']['output']>
    vegan?: Maybe<Scalars['Int']['output']>
    vegetarian?: Maybe<Scalars['Int']['output']>
    velkost_oblecenia?: Maybe<Scalars['String']['output']>
    velkost_sejkera?: Maybe<Scalars['String']['output']>
    visible_attributes?: Maybe<Array<Maybe<VisibleAttributes>>>
    vydanie?: Maybe<Scalars['String']['output']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    weight_kilograms_kg?: Maybe<Scalars['Int']['output']>
  }

/** DownloadableProduct defines a product that the shopper downloads */
export type DownloadableProductProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** DownloadableProduct defines a product that the shopper downloads */
export type DownloadableProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type DownloadableProductCartItemInput = {
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  data: CartItemInput
  downloadable_product_links?: InputMaybe<
    Array<InputMaybe<DownloadableProductLinksInput>>
  >
}

/** DownloadableProductLinks defines characteristics of a downloadable product */
export type DownloadableProductLinks = {
  __typename?: 'DownloadableProductLinks'
  /** @deprecated This information should not be exposed on frontend */
  id?: Maybe<Scalars['Int']['output']>
  /** @deprecated This information should not be exposed on frontend */
  is_shareable?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  link_type?: Maybe<DownloadableFileTypeEnum>
  /** @deprecated This information should not be exposed on frontend */
  number_of_downloads?: Maybe<Scalars['Int']['output']>
  /** The price of the downloadable product */
  price?: Maybe<Scalars['Float']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>
  /** URL to the downloadable sample */
  sample_url?: Maybe<Scalars['String']['output']>
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name of the link */
  title?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID']['output']
}

export type DownloadableProductLinksInput = {
  link_id: Scalars['Int']['input']
}

/** DownloadableProductSamples defines characteristics of a downloadable product */
export type DownloadableProductSamples = {
  __typename?: 'DownloadableProductSamples'
  /** @deprecated This information should not be exposed on frontend */
  id?: Maybe<Scalars['Int']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']['output']>
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>
  /** URL to the downloadable sample */
  sample_url?: Maybe<Scalars['String']['output']>
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']['output']>
  /** The display name of the sample */
  title?: Maybe<Scalars['String']['output']>
}

/** Contains details about downloadable products added to a requisition list */
export type DownloadableRequisitionListItem = RequisitionListItemInterface & {
  __typename?: 'DownloadableRequisitionListItem'
  /** Selected custom options for an item in the requisition list */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** An array of links for downloadable products in the requisition list */
  links?: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** The product model of the requisition list item */
  product: ProductInterface
  /** The quantity of the product added to the requisition list */
  quantity: Scalars['Float']['output']
  /** An array of links to downloadable product samples */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>
  /** The unique ID of an item in a requisition list */
  uid: Scalars['ID']['output']
}

/** A downloadable product wish list item */
export type DownloadableWishlistItem = WishlistItemInterface & {
  __typename?: 'DownloadableWishlistItem'
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String']['output']
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID']['output']
  /** An array containing information about the selected links */
  links_v2?: Maybe<Array<Maybe<DownloadableProductLinks>>>
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  quantity: Scalars['Float']['output']
  /** An array containing information about the selected samples */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>
}

export type DynamicBlock = {
  __typename?: 'DynamicBlock'
  /** Contains the renderable HTML code of the dynamic block */
  content: ComplexTextValue
  /** The unique ID of a `DynamicBlock` object */
  uid: Scalars['ID']['output']
}

export enum DynamicBlockLocationEnum {
  Content = 'CONTENT',
  Footer = 'FOOTER',
  Header = 'HEADER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum DynamicBlockTypeEnum {
  CartPriceRuleRelated = 'CART_PRICE_RULE_RELATED',
  CatalogPriceRuleRelated = 'CATALOG_PRICE_RULE_RELATED',
  Specified = 'SPECIFIED',
}

export type DynamicBlocks = {
  __typename?: 'DynamicBlocks'
  /** An array containing individual dynamic blocks */
  items: Array<Maybe<DynamicBlock>>
  /** Metadata for pagination rendering */
  page_info?: Maybe<SearchResultPageInfo>
  /** The number of returned dynamic blocks */
  total_count: Scalars['Int']['output']
}

export type DynamicBlocksFilterInput = {
  /** An array of dynamic block UIDs to filter on */
  dynamic_block_uids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** An array indicating the locations the dynamic block can be placed */
  locations?: InputMaybe<Array<InputMaybe<DynamicBlockLocationEnum>>>
  /** A value indicating the type of dynamic block to filter on */
  type: DynamicBlockTypeEnum
}

export type EnteredCustomAttributeInput = {
  /** A string that identifies the entered custom attribute */
  attribute_code: Scalars['String']['input']
  /** The text or other entered value */
  value: Scalars['String']['input']
}

/** Defines a customer-entered option */
export type EnteredOptionInput = {
  /** The unique ID for a `CustomizableFieldOption`, `CustomizableFileOption`, `CustomizableAreaOption`, etc. of `CustomizableOptionInterface` objects */
  uid: Scalars['ID']['input']
  /** Text the customer entered */
  value: Scalars['String']['input']
}

/** EntityUrl is an output object containing the `id`, `relative_url`, and `type` attributes */
export type EntityUrl = {
  __typename?: 'EntityUrl'
  /** @deprecated The canonical_url field is deprecated, use relative_url instead. */
  canonical_url?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, etc. object associated with the specified url. This could be a product UID, category UID, or cms page UID. */
  entity_uid?: Maybe<Scalars['ID']['output']>
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id?: Maybe<Scalars['Int']['output']>
  /** 301 or 302 HTTP code for url permanent or temporary redirect or 0 for the 200 no redirect */
  redirectCode?: Maybe<Scalars['Int']['output']>
  /** The internal relative URL. If the specified  url is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>
}

export type EstimatedDateOfArrivalType = {
  __typename?: 'EstimatedDateOfArrivalType'
  formatted_date?: Maybe<Scalars['String']['output']>
  timestamp?: Maybe<Scalars['String']['output']>
}

export type ExchangeRate = {
  __typename?: 'ExchangeRate'
  currency_to?: Maybe<Scalars['String']['output']>
  rate?: Maybe<Scalars['Float']['output']>
}

/** Defines a filter that matches the input exactly. */
export type FilterEqualTypeInput = {
  /** A string to filter on */
  eq?: InputMaybe<Scalars['String']['input']>
  /** An array of values to filter on */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type FilterIntTypeInput = {
  /** Equal to the specified value */
  eq?: InputMaybe<Scalars['Int']['input']>
  /** Matches any of the specified values */
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** Not equal to the specified value */
  neq?: InputMaybe<Scalars['Int']['input']>
}

/** Defines a filter that performs a fuzzy search. */
export type FilterMatchTypeInput = {
  /** One or more words to filter on */
  match?: InputMaybe<Scalars['String']['input']>
}

/** Defines a filter that matches a range of values, such as prices or dates. */
export type FilterRangeTypeInput = {
  /** The beginning of the range */
  from?: InputMaybe<Scalars['String']['input']>
  /** The end of the range */
  to?: InputMaybe<Scalars['String']['input']>
}

/** Defines a filter for an input string. */
export type FilterStringTypeInput = {
  /** Filters items that are exactly the same as the specified string. */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: InputMaybe<Scalars['String']['input']>
}

/** FilterTypeInput specifies which action will be performed in a query  */
export type FilterTypeInput = {
  /** Equals */
  eq?: InputMaybe<Scalars['String']['input']>
  finset?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** From. Must be used with 'to' */
  from?: InputMaybe<Scalars['String']['input']>
  /** Greater than */
  gt?: InputMaybe<Scalars['String']['input']>
  /** Greater than or equal to */
  gteq?: InputMaybe<Scalars['String']['input']>
  /** In. The value can contain a set of comma-separated values */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters */
  like?: InputMaybe<Scalars['String']['input']>
  /** Less than */
  lt?: InputMaybe<Scalars['String']['input']>
  /** Less than or equal to */
  lteq?: InputMaybe<Scalars['String']['input']>
  /** More than or equal to */
  moreq?: InputMaybe<Scalars['String']['input']>
  /** Not equal to */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Not in. The value can contain a set of comma-separated values */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Not null */
  notnull?: InputMaybe<Scalars['String']['input']>
  /** Is null */
  null?: InputMaybe<Scalars['String']['input']>
  /** To. Must be used with 'from' */
  to?: InputMaybe<Scalars['String']['input']>
}

/** A single FPT that can be applied to a product price. */
export type FixedProductTax = {
  __typename?: 'FixedProductTax'
  /** Amount of the FPT as a money object. */
  amount?: Maybe<Money>
  /** The label assigned to the FPT to be displayed on the frontend. */
  label?: Maybe<Scalars['String']['output']>
}

/** This enumeration display settings for the fixed product tax */
export enum FixedProductTaxDisplaySettings {
  /** The displayed price does not include the FPT amount. The values of ProductPrice.fixed_product_taxes and the price including the FPT are displayed separately. This value corresponds to 'Excluding FPT, Including FPT description and final price' */
  ExcludeFptAndIncludeWithDetails = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /** The displayed price does not include the FPT amount. The values from ProductPrice.fixed_product_taxes are not displayed. This value corresponds to 'Excluding FPT' */
  ExcludeFptWithoutDetails = 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /** The FPT feature is not enabled. You can omit  ProductPrice.fixed_product_taxes from your query */
  FptDisabled = 'FPT_DISABLED',
  /** The displayed price includes the FPT amount without displaying the ProductPrice.fixed_product_taxes values. This value corresponds to 'Including FPT only' */
  IncludeFptWithoutDetails = 'INCLUDE_FPT_WITHOUT_DETAILS',
  /** The displayed price includes the FPT amount while displaying the values of ProductPrice.fixed_product_taxes separately. This value corresponds to 'Including FPT and FPT description' */
  IncludeFptWithDetails = 'INCLUDE_FPT_WITH_DETAILS',
}

export type GenerateCustomerTokenAsAdminInput = {
  /** The email address of the customer requesting remote shopping assistance */
  customer_email: Scalars['String']['input']
}

export type GenerateCustomerTokenAsAdminOutput = {
  __typename?: 'GenerateCustomerTokenAsAdminOutput'
  /** The generated customer token */
  customer_token: Scalars['String']['output']
}

/** Contains details about the gift card account */
export type GiftCardAccount = {
  __typename?: 'GiftCardAccount'
  /** Balance remaining on gift card */
  balance?: Maybe<Money>
  /** Gift card account code */
  code?: Maybe<Scalars['String']['output']>
  /** Gift card expiration date */
  expiration_date?: Maybe<Scalars['String']['output']>
}

export type GiftCardAccountInput = {
  /** Defines the input required to identify the gift card account */
  gift_card_code: Scalars['String']['input']
}

/** GiftCardAmounts contains the value of a gift card, the website that generated the card, and related information */
export type GiftCardAmounts = {
  __typename?: 'GiftCardAmounts'
  /** An internal attribute ID. */
  attribute_id?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `GiftCardAmounts` object */
  uid: Scalars['ID']['output']
  /** The value of the gift card */
  value?: Maybe<Scalars['Float']['output']>
  /**
   * An ID that is assigned to each unique gift card amount.
   * @deprecated Use `uid` instead
   */
  value_id?: Maybe<Scalars['Int']['output']>
  /** ID of the website that generated the gift card */
  website_id?: Maybe<Scalars['Int']['output']>
  /** The value of the gift card */
  website_value?: Maybe<Scalars['Float']['output']>
}

/** Contains details about a gift card */
export type GiftCardCartItem = CartItemInterface & {
  __typename?: 'GiftCardCartItem'
  /** The amount and currency of the gift card */
  amount: Money
  /** An array of customizations made to the gift card */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** @deprecated Use `uid` instead */
  id: Scalars['String']['output']
  /** A message to the recipient */
  message?: Maybe<Scalars['String']['output']>
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']['output']
  /** The email of the person receiving the gift card */
  recipient_email?: Maybe<Scalars['String']['output']>
  /** The name of the person receiving the gift card */
  recipient_name: Scalars['String']['output']
  rule_id?: Maybe<Scalars['Int']['output']>
  /** The email of the sender */
  sender_email?: Maybe<Scalars['String']['output']>
  /** The name of the sender */
  sender_name: Scalars['String']['output']
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID']['output']
}

export type GiftCardCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'GiftCardCreditMemoItem'
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** Selected gift card properties for an credit memo item */
  gift_card?: Maybe<GiftCardItem>
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID']['output']
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product, including selected options */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
}

export type GiftCardInvoiceItem = InvoiceItemInterface & {
  __typename?: 'GiftCardInvoiceItem'
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** Selected gift card properties for an invoice item */
  gift_card?: Maybe<GiftCardItem>
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID']['output']
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
}

export type GiftCardItem = {
  __typename?: 'GiftCardItem'
  /** Entered gift card message intended for the recipient */
  message?: Maybe<Scalars['String']['output']>
  /** Entered gift card recipient email for virtual cards */
  recipient_email?: Maybe<Scalars['String']['output']>
  /** Entered gift card sender namefor physical and virtual cards */
  recipient_name?: Maybe<Scalars['String']['output']>
  /** Entered gift card sender email for virtual cards */
  sender_email?: Maybe<Scalars['String']['output']>
  /** Entered gift card sender name for physical and virtual cards */
  sender_name?: Maybe<Scalars['String']['output']>
}

export type GiftCardOptions = {
  __typename?: 'GiftCardOptions'
  /** The amount and currency of the gift card */
  amount?: Maybe<Money>
  /** The custom amount and currency of the gift card */
  custom_giftcard_amount?: Maybe<Money>
  /** A message to the recipient */
  message?: Maybe<Scalars['String']['output']>
  /** The email address of the person receiving the gift card */
  recipient_email?: Maybe<Scalars['String']['output']>
  /** The name of the person receiving the gift card */
  recipient_name?: Maybe<Scalars['String']['output']>
  /** The email address of the person sending the gift card */
  sender_email?: Maybe<Scalars['String']['output']>
  /** The name of the person sending the gift card */
  sender_name?: Maybe<Scalars['String']['output']>
}

export type GiftCardOrderItem = OrderItemInterface & {
  __typename?: 'GiftCardOrderItem'
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** Indicates whether the order item is eligible is eligible to be in a return request */
  eligible_for_return?: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** Selected gift card properties for an order item */
  gift_card?: Maybe<GiftCardItem>
  /** The selected gift wrapping for the order item */
  gift_wrapping?: Maybe<GiftWrapping>
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  is_saleable?: Maybe<Scalars['Boolean']['output']>
  parent_sku?: Maybe<Scalars['String']['output']>
  product_image?: Maybe<Scalars['String']['output']>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']['output']>
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']['output']>
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']['output']>
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']['output']>
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item */
  status?: Maybe<Scalars['String']['output']>
}

/** GiftCardProduct defines properties of a gift card, including the minimum and maximum values and an array that contains the current and past values on the specific gift card */
export type GiftCardProduct = CustomizableProductInterface &
  PhysicalProductInterface &
  ProductInterface &
  RoutableInterface & {
    __typename?: 'GiftCardProduct'
    allergen_celery?: Maybe<Scalars['Int']['output']>
    allergen_crustaceans?: Maybe<Scalars['Int']['output']>
    allergen_eggs?: Maybe<Scalars['Int']['output']>
    allergen_fish?: Maybe<Scalars['Int']['output']>
    allergen_gluten?: Maybe<Scalars['Int']['output']>
    allergen_lupin?: Maybe<Scalars['Int']['output']>
    allergen_milk?: Maybe<Scalars['Int']['output']>
    allergen_molluscs?: Maybe<Scalars['Int']['output']>
    allergen_mustard?: Maybe<Scalars['Int']['output']>
    allergen_peanuts?: Maybe<Scalars['Int']['output']>
    allergen_sesame?: Maybe<Scalars['Int']['output']>
    allergen_soya?: Maybe<Scalars['Int']['output']>
    allergen_sulphites?: Maybe<Scalars['Int']['output']>
    allergen_tree_nuts?: Maybe<Scalars['Int']['output']>
    /** Indicates whether the customer can provide a message to accompany the gift card. */
    allow_message?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether customers have the ability to set the value of the gift card. */
    allow_open_amount?: Maybe<Scalars['Boolean']['output']>
    application?: Maybe<Scalars['Int']['output']>
    artificial_sweetener_free?: Maybe<Scalars['Int']['output']>
    aspartame_free?: Maybe<Scalars['Int']['output']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attribute_set_id?: Maybe<Scalars['Int']['output']>
    available_for_sdd?: Maybe<Scalars['String']['output']>
    bag_volume_range?: Maybe<Scalars['Int']['output']>
    bio?: Maybe<Scalars['Int']['output']>
    blend?: Maybe<Scalars['Int']['output']>
    bottom?: Maybe<Scalars['String']['output']>
    breadcrumb?: Maybe<Array<Maybe<BreadcrumbType>>>
    breadcrumb_en?: Maybe<Scalars['String']['output']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']['output']>
    capsules?: Maybe<Scalars['String']['output']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    color?: Maybe<Scalars['String']['output']>
    colors?: Maybe<Scalars['String']['output']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']['output']>
    coupon_amount?: Maybe<Scalars['Int']['output']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    created_at?: Maybe<Scalars['String']['output']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    default_category?: Maybe<Scalars['String']['output']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    ean?: Maybe<Scalars['String']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    estimated_date_of_arrival?: Maybe<EstimatedDateOfArrivalType>
    expected_arrival_date?: Maybe<Scalars['String']['output']>
    farba_oblecenia?: Maybe<Scalars['String']['output']>
    farba_sejkera?: Maybe<Scalars['String']['output']>
    fit?: Maybe<Scalars['String']['output']>
    fitness_goal?: Maybe<Scalars['String']['output']>
    flavor?: Maybe<Scalars['String']['output']>
    form?: Maybe<Scalars['Int']['output']>
    /** Product Labels list */
    gb_product_labels?: Maybe<Array<Maybe<Label>>>
    gender_segmentation?: Maybe<Scalars['String']['output']>
    /** An array of giftcard options. */
    gift_card_options: Array<Maybe<CustomizableOptionInterface>>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']['output']>
    /** An array that contains information about the values and ID of a gift card. */
    giftcard_amounts?: Maybe<Array<Maybe<GiftCardAmounts>>>
    /** Either VIRTUAL, PHYSICAL, or COMBINED. */
    giftcard_type?: Maybe<GiftCardTypeEnum>
    glutenfree?: Maybe<Scalars['Int']['output']>
    gmo_free?: Maybe<Scalars['Int']['output']>
    heureka_name?: Maybe<Scalars['String']['output']>
    hodnota_kuponu?: Maybe<Scalars['String']['output']>
    hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']['output']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** Indicates whether the customer can redeem the value on the card for cash. */
    is_redeemable?: Maybe<Scalars['Boolean']['output']>
    /** Indicates whether the product can be returned */
    is_returnable?: Maybe<Scalars['String']['output']>
    lactosefree?: Maybe<Scalars['Int']['output']>
    /** The latest Related Blog Posts for a specific Product and Store */
    latest_related_blog_posts?: Maybe<LatestRelatedBlogPosts>
    legal_category_of_product?: Maybe<Scalars['Int']['output']>
    /** The number of days after purchase until the gift card expires. A null value means there is no limit. */
    lifetime?: Maybe<Scalars['Int']['output']>
    long_term_unavailable?: Maybe<Scalars['Int']['output']>
    main_ingredient?: Maybe<Scalars['Int']['output']>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']['output']>
    /** Manufacturer's info. */
    manufacturer_info?: Maybe<ManufacturerInfoInterface>
    manufacturer_model?: Maybe<Scalars['String']['output']>
    mass_grams_g?: Maybe<Scalars['String']['output']>
    mass_mililiter_ml?: Maybe<Scalars['Int']['output']>
    material?: Maybe<Scalars['Int']['output']>
    maximum_duration?: Maybe<Scalars['String']['output']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** Indicates whether the customer can provide a message to accompany the gift card. */
    message_max_length?: Maybe<Scalars['Int']['output']>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']['output']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']['output']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']['output']>
    method_of_protein_processing?: Maybe<Scalars['Int']['output']>
    mgs_brand?: Maybe<Scalars['Int']['output']>
    mgs_j360?: Maybe<Scalars['Int']['output']>
    milimeter_mm?: Maybe<Scalars['Int']['output']>
    mininum_order_quantity?: Maybe<Scalars['String']['output']>
    /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
    msrp_price?: Maybe<Scalars['Float']['output']>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']['output']>
    new_from?: Maybe<Scalars['String']['output']>
    /**
     * The beginning date for new product listings, and determines if the product is featured as a new product.
     * @deprecated The field should not be used on the storefront.
     */
    new_from_date?: Maybe<Scalars['String']['output']>
    /**
     * The end date for new product listings.
     * @deprecated The field should not be used on the storefront.
     */
    new_to_date?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_batch?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_package_o?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_pallette?: Maybe<Scalars['String']['output']>
    objem?: Maybe<Scalars['String']['output']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']['output']>
    /** The minimum acceptable value of an open amount gift card. */
    open_amount_max?: Maybe<Scalars['Float']['output']>
    /** The minimum acceptable value of an open amount gift card. */
    open_amount_min?: Maybe<Scalars['Float']['output']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']['output']>
    other_models?: Maybe<Array<Maybe<OtherModelsValues>>>
    plastic_packaging_free?: Maybe<Scalars['Int']['output']>
    preparation?: Maybe<Scalars['String']['output']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    prichut?: Maybe<Scalars['String']['output']>
    /** The list of products questions. */
    productQuestions: ProductQuestions
    /** The collection of Product labels for this Product */
    product_labels?: Maybe<Array<Maybe<AmLabel>>>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    product_sort_coefficient?: Maybe<Scalars['String']['output']>
    protein_content_in_product?: Maybe<Scalars['String']['output']>
    protein_sourcee?: Maybe<Scalars['Int']['output']>
    purchasing_category?: Maybe<Scalars['Int']['output']>
    /** The average of all the ratings given to the product. */
    rating_summary: Scalars['Float']['output']
    recycling_codes?: Maybe<Scalars['String']['output']>
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
    redirect_code: Scalars['Int']['output']
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
    relative_url?: Maybe<Scalars['String']['output']>
    /** The total count of all the reviews given to the product. */
    review_count: Scalars['Int']['output']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** A short description of the product. Its use depends on the theme. */
    sales_description?: Maybe<ComplexTextValue>
    seasonality?: Maybe<Scalars['Int']['output']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size_of_clothing?: Maybe<Scalars['String']['output']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']['output']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    special_from_date?: Maybe<Scalars['String']['output']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']['output']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']['output']>
    staged: Scalars['Boolean']['output']
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    supplier_internal_code?: Maybe<Scalars['String']['output']>
    sw_featured?: Maybe<Scalars['Int']['output']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']['output']>
    tablets?: Maybe<Scalars['String']['output']>
    tea_package?: Maybe<Scalars['String']['output']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']['output']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    top?: Maybe<Scalars['String']['output']>
    top_in_category?: Maybe<Scalars['String']['output']>
    tops?: Maybe<Scalars['Int']['output']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']['output']>
    type_of_fish?: Maybe<Scalars['Int']['output']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']['output']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updated_at?: Maybe<Scalars['String']['output']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']['output']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']['output']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']['output']>
    variants?: Maybe<Scalars['String']['output']>
    vegan?: Maybe<Scalars['Int']['output']>
    vegetarian?: Maybe<Scalars['Int']['output']>
    velkost_oblecenia?: Maybe<Scalars['String']['output']>
    velkost_sejkera?: Maybe<Scalars['String']['output']>
    visible_attributes?: Maybe<Array<Maybe<VisibleAttributes>>>
    vydanie?: Maybe<Scalars['String']['output']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']['output']>
    weight_kilograms_kg?: Maybe<Scalars['Int']['output']>
  }

/** GiftCardProduct defines properties of a gift card, including the minimum and maximum values and an array that contains the current and past values on the specific gift card */
export type GiftCardProductProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** GiftCardProduct defines properties of a gift card, including the minimum and maximum values and an array that contains the current and past values on the specific gift card */
export type GiftCardProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Contains details about gift cards added to a requisition list */
export type GiftCardRequisitionListItem = RequisitionListItemInterface & {
  __typename?: 'GiftCardRequisitionListItem'
  /** Selected custom options for an item in the requisition list */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** An array that defines gift card properties */
  gift_card_options: GiftCardOptions
  /** The product model of the requisition list item */
  product: ProductInterface
  /** The amount added */
  quantity: Scalars['Float']['output']
  /** The unique ID for the requisition list Item */
  uid: Scalars['ID']['output']
}

export type GiftCardShipmentItem = ShipmentItemInterface & {
  __typename?: 'GiftCardShipmentItem'
  /** Selected gift card properties for an shipment item */
  gift_card?: Maybe<GiftCardItem>
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID']['output']
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** Sale price for the base product */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** Number of shipped items */
  quantity_shipped: Scalars['Float']['output']
}

/** This enumeration defines the types of gift cards */
export enum GiftCardTypeEnum {
  Combined = 'COMBINED',
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL',
}

/** A gift card product wish list item */
export type GiftCardWishlistItem = WishlistItemInterface & {
  __typename?: 'GiftCardWishlistItem'
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String']['output']
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>
  gift_card_options: GiftCardOptions
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID']['output']
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  quantity: Scalars['Float']['output']
}

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessage = {
  __typename?: 'GiftMessage'
  /** Sender name */
  from: Scalars['String']['output']
  /** Gift message text */
  message: Scalars['String']['output']
  /** Recipient name */
  to: Scalars['String']['output']
}

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessageInput = {
  /** Sender name */
  from: Scalars['String']['input']
  /** Gift message text */
  message: Scalars['String']['input']
  /** Recepient name */
  to: Scalars['String']['input']
}

export type GiftOptionsPrices = {
  __typename?: 'GiftOptionsPrices'
  /** Price of the gift wrapping for all individual order items */
  gift_wrapping_for_items?: Maybe<Money>
  /** Price of the gift wrapping for the whole order */
  gift_wrapping_for_order?: Maybe<Money>
  /** Price for the printed card */
  printed_card?: Maybe<Money>
}

export type GiftRegistry = {
  __typename?: 'GiftRegistry'
  /** The date on which the gift registry was created. Only the registry owner can access this attribute */
  created_at: Scalars['String']['output']
  /** An array of attributes that define elements of the gift registry. Each attribute is specified as a code-value pair */
  dynamic_attributes?: Maybe<Array<Maybe<GiftRegistryDynamicAttribute>>>
  /** The name of the event */
  event_name: Scalars['String']['output']
  /** An array of products added to the gift registry */
  items?: Maybe<Array<Maybe<GiftRegistryItemInterface>>>
  /** The message text the customer entered to describe the event */
  message: Scalars['String']['output']
  /** The customer who created the gift registry */
  owner_name: Scalars['String']['output']
  /** An enum that states whether the gift registry is PRIVATE or PUBLIC. Only the registry owner can access this attribute */
  privacy_settings: GiftRegistryPrivacySettings
  /** Contains details about each registrant for the event */
  registrants?: Maybe<Array<Maybe<GiftRegistryRegistrant>>>
  /** Contains the customer's shipping address. Only the registry owner can access this attribute */
  shipping_address?: Maybe<CustomerAddress>
  /** An enum that states whether the gift registry is ACTIVE or INACTIVE. Only the registry owner can access this attribute */
  status: GiftRegistryStatus
  /** Contains details about the type of gift registry */
  type?: Maybe<GiftRegistryType>
  /** The unique ID assigned to the gift registry */
  uid: Scalars['ID']['output']
}

export type GiftRegistryDynamicAttribute =
  GiftRegistryDynamicAttributeInterface & {
    __typename?: 'GiftRegistryDynamicAttribute'
    /** The internal ID of the dynamic attribute */
    code: Scalars['ID']['output']
    /** Indicates which group the dynamic attribute is a member of */
    group: GiftRegistryDynamicAttributeGroup
    /** The display name of the dynamic attribute */
    label: Scalars['String']['output']
    /** A corresponding value for the code */
    value: Scalars['String']['output']
  }

export enum GiftRegistryDynamicAttributeGroup {
  DetailedInformation = 'DETAILED_INFORMATION',
  EventInformation = 'EVENT_INFORMATION',
  GeneralInformation = 'GENERAL_INFORMATION',
  PrivacySettings = 'PRIVACY_SETTINGS',
  Registrant = 'REGISTRANT',
  ShippingAddress = 'SHIPPING_ADDRESS',
}

export type GiftRegistryDynamicAttributeInput = {
  /** A unique key for an additional attribute of the event */
  code: Scalars['ID']['input']
  /** A string that describes a dynamic attribute */
  value: Scalars['String']['input']
}

export type GiftRegistryDynamicAttributeInterface = {
  /** The internal ID of the dynamic attribute */
  code: Scalars['ID']['output']
  /** The display name of the dynamic attribute */
  label: Scalars['String']['output']
  /** A corresponding value for the code */
  value: Scalars['String']['output']
}

export type GiftRegistryDynamicAttributeMetadata =
  GiftRegistryDynamicAttributeMetadataInterface & {
    __typename?: 'GiftRegistryDynamicAttributeMetadata'
    /** Indicates which group the dynamic attribute a member of */
    attribute_group: Scalars['String']['output']
    /** The internal ID of the dynamic attribute */
    code: Scalars['ID']['output']
    /** The selected input type for this dynamic attribute. The value can be one of several static or custom types */
    input_type: Scalars['String']['output']
    /** Indicates whether the dynamic attribute is required */
    is_required: Scalars['Boolean']['output']
    /** The display name of the dynamic attribute */
    label: Scalars['String']['output']
    /** The order in which to display the dynamic attribute */
    sort_order?: Maybe<Scalars['Int']['output']>
  }

export type GiftRegistryDynamicAttributeMetadataInterface = {
  /** Indicates which group the dynamic attribute a member of */
  attribute_group: Scalars['String']['output']
  /** The internal ID of the dynamic attribute */
  code: Scalars['ID']['output']
  /** The selected input type for this dynamic attribute. The value can be one of several static or custom types */
  input_type: Scalars['String']['output']
  /** Indicates whether the dynamic attribute is required */
  is_required: Scalars['Boolean']['output']
  /** The display name of the dynamic attribute */
  label: Scalars['String']['output']
  /** The order in which to display the dynamic attribute */
  sort_order?: Maybe<Scalars['Int']['output']>
}

export type GiftRegistryItem = GiftRegistryItemInterface & {
  __typename?: 'GiftRegistryItem'
  /** The date the product was added to the gift registry */
  created_at: Scalars['String']['output']
  /** A brief message about the gift registry item */
  note?: Maybe<Scalars['String']['output']>
  product?: Maybe<ProductInterface>
  /** The requested quantity of the product */
  quantity: Scalars['Float']['output']
  /** The fulfilled quantity of the product */
  quantity_fulfilled: Scalars['Float']['output']
  /** The unique ID of a gift registry item */
  uid: Scalars['ID']['output']
}

export type GiftRegistryItemInterface = {
  /** The date the product was added to the gift registry */
  created_at: Scalars['String']['output']
  /** A brief message about the gift registry item */
  note?: Maybe<Scalars['String']['output']>
  product?: Maybe<ProductInterface>
  /** The requested quantity of the product */
  quantity: Scalars['Float']['output']
  /** The fulfilled quantity of the product */
  quantity_fulfilled: Scalars['Float']['output']
  /** The unique ID of a gift registry item */
  uid: Scalars['ID']['output']
}

/** Contains the status and any errors that encountered with the customer's gift register item */
export type GiftRegistryItemUserErrorInterface = {
  /** Indicates whether the attempt to move the cart items to the gift registry was successful */
  status: Scalars['Boolean']['output']
  /** An array of errors encountered while moving items from the cart to the gift registry */
  user_errors: Array<Maybe<GiftRegistryItemsUserError>>
}

export type GiftRegistryItemUserErrors = GiftRegistryItemUserErrorInterface & {
  __typename?: 'GiftRegistryItemUserErrors'
  /** Indicates whether the attempt to move the cart items to the gift registry was successful */
  status: Scalars['Boolean']['output']
  /** An array of errors encountered while moving items from the cart to the gift registry */
  user_errors: Array<Maybe<GiftRegistryItemsUserError>>
}

export type GiftRegistryItemsUserError = {
  __typename?: 'GiftRegistryItemsUserError'
  /** An error code that describes the error encountered */
  code: GiftRegistryItemsUserErrorType
  /** The unique ID of the gift registry item containing an error */
  gift_registry_item_uid?: Maybe<Scalars['ID']['output']>
  /** The unique ID of the `Gift registry` object containing an error */
  gift_registry_uid?: Maybe<Scalars['ID']['output']>
  /** A localized error message */
  message: Scalars['String']['output']
  /** The unique ID of the product containing an error */
  product_uid?: Maybe<Scalars['ID']['output']>
}

export enum GiftRegistryItemsUserErrorType {
  /** Used for exceptions like EntityNotFound */
  NotFound = 'NOT_FOUND',
  /** Used for handling out of stock products */
  OutOfStock = 'OUT_OF_STOCK',
  /** Used for other exceptions, such as database connection failures */
  Undefined = 'UNDEFINED',
}

export type GiftRegistryOutput = GiftRegistryOutputInterface & {
  __typename?: 'GiftRegistryOutput'
  /** The gift registry */
  gift_registry?: Maybe<GiftRegistry>
}

/** Contains the customer's gift registry */
export type GiftRegistryOutputInterface = {
  /** The gift registry */
  gift_registry?: Maybe<GiftRegistry>
}

export enum GiftRegistryPrivacySettings {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type GiftRegistryRegistrant = {
  __typename?: 'GiftRegistryRegistrant'
  /** An array of dynamic attributes assigned to the registrant */
  dynamic_attributes?: Maybe<
    Array<Maybe<GiftRegistryRegistrantDynamicAttribute>>
  >
  /** The email address of the registrant. Only the registry owner can access this attribute */
  email: Scalars['String']['output']
  /** The first name of the registrant */
  firstname: Scalars['String']['output']
  /** The last name of the registrant */
  lastname: Scalars['String']['output']
  /** The unique ID assigned to the registrant */
  uid: Scalars['ID']['output']
}

export type GiftRegistryRegistrantDynamicAttribute =
  GiftRegistryDynamicAttributeInterface & {
    __typename?: 'GiftRegistryRegistrantDynamicAttribute'
    /** The internal ID of the dynamic attribute */
    code: Scalars['ID']['output']
    /** The display name of the dynamic attribute */
    label: Scalars['String']['output']
    /** A corresponding value for the code */
    value: Scalars['String']['output']
  }

export type GiftRegistrySearchResult = {
  __typename?: 'GiftRegistrySearchResult'
  /** The date of the event */
  event_date?: Maybe<Scalars['String']['output']>
  /** The title given to the event */
  event_title: Scalars['String']['output']
  /** The URL key of the gift registry */
  gift_registry_uid: Scalars['ID']['output']
  /** The location of the event */
  location?: Maybe<Scalars['String']['output']>
  /** The name of the gift registry owner */
  name: Scalars['String']['output']
  /** The type of event being held */
  type?: Maybe<Scalars['String']['output']>
}

/** Specify either address_data or the address_id. If both are provided, validation will fail */
export type GiftRegistryShippingAddressInput = {
  /** Defines the shipping address for this gift registry */
  address_data?: InputMaybe<CustomerAddressInput>
  /** The ID assigned to this customer address */
  address_id?: InputMaybe<Scalars['ID']['input']>
}

export enum GiftRegistryStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type GiftRegistryType = {
  __typename?: 'GiftRegistryType'
  /** An array of attributes that define elements of the gift registry. Each attribute is specified as a code-value pair */
  dynamic_attributes_metadata?: Maybe<
    Array<Maybe<GiftRegistryDynamicAttributeMetadataInterface>>
  >
  /** The label assigned to the gift registry type on the Admin */
  label: Scalars['String']['output']
  /** The unique ID assigned to the gift registry type */
  uid: Scalars['ID']['output']
}

export type GiftWrapping = {
  __typename?: 'GiftWrapping'
  /** Gift wrapping design name */
  design: Scalars['String']['output']
  /**
   * The unique ID for a `GiftWrapping` object
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID']['output']
  /** Gift wrapping preview image */
  image?: Maybe<GiftWrappingImage>
  /** Gift wrapping price */
  price: Money
  /** The unique ID for a `GiftWrapping` object */
  uid: Scalars['ID']['output']
}

export type GiftWrappingImage = {
  __typename?: 'GiftWrappingImage'
  /** Gift wrapping preview image label */
  label: Scalars['String']['output']
  /** Gift wrapping preview image URL */
  url: Scalars['String']['output']
}

/** A grouped product consists of simple standalone products that are presented as a group */
export type GroupedProduct = PhysicalProductInterface &
  ProductInterface &
  RoutableInterface & {
    __typename?: 'GroupedProduct'
    allergen_celery?: Maybe<Scalars['Int']['output']>
    allergen_crustaceans?: Maybe<Scalars['Int']['output']>
    allergen_eggs?: Maybe<Scalars['Int']['output']>
    allergen_fish?: Maybe<Scalars['Int']['output']>
    allergen_gluten?: Maybe<Scalars['Int']['output']>
    allergen_lupin?: Maybe<Scalars['Int']['output']>
    allergen_milk?: Maybe<Scalars['Int']['output']>
    allergen_molluscs?: Maybe<Scalars['Int']['output']>
    allergen_mustard?: Maybe<Scalars['Int']['output']>
    allergen_peanuts?: Maybe<Scalars['Int']['output']>
    allergen_sesame?: Maybe<Scalars['Int']['output']>
    allergen_soya?: Maybe<Scalars['Int']['output']>
    allergen_sulphites?: Maybe<Scalars['Int']['output']>
    allergen_tree_nuts?: Maybe<Scalars['Int']['output']>
    application?: Maybe<Scalars['Int']['output']>
    artificial_sweetener_free?: Maybe<Scalars['Int']['output']>
    aspartame_free?: Maybe<Scalars['Int']['output']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attribute_set_id?: Maybe<Scalars['Int']['output']>
    available_for_sdd?: Maybe<Scalars['String']['output']>
    bag_volume_range?: Maybe<Scalars['Int']['output']>
    bio?: Maybe<Scalars['Int']['output']>
    blend?: Maybe<Scalars['Int']['output']>
    bottom?: Maybe<Scalars['String']['output']>
    breadcrumb?: Maybe<Array<Maybe<BreadcrumbType>>>
    breadcrumb_en?: Maybe<Scalars['String']['output']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']['output']>
    capsules?: Maybe<Scalars['String']['output']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    color?: Maybe<Scalars['String']['output']>
    colors?: Maybe<Scalars['String']['output']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']['output']>
    coupon_amount?: Maybe<Scalars['Int']['output']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    created_at?: Maybe<Scalars['String']['output']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    default_category?: Maybe<Scalars['String']['output']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    ean?: Maybe<Scalars['String']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    estimated_date_of_arrival?: Maybe<EstimatedDateOfArrivalType>
    expected_arrival_date?: Maybe<Scalars['String']['output']>
    farba_oblecenia?: Maybe<Scalars['String']['output']>
    farba_sejkera?: Maybe<Scalars['String']['output']>
    fit?: Maybe<Scalars['String']['output']>
    fitness_goal?: Maybe<Scalars['String']['output']>
    flavor?: Maybe<Scalars['String']['output']>
    form?: Maybe<Scalars['Int']['output']>
    /** Product Labels list */
    gb_product_labels?: Maybe<Array<Maybe<Label>>>
    gender_segmentation?: Maybe<Scalars['String']['output']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']['output']>
    glutenfree?: Maybe<Scalars['Int']['output']>
    gmo_free?: Maybe<Scalars['Int']['output']>
    heureka_name?: Maybe<Scalars['String']['output']>
    hodnota_kuponu?: Maybe<Scalars['String']['output']>
    hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']['output']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** Indicates whether the product can be returned */
    is_returnable?: Maybe<Scalars['String']['output']>
    /** An array containing grouped product items */
    items?: Maybe<Array<Maybe<GroupedProductItem>>>
    lactosefree?: Maybe<Scalars['Int']['output']>
    /** The latest Related Blog Posts for a specific Product and Store */
    latest_related_blog_posts?: Maybe<LatestRelatedBlogPosts>
    legal_category_of_product?: Maybe<Scalars['Int']['output']>
    long_term_unavailable?: Maybe<Scalars['Int']['output']>
    main_ingredient?: Maybe<Scalars['Int']['output']>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']['output']>
    /** Manufacturer's info. */
    manufacturer_info?: Maybe<ManufacturerInfoInterface>
    manufacturer_model?: Maybe<Scalars['String']['output']>
    mass_grams_g?: Maybe<Scalars['String']['output']>
    mass_mililiter_ml?: Maybe<Scalars['Int']['output']>
    material?: Maybe<Scalars['Int']['output']>
    maximum_duration?: Maybe<Scalars['String']['output']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']['output']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']['output']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']['output']>
    method_of_protein_processing?: Maybe<Scalars['Int']['output']>
    mgs_brand?: Maybe<Scalars['Int']['output']>
    mgs_j360?: Maybe<Scalars['Int']['output']>
    milimeter_mm?: Maybe<Scalars['Int']['output']>
    mininum_order_quantity?: Maybe<Scalars['String']['output']>
    /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
    msrp_price?: Maybe<Scalars['Float']['output']>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']['output']>
    new_from?: Maybe<Scalars['String']['output']>
    /**
     * The beginning date for new product listings, and determines if the product is featured as a new product.
     * @deprecated The field should not be used on the storefront.
     */
    new_from_date?: Maybe<Scalars['String']['output']>
    /**
     * The end date for new product listings.
     * @deprecated The field should not be used on the storefront.
     */
    new_to_date?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_batch?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_package_o?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_pallette?: Maybe<Scalars['String']['output']>
    objem?: Maybe<Scalars['String']['output']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']['output']>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']['output']>
    other_models?: Maybe<Array<Maybe<OtherModelsValues>>>
    plastic_packaging_free?: Maybe<Scalars['Int']['output']>
    preparation?: Maybe<Scalars['String']['output']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    prichut?: Maybe<Scalars['String']['output']>
    /** The list of products questions. */
    productQuestions: ProductQuestions
    /** The collection of Product labels for this Product */
    product_labels?: Maybe<Array<Maybe<AmLabel>>>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    product_sort_coefficient?: Maybe<Scalars['String']['output']>
    protein_content_in_product?: Maybe<Scalars['String']['output']>
    protein_sourcee?: Maybe<Scalars['Int']['output']>
    purchasing_category?: Maybe<Scalars['Int']['output']>
    /** The average of all the ratings given to the product. */
    rating_summary: Scalars['Float']['output']
    recycling_codes?: Maybe<Scalars['String']['output']>
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
    redirect_code: Scalars['Int']['output']
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
    relative_url?: Maybe<Scalars['String']['output']>
    /** The total count of all the reviews given to the product. */
    review_count: Scalars['Int']['output']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** A short description of the product. Its use depends on the theme. */
    sales_description?: Maybe<ComplexTextValue>
    seasonality?: Maybe<Scalars['Int']['output']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size_of_clothing?: Maybe<Scalars['String']['output']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']['output']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    special_from_date?: Maybe<Scalars['String']['output']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']['output']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']['output']>
    staged: Scalars['Boolean']['output']
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    supplier_internal_code?: Maybe<Scalars['String']['output']>
    sw_featured?: Maybe<Scalars['Int']['output']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']['output']>
    tablets?: Maybe<Scalars['String']['output']>
    tea_package?: Maybe<Scalars['String']['output']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']['output']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    top?: Maybe<Scalars['String']['output']>
    top_in_category?: Maybe<Scalars['String']['output']>
    tops?: Maybe<Scalars['Int']['output']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']['output']>
    type_of_fish?: Maybe<Scalars['Int']['output']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']['output']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updated_at?: Maybe<Scalars['String']['output']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']['output']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']['output']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']['output']>
    variants?: Maybe<Scalars['String']['output']>
    vegan?: Maybe<Scalars['Int']['output']>
    vegetarian?: Maybe<Scalars['Int']['output']>
    velkost_oblecenia?: Maybe<Scalars['String']['output']>
    velkost_sejkera?: Maybe<Scalars['String']['output']>
    visible_attributes?: Maybe<Array<Maybe<VisibleAttributes>>>
    vydanie?: Maybe<Scalars['String']['output']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']['output']>
    weight_kilograms_kg?: Maybe<Scalars['Int']['output']>
  }

/** A grouped product consists of simple standalone products that are presented as a group */
export type GroupedProductProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** A grouped product consists of simple standalone products that are presented as a group */
export type GroupedProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** GroupedProductItem contains information about an individual grouped product item */
export type GroupedProductItem = {
  __typename?: 'GroupedProductItem'
  /** The relative position of this item compared to the other group items */
  position?: Maybe<Scalars['Int']['output']>
  /** The ProductInterface object, which contains details about this product option */
  product?: Maybe<ProductInterface>
  /** The quantity of this grouped product item */
  qty?: Maybe<Scalars['Float']['output']>
}

/** A grouped product wish list item */
export type GroupedProductWishlistItem = WishlistItemInterface & {
  __typename?: 'GroupedProductWishlistItem'
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String']['output']
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID']['output']
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  quantity: Scalars['Float']['output']
}

export type HeurekaType = {
  __typename?: 'HeurekaType'
  public_key?: Maybe<Scalars['String']['output']>
  shop_code?: Maybe<Scalars['String']['output']>
  shop_id?: Maybe<Scalars['String']['output']>
  shop_name?: Maybe<Scalars['String']['output']>
  verified_badge?: Maybe<HeurekaVerifiedBadgeType>
  verified_widget?: Maybe<HeurekaVerifiedWidgetType>
  widget_script_src?: Maybe<Scalars['String']['output']>
}

export type HeurekaVerifiedBadgeType = {
  __typename?: 'HeurekaVerifiedBadgeType'
  badge_id?: Maybe<Scalars['String']['output']>
  is_active?: Maybe<Scalars['Boolean']['output']>
}

export type HeurekaVerifiedWidgetType = {
  __typename?: 'HeurekaVerifiedWidgetType'
  is_active?: Maybe<Scalars['Boolean']['output']>
  set_top_pos?: Maybe<Scalars['String']['output']>
  show_widget?: Maybe<Scalars['String']['output']>
}

export type HomepageBannerSliderType = {
  __typename?: 'HomepageBannerSliderType'
  config?: Maybe<BannerSliderConfig>
  items?: Maybe<Array<Maybe<BannerSliderItem>>>
}

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export type HostedProInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

/** Contains secure URL used for Payments Pro Hosted Solution payment method. */
export type HostedProUrl = {
  __typename?: 'HostedProUrl'
  /** Secure Url generated by PayPal */
  secure_form_url?: Maybe<Scalars['String']['output']>
}

/** The required input to request the secure URL for Payments Pro Hosted Solution payment. */
export type HostedProUrlInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
}

export type Hreflangs = {
  __typename?: 'Hreflangs'
  href?: Maybe<Scalars['String']['output']>
  hreflang?: Maybe<Scalars['String']['output']>
}

/** The object details of target path parameters */
export type HttpQueryParameter = {
  __typename?: 'HttpQueryParameter'
  /** Parameter name */
  name?: Maybe<Scalars['String']['output']>
  /** Parameter value */
  value?: Maybe<Scalars['String']['output']>
}

export type ImageSwatchData = SwatchDataInterface & {
  __typename?: 'ImageSwatchData'
  /** Thumbnail swatch image URL */
  thumbnail?: Maybe<Scalars['String']['output']>
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']['output']>
}

export type IndexedEntity = {
  __typename?: 'IndexedEntity'
  id?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type InternalOrderInput = {
  internalOrderId?: InputMaybe<Scalars['String']['input']>
}

export type InternalOrderResponse = {
  __typename?: 'InternalOrderResponse'
  internal_order_id?: Maybe<Scalars['String']['output']>
}

/** Invoice details */
export type Invoice = {
  __typename?: 'Invoice'
  /** Comments on the invoice */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `Invoice` object */
  id: Scalars['ID']['output']
  /** Invoiced product details */
  items?: Maybe<Array<Maybe<InvoiceItemInterface>>>
  /** Sequential invoice number */
  number: Scalars['String']['output']
  /** Invoice total amount details */
  total?: Maybe<InvoiceTotal>
  url?: Maybe<Scalars['String']['output']>
}

export type InvoiceItem = InvoiceItemInterface & {
  __typename?: 'InvoiceItem'
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID']['output']
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
}

/** Invoice item details */
export type InvoiceItemInterface = {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID']['output']
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price for the base product including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
}

/** Contains price details from an invoice */
export type InvoiceTotal = {
  __typename?: 'InvoiceTotal'
  /** The final base grand total amount in the base currency */
  base_grand_total: Money
  /** The applied discounts to the invoice */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money
  /** Contains details about the shipping and handling costs for the invoice */
  shipping_handling?: Maybe<ShippingHandling>
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes */
  subtotal: Money
  /** The invoice tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>
  /** The shipping amount for the invoice */
  total_shipping: Money
  /** The amount of tax applied to the invoice */
  total_tax: Money
}

/** Contains the response of a company admin email validation query */
export type IsCompanyAdminEmailAvailableOutput = {
  __typename?: 'IsCompanyAdminEmailAvailableOutput'
  /** A value of `true` indicates the email address can be used to create a company administrator */
  is_email_available: Scalars['Boolean']['output']
}

/** Contains the response of a company email validation query */
export type IsCompanyEmailAvailableOutput = {
  __typename?: 'IsCompanyEmailAvailableOutput'
  /** A value of `true` indicates the email address can be used to create a company */
  is_email_available: Scalars['Boolean']['output']
}

/** Contains the response of a role name validation query */
export type IsCompanyRoleNameAvailableOutput = {
  __typename?: 'IsCompanyRoleNameAvailableOutput'
  /** A value of `true` indicates the company role name is available */
  is_role_name_available: Scalars['Boolean']['output']
}

/** Contains the response of a company user email validation query */
export type IsCompanyUserEmailAvailableOutput = {
  __typename?: 'IsCompanyUserEmailAvailableOutput'
  /** A value of `true` indicates the email address can be used to create a company user */
  is_email_available: Scalars['Boolean']['output']
}

export type IsEmailAvailableOutput = {
  __typename?: 'IsEmailAvailableOutput'
  /** Is email availabel value */
  is_email_available?: Maybe<Scalars['Boolean']['output']>
}

/** A list of options of the selected bundle product */
export type ItemSelectedBundleOption = {
  __typename?: 'ItemSelectedBundleOption'
  /**
   * The unique ID for a `ItemSelectedBundleOption` object
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID']['output']
  /** The label of the option */
  label: Scalars['String']['output']
  /** The unique ID for a `ItemSelectedBundleOption` object */
  uid: Scalars['ID']['output']
  /** A list of products that represent the values of the parent option */
  values?: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>
}

/** A list of values for the selected bundle product */
export type ItemSelectedBundleOptionValue = {
  __typename?: 'ItemSelectedBundleOptionValue'
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID']['output']
  /** The price of the child bundle product */
  price: Money
  /** The name of the child bundle product */
  product_name: Scalars['String']['output']
  /** The SKU of the child bundle product */
  product_sku: Scalars['String']['output']
  /** Indicates how many of this bundle product were ordered */
  quantity: Scalars['Float']['output']
  /** The unique ID for a `ItemSelectedBundleOptionValue` object */
  uid: Scalars['ID']['output']
}

export type KdukvhType = {
  __typename?: 'KdukvhType'
  is_active?: Maybe<Scalars['Boolean']['output']>
}

/** The key-value type */
export type KeyValue = {
  __typename?: 'KeyValue'
  /** The name part of the name/value pair */
  name?: Maybe<Scalars['String']['output']>
  /** The value part of the name/value pair */
  value?: Maybe<Scalars['String']['output']>
}

export type Label = {
  __typename?: 'Label'
  bg?: Maybe<Scalars['String']['output']>
  content?: Maybe<Scalars['String']['output']>
  discount?: Maybe<Scalars['Int']['output']>
  label?: Maybe<Scalars['Int']['output']>
  src?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

/** The latest Blog Posts for a specific Product and Store */
export type LatestRelatedBlogPosts = {
  __typename?: 'LatestRelatedBlogPosts'
  /** An array of latest Blog Posts for a specific Product and Store */
  items?: Maybe<Array<Maybe<BlogPostInterface>>>
  /** The total number of Blog Posts available at the specified Store */
  total_count?: Maybe<Scalars['Int']['output']>
}

export type LayerFilter = {
  __typename?: 'LayerFilter'
  /**
   * Array of filter items.
   * @deprecated Use Aggregation.options instead.
   */
  filter_items?: Maybe<Array<Maybe<LayerFilterItemInterface>>>
  /**
   * Count of filter items in filter group.
   * @deprecated Use Aggregation.count instead.
   */
  filter_items_count?: Maybe<Scalars['Int']['output']>
  /**
   * Layered navigation filter name.
   * @deprecated Use Aggregation.label instead.
   */
  name?: Maybe<Scalars['String']['output']>
  /**
   * Request variable name for filter query.
   * @deprecated Use Aggregation.attribute_code instead.
   */
  request_var?: Maybe<Scalars['String']['output']>
}

export type LayerFilterItem = LayerFilterItemInterface & {
  __typename?: 'LayerFilterItem'
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']['output']>
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']['output']>
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']['output']>
}

export type LayerFilterItemInterface = {
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']['output']>
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']['output']>
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']['output']>
}

/** Contains basic information about brand for current product */
export type ManufacturerInfoInterface = {
  __typename?: 'ManufacturerInfoInterface'
  name?: Maybe<Scalars['String']['output']>
  pathname?: Maybe<Scalars['String']['output']>
}

/** MediaGalleryEntry defines characteristics about images and videos associated with a specific product. */
export type MediaGalleryEntry = {
  __typename?: 'MediaGalleryEntry'
  /** Contains a ProductMediaGalleryEntriesContent object. */
  content?: Maybe<ProductMediaGalleryEntriesContent>
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']['output']>
  /** The path of the image on the server. */
  file?: Maybe<Scalars['String']['output']>
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']['output']>
  /** The alt text displayed on the UI when the user points to the image. */
  label?: Maybe<Scalars['String']['output']>
  /** image or video. */
  media_type?: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']['output']>
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID']['output']
  /** Contains a ProductMediaGalleryEntriesVideoContent object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** Contains basic information about a product image or video. */
export type MediaGalleryInterface = {
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url?: Maybe<MediaGalleryUrlInterface>
}

/** Contains basic information about a product image or video. */
export type MediaGalleryUrlInterface = {
  __typename?: 'MediaGalleryUrlInterface'
  /** url for full size */
  full?: Maybe<Scalars['String']['output']>
  /** url for size 700x700px */
  medium?: Maybe<Scalars['String']['output']>
  /** url for size 90x90px */
  small?: Maybe<Scalars['String']['output']>
}

export type MessageType = {
  __typename?: 'MessageType'
  message?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type MetaData = {
  __typename?: 'MetaData'
  hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
  meta_description?: Maybe<Scalars['String']['output']>
  meta_robots?: Maybe<Scalars['String']['output']>
  meta_title?: Maybe<Scalars['String']['output']>
}

/** A Money object defines a monetary value, including a numeric value and a currency code. */
export type Money = {
  __typename?: 'Money'
  /** A three-letter currency code, such as USD or EUR */
  currency?: Maybe<CurrencyEnum>
  /** A number expressing a monetary value */
  value?: Maybe<Scalars['Float']['output']>
}

export type MoneyTaxExtended = {
  __typename?: 'MoneyTaxExtended'
  currency?: Maybe<CurrencyEnum>
  value?: Maybe<Scalars['Float']['output']>
  value_excl_tax?: Maybe<Scalars['Float']['output']>
}

/** Contains the customer's gift registry and any errors encountered */
export type MoveCartItemsToGiftRegistryOutput =
  GiftRegistryItemUserErrorInterface &
    GiftRegistryOutputInterface & {
      __typename?: 'MoveCartItemsToGiftRegistryOutput'
      /** The gift registry */
      gift_registry?: Maybe<GiftRegistry>
      /** Indicates whether the attempt to move the cart items to the gift registry was successful */
      status: Scalars['Boolean']['output']
      /** An array of errors encountered while moving items from the cart to the gift registry */
      user_errors: Array<Maybe<GiftRegistryItemsUserError>>
    }

export type MoveItemsBetweenRequisitionListsInput = {
  /** An array of IDs representing products moved from one requisition list to another */
  requisitionListItemUids: Array<InputMaybe<Scalars['ID']['input']>>
}

/** Output of the request to move items to another requisition list */
export type MoveItemsBetweenRequisitionListsOutput = {
  __typename?: 'MoveItemsBetweenRequisitionListsOutput'
  /** The destination requisition list after moving items */
  destination_requisition_list?: Maybe<RequisitionList>
  /** The source requisition list after moving items */
  source_requisition_list?: Maybe<RequisitionList>
}

export type MoveProductsBetweenWishlistsOutput = {
  __typename?: 'MoveProductsBetweenWishlistsOutput'
  /** The destination wish list after receiving products move from the source wish list */
  destination_wishlist: Wishlist
  /** The source wish list after moving products from it */
  source_wishlist: Wishlist
  /** An array of errors encountered while moving products in a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Subscribe to product comes back in stock alert */
  AmxnotifStockSubscribe?: Maybe<AmxnotifSubscribeOutput>
  addBundleProductsToCart?: Maybe<AddBundleProductsToCartOutput>
  addConfigurableProductsToCart?: Maybe<AddConfigurableProductsToCartOutput>
  addDownloadableProductsToCart?: Maybe<AddDownloadableProductsToCartOutput>
  /** Add registrants to the specified gift registry */
  addGiftRegistryRegistrants?: Maybe<AddGiftRegistryRegistrantsOutput>
  /** Add any type of product to the cart */
  addProductsToCart?: Maybe<AddProductsToCartOutput>
  /** Add products to the specified compare list */
  addProductsToCompareList?: Maybe<CompareList>
  /** Add items to the specified requisition list */
  addProductsToRequisitionList?: Maybe<AddProductsToRequisitionListOutput>
  /** Adds one or more products to the specified wish list. This mutation supports all product types */
  addProductsToWishlist?: Maybe<AddProductsToWishlistOutput>
  addPromoItem?: Maybe<AddPromoItemOutput>
  /** Add items in the requisition list to the customer's cart */
  addRequisitionListItemsToCart?: Maybe<AddRequisitionListItemsToCartOutput>
  /** Add a comment to an existing return */
  addReturnComment?: Maybe<AddReturnCommentOutput>
  /** Add tracking information to the return */
  addReturnTracking?: Maybe<AddReturnTrackingOutput>
  addSimpleProductsToCart?: Maybe<AddSimpleProductsToCartOutput>
  addVirtualProductsToCart?: Maybe<AddVirtualProductsToCartOutput>
  /** Add items in the specified wishlist to the customer's cart */
  addWishlistItemsToCart?: Maybe<AddWishlistItemsToCartOutput>
  adyenPaymentDetails?: Maybe<AdyenPaymentStatus>
  applyCouponToCart?: Maybe<ApplyCouponToCartOutput>
  /** Apply a pre-defined gift card code to the specified cart. */
  applyGiftCardToCart?: Maybe<ApplyGiftCardToCartOutput>
  /** Apply all available points, up to the cart total. Partial redemption is not available */
  applyRewardPointsToCart?: Maybe<ApplyRewardPointsToCartOutput>
  /** Apply store credit to the specified cart. */
  applyStoreCreditToCart?: Maybe<ApplyStoreCreditToCartOutput>
  /** Assign the specified compare list to the logged in customer */
  assignCompareListToCustomer?: Maybe<AssignCompareListToCustomerOutput>
  /** Changes the password for the logged-in customer */
  changeCustomerPassword?: Maybe<Customer>
  /** Removes all items from the specified cart */
  clearCustomerCart?: Maybe<ClearCustomerCartOutput>
  /** Mark a negotiable quote as closed. The negotiable quote is still visible on the storefront */
  closeNegotiableQuotes?: Maybe<CloseNegotiableQuotesOutput>
  /** Copy items from one requisition list to another */
  copyItemsBetweenRequisitionLists?: Maybe<CopyItemsFromRequisitionListsOutput>
  /** Copy products from one wish list to another */
  copyProductsBetweenWishlists?: Maybe<CopyProductsBetweenWishlistsOutput>
  /** Create a company at the request of either a customer or a guest */
  createCompany?: Maybe<CreateCompanyOutput>
  /** Create a new company role */
  createCompanyRole?: Maybe<CreateCompanyRoleOutput>
  /** Create a new team for the authenticated customer's company */
  createCompanyTeam?: Maybe<CreateCompanyTeamOutput>
  /** Create a new company user at the request of an existing customer */
  createCompanyUser?: Maybe<CreateCompanyUserOutput>
  /** Creates a new compare list. The compare list is saved for logged in customers */
  createCompareList?: Maybe<CompareList>
  /** Create customer account */
  createCustomer?: Maybe<CustomerOutput>
  /** Create customer address */
  createCustomerAddress?: Maybe<CustomerAddress>
  /** Create customer account */
  createCustomerV2?: Maybe<CustomerOutput>
  /** Creates an empty shopping cart for a guest or logged in user */
  createEmptyCart?: Maybe<Scalars['String']['output']>
  /** Create a gift registry on behalf of the customer */
  createGiftRegistry?: Maybe<CreateGiftRegistryOutput>
  /** Initiates a transaction and receives a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken?: Maybe<CreatePayflowProTokenOutput>
  /** Initiates an Express Checkout transaction and receives a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken?: Maybe<PaypalExpressTokenOutput>
  createProductAnswer?: Maybe<CreateProductAnswerOutput>
  createProductQuestion?: Maybe<CreateProductQuestionOutput>
  /** Creates a product review for the specified SKU */
  createProductReview: CreateProductReviewOutput
  /** Create an empty requisition list */
  createRequisitionList?: Maybe<CreateRequisitionListOutput>
  /** Create a new wish list */
  createWishlist?: Maybe<CreateWishlistOutput>
  deactivateAccount?: Maybe<DeactivateAccountOutput>
  /** Delete the specified company role */
  deleteCompanyRole?: Maybe<DeleteCompanyRoleOutput>
  /** Delete the specified company team */
  deleteCompanyTeam?: Maybe<DeleteCompanyTeamOutput>
  /** Delete the specified company user */
  deleteCompanyUser?: Maybe<DeleteCompanyUserOutput>
  /** Delete the specified compare list */
  deleteCompareList?: Maybe<DeleteCompareListOutput>
  /** Delete customer address */
  deleteCustomerAddress?: Maybe<Scalars['Boolean']['output']>
  deleteInternalOrderId?: Maybe<InternalOrderResponse>
  /** Delete a negotiable quote. The negotiable quote will not be displayed on the storefront */
  deleteNegotiableQuotes?: Maybe<DeleteNegotiableQuotesOutput>
  /** Delete a customer payment token */
  deletePaymentToken?: Maybe<DeletePaymentTokenOutput>
  /** Delete a requisition list */
  deleteRequisitionList?: Maybe<DeleteRequisitionListOutput>
  /** Delete items from a requisition list */
  deleteRequisitionListItems?: Maybe<DeleteRequisitionListItemsOutput>
  /** Delete the specified wish list filtered by the unique ID for a `Wishlist` object */
  deleteWishlist?: Maybe<DeleteWishlistOutput>
  /** Retrieve the customer token */
  generateCustomerToken?: Maybe<CustomerToken>
  /** Request a customer token so that an administrator can perform remote shopping assistance */
  generateCustomerTokenAsAdmin?: Maybe<GenerateCustomerTokenAsAdminOutput>
  /** Handles payment response and saves payment in Quote. Use this mutations for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse?: Maybe<PayflowProResponseOutput>
  /** Merges the source cart into the destination cart */
  mergeCarts: Cart
  /** Moves all items from the cart to a gift registry */
  moveCartItemsToGiftRegistry?: Maybe<MoveCartItemsToGiftRegistryOutput>
  /** Move Items from one requisition list to another */
  moveItemsBetweenRequisitionLists?: Maybe<MoveItemsBetweenRequisitionListsOutput>
  /** Move products from one wish list to another */
  moveProductsBetweenWishlists?: Maybe<MoveProductsBetweenWishlistsOutput>
  placeOrder?: Maybe<PlaceOrderOutput>
  rateProductQuestion?: Maybe<RateProductQuestionOutput>
  /** Redeem gift card for store credit. */
  redeemGiftCardBalanceAsStoreCredit?: Maybe<GiftCardAccount>
  removeCouponFromCart?: Maybe<RemoveCouponFromCartOutput>
  removeGiftCardFromCart?: Maybe<RemoveGiftCardFromCartOutput>
  /** Delete the specified gift registry */
  removeGiftRegistry?: Maybe<RemoveGiftRegistryOutput>
  /** Delete the specified items from a gift registry */
  removeGiftRegistryItems?: Maybe<RemoveGiftRegistryItemsOutput>
  /** Removes registrants from a gift registry */
  removeGiftRegistryRegistrants?: Maybe<RemoveGiftRegistryRegistrantsOutput>
  removeItemFromCart?: Maybe<RemoveItemFromCartOutput>
  /** Remove one or more products from a negotiable quote */
  removeNegotiableQuoteItems?: Maybe<RemoveNegotiableQuoteItemsOutput>
  /** Remove products from the specified compare list */
  removeProductsFromCompareList?: Maybe<CompareList>
  /** Removes one or more products from the specified wish list */
  removeProductsFromWishlist?: Maybe<RemoveProductsFromWishlistOutput>
  /** Remove a tracked shipment from a return */
  removeReturnTracking?: Maybe<RemoveReturnTrackingOutput>
  /** Cancel the application of reward points to the cart */
  removeRewardPointsFromCart?: Maybe<RemoveRewardPointsFromCartOutput>
  /** Remove applied store credit from the specified cart. */
  removeStoreCreditFromCart?: Maybe<RemoveStoreCreditFromCartOutput>
  /** Adds all products from a customer's previous order to the cart. */
  reorderItems?: Maybe<ReorderItemsOutput>
  reportProductQuestion?: Maybe<ReportProductQuestionOutput>
  /** Request a new negotiable quote on behalf of the buyer */
  requestNegotiableQuote?: Maybe<RequestNegotiableQuoteOutput>
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail?: Maybe<Scalars['Boolean']['output']>
  /** Initiates a buyer's  request to return an item for replacement or refund */
  requestReturn?: Maybe<RequestReturnOutput>
  /** Reset a customer's password using the reset password token that the customer received in an email after requesting it using requestPasswordResetEmail. */
  resetPassword?: Maybe<Scalars['Boolean']['output']>
  /** Revoke the customer token */
  revokeCustomerToken?: Maybe<RevokeCustomerTokenOutput>
  /** Recommends Product by Sending Single/Multiple Email */
  sendEmailToFriend?: Maybe<SendEmailToFriendOutput>
  /** Send the negotiable quote to the seller for review */
  sendNegotiableQuoteForReview?: Maybe<SendNegotiableQuoteForReviewOutput>
  setBillingAddressOnCart?: Maybe<SetBillingAddressOnCartOutput>
  /** Set gift options like gift wrapping or gift message for the entire cart */
  setGiftOptionsOnCart?: Maybe<SetGiftOptionsOnCartOutput>
  setGuestEmailOnCart?: Maybe<SetGuestEmailOnCartOutput>
  /** Assign a previously-defined address as the shipping address for a negotiable quote */
  setNegotiableQuoteShippingAddress?: Maybe<SetNegotiableQuoteShippingAddressOutput>
  /** @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request. */
  setPaymentMethodAndPlaceOrder?: Maybe<PlaceOrderOutput>
  setPaymentMethodOnCart?: Maybe<SetPaymentMethodOnCartOutput>
  setShippingAddressesOnCart?: Maybe<SetShippingAddressesOnCartOutput>
  setShippingMethodsOnCart?: Maybe<SetShippingMethodsOnCartOutput>
  /** Send an email about the gift registry to a list of invitees */
  shareGiftRegistry?: Maybe<ShareGiftRegistryOutput>
  singleSignOn?: Maybe<SingleSignOnResponse>
  /** Subscribes the specified email to a newsletter */
  subscribeEmailToNewsletter?: Maybe<SubscribeEmailToNewsletterOutput>
  twistoCallback?: Maybe<TwistoPayload>
  twistoCheck?: Maybe<TwistoResponse>
  twistoTransaction?: Maybe<TwistoTransaction>
  updateCartItems?: Maybe<UpdateCartItemsOutput>
  /** Update company information */
  updateCompany?: Maybe<UpdateCompanyOutput>
  /** Update company role information */
  updateCompanyRole?: Maybe<UpdateCompanyRoleOutput>
  /** Change the parent node of a company team */
  updateCompanyStructure?: Maybe<UpdateCompanyStructureOutput>
  /** Update company team data */
  updateCompanyTeam?: Maybe<UpdateCompanyTeamOutput>
  /** Update an existing company user */
  updateCompanyUser?: Maybe<UpdateCompanyUserOutput>
  /** Deprecated. Use UpdateCustomerV2 instead. */
  updateCustomer?: Maybe<CustomerOutput>
  /** Update customer address */
  updateCustomerAddress?: Maybe<CustomerAddress>
  updateCustomerEmail?: Maybe<CustomerOutput>
  /** Update the customer's personal information */
  updateCustomerV2?: Maybe<CustomerOutput>
  /** Update the specified gift registry */
  updateGiftRegistry?: Maybe<UpdateGiftRegistryOutput>
  /** Update the specified items in the gift registry */
  updateGiftRegistryItems?: Maybe<UpdateGiftRegistryItemsOutput>
  /** Modify the properties of one or more gift registry registrants */
  updateGiftRegistryRegistrants?: Maybe<UpdateGiftRegistryRegistrantsOutput>
  updateInternalOrderId?: Maybe<InternalOrderResponse>
  /** Change the quantity of one or more items in an existing negotiable quote */
  updateNegotiableQuoteQuantities?: Maybe<UpdateNegotiableQuoteItemsQuantityOutput>
  /** Updates one or more products in the specified wish list */
  updateProductsInWishlist?: Maybe<UpdateProductsInWishlistOutput>
  /** Rename a requisition list and change its description */
  updateRequisitionList?: Maybe<UpdateRequisitionListOutput>
  /** Update items in a requisition list */
  updateRequisitionListItems?: Maybe<UpdateRequisitionListItemsOutput>
  /** Change the name and visibility of the specified wish list */
  updateWishlist?: Maybe<UpdateWishlistOutput>
}

export type MutationAmxnotifStockSubscribeArgs = {
  input?: InputMaybe<AmxnotifSubscribeInput>
}

export type MutationAddBundleProductsToCartArgs = {
  input?: InputMaybe<AddBundleProductsToCartInput>
}

export type MutationAddConfigurableProductsToCartArgs = {
  input?: InputMaybe<AddConfigurableProductsToCartInput>
}

export type MutationAddDownloadableProductsToCartArgs = {
  input?: InputMaybe<AddDownloadableProductsToCartInput>
}

export type MutationAddGiftRegistryRegistrantsArgs = {
  giftRegistryUid: Scalars['ID']['input']
  registrants: Array<AddGiftRegistryRegistrantInput>
}

export type MutationAddProductsToCartArgs = {
  cartId: Scalars['String']['input']
  cartItems: Array<CartItemInput>
}

export type MutationAddProductsToCompareListArgs = {
  input?: InputMaybe<AddProductsToCompareListInput>
}

export type MutationAddProductsToRequisitionListArgs = {
  requisitionListItems: Array<RequisitionListItemsInput>
  requisitionListUid: Scalars['ID']['input']
}

export type MutationAddProductsToWishlistArgs = {
  wishlistId: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemInput>
}

export type MutationAddPromoItemArgs = {
  input: AddPromoItemInput
}

export type MutationAddRequisitionListItemsToCartArgs = {
  requisitionListItemUids?: InputMaybe<Array<Scalars['ID']['input']>>
  requisitionListUid: Scalars['ID']['input']
}

export type MutationAddReturnCommentArgs = {
  input: AddReturnCommentInput
}

export type MutationAddReturnTrackingArgs = {
  input: AddReturnTrackingInput
}

export type MutationAddSimpleProductsToCartArgs = {
  input?: InputMaybe<AddSimpleProductsToCartInput>
}

export type MutationAddVirtualProductsToCartArgs = {
  input?: InputMaybe<AddVirtualProductsToCartInput>
}

export type MutationAddWishlistItemsToCartArgs = {
  wishlistId: Scalars['ID']['input']
  wishlistItemIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type MutationAdyenPaymentDetailsArgs = {
  cart_id: Scalars['String']['input']
  payload: Scalars['String']['input']
}

export type MutationApplyCouponToCartArgs = {
  input?: InputMaybe<ApplyCouponToCartInput>
}

export type MutationApplyGiftCardToCartArgs = {
  input?: InputMaybe<ApplyGiftCardToCartInput>
}

export type MutationApplyRewardPointsToCartArgs = {
  cartId: Scalars['ID']['input']
}

export type MutationApplyStoreCreditToCartArgs = {
  input: ApplyStoreCreditToCartInput
}

export type MutationAssignCompareListToCustomerArgs = {
  uid: Scalars['ID']['input']
}

export type MutationChangeCustomerPasswordArgs = {
  currentPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export type MutationClearCustomerCartArgs = {
  cartUid: Scalars['String']['input']
}

export type MutationCloseNegotiableQuotesArgs = {
  input: CloseNegotiableQuotesInput
}

export type MutationCopyItemsBetweenRequisitionListsArgs = {
  destinationRequisitionListUid?: InputMaybe<Scalars['ID']['input']>
  requisitionListItem?: InputMaybe<CopyItemsBetweenRequisitionListsInput>
  sourceRequisitionListUid: Scalars['ID']['input']
}

export type MutationCopyProductsBetweenWishlistsArgs = {
  destinationWishlistUid: Scalars['ID']['input']
  sourceWishlistUid: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemCopyInput>
}

export type MutationCreateCompanyArgs = {
  input: CompanyCreateInput
}

export type MutationCreateCompanyRoleArgs = {
  input: CompanyRoleCreateInput
}

export type MutationCreateCompanyTeamArgs = {
  input: CompanyTeamCreateInput
}

export type MutationCreateCompanyUserArgs = {
  input: CompanyUserCreateInput
}

export type MutationCreateCompareListArgs = {
  input?: InputMaybe<CreateCompareListInput>
}

export type MutationCreateCustomerArgs = {
  input: CustomerInput
}

export type MutationCreateCustomerAddressArgs = {
  input: CustomerAddressInput
}

export type MutationCreateCustomerV2Args = {
  input: CustomerCreateInput
}

export type MutationCreateEmptyCartArgs = {
  input?: InputMaybe<CreateEmptyCartInput>
}

export type MutationCreateGiftRegistryArgs = {
  giftRegistry: CreateGiftRegistryInput
}

export type MutationCreatePayflowProTokenArgs = {
  input: PayflowProTokenInput
}

export type MutationCreatePaypalExpressTokenArgs = {
  input: PaypalExpressTokenInput
}

export type MutationCreateProductAnswerArgs = {
  input: CreateProductAnswerInput
}

export type MutationCreateProductQuestionArgs = {
  input: CreateProductQuestionInput
}

export type MutationCreateProductReviewArgs = {
  input: CreateProductReviewInput
}

export type MutationCreateRequisitionListArgs = {
  input?: InputMaybe<CreateRequisitionListInput>
}

export type MutationCreateWishlistArgs = {
  input: CreateWishlistInput
}

export type MutationDeactivateAccountArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeleteCompanyRoleArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCompanyTeamArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCompanyUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCompareListArgs = {
  uid: Scalars['ID']['input']
}

export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeleteInternalOrderIdArgs = {
  number: Scalars['String']['input']
}

export type MutationDeleteNegotiableQuotesArgs = {
  input: DeleteNegotiableQuotesInput
}

export type MutationDeletePaymentTokenArgs = {
  public_hash: Scalars['String']['input']
}

export type MutationDeleteRequisitionListArgs = {
  requisitionListUid: Scalars['ID']['input']
}

export type MutationDeleteRequisitionListItemsArgs = {
  requisitionListItemUids: Array<Scalars['ID']['input']>
  requisitionListUid: Scalars['ID']['input']
}

export type MutationDeleteWishlistArgs = {
  wishlistId: Scalars['ID']['input']
}

export type MutationGenerateCustomerTokenArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationGenerateCustomerTokenAsAdminArgs = {
  input: GenerateCustomerTokenAsAdminInput
}

export type MutationHandlePayflowProResponseArgs = {
  input: PayflowProResponseInput
}

export type MutationMergeCartsArgs = {
  destination_cart_id?: InputMaybe<Scalars['String']['input']>
  source_cart_id: Scalars['String']['input']
}

export type MutationMoveCartItemsToGiftRegistryArgs = {
  cartUid: Scalars['ID']['input']
  giftRegistryUid: Scalars['ID']['input']
}

export type MutationMoveItemsBetweenRequisitionListsArgs = {
  destinationRequisitionListUid?: InputMaybe<Scalars['ID']['input']>
  requisitionListItem?: InputMaybe<MoveItemsBetweenRequisitionListsInput>
  sourceRequisitionListUid: Scalars['ID']['input']
}

export type MutationMoveProductsBetweenWishlistsArgs = {
  destinationWishlistUid: Scalars['ID']['input']
  sourceWishlistUid: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemMoveInput>
}

export type MutationPlaceOrderArgs = {
  input?: InputMaybe<PlaceOrderInput>
}

export type MutationRateProductQuestionArgs = {
  input: RateProductQuestionInput
}

export type MutationRedeemGiftCardBalanceAsStoreCreditArgs = {
  input: GiftCardAccountInput
}

export type MutationRemoveCouponFromCartArgs = {
  input?: InputMaybe<RemoveCouponFromCartInput>
}

export type MutationRemoveGiftCardFromCartArgs = {
  input?: InputMaybe<RemoveGiftCardFromCartInput>
}

export type MutationRemoveGiftRegistryArgs = {
  giftRegistryUid: Scalars['ID']['input']
}

export type MutationRemoveGiftRegistryItemsArgs = {
  giftRegistryUid: Scalars['ID']['input']
  itemsUid: Array<Scalars['ID']['input']>
}

export type MutationRemoveGiftRegistryRegistrantsArgs = {
  giftRegistryUid: Scalars['ID']['input']
  registrantsUid: Array<Scalars['ID']['input']>
}

export type MutationRemoveItemFromCartArgs = {
  input?: InputMaybe<RemoveItemFromCartInput>
}

export type MutationRemoveNegotiableQuoteItemsArgs = {
  input: RemoveNegotiableQuoteItemsInput
}

export type MutationRemoveProductsFromCompareListArgs = {
  input?: InputMaybe<RemoveProductsFromCompareListInput>
}

export type MutationRemoveProductsFromWishlistArgs = {
  wishlistId: Scalars['ID']['input']
  wishlistItemsIds: Array<Scalars['ID']['input']>
}

export type MutationRemoveReturnTrackingArgs = {
  input: RemoveReturnTrackingInput
}

export type MutationRemoveRewardPointsFromCartArgs = {
  cartId: Scalars['ID']['input']
}

export type MutationRemoveStoreCreditFromCartArgs = {
  input: RemoveStoreCreditFromCartInput
}

export type MutationReorderItemsArgs = {
  orderNumber: Scalars['String']['input']
}

export type MutationReportProductQuestionArgs = {
  input: ReportProductQuestionInput
}

export type MutationRequestNegotiableQuoteArgs = {
  input: RequestNegotiableQuoteInput
}

export type MutationRequestPasswordResetEmailArgs = {
  email: Scalars['String']['input']
}

export type MutationRequestReturnArgs = {
  input: RequestReturnInput
}

export type MutationResetPasswordArgs = {
  email: Scalars['String']['input']
  newPassword: Scalars['String']['input']
  resetPasswordToken: Scalars['String']['input']
}

export type MutationSendEmailToFriendArgs = {
  input?: InputMaybe<SendEmailToFriendInput>
}

export type MutationSendNegotiableQuoteForReviewArgs = {
  input: SendNegotiableQuoteForReviewInput
}

export type MutationSetBillingAddressOnCartArgs = {
  input?: InputMaybe<SetBillingAddressOnCartInput>
}

export type MutationSetGiftOptionsOnCartArgs = {
  input?: InputMaybe<SetGiftOptionsOnCartInput>
}

export type MutationSetGuestEmailOnCartArgs = {
  input?: InputMaybe<SetGuestEmailOnCartInput>
}

export type MutationSetNegotiableQuoteShippingAddressArgs = {
  input: SetNegotiableQuoteShippingAddressInput
}

export type MutationSetPaymentMethodAndPlaceOrderArgs = {
  input?: InputMaybe<SetPaymentMethodAndPlaceOrderInput>
}

export type MutationSetPaymentMethodOnCartArgs = {
  input?: InputMaybe<SetPaymentMethodOnCartInput>
}

export type MutationSetShippingAddressesOnCartArgs = {
  input?: InputMaybe<SetShippingAddressesOnCartInput>
}

export type MutationSetShippingMethodsOnCartArgs = {
  input?: InputMaybe<SetShippingMethodsOnCartInput>
}

export type MutationShareGiftRegistryArgs = {
  giftRegistryUid: Scalars['ID']['input']
  invitees: Array<ShareGiftRegistryInviteeInput>
  sender: ShareGiftRegistrySenderInput
}

export type MutationSingleSignOnArgs = {
  input: SingleSignOnInput
}

export type MutationSubscribeEmailToNewsletterArgs = {
  email: Scalars['String']['input']
}

export type MutationTwistoCallbackArgs = {
  guestCartId?: InputMaybe<Scalars['String']['input']>
  twistoInput: TwistoInput
}

export type MutationTwistoCheckArgs = {
  payload: Scalars['String']['input']
}

export type MutationTwistoTransactionArgs = {
  guestCartId?: InputMaybe<Scalars['String']['input']>
  transaction_id: Scalars['String']['input']
}

export type MutationUpdateCartItemsArgs = {
  input?: InputMaybe<UpdateCartItemsInput>
}

export type MutationUpdateCompanyArgs = {
  input: CompanyUpdateInput
}

export type MutationUpdateCompanyRoleArgs = {
  input: CompanyRoleUpdateInput
}

export type MutationUpdateCompanyStructureArgs = {
  input: CompanyStructureUpdateInput
}

export type MutationUpdateCompanyTeamArgs = {
  input: CompanyTeamUpdateInput
}

export type MutationUpdateCompanyUserArgs = {
  input: CompanyUserUpdateInput
}

export type MutationUpdateCustomerArgs = {
  input: CustomerInput
}

export type MutationUpdateCustomerAddressArgs = {
  id: Scalars['Int']['input']
  input?: InputMaybe<CustomerAddressInput>
}

export type MutationUpdateCustomerEmailArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationUpdateCustomerV2Args = {
  input: CustomerUpdateInput
}

export type MutationUpdateGiftRegistryArgs = {
  giftRegistry: UpdateGiftRegistryInput
  giftRegistryUid: Scalars['ID']['input']
}

export type MutationUpdateGiftRegistryItemsArgs = {
  giftRegistryUid: Scalars['ID']['input']
  items: Array<UpdateGiftRegistryItemInput>
}

export type MutationUpdateGiftRegistryRegistrantsArgs = {
  giftRegistryUid: Scalars['ID']['input']
  registrants: Array<UpdateGiftRegistryRegistrantInput>
}

export type MutationUpdateInternalOrderIdArgs = {
  input: InternalOrderInput
  number: Scalars['String']['input']
}

export type MutationUpdateNegotiableQuoteQuantitiesArgs = {
  input: UpdateNegotiableQuoteQuantitiesInput
}

export type MutationUpdateProductsInWishlistArgs = {
  wishlistId: Scalars['ID']['input']
  wishlistItems: Array<WishlistItemUpdateInput>
}

export type MutationUpdateRequisitionListArgs = {
  input?: InputMaybe<UpdateRequisitionListInput>
  requisitionListUid: Scalars['ID']['input']
}

export type MutationUpdateRequisitionListItemsArgs = {
  requisitionListItems: Array<UpdateRequisitionListItemsInput>
  requisitionListUid: Scalars['ID']['input']
}

export type MutationUpdateWishlistArgs = {
  name?: InputMaybe<Scalars['String']['input']>
  visibility?: InputMaybe<WishlistVisibilityEnum>
  wishlistId: Scalars['ID']['input']
}

export type NegotiableQuote = {
  __typename?: 'NegotiableQuote'
  /** The first and last name of the buyer */
  buyer: NegotiableQuoteUser
  /** A list of comments made by the buyer and seller */
  comments?: Maybe<Array<Maybe<NegotiableQuoteComment>>>
  /** Timestamp indicating when the negotiable quote was created */
  created_at?: Maybe<Scalars['String']['output']>
  /** A list of status and price changes for the negotiable quote */
  history?: Maybe<Array<Maybe<NegotiableQuoteHistoryEntry>>>
  /** The list of items in the negotiable quote */
  items?: Maybe<Array<Maybe<CartItemInterface>>>
  /** The title assigned to the negotiable quote */
  name: Scalars['String']['output']
  /** A set of subtotals and totals applied to the cart */
  prices?: Maybe<CartPrices>
  /** The status of the negotiable quote */
  status: NegotiableQuoteStatus
  /** The unique ID of a `NegotiableQuote` object */
  uid: Scalars['ID']['output']
  /** Timestamp indicating when the negotiable quote was updated */
  updated_at?: Maybe<Scalars['String']['output']>
}

export type NegotiableQuoteComment = {
  __typename?: 'NegotiableQuoteComment'
  /** The first and last name of the commenter */
  author: NegotiableQuoteUser
  /** Timestamp indicating when the comment was created */
  created_at: Scalars['String']['output']
  /** Indicates whether a buyer or seller commented */
  creator_type: NegotiableQuoteCommentCreatorType
  /** The plain text comment */
  text: Scalars['String']['output']
  /** The unique ID of a `NegotiableQuoteComment` object */
  uid: Scalars['ID']['output']
}

export enum NegotiableQuoteCommentCreatorType {
  Buyer = 'BUYER',
  Seller = 'SELLER',
}

export type NegotiableQuoteCommentInput = {
  /** The comment provided by the buyer */
  comment: Scalars['String']['input']
}

/** Custom log entries added by third-party extensions */
export type NegotiableQuoteCustomLogChange = {
  __typename?: 'NegotiableQuoteCustomLogChange'
  /** The new entry content */
  new_value: Scalars['String']['output']
  /** The previous entry in the custom log */
  old_value?: Maybe<Scalars['String']['output']>
  /** The title of the custom log entry */
  title: Scalars['String']['output']
}

export type NegotiableQuoteFilterInput = {
  /** Filter by the ID of one or more negotiable quotes */
  ids?: InputMaybe<FilterEqualTypeInput>
  /** Filter by the negotiable quote name */
  name?: InputMaybe<FilterMatchTypeInput>
}

export type NegotiableQuoteHistoryChanges = {
  __typename?: 'NegotiableQuoteHistoryChanges'
  /** The comment provided with a change in the negotiable quote history */
  comment_added?: Maybe<NegotiableQuoteHistoryCommentChange>
  /** Lists log entries added by third-party extensions */
  custom_changes?: Maybe<NegotiableQuoteCustomLogChange>
  /** The expiration date of the negotiable quote before and after a change in the quote history */
  expiration?: Maybe<NegotiableQuoteHistoryExpirationChange>
  /** Lists products that were removed as a result of a change in the quote history */
  products_removed?: Maybe<NegotiableQuoteHistoryProductsRemovedChange>
  /** The status before and after a change in the negotiable quote history */
  statuses?: Maybe<NegotiableQuoteHistoryStatusesChange>
  /** The total amount of the negotiable quote before and after a change in the quote history  */
  total?: Maybe<NegotiableQuoteHistoryTotalChange>
}

export type NegotiableQuoteHistoryCommentChange = {
  __typename?: 'NegotiableQuoteHistoryCommentChange'
  /** A simple (non-HTML) comment submitted by a seller or buyer */
  comment: Scalars['String']['output']
}

export type NegotiableQuoteHistoryEntry = {
  __typename?: 'NegotiableQuoteHistoryEntry'
  /** The person who made a change in the status of the negotiable quote */
  author: NegotiableQuoteUser
  /** An enum that describes the why the entry in the negotiable quote history changed status */
  change_type: NegotiableQuoteHistoryEntryChangeType
  /** The set of changes in the negotiable quote */
  changes?: Maybe<NegotiableQuoteHistoryChanges>
  /** Timestamp indicating when the negotiable quote entry was created */
  created_at?: Maybe<Scalars['String']['output']>
  /** The unique ID of a `NegotiableQuoteHistoryEntry` object */
  uid: Scalars['ID']['output']
}

export enum NegotiableQuoteHistoryEntryChangeType {
  Closed = 'CLOSED',
  Created = 'CREATED',
  Updated = 'UPDATED',
  UpdatedBySystem = 'UPDATED_BY_SYSTEM',
}

export type NegotiableQuoteHistoryExpirationChange = {
  __typename?: 'NegotiableQuoteHistoryExpirationChange'
  /** The expiration date after the change. The value will be 'null' if not set */
  new_expiration?: Maybe<Scalars['String']['output']>
  /** The previous expiration date. The value will be 'null' if not previously set */
  old_expiration?: Maybe<Scalars['String']['output']>
}

export type NegotiableQuoteHistoryProductsRemovedChange = {
  __typename?: 'NegotiableQuoteHistoryProductsRemovedChange'
  /** A list of product IDs the seller removed from the catalog */
  products_removed_from_catalog?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  /** A list of products removed from the quote by either the buyer or the seller */
  products_removed_from_quote?: Maybe<Array<Maybe<ProductInterface>>>
}

export type NegotiableQuoteHistoryStatusChange = {
  __typename?: 'NegotiableQuoteHistoryStatusChange'
  /** The updated status */
  new_status: NegotiableQuoteStatus
  /** The previous status. The value will be null for the first history entry in a negotiable quote */
  old_status?: Maybe<NegotiableQuoteStatus>
}

export type NegotiableQuoteHistoryStatusesChange = {
  __typename?: 'NegotiableQuoteHistoryStatusesChange'
  /** A list of status changes */
  changes: Array<Maybe<NegotiableQuoteHistoryStatusChange>>
}

export type NegotiableQuoteHistoryTotalChange = {
  __typename?: 'NegotiableQuoteHistoryTotalChange'
  /** The total price as a result of the change */
  new_price?: Maybe<Money>
  /** The previous total price on the negotiable quote */
  old_price?: Maybe<Money>
}

export type NegotiableQuoteItemQuantityInput = {
  /** The new quantity of the negotiable quote item */
  quantity: Scalars['Float']['input']
  /** The unique ID of a `CartItemInterface` object */
  quote_item_uid: Scalars['ID']['input']
}

export type NegotiableQuoteSortInput = {
  sort_direction: SortEnum
  sort_field: NegotiableQuoteSortableField
}

export enum NegotiableQuoteSortableField {
  /** Sorts negotiable quotes by the dates they were created */
  CreatedAt = 'CREATED_AT',
  /** Sorts negotiable quotes by name */
  QuoteName = 'QUOTE_NAME',
  /** Sorts negotiable quotes by the dates they were last modified */
  UpdatedAt = 'UPDATED_AT',
}

export enum NegotiableQuoteStatus {
  Closed = 'CLOSED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  Ordered = 'ORDERED',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Updated = 'UPDATED',
}

/** A limited view of a Buyer or Seller in the Negotiable Quote Process */
export type NegotiableQuoteUser = {
  __typename?: 'NegotiableQuoteUser'
  /** The first name of the buyer or seller making a change */
  firstname: Scalars['String']['output']
  /** The buyer's or seller's last name */
  lastname: Scalars['String']['output']
}

export type NegotiableQuotesOutput = {
  __typename?: 'NegotiableQuotesOutput'
  /** A list of negotiable quotes */
  items: Array<Maybe<NegotiableQuote>>
  /** Contains pagination metadata */
  page_info: SearchResultPageInfo
  /** Contains the default sort field and all available sort fields. */
  sort_fields?: Maybe<SortFields>
  /** The number of negotiable quotes returned */
  total_count: Scalars['Int']['output']
}

export type OgProperties = {
  __typename?: 'OgProperties'
  name?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type Order = {
  __typename?: 'Order'
  adyen_payment_status?: Maybe<AdyenPaymentStatus>
  /** Cart ID. */
  cart_id: Scalars['String']['output']
  /** @deprecated The order_id field is deprecated, use order_number instead. */
  order_id?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Order` object. */
  order_number: Scalars['String']['output']
}

/** OrderAddress contains detailed information about an order's billing and shipping addresses */
export type OrderAddress = {
  __typename?: 'OrderAddress'
  /** The city or town */
  city: Scalars['String']['output']
  /** The customer's company */
  company?: Maybe<Scalars['String']['output']>
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>
  /** The fax number */
  fax?: Maybe<Scalars['String']['output']>
  /** The first name of the person associated with the shipping/billing address */
  firstname: Scalars['String']['output']
  /** The family name of the person associated with the shipping/billing address */
  lastname: Scalars['String']['output']
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']['output']>
  /** The customer's order ZIP or postal code */
  postcode?: Maybe<Scalars['String']['output']>
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']['output']>
  /** The state or province name */
  region?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Region` object of a pre-defined region */
  region_id?: Maybe<Scalars['ID']['output']>
  /** An array of strings that define the street number and name */
  street: Array<Maybe<Scalars['String']['output']>>
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']['output']>
  /** The telephone number */
  telephone: Scalars['String']['output']
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']['output']>
}

export type OrderItem = OrderItemInterface & {
  __typename?: 'OrderItem'
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** Indicates whether the order item is eligible is eligible to be in a return request */
  eligible_for_return?: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift wrapping for the order item */
  gift_wrapping?: Maybe<GiftWrapping>
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  is_saleable?: Maybe<Scalars['Boolean']['output']>
  parent_sku?: Maybe<Scalars['String']['output']>
  product_image?: Maybe<Scalars['String']['output']>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']['output']>
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']['output']>
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']['output']>
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']['output']>
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item */
  status?: Maybe<Scalars['String']['output']>
}

/** Order item details */
export type OrderItemInterface = {
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** Indicates whether the order item is eligible is eligible to be in a return request */
  eligible_for_return?: Maybe<Scalars['Boolean']['output']>
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The selected gift wrapping for the order item */
  gift_wrapping?: Maybe<GiftWrapping>
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  is_saleable?: Maybe<Scalars['Boolean']['output']>
  parent_sku?: Maybe<Scalars['String']['output']>
  product_image?: Maybe<Scalars['String']['output']>
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** The sale price of the base product, including selected options */
  product_sale_price: Money
  /** The SKU of the base product */
  product_sku: Scalars['String']['output']
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']['output']>
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']['output']>
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']['output']>
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']['output']>
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']['output']>
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']['output']>
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']['output']>
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']['output']>
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>
  /** The status of the order item */
  status?: Maybe<Scalars['String']['output']>
}

/** Represents order item options like selected or entered */
export type OrderItemOption = {
  __typename?: 'OrderItemOption'
  /** The name of the option */
  label: Scalars['String']['output']
  /** The value of the option */
  value: Scalars['String']['output']
}

/** Contains details about the payment method used to pay for the order */
export type OrderPaymentMethod = {
  __typename?: 'OrderPaymentMethod'
  /** Additional data per payment method type */
  additional_data?: Maybe<Array<Maybe<KeyValue>>>
  bank_account_details?: Maybe<Array<Maybe<BankAccountDetails>>>
  /** Payment instructions specific to the payment method */
  instructions?: Maybe<Scalars['String']['output']>
  /** The label that describes the payment method */
  name: Scalars['String']['output']
  /** The payment method code that indicates how the order was paid for */
  type: Scalars['String']['output']
}

/** Order shipment details */
export type OrderShipment = {
  __typename?: 'OrderShipment'
  /** Comments added to the shipment */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>
  /** The unique ID for a `OrderShipment` object */
  id: Scalars['ID']['output']
  /** Contains items included in the shipment */
  items?: Maybe<Array<Maybe<ShipmentItemInterface>>>
  /** The sequential credit shipment number */
  number: Scalars['String']['output']
  /** Contains shipment tracking details */
  tracking?: Maybe<Array<Maybe<ShipmentTracking>>>
}

/** Contains details about the sales total amounts used to calculate the final price */
export type OrderTotal = {
  __typename?: 'OrderTotal'
  /** The final base grand total amount in the base currency */
  base_grand_total: Money
  /** The applied discounts to the order */
  discounts?: Maybe<Array<Maybe<Discount>>>
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money
  /** Payment fee amount */
  payment_fee?: Maybe<Money>
  /** Price of products */
  products_price?: Maybe<Money>
  /** Contains details about the shipping and handling costs for the order */
  shipping_handling?: Maybe<ShippingHandling>
  /** The subtotal of the order, excluding shipping, discounts, and taxes */
  subtotal: Money
  /** The order tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>
  /** The gift card balance applied to the order */
  total_giftcard?: Maybe<Money>
  /** The shipping amount for the order */
  total_shipping: Money
  /** The amount of tax applied to the order */
  total_tax: Money
}

export type OtherModelsValues = {
  __typename?: 'OtherModelsValues'
  color?: Maybe<Scalars['String']['output']>
  color_group?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

/** Required input for Payflow Express Checkout payments */
export type PayflowExpressInput = {
  /** The unique ID of the PayPal user */
  payer_id: Scalars['String']['input']
  /** The token returned by the createPaypalExpressToken mutation */
  token: Scalars['String']['input']
}

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the transaction error page that PayPal will redirect to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String']['input']
  /** The relative URL of the order confirmation page that PayPal will redirect to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

/** Mode for payment: TEST or LIVE. Applies to Payflow Link and Payments Advanced payment methods. */
export enum PayflowLinkMode {
  Live = 'LIVE',
  Test = 'TEST',
}

/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkToken = {
  __typename?: 'PayflowLinkToken'
  /** Mode for Payflow transaction */
  mode?: Maybe<PayflowLinkMode>
  /** PayPal URL used for requesting Payflow form */
  paypal_url?: Maybe<Scalars['String']['output']>
  /** Secure token generated by PayPal */
  secure_token?: Maybe<Scalars['String']['output']>
  /** Secure token ID generated by PayPal */
  secure_token_id?: Maybe<Scalars['String']['output']>
}

/** Input required to fetch payment token information for Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
}

/** Required input for Payflow Pro and Payments Pro payment methods. */
export type PayflowProInput = {
  /** Required input for credit card related information */
  cc_details: CreditCardDetailsInput
  /** States whether details about the customer's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for PayPal Payflow Pro payment integration. */
  is_active_payment_token_enabler?: InputMaybe<Scalars['Boolean']['input']>
}

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProResponseInput = {
  cart_id: Scalars['String']['input']
  paypal_payload: Scalars['String']['input']
}

export type PayflowProResponseOutput = {
  __typename?: 'PayflowProResponseOutput'
  cart: Cart
}

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProToken = {
  __typename?: 'PayflowProToken'
  response_message: Scalars['String']['output']
  result: Scalars['Int']['output']
  result_code: Scalars['Int']['output']
  secure_token: Scalars['String']['output']
  secure_token_id: Scalars['String']['output']
}

/** Input required to fetch payment token information for Payflow Pro and Payments Pro payment methods. */
export type PayflowProTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput
}

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Pro and Payment Pro payment methods. */
export type PayflowProUrlInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the transaction error page that PayPal will redirect to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String']['input']
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
}

export type PaymentMethodInput = {
  /** Required input for Adyen Boleto payments. */
  adyen_additional_data_boleto?: InputMaybe<AdyenAdditionalDataBoleto>
  /** Required input for Adyen CC payments. */
  adyen_additional_data_cc?: InputMaybe<AdyenAdditionalDataCc>
  /** Required input for Adyen HPP payments. */
  adyen_additional_data_hpp?: InputMaybe<AdyenAdditionalDataHpp>
  /** Required input for Adyen Oneclick payments. */
  adyen_additional_data_oneclick?: InputMaybe<AdyenAdditionalDataOneclick>
  /** Required input for Adyen POS Cloud payments. */
  adyen_additional_data_pos_cloud?: InputMaybe<AdyenAdditionalDataPosCloud>
  /** Payment method code */
  code: Scalars['String']['input']
  /** Required input for PayPal Hosted pro payments */
  hosted_pro?: InputMaybe<HostedProInput>
  /** Required input for Payflow Express Checkout payments */
  payflow_express?: InputMaybe<PayflowExpressInput>
  /** Required input for PayPal Payflow Link and Payments Advanced payments */
  payflow_link?: InputMaybe<PayflowLinkInput>
  /** Required input type for PayPal Payflow Pro and Payment Pro payments */
  payflowpro?: InputMaybe<PayflowProInput>
  /** Required input type for PayPal Payflow Pro vault payments */
  payflowpro_cc_vault?: InputMaybe<VaultTokenInput>
  /** Required input for Express Checkout and Payments Standard payments */
  paypal_express?: InputMaybe<PaypalExpressInput>
  /** Purchase order number */
  purchase_order_number?: InputMaybe<Scalars['String']['input']>
}

/** The stored payment method available to the customer */
export type PaymentToken = {
  __typename?: 'PaymentToken'
  /** Stored account details */
  details?: Maybe<Scalars['String']['output']>
  /** The payment method code associated with the token */
  payment_method_code: Scalars['String']['output']
  /** The public hash of the token */
  public_hash: Scalars['String']['output']
  type: PaymentTokenTypeEnum
}

/** The list of available payment token types */
export enum PaymentTokenTypeEnum {
  Account = 'account',
  Card = 'card',
}

/** Required input for Express Checkout and Payments Standard payments */
export type PaypalExpressInput = {
  /** The unique ID of the PayPal user */
  payer_id: Scalars['String']['input']
  /** The token returned by the createPaypalExpressToken mutation */
  token: Scalars['String']['input']
}

/** Deprecated: use type `PaypalExpressTokenOutput` instead */
export type PaypalExpressToken = {
  __typename?: 'PaypalExpressToken'
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details
   * @deprecated Use field `paypal_urls` of type `PaypalExpressTokenOutput` instead
   */
  paypal_urls?: Maybe<PaypalExpressUrlList>
  /**
   * The token returned by PayPal
   * @deprecated Use field `token` of type `PaypalExpressTokenOutput` instead
   */
  token?: Maybe<Scalars['String']['output']>
}

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
  /** Payment method code */
  code: Scalars['String']['input']
  /** Indicates whether the buyer selected the quick checkout button. The default value is false */
  express_button?: InputMaybe<Scalars['Boolean']['input']>
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process */
  urls: PaypalExpressUrlsInput
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false */
  use_paypal_credit?: InputMaybe<Scalars['Boolean']['input']>
}

/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenOutput = {
  __typename?: 'PaypalExpressTokenOutput'
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details */
  paypal_urls?: Maybe<PaypalExpressUrlList>
  /** The token returned by PayPal */
  token?: Maybe<Scalars['String']['output']>
}

/** A set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export type PaypalExpressUrlList = {
  __typename?: 'PaypalExpressUrlList'
  /** The PayPal URL that allows the buyer to edit their checkout details */
  edit?: Maybe<Scalars['String']['output']>
  /** The URL to the PayPal login page */
  start?: Maybe<Scalars['String']['output']>
}

/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressUrlsInput = {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String']['input']
  /** The relative URL of the page that PayPal will redirect to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html.  */
  pending_url?: InputMaybe<Scalars['String']['input']>
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String']['input']
  /** The relative URL of the order confirmation page that PayPal will redirect to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  success_url?: InputMaybe<Scalars['String']['input']>
}

export type PazaruvajType = {
  __typename?: 'PazaruvajType'
  is_enabled?: Maybe<Scalars['Boolean']['output']>
}

/** PhysicalProductInterface contains attributes specific to tangible products. */
export type PhysicalProductInterface = {
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']['output']>
}

/** Defines Pickup Location information. */
export type PickupLocation = {
  __typename?: 'PickupLocation'
  city?: Maybe<Scalars['String']['output']>
  contact_name?: Maybe<Scalars['String']['output']>
  country_id?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  fax?: Maybe<Scalars['String']['output']>
  latitude?: Maybe<Scalars['Float']['output']>
  longitude?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  pickup_location_code?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  region?: Maybe<Scalars['String']['output']>
  region_id?: Maybe<Scalars['Int']['output']>
  street?: Maybe<Scalars['String']['output']>
}

/** PickupLocationFilterInput defines the list of attributes and filters for the search. */
export type PickupLocationFilterInput = {
  /** Filter by city. */
  city?: InputMaybe<FilterTypeInput>
  /** Filter by country. */
  country_id?: InputMaybe<FilterTypeInput>
  /** Filter by pickup location name. */
  name?: InputMaybe<FilterTypeInput>
  /** Filter by pickup location code. */
  pickup_location_code?: InputMaybe<FilterTypeInput>
  /** Filter by postcode. */
  postcode?: InputMaybe<FilterTypeInput>
  /** Filter by region. */
  region?: InputMaybe<FilterTypeInput>
  /** Filter by region id. */
  region_id?: InputMaybe<FilterTypeInput>
  /** Filter by street. */
  street?: InputMaybe<FilterTypeInput>
}

/** PickupLocationSortInput specifies attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type PickupLocationSortInput = {
  /** City where pickup location is placed. */
  city?: InputMaybe<SortEnum>
  /** Name of the contact person. */
  contact_name?: InputMaybe<SortEnum>
  /** Id of the country in two letters. */
  country_id?: InputMaybe<SortEnum>
  /** Description of the pickup location. */
  description?: InputMaybe<SortEnum>
  /** Distance to the address, requested by distance filter. Applicable only with distance filter. If distance sort order is present, all other sort orders will be ignored. */
  distance?: InputMaybe<SortEnum>
  /** Contact email of the pickup location. */
  email?: InputMaybe<SortEnum>
  /** Contact fax of the pickup location. */
  fax?: InputMaybe<SortEnum>
  /** Geographic latitude where pickup location is placed. */
  latitude?: InputMaybe<SortEnum>
  /** Geographic longitude where pickup location is placed. */
  longitude?: InputMaybe<SortEnum>
  /** The pickup location name. Customer use this to identify the pickup location. */
  name?: InputMaybe<SortEnum>
  /** Contact phone number of the pickup location. */
  phone?: InputMaybe<SortEnum>
  /** A code assigned to pickup location to identify the source. */
  pickup_location_code?: InputMaybe<SortEnum>
  /** Postcode where pickup location is placed. */
  postcode?: InputMaybe<SortEnum>
  /** Name of the region. */
  region?: InputMaybe<SortEnum>
  /** Id of the region. */
  region_id?: InputMaybe<SortEnum>
  /** Street where pickup location is placed. */
  street?: InputMaybe<SortEnum>
}

/** Top level object returned in a pickup locations search. */
export type PickupLocations = {
  __typename?: 'PickupLocations'
  /** An array of pickup locations that match the specific search request. */
  items?: Maybe<Array<Maybe<PickupLocation>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>
  /** The number of products returned. */
  total_count?: Maybe<Scalars['Int']['output']>
}

export type PlaceOrderInput = {
  cart_id: Scalars['String']['input']
}

export type PlaceOrderOutput = {
  __typename?: 'PlaceOrderOutput'
  order: Order
}

/** Price is deprecated, replaced by ProductPrice. The Price object defines the price of a product as well as any tax-related adjustments. */
export type Price = {
  __typename?: 'Price'
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Price is deprecated, use ProductPrice.
   */
  adjustments?: Maybe<Array<Maybe<PriceAdjustment>>>
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Price is deprecated, use ProductPrice.
   */
  amount?: Maybe<Money>
}

/** PriceAdjustment is deprecated. Taxes will be included or excluded in the price. The PricedAdjustment object defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export type PriceAdjustment = {
  __typename?: 'PriceAdjustment'
  /** The amount of the price adjustment and its currency code. */
  amount?: Maybe<Money>
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated PriceAdjustment is deprecated.
   */
  code?: Maybe<PriceAdjustmentCodesEnum>
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated PriceAdjustment is deprecated.
   */
  description?: Maybe<PriceAdjustmentDescriptionEnum>
}

/** PriceAdjustment.code is deprecated. This enumeration contains values defined in modules other than the Catalog module. */
export enum PriceAdjustmentCodesEnum {
  /** @deprecated PriceAdjustmentCodesEnum is deprecated. Tax is included or excluded in price. Tax is not shown separtely in Catalog */
  Tax = 'TAX',
  /** @deprecated WEEE code is deprecated, use fixed_product_taxes.label */
  Weee = 'WEEE',
  /** @deprecated Use fixed_product_taxes. PriceAdjustmentCodesEnum is deprecated. Tax is included or excluded in price. Tax is not shown separtely in Catalog */
  WeeeTax = 'WEEE_TAX',
}

/** PriceAdjustmentDescriptionEnum is deprecated. This enumeration states whether a price adjustment is included or excluded. */
export enum PriceAdjustmentDescriptionEnum {
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED',
}

/** Price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export type PriceRange = {
  __typename?: 'PriceRange'
  formMax?: Maybe<Scalars['Float']['output']>
  formMin?: Maybe<Scalars['Float']['output']>
  max?: Maybe<Scalars['Float']['output']>
  /** The highest possible price for the product. */
  maximum_price?: Maybe<ProductPrice>
  min?: Maybe<Scalars['Float']['output']>
  /** The lowest possible price for the product. */
  minimum_price: ProductPrice
}

/** This enumeration the price type. */
export enum PriceTypeEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  Percent = 'PERCENT',
}

/** This enumeration defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum PriceViewEnum {
  AsLowAs = 'AS_LOW_AS',
  PriceRange = 'PRICE_RANGE',
}

export type ProductAttribute = {
  __typename?: 'ProductAttribute'
  /** The unique identifier for a product attribute code. */
  code: Scalars['String']['output']
  /** The display value of the attribute */
  value: Scalars['String']['output']
}

/** ProductAttributeFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductAttributeFilterInput = {
  /** Attribute label: Application */
  application?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Artificial sweetener Free */
  artificial_sweetener_free?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Aspartame Free */
  aspartame_free?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Bag volume range */
  bag_volume_range?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Bio */
  bio?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Blend */
  blend?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Bottoms */
  bottom?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Capsules */
  capsules?: InputMaybe<FilterEqualTypeInput>
  /** Deprecated: use `category_uid` to filter product by category id. */
  category_id?: InputMaybe<FilterEqualTypeInput>
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Colors */
  colors?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: ean */
  ean?: InputMaybe<FilterMatchTypeInput>
  /** Attribute label: Fit */
  fit?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Flavor */
  flavor?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Form */
  form?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Gluten Free */
  glutenfree?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: GMO Free */
  gmo_free?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: HS Code */
  hs_code?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Lactose Free */
  lactosefree?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Legal category of product */
  legal_category_of_product?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Main ingredient */
  main_ingredient?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Manufacturer */
  manufacturer?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Mass grams */
  mass_grams_g?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Mass mililiter */
  mass_mililiter_ml?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Material */
  material?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Method of protein processing */
  method_of_protein_processing?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Product Name */
  name?: InputMaybe<FilterMatchTypeInput>
  /** Attribute label: Plastic Packaging Free */
  plastic_packaging_free?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Price */
  price?: InputMaybe<FilterRangeTypeInput>
  /** Attribute label: Product Labels (MKT) */
  product_labels?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Protein source */
  protein_sourcee?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Recycling codes */
  recycling_codes?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Sale */
  sale?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Short Description */
  short_description?: InputMaybe<FilterMatchTypeInput>
  /** Attribute label: Size of clothing */
  size_of_clothing?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: SKU */
  sku?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Tablets */
  tablets?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Tea package */
  tea_package?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Tops */
  top?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Top */
  tops?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Type of fish */
  type_of_fish?: InputMaybe<FilterEqualTypeInput>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Vegan */
  vegan?: InputMaybe<FilterEqualTypeInput>
  /** Attribute label: Vegetarian */
  vegetarian?: InputMaybe<FilterEqualTypeInput>
}

/** ProductAttributeSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export type ProductAttributeSortInput = {
  /** Sort by ordered quantity. */
  bestsellers?: InputMaybe<SortEnum>
  /** Attribute label: Colors */
  colors?: InputMaybe<SortEnum>
  /** Attribute label: ean */
  ean?: InputMaybe<SortEnum>
  /** Attribute label: Flavor */
  flavor?: InputMaybe<SortEnum>
  /** Attribute label: Flavor Group */
  flavor_group?: InputMaybe<SortEnum>
  /** Attribute label: HS Code */
  hs_code?: InputMaybe<SortEnum>
  /** Attribute label: Manufacturer */
  manufacturer?: InputMaybe<SortEnum>
  /** Attribute label: Material */
  material?: InputMaybe<SortEnum>
  /** Attribute label: Product Name */
  name?: InputMaybe<SortEnum>
  /** Sort by the position assigned to each product. */
  position?: InputMaybe<SortEnum>
  /** Sort by the search relevance score (default). */
  relevance?: InputMaybe<SortEnum>
  /** Attribute label: Sale */
  sale?: InputMaybe<SortEnum>
}

/** A discount applied to a product price. */
export type ProductDiscount = {
  __typename?: 'ProductDiscount'
  /** The actual value of the discount. */
  amount_off?: Maybe<Scalars['Float']['output']>
  /** The discount expressed a percentage. */
  percent_off?: Maybe<Scalars['Float']['output']>
}

/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductFilterInput = {
  /** Category ID the product belongs to. */
  category_id?: InputMaybe<FilterTypeInput>
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<FilterTypeInput>
  /** Timestamp indicating when the product was created. */
  created_at?: InputMaybe<FilterTypeInput>
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<FilterTypeInput>
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<FilterTypeInput>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<FilterTypeInput>
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<FilterTypeInput>
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<FilterTypeInput>
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product image. */
  image_label?: InputMaybe<FilterTypeInput>
  /** Indicates whether the product can be returned */
  is_returnable?: InputMaybe<FilterTypeInput>
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<FilterTypeInput>
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: InputMaybe<FilterTypeInput>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<FilterTypeInput>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<FilterTypeInput>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<FilterTypeInput>
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: InputMaybe<FilterTypeInput>
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<FilterTypeInput>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<FilterTypeInput>
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<FilterTypeInput>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<FilterTypeInput>
  /** The keyword required to perform a logical OR comparison. */
  or?: InputMaybe<ProductFilterInput>
  /** The price of an item. */
  price?: InputMaybe<FilterTypeInput>
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<FilterTypeInput>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<FilterTypeInput>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<FilterTypeInput>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<FilterTypeInput>
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<FilterTypeInput>
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: InputMaybe<FilterTypeInput>
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<FilterTypeInput>
  /** The file name of a swatch image */
  swatch_image?: InputMaybe<FilterTypeInput>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<FilterTypeInput>
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<FilterTypeInput>
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<FilterTypeInput>
  /** Timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<FilterTypeInput>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<FilterTypeInput>
  url_path?: InputMaybe<FilterTypeInput>
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<FilterTypeInput>
}

/** Product image information. Contains the image URL and label. */
export type ProductImage = MediaGalleryInterface & {
  __typename?: 'ProductImage'
  disabled?: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url?: Maybe<MediaGalleryUrlInterface>
}

/** Product Information used for Pickup Locations search. */
export type ProductInfoInput = {
  /** Product SKU. */
  sku: Scalars['String']['input']
}

/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterface = {
  allergen_celery?: Maybe<Scalars['Int']['output']>
  allergen_crustaceans?: Maybe<Scalars['Int']['output']>
  allergen_eggs?: Maybe<Scalars['Int']['output']>
  allergen_fish?: Maybe<Scalars['Int']['output']>
  allergen_gluten?: Maybe<Scalars['Int']['output']>
  allergen_lupin?: Maybe<Scalars['Int']['output']>
  allergen_milk?: Maybe<Scalars['Int']['output']>
  allergen_molluscs?: Maybe<Scalars['Int']['output']>
  allergen_mustard?: Maybe<Scalars['Int']['output']>
  allergen_peanuts?: Maybe<Scalars['Int']['output']>
  allergen_sesame?: Maybe<Scalars['Int']['output']>
  allergen_soya?: Maybe<Scalars['Int']['output']>
  allergen_sulphites?: Maybe<Scalars['Int']['output']>
  allergen_tree_nuts?: Maybe<Scalars['Int']['output']>
  application?: Maybe<Scalars['Int']['output']>
  artificial_sweetener_free?: Maybe<Scalars['Int']['output']>
  aspartame_free?: Maybe<Scalars['Int']['output']>
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']['output']>
  available_for_sdd?: Maybe<Scalars['String']['output']>
  bag_volume_range?: Maybe<Scalars['Int']['output']>
  bio?: Maybe<Scalars['Int']['output']>
  blend?: Maybe<Scalars['Int']['output']>
  bottom?: Maybe<Scalars['String']['output']>
  breadcrumb?: Maybe<Array<Maybe<BreadcrumbType>>>
  breadcrumb_en?: Maybe<Scalars['String']['output']>
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']['output']>
  capsules?: Maybe<Scalars['String']['output']>
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>
  color?: Maybe<Scalars['String']['output']>
  colors?: Maybe<Scalars['String']['output']>
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']['output']>
  coupon_amount?: Maybe<Scalars['Int']['output']>
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']['output']>
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
  default_category?: Maybe<Scalars['String']['output']>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>
  ean?: Maybe<Scalars['String']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
  estimated_date_of_arrival?: Maybe<EstimatedDateOfArrivalType>
  expected_arrival_date?: Maybe<Scalars['String']['output']>
  farba_oblecenia?: Maybe<Scalars['String']['output']>
  farba_sejkera?: Maybe<Scalars['String']['output']>
  fit?: Maybe<Scalars['String']['output']>
  fitness_goal?: Maybe<Scalars['String']['output']>
  flavor?: Maybe<Scalars['String']['output']>
  form?: Maybe<Scalars['Int']['output']>
  /** Product Labels list */
  gb_product_labels?: Maybe<Array<Maybe<Label>>>
  gender_segmentation?: Maybe<Scalars['String']['output']>
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']['output']>
  glutenfree?: Maybe<Scalars['Int']['output']>
  gmo_free?: Maybe<Scalars['Int']['output']>
  heureka_name?: Maybe<Scalars['String']['output']>
  hodnota_kuponu?: Maybe<Scalars['String']['output']>
  hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']['output']>
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>
  /** Indicates whether the product can be returned */
  is_returnable?: Maybe<Scalars['String']['output']>
  lactosefree?: Maybe<Scalars['Int']['output']>
  /** The latest Related Blog Posts for a specific Product and Store */
  latest_related_blog_posts?: Maybe<LatestRelatedBlogPosts>
  legal_category_of_product?: Maybe<Scalars['Int']['output']>
  long_term_unavailable?: Maybe<Scalars['Int']['output']>
  main_ingredient?: Maybe<Scalars['Int']['output']>
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']['output']>
  /** Manufacturer's info. */
  manufacturer_info?: Maybe<ManufacturerInfoInterface>
  manufacturer_model?: Maybe<Scalars['String']['output']>
  mass_grams_g?: Maybe<Scalars['String']['output']>
  mass_mililiter_ml?: Maybe<Scalars['Int']['output']>
  material?: Maybe<Scalars['Int']['output']>
  maximum_duration?: Maybe<Scalars['String']['output']>
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']['output']>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']['output']>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']['output']>
  method_of_protein_processing?: Maybe<Scalars['Int']['output']>
  mgs_brand?: Maybe<Scalars['Int']['output']>
  mgs_j360?: Maybe<Scalars['Int']['output']>
  milimeter_mm?: Maybe<Scalars['Int']['output']>
  mininum_order_quantity?: Maybe<Scalars['String']['output']>
  /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
  msrp_price?: Maybe<Scalars['Float']['output']>
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']['output']>
  new_from?: Maybe<Scalars['String']['output']>
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']['output']>
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']['output']>
  number_of_pieces_per_batch?: Maybe<Scalars['String']['output']>
  number_of_pieces_per_package_o?: Maybe<Scalars['String']['output']>
  number_of_pieces_per_pallette?: Maybe<Scalars['String']['output']>
  objem?: Maybe<Scalars['String']['output']>
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']['output']>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']['output']>
  other_models?: Maybe<Array<Maybe<OtherModelsValues>>>
  plastic_packaging_free?: Maybe<Scalars['Int']['output']>
  preparation?: Maybe<Scalars['String']['output']>
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>
  prichut?: Maybe<Scalars['String']['output']>
  /** The list of products questions. */
  productQuestions: ProductQuestions
  /** The collection of Product labels for this Product */
  product_labels?: Maybe<Array<Maybe<AmLabel>>>
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
  product_sort_coefficient?: Maybe<Scalars['String']['output']>
  protein_content_in_product?: Maybe<Scalars['String']['output']>
  protein_sourcee?: Maybe<Scalars['Int']['output']>
  purchasing_category?: Maybe<Scalars['Int']['output']>
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float']['output']
  recycling_codes?: Maybe<Scalars['String']['output']>
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int']['output']
  /** The list of products reviews. */
  reviews: ProductReviews
  /** A short description of the product. Its use depends on the theme. */
  sales_description?: Maybe<ComplexTextValue>
  seasonality?: Maybe<Scalars['Int']['output']>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>
  size_of_clothing?: Maybe<Scalars['String']['output']>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']['output']>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']['output']>
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']['output']>
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']['output']>
  staged: Scalars['Boolean']['output']
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>
  supplier_internal_code?: Maybe<Scalars['String']['output']>
  sw_featured?: Maybe<Scalars['Int']['output']>
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']['output']>
  tablets?: Maybe<Scalars['String']['output']>
  tea_package?: Maybe<Scalars['String']['output']>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']['output']>
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
  top?: Maybe<Scalars['String']['output']>
  top_in_category?: Maybe<Scalars['String']['output']>
  tops?: Maybe<Scalars['Int']['output']>
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']['output']>
  type_of_fish?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output']
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']['output']>
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']['output']>
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']['output']>
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']['output']>
  variants?: Maybe<Scalars['String']['output']>
  vegan?: Maybe<Scalars['Int']['output']>
  vegetarian?: Maybe<Scalars['Int']['output']>
  velkost_oblecenia?: Maybe<Scalars['String']['output']>
  velkost_sejkera?: Maybe<Scalars['String']['output']>
  visible_attributes?: Maybe<Array<Maybe<VisibleAttributes>>>
  vydanie?: Maybe<Scalars['String']['output']>
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>
  weight_kilograms_kg?: Maybe<Scalars['Int']['output']>
}

/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterfaceProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterfaceReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type ProductLabels = {
  __typename?: 'ProductLabels'
  current_page?: Maybe<Scalars['Int']['output']>
  page_size?: Maybe<Scalars['Int']['output']>
  products?: Maybe<Array<Maybe<ProductsLabels>>>
  products_count?: Maybe<Scalars['Int']['output']>
  total_pages?: Maybe<Scalars['Int']['output']>
}

/** ProductLinks is an implementation of ProductLinksInterface. */
export type ProductLinks = ProductLinksInterface & {
  __typename?: 'ProductLinks'
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']['output']>
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']['output']>
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']['output']>
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']['output']>
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']['output']>
}

/** ProductLinks contains information about linked products, including the link type and product type of each item. */
export type ProductLinksInterface = {
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']['output']>
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']['output']>
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']['output']>
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']['output']>
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']['output']>
}

/** ProductMediaGalleryEntriesContent contains an image in base64 format and basic information about the image. */
export type ProductMediaGalleryEntriesContent = {
  __typename?: 'ProductMediaGalleryEntriesContent'
  /** The image in base64 format. */
  base64_encoded_data?: Maybe<Scalars['String']['output']>
  /** The file name of the image. */
  name?: Maybe<Scalars['String']['output']>
  /** The MIME type of the file, such as image/png. */
  type?: Maybe<Scalars['String']['output']>
}

/** ProductMediaGalleryEntriesVideoContent contains a link to a video file and basic information about the video. */
export type ProductMediaGalleryEntriesVideoContent = {
  __typename?: 'ProductMediaGalleryEntriesVideoContent'
  /** Must be external-video. */
  media_type?: Maybe<Scalars['String']['output']>
  /** A description of the video. */
  video_description?: Maybe<Scalars['String']['output']>
  /** Optional data about the video. */
  video_metadata?: Maybe<Scalars['String']['output']>
  /** Describes the video source. */
  video_provider?: Maybe<Scalars['String']['output']>
  /** The title of the video. */
  video_title?: Maybe<Scalars['String']['output']>
  /** The URL to the video. */
  video_url?: Maybe<Scalars['String']['output']>
}

/** Represents a product price. */
export type ProductPrice = {
  __typename?: 'ProductPrice'
  /** The price discount. Represents the difference between the regular and final price. */
  discount?: Maybe<ProductDiscount>
  /** The final price of the product after discounts applied. */
  final_price: MoneyTaxExtended
  /** The multiple FPTs that can be applied to a product price. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>
  /** The regular price of the product. */
  regular_price: MoneyTaxExtended
}

/** ProductPrices is deprecated, replaced by PriceRange. The ProductPrices object contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export type ProductPrices = {
  __typename?: 'ProductPrices'
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the to value.
   * @deprecated Use PriceRange.maximum_price.
   */
  maximalPrice?: Maybe<Price>
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the from value.
   * @deprecated Use PriceRange.minimum_price.
   */
  minimalPrice?: Maybe<Price>
  /**
   * The base price of a product.
   * @deprecated Use regular_price from PriceRange.minimum_price or PriceRange.maximum_price.
   */
  regularPrice?: Maybe<Price>
}

/** Details of a product question */
export type ProductQuestion = {
  __typename?: 'ProductQuestion'
  answer?: Maybe<Array<Maybe<ProductQuestionAnswer>>>
  date?: Maybe<Scalars['String']['output']>
  dislike?: Maybe<Scalars['Int']['output']>
  like?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  question_text?: Maybe<Scalars['String']['output']>
  uid?: Maybe<Scalars['String']['output']>
  user_avatar?: Maybe<Scalars['String']['output']>
  user_name?: Maybe<Scalars['String']['output']>
  user_uid?: Maybe<Scalars['String']['output']>
}

/** Details of a product answer */
export type ProductQuestionAnswer = {
  __typename?: 'ProductQuestionAnswer'
  answer_text?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['String']['output']>
  dislike?: Maybe<Scalars['Int']['output']>
  like?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  uid?: Maybe<Scalars['String']['output']>
  user_avatar?: Maybe<Scalars['String']['output']>
  user_name?: Maybe<Scalars['String']['output']>
  user_uid?: Maybe<Scalars['String']['output']>
}

export type ProductQuestions = {
  __typename?: 'ProductQuestions'
  /** An array of product questions with answers. */
  list: Array<Maybe<ProductQuestion>>
  /** Metadata for pagination rendering. */
  page_info: SearchResultPageInfo
}

/** Details of a product review */
export type ProductReview = {
  __typename?: 'ProductReview'
  /** The average rating for product review. */
  average_rating: Scalars['Float']['output']
  /** Date indicating when the review was created. */
  created_at: Scalars['String']['output']
  customer_avatar?: Maybe<Scalars['String']['output']>
  customer_points?: Maybe<Scalars['Int']['output']>
  /** The customer's nickname. Defaults to the customer name, if logged in */
  nickname: Scalars['String']['output']
  /** Contains details about the reviewed product */
  product: ProductInterface
  rate_minus?: Maybe<Scalars['Int']['output']>
  rate_plus?: Maybe<Scalars['Int']['output']>
  /** An array of ratings by rating category, such as quality, price, and value */
  ratings_breakdown: Array<Maybe<ProductReviewRating>>
  review_id?: Maybe<Scalars['Int']['output']>
  source?: Maybe<ProductReviewSource>
  /** The summary (title) of the review */
  summary: Scalars['String']['output']
  summary_rating?: Maybe<Scalars['Int']['output']>
  /** The review text. */
  text: Scalars['String']['output']
  verified_purchase?: Maybe<Scalars['Boolean']['output']>
}

export type ProductReviewRating = {
  __typename?: 'ProductReviewRating'
  /** The label assigned to an aspect of a product that is being rated, such as quality or price */
  name: Scalars['String']['output']
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String']['output']
}

export type ProductReviewRatingInput = {
  /** An encoded rating ID. */
  id: Scalars['String']['input']
  option_id?: InputMaybe<Scalars['String']['input']>
  /** An encoded rating value id. */
  value_id: Scalars['String']['input']
}

export type ProductReviewRatingMetadata = {
  __typename?: 'ProductReviewRatingMetadata'
  /** An encoded rating ID. */
  id: Scalars['String']['output']
  /** The label assigned to an aspect of a product that is being rated, such as quality or price */
  name: Scalars['String']['output']
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>
}

export type ProductReviewRatingValueMetadata = {
  __typename?: 'ProductReviewRatingValueMetadata'
  /** A ratings scale, such as the number of stars awarded */
  value: Scalars['String']['output']
  /** An encoded rating value id. */
  value_id: Scalars['String']['output']
}

export type ProductReviewRatingsMetadata = {
  __typename?: 'ProductReviewRatingsMetadata'
  /** List of product reviews sorted by position */
  items: Array<Maybe<ProductReviewRatingMetadata>>
}

/** Translation source */
export type ProductReviewSource = {
  __typename?: 'ProductReviewSource'
  country_code?: Maybe<Scalars['String']['output']>
  country_flag?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  summary?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
}

export type ProductReviews = {
  __typename?: 'ProductReviews'
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>
  /** Metadata for pagination rendering. */
  page_info: SearchResultPageInfo
  summary?: Maybe<ProductReviewsSummary>
  types?: Maybe<ProductReviewsTypes>
}

export type ProductReviewsSummary = {
  __typename?: 'ProductReviewsSummary'
  global_rating?: Maybe<ProductReviewsSummaryGlobalRating>
  top_negative_review?: Maybe<ProductReviewsSummaryTopReview>
  top_positive_review?: Maybe<ProductReviewsSummaryTopReview>
}

export type ProductReviewsSummaryGlobalRating = {
  __typename?: 'ProductReviewsSummaryGlobalRating'
  overall?: Maybe<Scalars['Int']['output']>
  segments?: Maybe<Array<Maybe<ProductReviewsSummaryGlobalRatingSegment>>>
  total?: Maybe<Scalars['Int']['output']>
  verified_purchases_rating?: Maybe<Scalars['Int']['output']>
}

export type ProductReviewsSummaryGlobalRatingSegment = {
  __typename?: 'ProductReviewsSummaryGlobalRatingSegment'
  count?: Maybe<Scalars['Int']['output']>
  rating?: Maybe<Scalars['Int']['output']>
}

export type ProductReviewsSummaryTopReview = {
  __typename?: 'ProductReviewsSummaryTopReview'
  detail?: Maybe<Scalars['String']['output']>
  rate_minus?: Maybe<Scalars['Int']['output']>
  rate_plus?: Maybe<Scalars['Int']['output']>
  review_id?: Maybe<Scalars['Int']['output']>
  summary_rating?: Maybe<Scalars['Int']['output']>
}

export type ProductReviewsTypeSummary = {
  __typename?: 'ProductReviewsTypeSummary'
  total_count?: Maybe<Scalars['Int']['output']>
}

export type ProductReviewsTypes = {
  __typename?: 'ProductReviewsTypes'
  current_store?: Maybe<ProductReviewsTypeSummary>
  negative?: Maybe<ProductReviewsTypeSummary>
  other_stores?: Maybe<ProductReviewsTypeSummary>
  positive?: Maybe<ProductReviewsTypeSummary>
  verified?: Maybe<ProductReviewsTypeSummary>
}

/** ProductSortInput is deprecated, use @ProductAttributeSortInput instead. ProductSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type ProductSortInput = {
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<SortEnum>
  /** Timestamp indicating when the product was created. */
  created_at?: InputMaybe<SortEnum>
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<SortEnum>
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<SortEnum>
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<SortEnum>
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<SortEnum>
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<SortEnum>
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<SortEnum>
  /** The label assigned to a product image. */
  image_label?: InputMaybe<SortEnum>
  /** Indicates whether the product can be returned */
  is_returnable?: InputMaybe<SortEnum>
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<SortEnum>
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<SortEnum>
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<SortEnum>
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<SortEnum>
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<SortEnum>
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<SortEnum>
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<SortEnum>
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<SortEnum>
  /** The price of the item. */
  price?: InputMaybe<SortEnum>
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<SortEnum>
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<SortEnum>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<SortEnum>
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<SortEnum>
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<SortEnum>
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<SortEnum>
  /** The discounted price of the product. */
  special_price?: InputMaybe<SortEnum>
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<SortEnum>
  /** The file name of a swatch image */
  swatch_image?: InputMaybe<SortEnum>
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<SortEnum>
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<SortEnum>
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<SortEnum>
  /** Timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<SortEnum>
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<SortEnum>
  url_path?: InputMaybe<SortEnum>
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<SortEnum>
}

/** This enumeration states whether a product stock status is in stock or out of stock */
export enum ProductStockStatus {
  InStock = 'IN_STOCK',
  OutOfStock = 'OUT_OF_STOCK',
}

/** ProductTierPrices is deprecated and has been replaced by TierPrice. The ProductTierPrices object defines a tier price, which is a quantity discount offered to a specific customer group. */
export type ProductTierPrices = {
  __typename?: 'ProductTierPrices'
  /**
   * The ID of the customer group.
   * @deprecated customer_group_id is not relevant for storefront.
   */
  customer_group_id?: Maybe<Scalars['String']['output']>
  /**
   * The percentage discount of the item.
   * @deprecated ProductTierPrices is deprecated. Use TierPrice.discount.
   */
  percentage_value?: Maybe<Scalars['Float']['output']>
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated ProductTierPrices is deprecated, use TierPrice.quantity.
   */
  qty?: Maybe<Scalars['Float']['output']>
  /**
   * The price of the fixed price item.
   * @deprecated ProductTierPrices is deprecated. Use TierPrice.final_price
   */
  value?: Maybe<Scalars['Float']['output']>
  /**
   * The ID assigned to the website.
   * @deprecated website_id is not relevant for storefront.
   */
  website_id?: Maybe<Scalars['Float']['output']>
}

/** Contains information about a product video. */
export type ProductVideo = MediaGalleryInterface & {
  __typename?: 'ProductVideo'
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']['output']>
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']['output']>
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']['output']>
  /** The URL of the product image or video. */
  url?: Maybe<MediaGalleryUrlInterface>
  /** Contains a ProductMediaGalleryEntriesVideoContent object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>
}

/** The Products object is the top-level object returned in a product search. */
export type Products = {
  __typename?: 'Products'
  /** Layered navigation aggregations. */
  aggregations?: Maybe<Array<Maybe<Aggregation>>>
  /**
   * Layered navigation filters array.
   * @deprecated Use aggregations instead
   */
  filters?: Maybe<Array<Maybe<LayerFilter>>>
  /** An array of products that match the specified search criteria. */
  items?: Maybe<Array<Maybe<ProductInterface>>>
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>
  /** An object that includes the default sort field and all available sort fields. */
  sort_fields?: Maybe<SortFields>
  /** The number of products that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  total_count?: Maybe<Scalars['Int']['output']>
}

export type ProductsLabels = {
  __typename?: 'ProductsLabels'
  id?: Maybe<Scalars['Int']['output']>
  labels?: Maybe<Array<Maybe<Label>>>
  labels_count?: Maybe<Scalars['Int']['output']>
  sku?: Maybe<Scalars['String']['output']>
}

/** Identifies which product attributes to search for and return. (entity_id, sku) */
export type ProductsLabelsFilterInput = {
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  entity_id?: InputMaybe<FilterTypeInput>
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<FilterTypeInput>
}

export type Query = {
  __typename?: 'Query'
  /** The Blog Posts query returns the newest Blog Posts from WordPress. */
  NewestBlogPosts?: Maybe<BlogPosts>
  /** The Blog Posts query searches for Blogposts that match the criteria from filter attributes. */
  RelatedBlogPosts?: Maybe<BlogPosts>
  adyenPaymentMethods?: Maybe<AdyenPaymentMethods>
  adyenPaymentStatus?: Maybe<AdyenPaymentStatus>
  amLabelProvider?: Maybe<Array<Maybe<AmLabelList>>>
  amLabelSetting?: Maybe<AmLabelSetting>
  /** Get a list of available store views and their config information. */
  availableStores?: Maybe<Array<Maybe<StoreConfig>>>
  /** Returns information about shopping cart */
  cart?: Maybe<Cart>
  categories?: Maybe<CategoryResult>
  categoriesByGoal?: Maybe<Array<Maybe<CategoryByGoal>>>
  /**
   * The category query searches for categories that match the criteria specified in the search and filter attributes.
   * @deprecated Use 'categoryList' query instead of 'category' query
   */
  category?: Maybe<CategoryTree>
  /** Returns an array of categories based on the specified filters. */
  categoryList?: Maybe<Array<Maybe<CategoryTree>>>
  /** Search for products in a Category using Indexed data (faster than regular search) */
  categorySearch?: Maybe<CategorySearchProducts>
  /** The Checkout Agreements information */
  checkoutAgreements?: Maybe<Array<Maybe<CheckoutAgreement>>>
  /** The CMS block query returns information about CMS blocks */
  cmsBlocks?: Maybe<CmsBlocks>
  /** The CMS page query returns information about a CMS page */
  cmsPage?: Maybe<CmsPage>
  /** Return detailed information about the authenticated customer's company */
  company?: Maybe<Company>
  /** Return products that have been added to the specified compare list */
  compareList?: Maybe<CompareList>
  /** The countries query provides information for all countries. */
  countries?: Maybe<Array<Maybe<Country>>>
  /** The countries query provides information for a single country. */
  country?: Maybe<Country>
  /** The currency query returns information about store currency. */
  currency?: Maybe<Currency>
  /** The customAttributeMetadata query returns the attribute type, given an attribute code and entity type */
  customAttributeMetadata?: Maybe<CustomAttributeMetadata>
  /** The customer query returns information about a customer account */
  customer?: Maybe<Customer>
  /** Returns information about the customer shopping cart */
  customerCart: Cart
  /** The query returns the contents of a customer's downloadable products */
  customerDownloadableProducts?: Maybe<CustomerDownloadableProducts>
  /** @deprecated Use orders from customer instead */
  customerOrders?: Maybe<CustomerOrders>
  /** Return a list of customer payment tokens */
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>
  /** Return a list of dynamic blocks filtered by type, location, or UIDs */
  dynamicBlocks: DynamicBlocks
  /** Retrieve secure PayPal url for Payments Pro Hosted Solution transaction. */
  getHostedProUrl?: Maybe<HostedProUrl>
  /** Retrieve payment credentials for transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken?: Maybe<PayflowLinkToken>
  /** Get information for gift card account by code */
  giftCardAccount?: Maybe<GiftCardAccount>
  /** Return the specified gift registry. Some details will not be available to guests. */
  giftRegistry?: Maybe<GiftRegistry>
  /** Search for gift registries by specifying a registrant email address */
  giftRegistryEmailSearch?: Maybe<Array<Maybe<GiftRegistrySearchResult>>>
  /** Search for gift registries by specifying a registry URL key */
  giftRegistryIdSearch?: Maybe<Array<Maybe<GiftRegistrySearchResult>>>
  /** Search for gift registries by specifying the registrant name and registry type ID */
  giftRegistryTypeSearch?: Maybe<Array<Maybe<GiftRegistrySearchResult>>>
  /** Get a list of available gift registry types */
  giftRegistryTypes?: Maybe<Array<Maybe<GiftRegistryType>>>
  /** banner sliders on homepage */
  homepageBannerSlider?: Maybe<HomepageBannerSliderType>
  /** Check whether the specified email can be used to register a company admin */
  isCompanyAdminEmailAvailable?: Maybe<IsCompanyAdminEmailAvailableOutput>
  /** Check whether the specified email can be used to register a new company */
  isCompanyEmailAvailable?: Maybe<IsCompanyEmailAvailableOutput>
  /** Check whether the specified role name is valid for the company */
  isCompanyRoleNameAvailable?: Maybe<IsCompanyRoleNameAvailableOutput>
  /** Check whether the specified email can be used to register a company user */
  isCompanyUserEmailAvailable?: Maybe<IsCompanyUserEmailAvailableOutput>
  isEmailAvailable?: Maybe<IsEmailAvailableOutput>
  /** Retrieve the specified negotiable quote */
  negotiableQuote?: Maybe<NegotiableQuote>
  /** Return a list of negotiable quotes that can be viewed by the logged-in customer */
  negotiableQuotes?: Maybe<NegotiableQuotesOutput>
  /** The pickup locations query searches for locations that match the search request requirements. */
  pickupLocations?: Maybe<PickupLocations>
  productLabels?: Maybe<ProductLabels>
  /** The list of products questions. */
  productQuestions: ProductQuestions
  /** Retrieves metadata required by clients to render the Reviews section. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata
  /** The products query searches for products that match the criteria specified in the search and filter attributes. */
  products?: Maybe<Products>
  /** The list of products reviews. */
  reviews: ProductReviews
  /** Return the full details for a specified product, category, or CMS page given the specified url_key, appended by the url_suffix, if one exists */
  route?: Maybe<RoutableInterface>
  /** The store config query */
  storeConfig?: Maybe<StoreConfig>
  /**
   * The urlResolver query returns the relative URL for a specified product, category or CMS page, using as input a url_key appended by the url_suffix, if one exists
   * @deprecated Use the 'route' query instead
   */
  urlResolver?: Maybe<EntityUrl>
  /**
   * The wishlist query returns the contents of a customer's wish list
   * @deprecated Moved under `Customer` `wishlist`
   */
  wishlist?: Maybe<WishlistOutput>
  xsearch?: Maybe<SearchResults>
  xsearchPopular?: Maybe<Array<Maybe<SearchPopular>>>
}

export type QueryNewestBlogPostsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRelatedBlogPostsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<RelatedBlogPostsFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type QueryAdyenPaymentMethodsArgs = {
  cart_id: Scalars['String']['input']
  shopper_locale?: InputMaybe<Scalars['String']['input']>
}

export type QueryAdyenPaymentStatusArgs = {
  cartId?: InputMaybe<Scalars['String']['input']>
  orderNumber?: InputMaybe<Scalars['String']['input']>
}

export type QueryAmLabelProviderArgs = {
  mode?: InputMaybe<AmLabelMode>
  productIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type QueryAvailableStoresArgs = {
  useCurrentGroup?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCartArgs = {
  cart_id: Scalars['String']['input']
}

export type QueryCategoriesArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filters?: InputMaybe<CategoryFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCategoryListArgs = {
  filters?: InputMaybe<CategoryFilterInput>
}

export type QueryCategorySearchArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<CategorySearchFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<CategorySearchSort>
}

export type QueryCmsBlocksArgs = {
  identifiers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type QueryCmsPageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
}

export type QueryCompareListArgs = {
  uid: Scalars['ID']['input']
}

export type QueryCountryArgs = {
  id?: InputMaybe<Scalars['String']['input']>
}

export type QueryCustomAttributeMetadataArgs = {
  attributes: Array<AttributeInput>
}

export type QueryDynamicBlocksArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  input?: InputMaybe<DynamicBlocksFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetHostedProUrlArgs = {
  input: HostedProUrlInput
}

export type QueryGetPayflowLinkTokenArgs = {
  input: PayflowLinkTokenInput
}

export type QueryGiftCardAccountArgs = {
  input: GiftCardAccountInput
}

export type QueryGiftRegistryArgs = {
  giftRegistryUid: Scalars['ID']['input']
}

export type QueryGiftRegistryEmailSearchArgs = {
  email: Scalars['String']['input']
}

export type QueryGiftRegistryIdSearchArgs = {
  giftRegistryUid: Scalars['ID']['input']
}

export type QueryGiftRegistryTypeSearchArgs = {
  firstName: Scalars['String']['input']
  giftRegistryTypeUid?: InputMaybe<Scalars['ID']['input']>
  lastName: Scalars['String']['input']
}

export type QueryIsCompanyAdminEmailAvailableArgs = {
  email: Scalars['String']['input']
}

export type QueryIsCompanyEmailAvailableArgs = {
  email: Scalars['String']['input']
}

export type QueryIsCompanyRoleNameAvailableArgs = {
  name: Scalars['String']['input']
}

export type QueryIsCompanyUserEmailAvailableArgs = {
  email: Scalars['String']['input']
}

export type QueryIsEmailAvailableArgs = {
  email: Scalars['String']['input']
}

export type QueryNegotiableQuoteArgs = {
  uid: Scalars['ID']['input']
}

export type QueryNegotiableQuotesArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<NegotiableQuoteFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<NegotiableQuoteSortInput>
}

export type QueryPickupLocationsArgs = {
  area?: InputMaybe<AreaInput>
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filters?: InputMaybe<PickupLocationFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  productsInfo?: InputMaybe<Array<InputMaybe<ProductInfoInput>>>
  sort?: InputMaybe<PickupLocationSortInput>
}

export type QueryProductLabelsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<ProductsLabelsFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<ProductAttributeSortInput>
}

export type QueryProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type QueryProductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<ProductAttributeFilterInput>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<ProductAttributeSortInput>
}

export type QueryReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type QueryRouteArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  url: Scalars['String']['input']
}

export type QueryUrlResolverArgs = {
  url: Scalars['String']['input']
}

export type QueryXsearchArgs = {
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryXsearchPopularArgs = {
  count?: InputMaybe<Scalars['Int']['input']>
}

/** question or answer type */
export enum QuestionAnswerObjectTypeEnum {
  Answer = 'ANSWER',
  Question = 'QUESTION',
}

/** like or dislike type */
export enum QuestionAnswerRateActionEnum {
  Dislike = 'DISLIKE',
  Like = 'LIKE',
}

export type RateProductQuestionInput = {
  action: QuestionAnswerRateActionEnum
  type: QuestionAnswerObjectTypeEnum
  uid: Scalars['String']['input']
}

export type RateProductQuestionOutput = {
  __typename?: 'RateProductQuestionOutput'
  action: QuestionAnswerRateActionEnum
  type: QuestionAnswerObjectTypeEnum
  uid: Scalars['String']['output']
}

export type Region = {
  __typename?: 'Region'
  code?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `Region` object. */
  id?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type RelNavigation = {
  __typename?: 'RelNavigation'
  next?: Maybe<Scalars['String']['output']>
  prev?: Maybe<Scalars['String']['output']>
}

/** BlogPostsFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type RelatedBlogPostsFilterInput = {
  /** Filter Blog Posts by the Product SKU */
  sku?: InputMaybe<FilterEqualTypeInput>
}

export type RemoveCouponFromCartInput = {
  cart_id: Scalars['String']['input']
}

export type RemoveCouponFromCartOutput = {
  __typename?: 'RemoveCouponFromCartOutput'
  cart?: Maybe<Cart>
}

/** Defines the input required to run the removeGiftCardFromCart mutation */
export type RemoveGiftCardFromCartInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
  /** The gift card code to be removed to the cart */
  gift_card_code: Scalars['String']['input']
}

/** Defines the possible output for the removeGiftCardFromCart mutation */
export type RemoveGiftCardFromCartOutput = {
  __typename?: 'RemoveGiftCardFromCartOutput'
  /** Describes the contents of the specified shopping cart */
  cart: Cart
}

export type RemoveGiftRegistryItemsOutput = {
  __typename?: 'RemoveGiftRegistryItemsOutput'
  /** The gift registry after removing items */
  gift_registry?: Maybe<GiftRegistry>
}

export type RemoveGiftRegistryOutput = {
  __typename?: 'RemoveGiftRegistryOutput'
  /** Indicates whether the gift registry was successfully deleted */
  success: Scalars['Boolean']['output']
}

export type RemoveGiftRegistryRegistrantsOutput = {
  __typename?: 'RemoveGiftRegistryRegistrantsOutput'
  /** The gift registry after deleting registrants */
  gift_registry?: Maybe<GiftRegistry>
}

export type RemoveItemFromCartInput = {
  cart_id: Scalars['String']['input']
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']['input']>
  /** Required field. The unique ID for a `CartItemInterface` object */
  cart_item_uid?: InputMaybe<Scalars['ID']['input']>
}

export type RemoveItemFromCartOutput = {
  __typename?: 'RemoveItemFromCartOutput'
  cart: Cart
}

export type RemoveNegotiableQuoteItemsInput = {
  /** An array of IDs indicating which items to remove from the negotiable quote */
  quote_item_uids: Array<InputMaybe<Scalars['ID']['input']>>
  /** The unique ID of a `NegotiableQuote` object */
  quote_uid: Scalars['ID']['input']
}

export type RemoveNegotiableQuoteItemsOutput = {
  __typename?: 'RemoveNegotiableQuoteItemsOutput'
  /** The negotiable quote after removing items */
  quote?: Maybe<NegotiableQuote>
}

export type RemoveProductsFromCompareListInput = {
  /** An array of product IDs to remove from the compare list */
  products: Array<InputMaybe<Scalars['ID']['input']>>
  /** The unique identifier of the compare list to modify */
  uid: Scalars['ID']['input']
}

/** Contains the customer's wish list and any errors encountered */
export type RemoveProductsFromWishlistOutput = {
  __typename?: 'RemoveProductsFromWishlistOutput'
  /** An array of errors encountered while deleting products from a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with after items were successfully deleted */
  wishlist: Wishlist
}

export type RemoveReturnTrackingInput = {
  /** The unique ID for a `ReturnShippingTracking` object */
  return_shipping_tracking_uid: Scalars['ID']['input']
}

export type RemoveReturnTrackingOutput = {
  __typename?: 'RemoveReturnTrackingOutput'
  /** Contains details about the modified return */
  return?: Maybe<Return>
}

export type RemoveRewardPointsFromCartOutput = {
  __typename?: 'RemoveRewardPointsFromCartOutput'
  /** The customer cart after reward points are removed */
  cart: Cart
}

/** Defines the input required to run the removeStoreCreditFromCart mutation */
export type RemoveStoreCreditFromCartInput = {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String']['input']
}

/** Defines the possible output for the removeStoreCreditFromCart mutation */
export type RemoveStoreCreditFromCartOutput = {
  __typename?: 'RemoveStoreCreditFromCartOutput'
  /** Describes the contents of the specified shopping cart */
  cart: Cart
}

export type ReorderItemsOutput = {
  __typename?: 'ReorderItemsOutput'
  /** Contains detailed information about the customer's cart. */
  cart: Cart
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>
}

export type ReportProductQuestionInput = {
  type: QuestionAnswerObjectTypeEnum
  uid: Scalars['String']['input']
}

export type ReportProductQuestionOutput = {
  __typename?: 'ReportProductQuestionOutput'
  type: QuestionAnswerObjectTypeEnum
  uid: Scalars['String']['output']
}

export type RequestNegotiableQuoteInput = {
  /** The cart ID of the buyer requesting a new negotiable quote */
  cart_id: Scalars['ID']['input']
  /** Comments the buyer entered to describe the request */
  comment: NegotiableQuoteCommentInput
  /** The name the buyer assigned to the negotiable quote request */
  quote_name: Scalars['String']['input']
}

export type RequestNegotiableQuoteOutput = {
  __typename?: 'RequestNegotiableQuoteOutput'
  quote?: Maybe<NegotiableQuote>
}

export type RequestReturnInput = {
  /** Text the buyer entered that describes the reason for the refund request */
  comment_text?: InputMaybe<Scalars['String']['input']>
  /** An email address the buyer enters to receive notifications about the status of the return */
  contact_email?: InputMaybe<Scalars['String']['input']>
  /** An array of items to be returned */
  items: Array<InputMaybe<RequestReturnItemInput>>
  /** The unique ID for a `Order` object */
  order_uid: Scalars['ID']['input']
}

export type RequestReturnItemInput = {
  /** Contains details about a custom attribute that was entered, such as text or a file */
  entered_custom_attributes?: InputMaybe<
    Array<InputMaybe<EnteredCustomAttributeInput>>
  >
  /** The unique ID for a `OrderItemInterface` object */
  order_item_uid: Scalars['ID']['input']
  /** The quantity of the item to be returned */
  quantity_to_return: Scalars['Float']['input']
  /** An array of selected custom option IDs associated with the item to be returned. For example, the IDs for the selected color and size of a configurable product */
  selected_custom_attributes?: InputMaybe<
    Array<InputMaybe<SelectedCustomAttributeInput>>
  >
}

export type RequestReturnOutput = {
  __typename?: 'RequestReturnOutput'
  /** Contains details about a single return request */
  return?: Maybe<Return>
  /** Contains an array of return requests */
  returns?: Maybe<Returns>
}

export type RequestReturnOutputReturnsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines the contents of a requisition list */
export type RequisitionList = {
  __typename?: 'RequisitionList'
  /** Optional text that describes the requisition list */
  description?: Maybe<Scalars['String']['output']>
  /** An array of products added to the requisition list */
  items?: Maybe<RequistionListItems>
  /** The number of items in the list */
  items_count: Scalars['Int']['output']
  /** The requisition list name */
  name: Scalars['String']['output']
  /** The unique requisition list ID */
  uid: Scalars['ID']['output']
  /** The time of the last modification of the requisition list */
  updated_at?: Maybe<Scalars['String']['output']>
}

/** Defines the contents of a requisition list */
export type RequisitionListItemsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Defines requisition list filters */
export type RequisitionListFilterInput = {
  /** Filter by the display name of the requisition list */
  name?: InputMaybe<FilterMatchTypeInput>
  /** Filter requisition lists by one or more requisition list IDs */
  uids?: InputMaybe<FilterEqualTypeInput>
}

/** The interface for requisition list Items */
export type RequisitionListItemInterface = {
  /** Selected custom options for an item in the requisition list */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The product model of the requisition list item */
  product: ProductInterface
  /** The amount added */
  quantity: Scalars['Float']['output']
  /** The unique ID for the requisition list Item */
  uid: Scalars['ID']['output']
}

/** Defines the items to add */
export type RequisitionListItemsInput = {
  /** Entered option IDs */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** For configurable products, the SKU of the parent product */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The quantity of the product to add */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** Selected option IDs */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The product SKU */
  sku: Scalars['String']['input']
}

/** Defines customer requisition lists */
export type RequisitionLists = {
  __typename?: 'RequisitionLists'
  /** An array of requisition lists */
  items?: Maybe<Array<Maybe<RequisitionList>>>
  /** Contains pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>
  /** The number of returned requisition lists */
  total_count?: Maybe<Scalars['Int']['output']>
}

export type RequistionListItems = {
  __typename?: 'RequistionListItems'
  /** An array of items in the requisition list */
  items: Array<Maybe<RequisitionListItemInterface>>
  /** Contains pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>
  /** The number of pages returned */
  total_pages: Scalars['Int']['output']
}

/** Customer return */
export type Return = {
  __typename?: 'Return'
  /** A list of shipping carriers available for returns */
  available_shipping_carriers?: Maybe<Array<Maybe<ReturnShippingCarrier>>>
  /** A list of comments posted for the return request */
  comments?: Maybe<Array<Maybe<ReturnComment>>>
  /** The date the return was requested */
  created_at: Scalars['String']['output']
  /** The data from customer who created the return request */
  customer: ReturnCustomer
  /** A list of items being returned */
  items?: Maybe<Array<Maybe<ReturnItem>>>
  /** Human-readable return number */
  number: Scalars['String']['output']
  /** The order associated with the return */
  order?: Maybe<CustomerOrder>
  /** Shipping information for the return */
  shipping?: Maybe<ReturnShipping>
  /** The status of the return request */
  status?: Maybe<ReturnStatus>
  /** The unique ID for a `Return` object */
  uid: Scalars['ID']['output']
}

export type ReturnComment = {
  __typename?: 'ReturnComment'
  /** The name or author who posted the comment */
  author_name: Scalars['String']['output']
  /** The date and time the comment was posted */
  created_at: Scalars['String']['output']
  /** The contents of the comment */
  text: Scalars['String']['output']
  /** The unique ID for a `ReturnComment` object */
  uid: Scalars['ID']['output']
}

export type ReturnCustomAttribute = {
  __typename?: 'ReturnCustomAttribute'
  /** A description of the attribute */
  label: Scalars['String']['output']
  /** The unique ID for a `ReturnCustomAttribute` object */
  uid: Scalars['ID']['output']
  /** A JSON-encoded value of the attribute */
  value: Scalars['String']['output']
}

/** The Customer information for the return. */
export type ReturnCustomer = {
  __typename?: 'ReturnCustomer'
  /** Customer email address. */
  email: Scalars['String']['output']
  /** Customer first name. */
  firstname?: Maybe<Scalars['String']['output']>
  /** Customer last name. */
  lastname?: Maybe<Scalars['String']['output']>
}

export type ReturnItem = {
  __typename?: 'ReturnItem'
  /** Return item custom attributes that are visible on the storefront */
  custom_attributes?: Maybe<Array<Maybe<ReturnCustomAttribute>>>
  /** Provides access to the product being returned, including information about selected and entered options */
  order_item: OrderItemInterface
  /** The quantity of the item the merchant authorized to be returned */
  quantity: Scalars['Float']['output']
  /** The quantity of the item requested to be returned */
  request_quantity: Scalars['Float']['output']
  /** The return status of the item */
  status: ReturnItemStatus
  /** The unique ID for a `ReturnItem` object */
  uid: Scalars['ID']['output']
}

export enum ReturnItemStatus {
  Approved = 'APPROVED',
  Authorized = 'AUTHORIZED',
  Denied = 'DENIED',
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Rejected = 'REJECTED',
}

export type ReturnShipping = {
  __typename?: 'ReturnShipping'
  /** The merchant-defined return shipping address */
  address?: Maybe<ReturnShippingAddress>
  /** The unique ID for a `ReturnShippingTracking` object. If a single UID is specified, contains a single tracking record. Otherwise, contains all tracking information */
  tracking?: Maybe<Array<Maybe<ReturnShippingTracking>>>
}

export type ReturnShippingTrackingArgs = {
  uid?: InputMaybe<Scalars['ID']['input']>
}

export type ReturnShippingAddress = {
  __typename?: 'ReturnShippingAddress'
  /** The city for product returns */
  city: Scalars['String']['output']
  /** The merchant's contact person */
  contact_name?: Maybe<Scalars['String']['output']>
  /** An object that defines the country for product returns */
  country: Country
  /** The postal code for product returns */
  postcode: Scalars['String']['output']
  /** An object that defines the state or province for product returns */
  region: Region
  /** The street address for product returns */
  street: Array<Maybe<Scalars['String']['output']>>
  /** The telephone number for product returns */
  telephone?: Maybe<Scalars['String']['output']>
}

export type ReturnShippingCarrier = {
  __typename?: 'ReturnShippingCarrier'
  /** A description of the shipping carrier */
  label: Scalars['String']['output']
  /** The unique ID for a `ReturnShippingCarrier` object assigned to the shipping carrier */
  uid: Scalars['ID']['output']
}

export type ReturnShippingTracking = {
  __typename?: 'ReturnShippingTracking'
  /** Contains details of a shipping carrier */
  carrier: ReturnShippingCarrier
  /** Contains details about the status of a shipment */
  status?: Maybe<ReturnShippingTrackingStatus>
  /** A tracking number assigned by the carrier */
  tracking_number: Scalars['String']['output']
  /** The unique ID for a `ReturnShippingTracking` object assigned to the tracking item */
  uid: Scalars['ID']['output']
}

export type ReturnShippingTrackingStatus = {
  __typename?: 'ReturnShippingTrackingStatus'
  /** Text that describes the status */
  text: Scalars['String']['output']
  /** Indicates whether the status type is informational or an error */
  type: ReturnShippingTrackingStatusType
}

export enum ReturnShippingTrackingStatusType {
  Error = 'ERROR',
  Information = 'INFORMATION',
}

export enum ReturnStatus {
  Approved = 'APPROVED',
  Authorized = 'AUTHORIZED',
  Closed = 'CLOSED',
  Denied = 'DENIED',
  PartiallyApproved = 'PARTIALLY_APPROVED',
  PartiallyAuthorized = 'PARTIALLY_AUTHORIZED',
  PartiallyReceived = 'PARTIALLY_RECEIVED',
  PartiallyRejected = 'PARTIALLY_REJECTED',
  Pending = 'PENDING',
  ProcessedAndClosed = 'PROCESSED_AND_CLOSED',
  Received = 'RECEIVED',
  Rejected = 'REJECTED',
}

export type Returns = {
  __typename?: 'Returns'
  /** A list of return requests */
  items?: Maybe<Array<Maybe<Return>>>
  /** Pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>
  /** The total number of return requests */
  total_count?: Maybe<Scalars['Int']['output']>
}

export type RevokeCustomerTokenOutput = {
  __typename?: 'RevokeCustomerTokenOutput'
  result: Scalars['Boolean']['output']
}

export type RewardPoints = {
  __typename?: 'RewardPoints'
  /** The current balance of reward points */
  balance?: Maybe<RewardPointsAmount>
  /** The balance history of reward points. If the ability for customers to view the balance history has been disabled in the Admin, this field will be set to null */
  balance_history?: Maybe<Array<Maybe<RewardPointsBalanceHistoryItem>>>
  /** The current exchange rates for reward points */
  exchange_rates?: Maybe<RewardPointsExchangeRates>
  /** The subscription status of emails related to reward points */
  subscription_status?: Maybe<RewardPointsSubscriptionStatus>
}

export type RewardPointsAmount = {
  __typename?: 'RewardPointsAmount'
  /** Reward points amount in store currency */
  money: Money
  /** Reward points amount in points */
  points: Scalars['Float']['output']
}

export type RewardPointsBalanceHistoryItem = {
  __typename?: 'RewardPointsBalanceHistoryItem'
  /** Reward points balance after the completion of the transaction */
  balance?: Maybe<RewardPointsAmount>
  /** The reason the balance changed */
  change_reason: Scalars['String']['output']
  /** Transaction date */
  date: Scalars['String']['output']
  /** The number of points added or deducted in the transaction */
  points_change: Scalars['Float']['output']
}

/** Exchange rates depend on the customer group */
export type RewardPointsExchangeRates = {
  __typename?: 'RewardPointsExchangeRates'
  /** How many points are earned for a given amount spent */
  earning?: Maybe<RewardPointsRate>
  /** How many points must be redeemed to get a given amount of currency discount at the checkout */
  redemption?: Maybe<RewardPointsRate>
}

export type RewardPointsRate = {
  __typename?: 'RewardPointsRate'
  /** The money value for exchange rate. For earnings this is amount spent to earn the specified points. For redemption this is the amount of money the number of points represents. */
  currency_amount: Scalars['Float']['output']
  /** The number of points for exchange rate. For earnings this is the number of points earned. For redemption this is the number of points needed for redemption. */
  points: Scalars['Float']['output']
}

export type RewardPointsSubscriptionStatus = {
  __typename?: 'RewardPointsSubscriptionStatus'
  /** Customer subscription status to 'Reward points balance updates' emails */
  balance_updates: RewardPointsSubscriptionStatusesEnum
  /** Customer subscription status to 'Reward points expiration notifications' emails */
  points_expiration_notifications: RewardPointsSubscriptionStatusesEnum
}

export enum RewardPointsSubscriptionStatusesEnum {
  NotSubscribed = 'NOT_SUBSCRIBED',
  Subscribed = 'SUBSCRIBED',
}

/** Routable entities serve as the model for a rendered page */
export type RoutableInterface = {
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int']['output']
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']['output']>
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>
}

/** Comment item details */
export type SalesCommentItem = {
  __typename?: 'SalesCommentItem'
  /** The text of the message */
  message: Scalars['String']['output']
  /** The timestamp of the comment */
  timestamp: Scalars['String']['output']
}

export type SalesItemInterface = {
  __typename?: 'SalesItemInterface'
  /** The entered gift message for the order item */
  gift_message?: Maybe<GiftMessage>
}

export type SearchPopular = {
  __typename?: 'SearchPopular'
  query?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

/** SearchResultPageInfo provides navigation for the query response */
export type SearchResultPageInfo = {
  __typename?: 'SearchResultPageInfo'
  /** Specifies which page of results to return */
  current_page?: Maybe<Scalars['Int']['output']>
  /** Specifies the maximum number of items to return */
  page_size?: Maybe<Scalars['Int']['output']>
  /** Total pages */
  total_pages?: Maybe<Scalars['Int']['output']>
}

export type SearchResultType = {
  __typename?: 'SearchResultType'
  id?: Maybe<Scalars['Int']['output']>
  image?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type SearchResults = {
  __typename?: 'SearchResults'
  brands?: Maybe<Array<Maybe<SearchResultType>>>
  categories?: Maybe<Array<Maybe<SearchResultType>>>
  products?: Maybe<Array<Maybe<SearchResultType>>>
}

export type SelectedBundleOption = {
  __typename?: 'SelectedBundleOption'
  /** @deprecated Use `uid` instead */
  id: Scalars['Int']['output']
  label: Scalars['String']['output']
  type: Scalars['String']['output']
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID']['output']
  values: Array<Maybe<SelectedBundleOptionValue>>
}

export type SelectedBundleOptionValue = {
  __typename?: 'SelectedBundleOptionValue'
  /** Use `uid` instead */
  id: Scalars['Int']['output']
  label: Scalars['String']['output']
  price: Scalars['Float']['output']
  quantity: Scalars['Float']['output']
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID']['output']
}

export type SelectedConfigurableOption = {
  __typename?: 'SelectedConfigurableOption'
  /** The unique ID for a `ConfigurableProductOptions` object */
  configurable_product_option_uid: Scalars['ID']['output']
  /** The unique ID for a `ConfigurableProductOptionsValues` object */
  configurable_product_option_value_uid: Scalars['ID']['output']
  /** @deprecated Use SelectedConfigurableOption.configurable_product_option_uid instead */
  id: Scalars['Int']['output']
  option_label: Scalars['String']['output']
  /** @deprecated Use SelectedConfigurableOption.configurable_product_option_value_uid instead */
  value_id: Scalars['Int']['output']
  value_label: Scalars['String']['output']
}

export type SelectedCustomAttributeInput = {
  /** A string that identifies the selected attribute */
  attribute_code: Scalars['String']['input']
  /** The unique ID for a `CustomAttribute` object of a selected custom attribute */
  value: Scalars['ID']['input']
}

export type SelectedCustomizableOption = {
  __typename?: 'SelectedCustomizableOption'
  /** The unique ID for a `CustomizableRadioOption`, `CustomizableDropDownOption`, `CustomizableMultipleOption`, etc. of `CustomizableOptionInterface` objects */
  customizable_option_uid: Scalars['ID']['output']
  /** @deprecated Use SelectedCustomizableOption.customizable_option_uid instead */
  id: Scalars['Int']['output']
  is_required: Scalars['Boolean']['output']
  label: Scalars['String']['output']
  sort_order: Scalars['Int']['output']
  type: Scalars['String']['output']
  values: Array<Maybe<SelectedCustomizableOptionValue>>
}

export type SelectedCustomizableOptionValue = {
  __typename?: 'SelectedCustomizableOptionValue'
  /** The unique ID for a `CustomizableMultipleValue`, `CustomizableRadioValue`, `CustomizableCheckboxValue`, `CustomizableDropDownValue`, etc. objects */
  customizable_option_value_uid: Scalars['ID']['output']
  /** @deprecated Use SelectedCustomizableOptionValue.customizable_option_value_uid instead */
  id: Scalars['Int']['output']
  label: Scalars['String']['output']
  price: CartItemSelectedOptionValuePrice
  value: Scalars['String']['output']
}

export type SelectedPaymentMethod = {
  __typename?: 'SelectedPaymentMethod'
  /** The payment method code */
  code: Scalars['String']['output']
  /** The purchase order number. */
  purchase_order_number?: Maybe<Scalars['String']['output']>
  /** The payment method title. */
  title: Scalars['String']['output']
}

export type SelectedShippingMethod = {
  __typename?: 'SelectedShippingMethod'
  amount: Money
  /** @deprecated The field should not be used on the storefront */
  base_amount?: Maybe<Money>
  carrier_code: Scalars['String']['output']
  carrier_title: Scalars['String']['output']
  method_code: Scalars['String']['output']
  method_title: Scalars['String']['output']
}

export type SendEmailToFriendInput = {
  product_id: Scalars['Int']['input']
  recipients: Array<InputMaybe<SendEmailToFriendRecipientInput>>
  sender: SendEmailToFriendSenderInput
}

export type SendEmailToFriendOutput = {
  __typename?: 'SendEmailToFriendOutput'
  recipients?: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>
  sender?: Maybe<SendEmailToFriendSender>
}

export type SendEmailToFriendRecipient = {
  __typename?: 'SendEmailToFriendRecipient'
  email: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type SendEmailToFriendRecipientInput = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type SendEmailToFriendSender = {
  __typename?: 'SendEmailToFriendSender'
  email: Scalars['String']['output']
  message: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type SendEmailToFriendSenderInput = {
  email: Scalars['String']['input']
  message: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type SendFriendConfiguration = {
  __typename?: 'SendFriendConfiguration'
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabled_for_customers: Scalars['Boolean']['output']
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabled_for_guests: Scalars['Boolean']['output']
}

export type SendNegotiableQuoteForReviewInput = {
  /** A comment for the seller to review */
  comment?: InputMaybe<NegotiableQuoteCommentInput>
  /** The unique ID of a `NegotiableQuote` object */
  quote_uid: Scalars['ID']['input']
}

export type SendNegotiableQuoteForReviewOutput = {
  __typename?: 'SendNegotiableQuoteForReviewOutput'
  /** The negotiable quote after sending for seller review */
  quote?: Maybe<NegotiableQuote>
}

export type SetBillingAddressOnCartInput = {
  billing_address: BillingAddressInput
  cart_id: Scalars['String']['input']
}

export type SetBillingAddressOnCartOutput = {
  __typename?: 'SetBillingAddressOnCartOutput'
  cart: Cart
}

export type SetGiftOptionsOnCartInput = {
  /** The unique ID that identifies the shopper's cart */
  cart_id: Scalars['String']['input']
  /** Gift message details for the cart */
  gift_message?: InputMaybe<GiftMessageInput>
  /** Whether customer requested gift receipt for the cart */
  gift_receipt_included: Scalars['Boolean']['input']
  /** The unique ID for a `GiftWrapping` object to be used for the cart */
  gift_wrapping_id?: InputMaybe<Scalars['ID']['input']>
  /** Whether customer requested printed card for the cart */
  printed_card_included: Scalars['Boolean']['input']
}

export type SetGiftOptionsOnCartOutput = {
  __typename?: 'SetGiftOptionsOnCartOutput'
  /** The modified cart object */
  cart: Cart
}

export type SetGuestEmailOnCartInput = {
  cart_id: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type SetGuestEmailOnCartOutput = {
  __typename?: 'SetGuestEmailOnCartOutput'
  cart: Cart
}

export type SetNegotiableQuoteShippingAddressInput = {
  /** The unique ID of a `CustomerAddress` object */
  customer_address_id: Scalars['ID']['input']
  /** The unique ID of a `NegotiableQuote` object */
  quote_uid: Scalars['ID']['input']
}

export type SetNegotiableQuoteShippingAddressOutput = {
  __typename?: 'SetNegotiableQuoteShippingAddressOutput'
  /** The negotiable quote after assigning a shipping address */
  quote?: Maybe<NegotiableQuote>
}

export type SetPaymentMethodAndPlaceOrderInput = {
  cart_id: Scalars['String']['input']
  payment_method: PaymentMethodInput
}

export type SetPaymentMethodOnCartInput = {
  cart_id: Scalars['String']['input']
  payment_method: PaymentMethodInput
}

export type SetPaymentMethodOnCartOutput = {
  __typename?: 'SetPaymentMethodOnCartOutput'
  cart: Cart
}

export type SetShippingAddressesOnCartInput = {
  cart_id: Scalars['String']['input']
  shipping_addresses: Array<InputMaybe<ShippingAddressInput>>
}

export type SetShippingAddressesOnCartOutput = {
  __typename?: 'SetShippingAddressesOnCartOutput'
  cart: Cart
}

export type SetShippingMethodsOnCartInput = {
  cart_id: Scalars['String']['input']
  shipping_methods: Array<InputMaybe<ShippingMethodInput>>
}

export type SetShippingMethodsOnCartOutput = {
  __typename?: 'SetShippingMethodsOnCartOutput'
  cart: Cart
}

export type ShareGiftRegistryInviteeInput = {
  /** The email address of the gift registry invitee */
  email: Scalars['String']['input']
  /** The name of the gift registry invitee */
  name: Scalars['String']['input']
}

export type ShareGiftRegistryOutput = {
  __typename?: 'ShareGiftRegistryOutput'
  /** Indicates whether the gift registry was successfully shared */
  is_shared: Scalars['Boolean']['output']
}

export type ShareGiftRegistrySenderInput = {
  /** A brief message from the sender */
  message: Scalars['String']['input']
  /** The sender of the gift registry invitation */
  name: Scalars['String']['input']
}

/** This enumeration defines whether bundle items must be shipped together. */
export enum ShipBundleItemsEnum {
  Separately = 'SEPARATELY',
  Together = 'TOGETHER',
}

export type ShipmentItem = ShipmentItemInterface & {
  __typename?: 'ShipmentItem'
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID']['output']
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** Sale price for the base product */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** Number of shipped items */
  quantity_shipped: Scalars['Float']['output']
}

/** Order shipment item details */
export type ShipmentItemInterface = {
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID']['output']
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']['output']>
  /** Sale price for the base product */
  product_sale_price: Money
  /** SKU of the base product */
  product_sku: Scalars['String']['output']
  /** Number of shipped items */
  quantity_shipped: Scalars['Float']['output']
}

/** Order shipment tracking details */
export type ShipmentTracking = {
  __typename?: 'ShipmentTracking'
  /** The shipping carrier for the order delivery */
  carrier: Scalars['String']['output']
  /** The tracking number of the order shipment */
  number?: Maybe<Scalars['String']['output']>
  /** The shipment tracking title */
  title: Scalars['String']['output']
}

export type ShippingAddressInput = {
  address?: InputMaybe<CartAddressInput>
  customer_address_id?: InputMaybe<Scalars['Int']['input']>
  customer_notes?: InputMaybe<Scalars['String']['input']>
  /** The code of Pickup Location which will be used for In-Store Pickup. */
  pickup_location_code?: InputMaybe<Scalars['String']['input']>
}

export type ShippingCartAddress = CartAddressInterface & {
  __typename?: 'ShippingCartAddress'
  available_shipping_methods?: Maybe<Array<Maybe<AvailableShippingMethod>>>
  /** @deprecated `cart_items_v2` should be used instead */
  cart_items?: Maybe<Array<Maybe<CartItemQuantity>>>
  cart_items_v2?: Maybe<Array<Maybe<CartItemInterface>>>
  city: Scalars['String']['output']
  company?: Maybe<Scalars['String']['output']>
  country: CartAddressCountry
  customer_notes?: Maybe<Scalars['String']['output']>
  firstname: Scalars['String']['output']
  /** @deprecated This information shoud not be exposed on frontend */
  items_weight?: Maybe<Scalars['Float']['output']>
  lastname: Scalars['String']['output']
  pickup_location_code?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  region?: Maybe<CartAddressRegion>
  selected_shipping_method?: Maybe<SelectedShippingMethod>
  street: Array<Maybe<Scalars['String']['output']>>
  telephone: Scalars['String']['output']
}

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export type ShippingDiscount = {
  __typename?: 'ShippingDiscount'
  /** The amount of the discount */
  amount: Money
}

/** The Shipping handling details */
export type ShippingHandling = {
  __typename?: 'ShippingHandling'
  /** The shipping amount, excluding tax */
  amount_excluding_tax?: Maybe<Money>
  /** The shipping amount, including tax */
  amount_including_tax?: Maybe<Money>
  /** The applied discounts to the shipping */
  discounts?: Maybe<Array<Maybe<ShippingDiscount>>>
  /** Contains details about taxes applied for shipping */
  taxes?: Maybe<Array<Maybe<TaxItem>>>
  /** The total amount for shipping */
  total_amount: Money
}

export type ShippingMethodInput = {
  carrier_code: Scalars['String']['input']
  method_code: Scalars['String']['input']
}

/** Simple Cart Item */
export type SimpleCartItem = CartItemInterface & {
  __typename?: 'SimpleCartItem'
  /** The list of available gift wrapping options for the cart item */
  available_gift_wrapping: Array<Maybe<GiftWrapping>>
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>
  /** The selected gift wrapping for the cart item */
  gift_wrapping?: Maybe<GiftWrapping>
  /** @deprecated Use `uid` instead */
  id: Scalars['String']['output']
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']['output']
  rule_id?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID']['output']
}

/** A simple product is tangible and is usually sold in single units or in fixed quantities */
export type SimpleProduct = CustomizableProductInterface &
  PhysicalProductInterface &
  ProductInterface &
  RoutableInterface & {
    __typename?: 'SimpleProduct'
    allergen_celery?: Maybe<Scalars['Int']['output']>
    allergen_crustaceans?: Maybe<Scalars['Int']['output']>
    allergen_eggs?: Maybe<Scalars['Int']['output']>
    allergen_fish?: Maybe<Scalars['Int']['output']>
    allergen_gluten?: Maybe<Scalars['Int']['output']>
    allergen_lupin?: Maybe<Scalars['Int']['output']>
    allergen_milk?: Maybe<Scalars['Int']['output']>
    allergen_molluscs?: Maybe<Scalars['Int']['output']>
    allergen_mustard?: Maybe<Scalars['Int']['output']>
    allergen_peanuts?: Maybe<Scalars['Int']['output']>
    allergen_sesame?: Maybe<Scalars['Int']['output']>
    allergen_soya?: Maybe<Scalars['Int']['output']>
    allergen_sulphites?: Maybe<Scalars['Int']['output']>
    allergen_tree_nuts?: Maybe<Scalars['Int']['output']>
    allergens?: Maybe<AllergensInfoInterface>
    application?: Maybe<Scalars['Int']['output']>
    artificial_sweetener_free?: Maybe<Scalars['Int']['output']>
    aspartame_free?: Maybe<Scalars['Int']['output']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attribute_set_id?: Maybe<Scalars['Int']['output']>
    available_for_sdd?: Maybe<Scalars['String']['output']>
    bag_volume_range?: Maybe<Scalars['Int']['output']>
    bio?: Maybe<Scalars['Int']['output']>
    blend?: Maybe<Scalars['Int']['output']>
    bottom?: Maybe<Scalars['String']['output']>
    breadcrumb?: Maybe<Array<Maybe<BreadcrumbType>>>
    breadcrumb_en?: Maybe<Scalars['String']['output']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']['output']>
    capsules?: Maybe<Scalars['String']['output']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    color?: Maybe<Scalars['String']['output']>
    colors?: Maybe<Scalars['String']['output']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']['output']>
    coupon_amount?: Maybe<Scalars['Int']['output']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    created_at?: Maybe<Scalars['String']['output']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    default_category?: Maybe<Scalars['String']['output']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    ean?: Maybe<Scalars['String']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    estimated_date_of_arrival?: Maybe<EstimatedDateOfArrivalType>
    expected_arrival_date?: Maybe<Scalars['String']['output']>
    expiration_date?: Maybe<Scalars['String']['output']>
    farba_oblecenia?: Maybe<Scalars['String']['output']>
    farba_sejkera?: Maybe<Scalars['String']['output']>
    fit?: Maybe<Scalars['String']['output']>
    fitness_goal?: Maybe<Scalars['String']['output']>
    flavor?: Maybe<Scalars['String']['output']>
    form?: Maybe<Scalars['Int']['output']>
    /** Product Labels list */
    gb_product_labels?: Maybe<Array<Maybe<Label>>>
    gender_segmentation?: Maybe<Scalars['String']['output']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']['output']>
    glutenfree?: Maybe<Scalars['Int']['output']>
    gmo_free?: Maybe<Scalars['Int']['output']>
    heureka_name?: Maybe<Scalars['String']['output']>
    hodnota_kuponu?: Maybe<Scalars['String']['output']>
    hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']['output']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** Indicates whether the product can be returned */
    is_returnable?: Maybe<Scalars['String']['output']>
    lactosefree?: Maybe<Scalars['Int']['output']>
    /** The latest Related Blog Posts for a specific Product and Store */
    latest_related_blog_posts?: Maybe<LatestRelatedBlogPosts>
    legal_category_of_product?: Maybe<Scalars['Int']['output']>
    long_term_unavailable?: Maybe<Scalars['Int']['output']>
    main_ingredient?: Maybe<Scalars['Int']['output']>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']['output']>
    /** Manufacturer's info. */
    manufacturer_info?: Maybe<ManufacturerInfoInterface>
    manufacturer_model?: Maybe<Scalars['String']['output']>
    mass_grams_g?: Maybe<Scalars['String']['output']>
    mass_mililiter_ml?: Maybe<Scalars['Int']['output']>
    material?: Maybe<Scalars['Int']['output']>
    maximum_duration?: Maybe<Scalars['String']['output']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']['output']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']['output']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']['output']>
    method_of_protein_processing?: Maybe<Scalars['Int']['output']>
    mgs_brand?: Maybe<Scalars['Int']['output']>
    mgs_j360?: Maybe<Scalars['Int']['output']>
    milimeter_mm?: Maybe<Scalars['Int']['output']>
    mininum_order_quantity?: Maybe<Scalars['String']['output']>
    /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
    msrpPrice?: Maybe<Price>
    /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
    msrp_price?: Maybe<Scalars['Float']['output']>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']['output']>
    new_from?: Maybe<Scalars['String']['output']>
    /**
     * The beginning date for new product listings, and determines if the product is featured as a new product.
     * @deprecated The field should not be used on the storefront.
     */
    new_from_date?: Maybe<Scalars['String']['output']>
    /**
     * The end date for new product listings.
     * @deprecated The field should not be used on the storefront.
     */
    new_to_date?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_batch?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_package_o?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_pallette?: Maybe<Scalars['String']['output']>
    objem?: Maybe<Scalars['String']['output']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']['output']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']['output']>
    other_models?: Maybe<Array<Maybe<OtherModelsValues>>>
    parent_sku?: Maybe<Scalars['String']['output']>
    plastic_packaging_free?: Maybe<Scalars['Int']['output']>
    preparation?: Maybe<Scalars['String']['output']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    prichut?: Maybe<Scalars['String']['output']>
    /** The list of products questions. */
    productQuestions: ProductQuestions
    /** The collection of Product labels for this Product */
    product_labels?: Maybe<Array<Maybe<AmLabel>>>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    product_sort_coefficient?: Maybe<Scalars['String']['output']>
    protein_content_in_product?: Maybe<Scalars['String']['output']>
    protein_sourcee?: Maybe<Scalars['Int']['output']>
    purchasing_category?: Maybe<Scalars['Int']['output']>
    /** The average of all the ratings given to the product. */
    rating_summary: Scalars['Float']['output']
    recycling_codes?: Maybe<Scalars['String']['output']>
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
    redirect_code: Scalars['Int']['output']
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
    relative_url?: Maybe<Scalars['String']['output']>
    /** The total count of all the reviews given to the product. */
    review_count: Scalars['Int']['output']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** A short description of the product. Its use depends on the theme. */
    sales_description?: Maybe<ComplexTextValue>
    seasonality?: Maybe<Scalars['Int']['output']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size_of_clothing?: Maybe<Scalars['String']['output']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']['output']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    special_from_date?: Maybe<Scalars['String']['output']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']['output']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']['output']>
    staged: Scalars['Boolean']['output']
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    supplier_internal_code?: Maybe<Scalars['String']['output']>
    sw_featured?: Maybe<Scalars['Int']['output']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']['output']>
    tablets?: Maybe<Scalars['String']['output']>
    tea_package?: Maybe<Scalars['String']['output']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']['output']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    top?: Maybe<Scalars['String']['output']>
    top_in_category?: Maybe<Scalars['String']['output']>
    tops?: Maybe<Scalars['Int']['output']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']['output']>
    type_of_fish?: Maybe<Scalars['Int']['output']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']['output']
    unit_price?: Maybe<UnitPrice>
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updated_at?: Maybe<Scalars['String']['output']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']['output']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']['output']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']['output']>
    variants?: Maybe<Scalars['String']['output']>
    vegan?: Maybe<Scalars['Int']['output']>
    vegetarian?: Maybe<Scalars['Int']['output']>
    velkost_oblecenia?: Maybe<Scalars['String']['output']>
    velkost_sejkera?: Maybe<Scalars['String']['output']>
    visible_attributes?: Maybe<Array<Maybe<VisibleAttributes>>>
    vydanie?: Maybe<Scalars['String']['output']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    /** The weight of the item, in units defined by the store. */
    weight?: Maybe<Scalars['Float']['output']>
    weight_kilograms_kg?: Maybe<Scalars['Int']['output']>
  }

/** A simple product is tangible and is usually sold in single units or in fixed quantities */
export type SimpleProductProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** A simple product is tangible and is usually sold in single units or in fixed quantities */
export type SimpleProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type SimpleProductCartItemInput = {
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  data: CartItemInput
}

/** Contains details about simple products added to a  requisition list */
export type SimpleRequisitionListItem = RequisitionListItemInterface & {
  __typename?: 'SimpleRequisitionListItem'
  /** Selected custom options for an item in the requisition list */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The product model of the requisition list item */
  product: ProductInterface
  /** The amount added */
  quantity: Scalars['Float']['output']
  /** The unique ID for the requisition list Item */
  uid: Scalars['ID']['output']
}

/** A simple product wish list Item */
export type SimpleWishlistItem = WishlistItemInterface & {
  __typename?: 'SimpleWishlistItem'
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String']['output']
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID']['output']
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  quantity: Scalars['Float']['output']
}

export type SingleSignOnInput = {
  jwt_token: Scalars['String']['input']
  provider: Scalars['String']['input']
}

export type SingleSignOnResponse = {
  __typename?: 'SingleSignOnResponse'
  customerToken?: Maybe<Scalars['String']['output']>
}

export type SkroutzType = {
  __typename?: 'SkroutzType'
  id?: Maybe<Scalars['String']['output']>
  is_active?: Maybe<Scalars['Boolean']['output']>
}

/** This enumeration indicates whether to return results in ascending or descending order */
export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortField = {
  __typename?: 'SortField'
  /** Label of sort field. */
  label?: Maybe<Scalars['String']['output']>
  /** Attribute code of sort field. */
  value?: Maybe<Scalars['String']['output']>
}

/** SortFields contains a default value for sort fields and all available sort fields. */
export type SortFields = {
  __typename?: 'SortFields'
  /** Default value of sort fields. */
  default?: Maybe<Scalars['String']['output']>
  /** Available sort fields. */
  options?: Maybe<Array<Maybe<SortField>>>
}

/** The type contains information about a store config */
export type StoreConfig = {
  __typename?: 'StoreConfig'
  /** Footer Miscellaneous HTML */
  absolute_footer?: Maybe<Scalars['String']['output']>
  /** Adyen front end resources region. */
  adyen_checkout_frontend_region?: Maybe<Scalars['String']['output']>
  /** Adyen client key for LIVE environment. */
  adyen_client_key_live?: Maybe<Scalars['String']['output']>
  /** Adyen client key for TEST environment. */
  adyen_client_key_test?: Maybe<Scalars['String']['output']>
  /** Adyen demo mode enabled (TEST). */
  adyen_demo_mode?: Maybe<Scalars['Boolean']['output']>
  /** Adyen card holder's name is visible. */
  adyen_has_holder_name?: Maybe<Scalars['Boolean']['output']>
  /** Adyen card holder's name is required. */
  adyen_holder_name_required?: Maybe<Scalars['Boolean']['output']>
  /** Use Magento Vault (one-click CVC-less payments) or the more secure Adyen Tokens (one-click CVC required payments) functionality */
  adyen_oneclick_card_mode?: Maybe<Scalars['String']['output']>
  /** Adyen Return path in case of error */
  adyen_return_path_error?: Maybe<Scalars['String']['output']>
  /** Adyen Render Mode for Payment Method. */
  adyen_title_renderer?: Maybe<Scalars['String']['output']>
  /** Allow Gift Receipt */
  allow_gift_receipt?: Maybe<Scalars['String']['output']>
  /** Allow Gift Wrapping on Order Level */
  allow_gift_wrapping_on_order?: Maybe<Scalars['String']['output']>
  /** Allow Gift Wrapping for Order Items */
  allow_gift_wrapping_on_order_items?: Maybe<Scalars['String']['output']>
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No) */
  allow_guests_to_write_product_reviews?: Maybe<Scalars['String']['output']>
  /** The value of the Allow Gift Messages for Order Items option */
  allow_items?: Maybe<Scalars['String']['output']>
  /** The value of the Allow Gift Messages on Order Level option */
  allow_order?: Maybe<Scalars['String']['output']>
  /** Allow Printed Card */
  allow_printed_card?: Maybe<Scalars['String']['output']>
  arukereso?: Maybe<ArukeresoType>
  /** Enable autocomplete on login and forgot password forms */
  autocomplete_on_storefront?: Maybe<Scalars['Boolean']['output']>
  /** Base currency code */
  base_currency_code?: Maybe<Scalars['String']['output']>
  /** Base link URL for the store */
  base_link_url?: Maybe<Scalars['String']['output']>
  /** Base media URL for the store */
  base_media_url?: Maybe<Scalars['String']['output']>
  /** Base static URL for the store */
  base_static_url?: Maybe<Scalars['String']['output']>
  /** Base URL for the store */
  base_url?: Maybe<Scalars['String']['output']>
  /** Display Gift Wrapping Prices */
  cart_gift_wrapping?: Maybe<Scalars['String']['output']>
  /** Display Printed Card Prices */
  cart_printed_card?: Maybe<Scalars['String']['output']>
  /** Default Sort By. */
  catalog_default_sort_by?: Maybe<Scalars['String']['output']>
  /** Corresponds to the 'Display Prices In Product Lists' field. It indicates how FPT information is displayed on category pages */
  category_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>
  /** Category URL Suffix. */
  category_url_suffix?: Maybe<Scalars['String']['output']>
  checkout_currency?: Maybe<CheckoutCurrency>
  /** CMS Home Page */
  cms_home_page?: Maybe<Scalars['String']['output']>
  /** CMS No Cookies Page */
  cms_no_cookies?: Maybe<Scalars['String']['output']>
  /** CMS No Route Page */
  cms_no_route?: Maybe<Scalars['String']['output']>
  /**
   * A code assigned to the store to identify it
   * @deprecated Use `store_code` instead.
   */
  code?: Maybe<Scalars['String']['output']>
  compari?: Maybe<CompariType>
  /** The configuration setting determines which thumbnail should be used in the cart for configurable products. */
  configurable_thumbnail_source?: Maybe<Scalars['String']['output']>
  cookie_restriction?: Maybe<Scalars['Boolean']['output']>
  /** Copyright */
  copyright?: Maybe<Scalars['String']['output']>
  date_format?: Maybe<Scalars['String']['output']>
  /** Default Meta Description */
  default_description?: Maybe<Scalars['String']['output']>
  /** Default display currency code */
  default_display_currency_code?: Maybe<Scalars['String']['output']>
  /** Default display currency symbol */
  default_display_currency_symbol?: Maybe<Scalars['String']['output']>
  /** Default Meta Keywords */
  default_keywords?: Maybe<Scalars['String']['output']>
  /** Default Page Title */
  default_title?: Maybe<Scalars['String']['output']>
  /** Display Demo Store Notice */
  demonotice?: Maybe<Scalars['Int']['output']>
  dognet?: Maybe<DognetType>
  dognet_s2s?: Maybe<DognetS2SType>
  /** Indicates whether customers can have multiple wish lists. Possible values: 1 (Yes) and 0 (No) */
  enable_multiple_wishlists?: Maybe<Scalars['String']['output']>
  facebook_app_id?: Maybe<Scalars['String']['output']>
  /** Default Web URL */
  front?: Maybe<Scalars['String']['output']>
  gb_ribbon_background_color?: Maybe<Scalars['String']['output']>
  gb_ribbon_date?: Maybe<Scalars['String']['output']>
  gb_ribbon_enabled?: Maybe<Scalars['Boolean']['output']>
  gb_ribbon_font_color?: Maybe<Scalars['String']['output']>
  gb_ribbon_message?: Maybe<Scalars['String']['output']>
  /** Products per Page on Grid Default Value. */
  grid_per_page?: Maybe<Scalars['Int']['output']>
  /** Products per Page on Grid Allowed Values. */
  grid_per_page_values?: Maybe<Scalars['String']['output']>
  /** Scripts and Style Sheets */
  head_includes?: Maybe<Scalars['String']['output']>
  /** Favicon Icon */
  head_shortcut_icon?: Maybe<Scalars['String']['output']>
  /** Logo Image */
  header_logo_src?: Maybe<Scalars['String']['output']>
  header_website_switcher?: Maybe<Scalars['String']['output']>
  heureka?: Maybe<HeurekaType>
  /**
   * The ID number assigned to the store
   * @deprecated Use `store_code` instead.
   */
  id?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the store view has been designated as the default within the store group */
  is_default_store?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the store group has been designated as the default within the website */
  is_default_store_group?: Maybe<Scalars['Boolean']['output']>
  /** Indicates if negotiable quote functionality is enabled. */
  is_negotiable_quote_active?: Maybe<Scalars['Boolean']['output']>
  /** Indicates if requisition lists are enabled. Possible values: 1 (Yes) and 0 (No) */
  is_requisition_list_active?: Maybe<Scalars['String']['output']>
  kdukvh?: Maybe<KdukvhType>
  /** List Mode. */
  list_mode?: Maybe<Scalars['String']['output']>
  /** Products per Page on List Default Value. */
  list_per_page?: Maybe<Scalars['Int']['output']>
  /** Products per Page on List Allowed Values. */
  list_per_page_values?: Maybe<Scalars['String']['output']>
  /** Store locale */
  locale?: Maybe<Scalars['String']['output']>
  /** Logo Image Alt */
  logo_alt?: Maybe<Scalars['String']['output']>
  /** Logo Attribute Height */
  logo_height?: Maybe<Scalars['Int']['output']>
  /** Logo Attribute Width */
  logo_width?: Maybe<Scalars['Int']['output']>
  /** Reward points functionality status: enabled/disabled */
  magento_reward_general_is_enabled?: Maybe<Scalars['String']['output']>
  /** Reward points functionality status on the storefront: enabled/disabled */
  magento_reward_general_is_enabled_on_front?: Maybe<
    Scalars['String']['output']
  >
  /** Reward points redemption minimum threshold */
  magento_reward_general_min_points_balance?: Maybe<Scalars['String']['output']>
  /** Enable reward points history for the customer */
  magento_reward_general_publish_history?: Maybe<Scalars['String']['output']>
  /** Number of points for referral, when invitee registers on the site */
  magento_reward_points_invitation_customer?: Maybe<Scalars['String']['output']>
  /** Maximum number of registration referrals that will qualify for rewards */
  magento_reward_points_invitation_customer_limit?: Maybe<
    Scalars['String']['output']
  >
  /** Number of points for referral, when invitee places an initial order on the site */
  magento_reward_points_invitation_order?: Maybe<Scalars['String']['output']>
  /** Maximum number of order placements by invitees that will qualify for rewards */
  magento_reward_points_invitation_order_limit?: Maybe<
    Scalars['String']['output']
  >
  /** Number of points for newsletter subscription */
  magento_reward_points_newsletter?: Maybe<Scalars['String']['output']>
  /** Whether customer earns points for shopping according to the reward point exchange rate. In Luma this also controls whether to show a message in shopping cart about the rewards points earned for the purchase, as well as the customer’s current reward point balance */
  magento_reward_points_order?: Maybe<Scalars['String']['output']>
  /** Number of points customer gets for registration */
  magento_reward_points_register?: Maybe<Scalars['String']['output']>
  /** Number of points for writing a review */
  magento_reward_points_review?: Maybe<Scalars['String']['output']>
  /** Maximum number of reviews that will qualify for the rewards */
  magento_reward_points_review_limit?: Maybe<Scalars['String']['output']>
  /** Indicates whether wishlists are enabled (1) or disabled (0) */
  magento_wishlist_general_is_enabled?: Maybe<Scalars['String']['output']>
  /** If multiple wish lists are enabled, the maximum number of wish lists the customer can have */
  maximum_number_of_wishlists?: Maybe<Scalars['String']['output']>
  /** The minimum number of characters required for a valid password. */
  minimum_password_length?: Maybe<Scalars['String']['output']>
  /** Default No-route URL */
  no_route?: Maybe<Scalars['String']['output']>
  /** Payflow Pro vault status. */
  payment_payflowpro_cc_vault_active?: Maybe<Scalars['String']['output']>
  pazaruvaj?: Maybe<PazaruvajType>
  /** Default Price for Printed Card */
  printed_card_price?: Maybe<Scalars['String']['output']>
  /** Corresponds to the 'Display Prices On Product View Page' field. It indicates how FPT information is displayed on product pages */
  product_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No) */
  product_reviews_enabled?: Maybe<Scalars['String']['output']>
  /** Product URL Suffix. */
  product_url_suffix?: Maybe<Scalars['String']['output']>
  /** The number of different character classes required in a password (lowercase, uppercase, digits, special characters). */
  required_character_classes_number?: Maybe<Scalars['String']['output']>
  /** Indicates whether RMA is enabled on the storefront. Possible values: enabled/disabled */
  returns_enabled: Scalars['String']['output']
  /**
   * The ID of the root category
   * @deprecated Use `root_category_uid` instead
   */
  root_category_id?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CategoryInterface` object. */
  root_category_uid?: Maybe<Scalars['ID']['output']>
  /** Corresponds to the 'Display Prices In Sales Modules' field. It indicates how FPT information is displayed on cart, checkout, and order pages */
  sales_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>
  /** Display Gift Wrapping Prices */
  sales_gift_wrapping?: Maybe<Scalars['String']['output']>
  /** Display Printed Card Prices */
  sales_printed_card?: Maybe<Scalars['String']['output']>
  /** Secure base link URL for the store */
  secure_base_link_url?: Maybe<Scalars['String']['output']>
  /** Secure base media URL for the store */
  secure_base_media_url?: Maybe<Scalars['String']['output']>
  /** Secure base static URL for the store */
  secure_base_static_url?: Maybe<Scalars['String']['output']>
  /** Secure base URL for the store */
  secure_base_url?: Maybe<Scalars['String']['output']>
  /** Email to a Friend configuration. */
  send_friend?: Maybe<SendFriendConfiguration>
  /** Show Breadcrumbs for CMS Pages */
  show_cms_breadcrumbs?: Maybe<Scalars['Int']['output']>
  skroutz?: Maybe<SkroutzType>
  /** The unique ID of the store view. In the Admin, this is called the Store View Code. When making a GraphQL call, assign this value to the `Store` header to provide the scope */
  store_code?: Maybe<Scalars['ID']['output']>
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name */
  store_group_code?: Maybe<Scalars['ID']['output']>
  /** The label assigned to the store group */
  store_group_name?: Maybe<Scalars['String']['output']>
  /** The label assigned to the store view */
  store_name?: Maybe<Scalars['String']['output']>
  /** The store view sort order */
  store_sort_order?: Maybe<Scalars['Int']['output']>
  /** Timezone of the store */
  timezone?: Maybe<Scalars['String']['output']>
  /** Page Title Prefix */
  title_prefix?: Maybe<Scalars['String']['output']>
  /** Page Title Separator. */
  title_separator?: Maybe<Scalars['String']['output']>
  /** Page Title Suffix */
  title_suffix?: Maybe<Scalars['String']['output']>
  twisto_branding?: Maybe<Scalars['Boolean']['output']>
  /** The configuration determines if the store code should be used in the URL */
  use_store_in_url?: Maybe<Scalars['Boolean']['output']>
  /** The unique ID for the website */
  website_code?: Maybe<Scalars['ID']['output']>
  /**
   * The ID number assigned to the website store
   * @deprecated The field should not be used on the storefront
   */
  website_id?: Maybe<Scalars['Int']['output']>
  /** The label assigned to the website */
  website_name?: Maybe<Scalars['String']['output']>
  website_switcher: Array<Maybe<WebsiteType>>
  /** The unit of weight */
  weight_unit?: Maybe<Scalars['String']['output']>
  /** Welcome Text */
  welcome?: Maybe<Scalars['String']['output']>
}

export type StorefrontProperties = {
  __typename?: 'StorefrontProperties'
  /** The relative position of the attribute in the layered navigation block */
  position?: Maybe<Scalars['Int']['output']>
  /** Indicates whether the attribute is filterable with results, without results, or not at all */
  use_in_layered_navigation?: Maybe<UseInLayeredNavigationOptions>
  /** Indicates whether the attribute is displayed in product listings */
  use_in_product_listing?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the attribute can be used in layered navigation on search results pages */
  use_in_search_results_layered_navigation?: Maybe<Scalars['Boolean']['output']>
  /** Indicates whether the attribute is displayed on product pages */
  visible_on_catalog_pages?: Maybe<Scalars['Boolean']['output']>
}

export type SubscribeEmailToNewsletterOutput = {
  __typename?: 'SubscribeEmailToNewsletterOutput'
  /** Returns the status of the subscription request */
  status?: Maybe<SubscriptionStatusesEnum>
}

export enum SubscriptionStatusesEnum {
  NotActive = 'NOT_ACTIVE',
  Subscribed = 'SUBSCRIBED',
  Unconfirmed = 'UNCONFIRMED',
  Unsubscribed = 'UNSUBSCRIBED',
}

export type SwatchData = {
  __typename?: 'SwatchData'
  /** Type of swatch filter item: 1 - text, 2 - image */
  type?: Maybe<Scalars['String']['output']>
  /** Value for swatch item (text or image link) */
  value?: Maybe<Scalars['String']['output']>
}

export type SwatchDataInterface = {
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']['output']>
}

export type SwatchLayerFilterItem = LayerFilterItemInterface &
  SwatchLayerFilterItemInterface & {
    __typename?: 'SwatchLayerFilterItem'
    /**
     * Count of items by filter.
     * @deprecated Use AggregationOption.count instead.
     */
    items_count?: Maybe<Scalars['Int']['output']>
    /**
     * Filter label.
     * @deprecated Use AggregationOption.label instead.
     */
    label?: Maybe<Scalars['String']['output']>
    /** Data required to render swatch filter item */
    swatch_data?: Maybe<SwatchData>
    /**
     * Value for filter request variable to be used in query.
     * @deprecated Use AggregationOption.value instead.
     */
    value_string?: Maybe<Scalars['String']['output']>
  }

export type SwatchLayerFilterItemInterface = {
  /** Data required to render swatch filter item */
  swatch_data?: Maybe<SwatchData>
}

/** The tax item details */
export type TaxItem = {
  __typename?: 'TaxItem'
  /** The amount of tax applied to the item */
  amount: Money
  /** The rate used to calculate the tax */
  rate: Scalars['Float']['output']
  /** A title that describes the tax */
  title: Scalars['String']['output']
}

export type TextSwatchData = SwatchDataInterface & {
  __typename?: 'TextSwatchData'
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']['output']>
}

/** A price based on the quantity purchased. */
export type TierPrice = {
  __typename?: 'TierPrice'
  /** The price discount that this tier represents. */
  discount?: Maybe<ProductDiscount>
  final_price?: Maybe<Money>
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity?: Maybe<Scalars['Float']['output']>
}

export type Timestamps = {
  __typename?: 'Timestamps'
  canceled_at?: Maybe<Scalars['String']['output']>
  completed_at?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['String']['output']>
  paid_at?: Maybe<Scalars['String']['output']>
  processing_at?: Maybe<Scalars['String']['output']>
  sent_at?: Maybe<Scalars['String']['output']>
  suspended_note_at?: Maybe<Scalars['String']['output']>
  suspended_shipment_at?: Maybe<Scalars['String']['output']>
}

export type TwistoInput = {
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  country_id?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  region?: InputMaybe<Scalars['String']['input']>
  region_code?: InputMaybe<Scalars['String']['input']>
  region_id?: InputMaybe<Scalars['Int']['input']>
  street?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  telephone?: InputMaybe<Scalars['String']['input']>
}

export type TwistoPayload = {
  __typename?: 'TwistoPayload'
  payload?: Maybe<Scalars['String']['output']>
}

export type TwistoResponse = {
  __typename?: 'TwistoResponse'
  reason?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  status_id?: Maybe<Scalars['Int']['output']>
  transaction_id?: Maybe<Scalars['String']['output']>
}

export type TwistoTransaction = {
  __typename?: 'TwistoTransaction'
  status?: Maybe<Scalars['Boolean']['output']>
  transaction_id?: Maybe<Scalars['String']['output']>
}

export type UnitPrice = {
  __typename?: 'UnitPrice'
  currency: Scalars['String']['output']
  unit: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type UpdateCartItemsInput = {
  cart_id: Scalars['String']['input']
  cart_items: Array<InputMaybe<CartItemUpdateInput>>
}

export type UpdateCartItemsOutput = {
  __typename?: 'UpdateCartItemsOutput'
  cart: Cart
}

/** Contains the response to the request to update the company */
export type UpdateCompanyOutput = {
  __typename?: 'UpdateCompanyOutput'
  /** The updated company instance */
  company: Company
}

/** Contains the response to the request to update the company role */
export type UpdateCompanyRoleOutput = {
  __typename?: 'UpdateCompanyRoleOutput'
  /** The updated company role instance */
  role: CompanyRole
}

/** Contains the response to the request to update the company structure */
export type UpdateCompanyStructureOutput = {
  __typename?: 'UpdateCompanyStructureOutput'
  /** The updated company instance */
  company: Company
}

/** Contains the response to the request to update a company team */
export type UpdateCompanyTeamOutput = {
  __typename?: 'UpdateCompanyTeamOutput'
  /** The updated company team instance */
  team: CompanyTeam
}

/** Contains the response to the request to update the company user */
export type UpdateCompanyUserOutput = {
  __typename?: 'UpdateCompanyUserOutput'
  /** The updated company user instance */
  user: Customer
}

export type UpdateGiftRegistryInput = {
  /** Additional attributes specified as a code-value pair. Unspecified dynamic attributes are not changed */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<GiftRegistryDynamicAttributeInput>>
  >
  /** The updated name of the event */
  event_name?: InputMaybe<Scalars['String']['input']>
  /** The updated message describing the event */
  message?: InputMaybe<Scalars['String']['input']>
  /** Indicates whether the gift registry is PRIVATE or PUBLIC */
  privacy_settings?: InputMaybe<GiftRegistryPrivacySettings>
  /** The updated shipping address for all gift registry items */
  shipping_address?: InputMaybe<GiftRegistryShippingAddressInput>
  /** Indicates whether the gift registry is ACTIVE or INACTIVE */
  status?: InputMaybe<GiftRegistryStatus>
}

export type UpdateGiftRegistryItemInput = {
  /** The unique ID of a `giftRegistryItem` object */
  gift_registry_item_uid: Scalars['ID']['input']
  /** The updated description of the item */
  note?: InputMaybe<Scalars['String']['input']>
  /** The updated quantity of the gift registry item */
  quantity: Scalars['Float']['input']
}

export type UpdateGiftRegistryItemsOutput = {
  __typename?: 'UpdateGiftRegistryItemsOutput'
  /** The gift registry after updating updating items */
  gift_registry?: Maybe<GiftRegistry>
}

export type UpdateGiftRegistryOutput = {
  __typename?: 'UpdateGiftRegistryOutput'
  /** The updated gift registry */
  gift_registry?: Maybe<GiftRegistry>
}

export type UpdateGiftRegistryRegistrantInput = {
  /** As a result of the update, only the values of provided attributes will be affected. If the attribute is missing in the request, its value will not be changed */
  dynamic_attributes?: InputMaybe<
    Array<InputMaybe<GiftRegistryDynamicAttributeInput>>
  >
  /** The updated email address of the registrant */
  email?: InputMaybe<Scalars['String']['input']>
  /** The updated first name of the registrant */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** The unique ID of a `giftRegistryRegistrant` object */
  gift_registry_registrant_uid: Scalars['ID']['input']
  /** The updated last name of the registrant */
  lastname?: InputMaybe<Scalars['String']['input']>
}

export type UpdateGiftRegistryRegistrantsOutput = {
  __typename?: 'UpdateGiftRegistryRegistrantsOutput'
  /** The gift registry after updating registrants */
  gift_registry?: Maybe<GiftRegistry>
}

export type UpdateNegotiableQuoteItemsQuantityOutput = {
  __typename?: 'UpdateNegotiableQuoteItemsQuantityOutput'
  /** The updated negotiable quote */
  quote?: Maybe<NegotiableQuote>
}

export type UpdateNegotiableQuoteQuantitiesInput = {
  /** An array of items to update */
  items: Array<InputMaybe<NegotiableQuoteItemQuantityInput>>
  /** The unique ID of a `NegotiableQuote` object */
  quote_uid: Scalars['ID']['input']
}

/** Contains the customer's wish list and any errors encountered */
export type UpdateProductsInWishlistOutput = {
  __typename?: 'UpdateProductsInWishlistOutput'
  /** An array of errors encountered while updating products in a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>
  /** Contains the wish list with all items that were successfully updated */
  wishlist: Wishlist
}

export type UpdateRequisitionListInput = {
  /** The updated description of the requisition list */
  description?: InputMaybe<Scalars['String']['input']>
  /** The new name of the requisition list */
  name: Scalars['String']['input']
}

/** Defines which items in a requisition list to update */
export type UpdateRequisitionListItemsInput = {
  /** Defines customer-entered options */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** The ID of the requisition list item to update */
  item_id: Scalars['ID']['input']
  /** The new quantity of the item */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** An array of selected option IDs */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/** Output of the request to update items in the specified requisition list */
export type UpdateRequisitionListItemsOutput = {
  __typename?: 'UpdateRequisitionListItemsOutput'
  /** The requisition list after updating items */
  requisition_list?: Maybe<RequisitionList>
}

/** Output of the request to rename the requisition list */
export type UpdateRequisitionListOutput = {
  __typename?: 'UpdateRequisitionListOutput'
  /** The renamed requisition list */
  requisition_list?: Maybe<RequisitionList>
}

export type UpdateWishlistOutput = {
  __typename?: 'UpdateWishlistOutput'
  /** The wish list name */
  name: Scalars['String']['output']
  /** The unique ID for a `Wishlist` object */
  uid: Scalars['ID']['output']
  /** Indicates whether the wish list is public or private */
  visibility: WishlistVisibilityEnum
}

/** The object contains URL rewrite details */
export type UrlRewrite = {
  __typename?: 'UrlRewrite'
  /** Request parameters */
  parameters?: Maybe<Array<Maybe<HttpQueryParameter>>>
  /** Request URL */
  url?: Maybe<Scalars['String']['output']>
}

/** This enumeration defines the entity type. */
export enum UrlRewriteEntityTypeEnum {
  Category = 'CATEGORY',
  CmsPage = 'CMS_PAGE',
  Product = 'PRODUCT',
}

export enum UseInLayeredNavigationOptions {
  FilterableNoResult = 'FILTERABLE_NO_RESULT',
  FilterableWithResults = 'FILTERABLE_WITH_RESULTS',
  No = 'NO',
}

/** Required input for payment methods with Vault support. */
export type VaultTokenInput = {
  /** The public hash of the payment token */
  public_hash: Scalars['String']['input']
}

/** Virtual Cart Item */
export type VirtualCartItem = CartItemInterface & {
  __typename?: 'VirtualCartItem'
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** @deprecated Use `uid` instead */
  id: Scalars['String']['output']
  prices?: Maybe<CartItemPrices>
  product: ProductInterface
  quantity: Scalars['Float']['output']
  rule_id?: Maybe<Scalars['Int']['output']>
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID']['output']
}

/** A virtual product is a non-tangible product that does not require shipping and is not kept in inventory */
export type VirtualProduct = CustomizableProductInterface &
  ProductInterface &
  RoutableInterface & {
    __typename?: 'VirtualProduct'
    allergen_celery?: Maybe<Scalars['Int']['output']>
    allergen_crustaceans?: Maybe<Scalars['Int']['output']>
    allergen_eggs?: Maybe<Scalars['Int']['output']>
    allergen_fish?: Maybe<Scalars['Int']['output']>
    allergen_gluten?: Maybe<Scalars['Int']['output']>
    allergen_lupin?: Maybe<Scalars['Int']['output']>
    allergen_milk?: Maybe<Scalars['Int']['output']>
    allergen_molluscs?: Maybe<Scalars['Int']['output']>
    allergen_mustard?: Maybe<Scalars['Int']['output']>
    allergen_peanuts?: Maybe<Scalars['Int']['output']>
    allergen_sesame?: Maybe<Scalars['Int']['output']>
    allergen_soya?: Maybe<Scalars['Int']['output']>
    allergen_sulphites?: Maybe<Scalars['Int']['output']>
    allergen_tree_nuts?: Maybe<Scalars['Int']['output']>
    application?: Maybe<Scalars['Int']['output']>
    artificial_sweetener_free?: Maybe<Scalars['Int']['output']>
    aspartame_free?: Maybe<Scalars['Int']['output']>
    /**
     * The attribute set assigned to the product.
     * @deprecated The field should not be used on the storefront.
     */
    attribute_set_id?: Maybe<Scalars['Int']['output']>
    available_for_sdd?: Maybe<Scalars['String']['output']>
    bag_volume_range?: Maybe<Scalars['Int']['output']>
    bio?: Maybe<Scalars['Int']['output']>
    blend?: Maybe<Scalars['Int']['output']>
    bottom?: Maybe<Scalars['String']['output']>
    breadcrumb?: Maybe<Array<Maybe<BreadcrumbType>>>
    breadcrumb_en?: Maybe<Scalars['String']['output']>
    /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
    canonical_url?: Maybe<Scalars['String']['output']>
    capsules?: Maybe<Scalars['String']['output']>
    /** The categories assigned to a product. */
    categories?: Maybe<Array<Maybe<CategoryInterface>>>
    color?: Maybe<Scalars['String']['output']>
    colors?: Maybe<Scalars['String']['output']>
    /** The product's country of origin. */
    country_of_manufacture?: Maybe<Scalars['String']['output']>
    coupon_amount?: Maybe<Scalars['Int']['output']>
    /**
     * Timestamp indicating when the product was created.
     * @deprecated The field should not be used on the storefront.
     */
    created_at?: Maybe<Scalars['String']['output']>
    /** Crosssell Products */
    crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>
    default_category?: Maybe<Scalars['String']['output']>
    /** Detailed information about the product. The value can include simple HTML tags. */
    description?: Maybe<ComplexTextValue>
    ean?: Maybe<Scalars['String']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    estimated_date_of_arrival?: Maybe<EstimatedDateOfArrivalType>
    expected_arrival_date?: Maybe<Scalars['String']['output']>
    farba_oblecenia?: Maybe<Scalars['String']['output']>
    farba_sejkera?: Maybe<Scalars['String']['output']>
    fit?: Maybe<Scalars['String']['output']>
    fitness_goal?: Maybe<Scalars['String']['output']>
    flavor?: Maybe<Scalars['String']['output']>
    form?: Maybe<Scalars['Int']['output']>
    /** Product Labels list */
    gb_product_labels?: Maybe<Array<Maybe<Label>>>
    gender_segmentation?: Maybe<Scalars['String']['output']>
    /** Indicates whether a gift message is available. */
    gift_message_available?: Maybe<Scalars['String']['output']>
    glutenfree?: Maybe<Scalars['Int']['output']>
    gmo_free?: Maybe<Scalars['Int']['output']>
    heureka_name?: Maybe<Scalars['String']['output']>
    hodnota_kuponu?: Maybe<Scalars['String']['output']>
    hreflangs?: Maybe<Array<Maybe<Hreflangs>>>
    /**
     * The ID number assigned to the product.
     * @deprecated Use the `uid` field instead.
     */
    id?: Maybe<Scalars['Int']['output']>
    /** The relative path to the main image on the product page. */
    image?: Maybe<ProductImage>
    /** Indicates whether the product can be returned */
    is_returnable?: Maybe<Scalars['String']['output']>
    lactosefree?: Maybe<Scalars['Int']['output']>
    /** The latest Related Blog Posts for a specific Product and Store */
    latest_related_blog_posts?: Maybe<LatestRelatedBlogPosts>
    legal_category_of_product?: Maybe<Scalars['Int']['output']>
    long_term_unavailable?: Maybe<Scalars['Int']['output']>
    main_ingredient?: Maybe<Scalars['Int']['output']>
    /** A number representing the product's manufacturer. */
    manufacturer?: Maybe<Scalars['Int']['output']>
    /** Manufacturer's info. */
    manufacturer_info?: Maybe<ManufacturerInfoInterface>
    manufacturer_model?: Maybe<Scalars['String']['output']>
    mass_grams_g?: Maybe<Scalars['String']['output']>
    mass_mililiter_ml?: Maybe<Scalars['Int']['output']>
    material?: Maybe<Scalars['Int']['output']>
    maximum_duration?: Maybe<Scalars['String']['output']>
    /** An array of Media Gallery objects. */
    media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>
    /**
     * An array of MediaGalleryEntry objects.
     * @deprecated Use product's `media_gallery` instead
     */
    media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>
    /** A brief overview of the product for search results listings, maximum 255 characters. */
    meta_description?: Maybe<Scalars['String']['output']>
    /** A comma-separated list of keywords that are visible only to search engines. */
    meta_keyword?: Maybe<Scalars['String']['output']>
    /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
    meta_title?: Maybe<Scalars['String']['output']>
    method_of_protein_processing?: Maybe<Scalars['Int']['output']>
    mgs_brand?: Maybe<Scalars['Int']['output']>
    mgs_j360?: Maybe<Scalars['Int']['output']>
    milimeter_mm?: Maybe<Scalars['Int']['output']>
    mininum_order_quantity?: Maybe<Scalars['String']['output']>
    /** The Manufacturer's Suggested Retail Price (MSRP) of the product. */
    msrp_price?: Maybe<Scalars['Float']['output']>
    /** The product name. Customers use this name to identify the product. */
    name?: Maybe<Scalars['String']['output']>
    new_from?: Maybe<Scalars['String']['output']>
    /**
     * The beginning date for new product listings, and determines if the product is featured as a new product.
     * @deprecated The field should not be used on the storefront.
     */
    new_from_date?: Maybe<Scalars['String']['output']>
    /**
     * The end date for new product listings.
     * @deprecated The field should not be used on the storefront.
     */
    new_to_date?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_batch?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_package_o?: Maybe<Scalars['String']['output']>
    number_of_pieces_per_pallette?: Maybe<Scalars['String']['output']>
    objem?: Maybe<Scalars['String']['output']>
    /** Product stock only x left count */
    only_x_left_in_stock?: Maybe<Scalars['Float']['output']>
    /** An array of options for a customizable product. */
    options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>
    /** If the product has multiple options, determines where they appear on the product page. */
    options_container?: Maybe<Scalars['String']['output']>
    other_models?: Maybe<Array<Maybe<OtherModelsValues>>>
    plastic_packaging_free?: Maybe<Scalars['Int']['output']>
    preparation?: Maybe<Scalars['String']['output']>
    /**
     * A ProductPrices object, indicating the price of an item.
     * @deprecated Use price_range for product price information.
     */
    price?: Maybe<ProductPrices>
    /** A PriceRange object, indicating the range of prices for the product */
    price_range: PriceRange
    /** An array of TierPrice objects. */
    price_tiers?: Maybe<Array<Maybe<TierPrice>>>
    prichut?: Maybe<Scalars['String']['output']>
    /** The list of products questions. */
    productQuestions: ProductQuestions
    /** The collection of Product labels for this Product */
    product_labels?: Maybe<Array<Maybe<AmLabel>>>
    /** An array of ProductLinks objects. */
    product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>
    product_sort_coefficient?: Maybe<Scalars['String']['output']>
    protein_content_in_product?: Maybe<Scalars['String']['output']>
    protein_sourcee?: Maybe<Scalars['Int']['output']>
    purchasing_category?: Maybe<Scalars['Int']['output']>
    /** The average of all the ratings given to the product. */
    rating_summary: Scalars['Float']['output']
    recycling_codes?: Maybe<Scalars['String']['output']>
    /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
    redirect_code: Scalars['Int']['output']
    /** Related Products */
    related_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
    relative_url?: Maybe<Scalars['String']['output']>
    /** The total count of all the reviews given to the product. */
    review_count: Scalars['Int']['output']
    /** The list of products reviews. */
    reviews: ProductReviews
    /** A short description of the product. Its use depends on the theme. */
    sales_description?: Maybe<ComplexTextValue>
    seasonality?: Maybe<Scalars['Int']['output']>
    /** A short description of the product. Its use depends on the theme. */
    short_description?: Maybe<ComplexTextValue>
    size_of_clothing?: Maybe<Scalars['String']['output']>
    /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
    sku?: Maybe<Scalars['String']['output']>
    /** The relative path to the small image, which is used on catalog pages. */
    small_image?: Maybe<ProductImage>
    /**
     * The beginning date that a product has a special price.
     * @deprecated The field should not be used on the storefront.
     */
    special_from_date?: Maybe<Scalars['String']['output']>
    /** The discounted price of the product. */
    special_price?: Maybe<Scalars['Float']['output']>
    /** The end date that a product has a special price. */
    special_to_date?: Maybe<Scalars['String']['output']>
    staged: Scalars['Boolean']['output']
    /** Stock status of the product */
    stock_status?: Maybe<ProductStockStatus>
    supplier_internal_code?: Maybe<Scalars['String']['output']>
    sw_featured?: Maybe<Scalars['Int']['output']>
    /** The file name of a swatch image */
    swatch_image?: Maybe<Scalars['String']['output']>
    tablets?: Maybe<Scalars['String']['output']>
    tea_package?: Maybe<Scalars['String']['output']>
    /** The relative path to the product's thumbnail image. */
    thumbnail?: Maybe<ProductImage>
    /**
     * The price when tier pricing is in effect and the items purchased threshold has been reached.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_price?: Maybe<Scalars['Float']['output']>
    /**
     * An array of ProductTierPrices objects.
     * @deprecated Use price_tiers for product tier price information.
     */
    tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>
    top?: Maybe<Scalars['String']['output']>
    top_in_category?: Maybe<Scalars['String']['output']>
    tops?: Maybe<Scalars['Int']['output']>
    /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
    type?: Maybe<UrlRewriteEntityTypeEnum>
    /**
     * One of simple, virtual, bundle, downloadable, grouped, or configurable.
     * @deprecated Use __typename instead.
     */
    type_id?: Maybe<Scalars['String']['output']>
    type_of_fish?: Maybe<Scalars['Int']['output']>
    /** The unique ID for a `ProductInterface` object. */
    uid: Scalars['ID']['output']
    /**
     * Timestamp indicating when the product was updated.
     * @deprecated The field should not be used on the storefront.
     */
    updated_at?: Maybe<Scalars['String']['output']>
    /** Upsell Products */
    upsell_products?: Maybe<Array<Maybe<ProductInterface>>>
    /** The part of the URL that identifies the product */
    url_key?: Maybe<Scalars['String']['output']>
    /** @deprecated Use product's `canonical_url` or url rewrites instead */
    url_path?: Maybe<Scalars['String']['output']>
    /** URL rewrites list */
    url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>
    /** The part of the product URL that is appended after the url key */
    url_suffix?: Maybe<Scalars['String']['output']>
    variants?: Maybe<Scalars['String']['output']>
    vegan?: Maybe<Scalars['Int']['output']>
    vegetarian?: Maybe<Scalars['Int']['output']>
    velkost_oblecenia?: Maybe<Scalars['String']['output']>
    velkost_sejkera?: Maybe<Scalars['String']['output']>
    visible_attributes?: Maybe<Array<Maybe<VisibleAttributes>>>
    vydanie?: Maybe<Scalars['String']['output']>
    /**
     * An array of websites in which the product is available.
     * @deprecated The field should not be used on the storefront.
     */
    websites?: Maybe<Array<Maybe<Website>>>
    weight_kilograms_kg?: Maybe<Scalars['Int']['output']>
  }

/** A virtual product is a non-tangible product that does not require shipping and is not kept in inventory */
export type VirtualProductProductQuestionsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** A virtual product is a non-tangible product that does not require shipping and is not kept in inventory */
export type VirtualProductReviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  filter?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type VirtualProductCartItemInput = {
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>
  data: CartItemInput
}

/** Contains details about virtual products added to a  requisition list */
export type VirtualRequisitionListItem = RequisitionListItemInterface & {
  __typename?: 'VirtualRequisitionListItem'
  /** Selected custom options for an item in the requisition list */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The product model of the requisition list item */
  product: ProductInterface
  /** The amount added */
  quantity: Scalars['Float']['output']
  /** The unique ID for the requisition list Item */
  uid: Scalars['ID']['output']
}

/** A virtual product wish list item */
export type VirtualWishlistItem = WishlistItemInterface & {
  __typename?: 'VirtualWishlistItem'
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String']['output']
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID']['output']
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  quantity: Scalars['Float']['output']
}

export type VisibleAttributes = {
  __typename?: 'VisibleAttributes'
  code?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
  order?: Maybe<Scalars['Int']['output']>
  values?: Maybe<Array<Maybe<VisibleAttributesValues>>>
}

export type VisibleAttributesValues = {
  __typename?: 'VisibleAttributesValues'
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** Website is deprecated because it is should not be used on storefront. The type contains information about a website */
export type Website = {
  __typename?: 'Website'
  /**
   * A code assigned to the website to identify it
   * @deprecated The field should not be used on the storefront.
   */
  code?: Maybe<Scalars['String']['output']>
  /**
   * The default group ID that the website has
   * @deprecated The field should not be used on the storefront.
   */
  default_group_id?: Maybe<Scalars['String']['output']>
  /**
   * The ID number assigned to the website
   * @deprecated The field should not be used on the storefront.
   */
  id?: Maybe<Scalars['Int']['output']>
  /**
   * Specifies if this is the default website
   * @deprecated The field should not be used on the storefront.
   */
  is_default?: Maybe<Scalars['Boolean']['output']>
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name?: Maybe<Scalars['String']['output']>
  /**
   * The attribute to use for sorting websites
   * @deprecated The field should not be used on the storefront.
   */
  sort_order?: Maybe<Scalars['Int']['output']>
}

export type WebsiteType = {
  __typename?: 'WebsiteType'
  icon: Scalars['String']['output']
  nameDesktop: Scalars['String']['output']
  nameMobile: Scalars['String']['output']
  storeCodes: Array<Maybe<Scalars['String']['output']>>
  url: Scalars['String']['output']
}

/** An error encountered while performing operations with WishList. */
export type WishListUserInputError = {
  __typename?: 'WishListUserInputError'
  /** Wishlist-specific error code */
  code: WishListUserInputErrorType
  /** A localized error message */
  message: Scalars['String']['output']
}

export enum WishListUserInputErrorType {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED',
}

export type Wishlist = {
  __typename?: 'Wishlist'
  /** The unique ID for a `Wishlist` object */
  id?: Maybe<Scalars['ID']['output']>
  /** @deprecated Use field `items_v2` from type `Wishlist` instead */
  items?: Maybe<Array<Maybe<WishlistItem>>>
  /** The number of items in the wish list */
  items_count?: Maybe<Scalars['Int']['output']>
  /** An array of items in the customer's wish list */
  items_v2?: Maybe<WishlistItems>
  /** The wish list name */
  name?: Maybe<Scalars['String']['output']>
  /** An encrypted code that Magento uses to link to the wish list */
  sharing_code?: Maybe<Scalars['String']['output']>
  /** The time of the last modification to the wish list */
  updated_at?: Maybe<Scalars['String']['output']>
  /** Indicates whether the wish list is public or private */
  visibility: WishlistVisibilityEnum
}

export type WishlistItems_V2Args = {
  currentPage?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

export type WishlistCartUserInputError = {
  __typename?: 'WishlistCartUserInputError'
  /** An error code that describes the error encountered */
  code: WishlistCartUserInputErrorType
  /** A localized error message */
  message: Scalars['String']['output']
  /** The unique ID of the `Wishlist` object containing an error */
  wishlistId: Scalars['ID']['output']
  /** The unique ID of the wish list item containing an error */
  wishlistItemId: Scalars['ID']['output']
}

export enum WishlistCartUserInputErrorType {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED',
}

export type WishlistItem = {
  __typename?: 'WishlistItem'
  /** The time when the customer added the item to the wish list */
  added_at?: Maybe<Scalars['String']['output']>
  /** The customer's comment about this item */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItem` object */
  id?: Maybe<Scalars['Int']['output']>
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  qty?: Maybe<Scalars['Float']['output']>
}

export type WishlistItemCopyInput = {
  /** The quantity of this item to copy to the destination wish list. This value can't be greater than the quantity in the source wish list. */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** The unique ID for a `WishlistItemInterface` object to be copied */
  wishlist_item_id: Scalars['ID']['input']
}

/** Defines the items to add to a wish list */
export type WishlistItemInput = {
  /** An array of options that the customer entered */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** For complex product types, the SKU of the parent product */
  parent_sku?: InputMaybe<Scalars['String']['input']>
  /** The amount or number of items to add */
  quantity: Scalars['Float']['input']
  /** An array of strings corresponding to options the customer selected */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The SKU of the product to add. For complex product types, specify the child product SKU */
  sku: Scalars['String']['input']
}

export type WishlistItemInterface = {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String']['output']
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>
  /** The description of the item */
  description?: Maybe<Scalars['String']['output']>
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID']['output']
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>
  /** The quantity of this wish list item */
  quantity: Scalars['Float']['output']
}

export type WishlistItemMoveInput = {
  /** The quantity of this item to move to the destination wish list. This value can't be greater than the quantity in the source wish list. */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** filtered by the unique ID for a `WishlistItemInterface` object to be moved */
  wishlist_item_id: Scalars['ID']['input']
}

/** Defines updates to items in a wish list */
export type WishlistItemUpdateInput = {
  /** Customer-entered comments about the item */
  description?: InputMaybe<Scalars['String']['input']>
  /** An array of options that the customer entered */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>
  /** The new amount or number of this item */
  quantity?: InputMaybe<Scalars['Float']['input']>
  /** An array of strings corresponding to options the customer selected */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** The unique ID for a `WishlistItemInterface` object */
  wishlist_item_id: Scalars['ID']['input']
}

export type WishlistItems = {
  __typename?: 'WishlistItems'
  /** A list of items in the wish list */
  items: Array<Maybe<WishlistItemInterface>>
  /** Contains pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>
}

/** Deprecated: `Wishlist` type should be used instead */
export type WishlistOutput = {
  __typename?: 'WishlistOutput'
  /**
   * An array of items in the customer's wish list
   * @deprecated Use field `items` from type `Wishlist` instead
   */
  items?: Maybe<Array<Maybe<WishlistItem>>>
  /**
   * The number of items in the wish list
   * @deprecated Use field `items_count` from type `Wishlist` instead
   */
  items_count?: Maybe<Scalars['Int']['output']>
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source edition
   */
  name?: Maybe<Scalars['String']['output']>
  /**
   * An encrypted code that Magento uses to link to the wish list
   * @deprecated Use field `sharing_code` from type `Wishlist` instead
   */
  sharing_code?: Maybe<Scalars['String']['output']>
  /**
   * The time of the last modification to the wish list
   * @deprecated Use field `updated_at` from type `Wishlist` instead
   */
  updated_at?: Maybe<Scalars['String']['output']>
}

/** This enumeration defines the wish list visibility types */
export enum WishlistVisibilityEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CreateEmptyCartInput = {
  cart_id?: InputMaybe<Scalars['String']['input']>
}

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  generateCustomerToken?: {
    __typename?: 'CustomerToken'
    token?: string | null
  } | null
}

export type CreateProductReviewMutationVariables = Exact<{
  nickname: Scalars['String']['input']
  ratings: Array<ProductReviewRatingInput> | ProductReviewRatingInput
  sku: Scalars['String']['input']
  summary: Scalars['String']['input']
  text: Scalars['String']['input']
}>

export type CreateProductReviewMutation = {
  __typename?: 'Mutation'
  createProductReview: {
    __typename?: 'CreateProductReviewOutput'
    review: { __typename?: 'ProductReview'; created_at: string }
  }
}

export type AddGiftCardToCartMutationVariables = Exact<{
  cartId: Scalars['String']['input']
  cartItems: Array<CartItemInput> | CartItemInput
}>

export type AddGiftCardToCartMutation = {
  __typename?: 'Mutation'
  addProductsToCart?: {
    __typename?: 'AddProductsToCartOutput'
    cart: { __typename?: 'Cart'; id: string }
  } | null
}

export type AddConfigurableProductToCartMutationVariables = Exact<{
  cartId: Scalars['String']['input']
  cartItems:
    | Array<InputMaybe<ConfigurableProductCartItemInput>>
    | InputMaybe<ConfigurableProductCartItemInput>
}>

export type AddConfigurableProductToCartMutation = {
  __typename?: 'Mutation'
  addConfigurableProductsToCart?: {
    __typename?: 'AddConfigurableProductsToCartOutput'
    cart: { __typename?: 'Cart'; id: string }
    messages?: Array<{
      __typename?: 'MessageType'
      type?: string | null
      message?: string | null
    } | null> | null
  } | null
}

export type RelatedBlogPostsQueryVariables = Exact<{
  sku: Scalars['String']['input']
  pageSize: Scalars['Int']['input']
  currentPage: Scalars['Int']['input']
}>

export type RelatedBlogPostsQuery = {
  __typename?: 'Query'
  RelatedBlogPosts?: {
    __typename?: 'BlogPosts'
    total_count?: number | null
    items?: Array<{
      __typename?: 'BlogPostInterface'
      excerpt?: string | null
      img?: string | null
      title?: string | null
      url?: string | null
    } | null> | null
    page_info?: {
      __typename?: 'SearchResultPageInfo'
      current_page?: number | null
      page_size?: number | null
      total_pages?: number | null
    } | null
  } | null
}

export type CartQueryVariables = Exact<{
  cartId: Scalars['String']['input']
}>

export type CartQuery = {
  __typename?: 'Query'
  cart?: {
    __typename?: 'Cart'
    id: string
    items?: Array<
      | {
          __typename: 'BundleCartItem'
          uid: string
          quantity: number
          product:
            | {
                __typename?: 'BundleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'ConfigurableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'DownloadableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GiftCardProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GroupedProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'SimpleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'VirtualProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
          prices?: {
            __typename?: 'CartItemPrices'
            price_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
            row_total_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
          } | null
        }
      | {
          __typename: 'ConfigurableCartItem'
          uid: string
          quantity: number
          configurable_options: Array<{
            __typename?: 'SelectedConfigurableOption'
            id: number
            value_label: string
          } | null>
          configured_variant:
            | {
                __typename?: 'BundleProduct'
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                    medium?: string | null
                    full?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'ConfigurableProduct'
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                    medium?: string | null
                    full?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'DownloadableProduct'
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                    medium?: string | null
                    full?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GiftCardProduct'
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                    medium?: string | null
                    full?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GroupedProduct'
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                    medium?: string | null
                    full?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'SimpleProduct'
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                    medium?: string | null
                    full?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'VirtualProduct'
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                    medium?: string | null
                    full?: string | null
                  } | null
                } | null
              }
          product:
            | {
                __typename?: 'BundleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'ConfigurableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'DownloadableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GiftCardProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GroupedProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'SimpleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'VirtualProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
          prices?: {
            __typename?: 'CartItemPrices'
            price_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
            row_total_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
          } | null
        }
      | {
          __typename: 'DownloadableCartItem'
          uid: string
          quantity: number
          product:
            | {
                __typename?: 'BundleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'ConfigurableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'DownloadableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GiftCardProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GroupedProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'SimpleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'VirtualProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
          prices?: {
            __typename?: 'CartItemPrices'
            price_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
            row_total_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
          } | null
        }
      | {
          __typename: 'GiftCardCartItem'
          uid: string
          quantity: number
          product:
            | {
                __typename?: 'BundleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'ConfigurableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'DownloadableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GiftCardProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GroupedProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'SimpleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'VirtualProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
          prices?: {
            __typename?: 'CartItemPrices'
            price_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
            row_total_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
          } | null
        }
      | {
          __typename: 'SimpleCartItem'
          uid: string
          quantity: number
          product:
            | {
                __typename?: 'BundleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'ConfigurableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'DownloadableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GiftCardProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GroupedProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'SimpleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'VirtualProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
          prices?: {
            __typename?: 'CartItemPrices'
            price_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
            row_total_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
          } | null
        }
      | {
          __typename: 'VirtualCartItem'
          uid: string
          quantity: number
          product:
            | {
                __typename?: 'BundleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'ConfigurableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'DownloadableProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GiftCardProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'GroupedProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'SimpleProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
            | {
                __typename?: 'VirtualProduct'
                uid: string
                name?: string | null
                canonical_url?: string | null
                image?: {
                  __typename?: 'ProductImage'
                  label?: string | null
                  url?: {
                    __typename?: 'MediaGalleryUrlInterface'
                    small?: string | null
                  } | null
                } | null
              }
          prices?: {
            __typename?: 'CartItemPrices'
            price_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
            row_total_including_tax: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
          } | null
        }
      | null
    > | null
  } | null
}

export type CartFragment = {
  __typename?: 'Cart'
  id: string
  items?: Array<
    | {
        __typename: 'BundleCartItem'
        uid: string
        quantity: number
        product:
          | {
              __typename?: 'BundleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'ConfigurableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'DownloadableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GiftCardProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GroupedProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'SimpleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'VirtualProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
        prices?: {
          __typename?: 'CartItemPrices'
          price_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          row_total_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
        } | null
      }
    | {
        __typename: 'ConfigurableCartItem'
        uid: string
        quantity: number
        configurable_options: Array<{
          __typename?: 'SelectedConfigurableOption'
          id: number
          value_label: string
        } | null>
        configured_variant:
          | {
              __typename?: 'BundleProduct'
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                  medium?: string | null
                  full?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'ConfigurableProduct'
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                  medium?: string | null
                  full?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'DownloadableProduct'
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                  medium?: string | null
                  full?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GiftCardProduct'
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                  medium?: string | null
                  full?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GroupedProduct'
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                  medium?: string | null
                  full?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'SimpleProduct'
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                  medium?: string | null
                  full?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'VirtualProduct'
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                  medium?: string | null
                  full?: string | null
                } | null
              } | null
            }
        product:
          | {
              __typename?: 'BundleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'ConfigurableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'DownloadableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GiftCardProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GroupedProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'SimpleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'VirtualProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
        prices?: {
          __typename?: 'CartItemPrices'
          price_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          row_total_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
        } | null
      }
    | {
        __typename: 'DownloadableCartItem'
        uid: string
        quantity: number
        product:
          | {
              __typename?: 'BundleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'ConfigurableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'DownloadableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GiftCardProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GroupedProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'SimpleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'VirtualProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
        prices?: {
          __typename?: 'CartItemPrices'
          price_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          row_total_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
        } | null
      }
    | {
        __typename: 'GiftCardCartItem'
        uid: string
        quantity: number
        product:
          | {
              __typename?: 'BundleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'ConfigurableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'DownloadableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GiftCardProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GroupedProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'SimpleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'VirtualProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
        prices?: {
          __typename?: 'CartItemPrices'
          price_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          row_total_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
        } | null
      }
    | {
        __typename: 'SimpleCartItem'
        uid: string
        quantity: number
        product:
          | {
              __typename?: 'BundleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'ConfigurableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'DownloadableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GiftCardProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GroupedProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'SimpleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'VirtualProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
        prices?: {
          __typename?: 'CartItemPrices'
          price_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          row_total_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
        } | null
      }
    | {
        __typename: 'VirtualCartItem'
        uid: string
        quantity: number
        product:
          | {
              __typename?: 'BundleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'ConfigurableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'DownloadableProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GiftCardProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'GroupedProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'SimpleProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
          | {
              __typename?: 'VirtualProduct'
              uid: string
              name?: string | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  small?: string | null
                } | null
              } | null
            }
        prices?: {
          __typename?: 'CartItemPrices'
          price_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          row_total_including_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
        } | null
      }
    | null
  > | null
}

type CartItem_BundleCartItem_Fragment = {
  __typename: 'BundleCartItem'
  uid: string
  quantity: number
  product:
    | {
        __typename?: 'BundleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'ConfigurableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'DownloadableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GiftCardProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GroupedProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'SimpleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'VirtualProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
  prices?: {
    __typename?: 'CartItemPrices'
    price_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
    row_total_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
  } | null
}

type CartItem_ConfigurableCartItem_Fragment = {
  __typename: 'ConfigurableCartItem'
  uid: string
  quantity: number
  configurable_options: Array<{
    __typename?: 'SelectedConfigurableOption'
    id: number
    value_label: string
  } | null>
  configured_variant:
    | {
        __typename?: 'BundleProduct'
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
            medium?: string | null
            full?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'ConfigurableProduct'
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
            medium?: string | null
            full?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'DownloadableProduct'
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
            medium?: string | null
            full?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GiftCardProduct'
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
            medium?: string | null
            full?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GroupedProduct'
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
            medium?: string | null
            full?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'SimpleProduct'
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
            medium?: string | null
            full?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'VirtualProduct'
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
            medium?: string | null
            full?: string | null
          } | null
        } | null
      }
  product:
    | {
        __typename?: 'BundleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'ConfigurableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'DownloadableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GiftCardProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GroupedProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'SimpleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'VirtualProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
  prices?: {
    __typename?: 'CartItemPrices'
    price_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
    row_total_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
  } | null
}

type CartItem_DownloadableCartItem_Fragment = {
  __typename: 'DownloadableCartItem'
  uid: string
  quantity: number
  product:
    | {
        __typename?: 'BundleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'ConfigurableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'DownloadableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GiftCardProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GroupedProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'SimpleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'VirtualProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
  prices?: {
    __typename?: 'CartItemPrices'
    price_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
    row_total_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
  } | null
}

type CartItem_GiftCardCartItem_Fragment = {
  __typename: 'GiftCardCartItem'
  uid: string
  quantity: number
  product:
    | {
        __typename?: 'BundleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'ConfigurableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'DownloadableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GiftCardProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GroupedProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'SimpleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'VirtualProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
  prices?: {
    __typename?: 'CartItemPrices'
    price_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
    row_total_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
  } | null
}

type CartItem_SimpleCartItem_Fragment = {
  __typename: 'SimpleCartItem'
  uid: string
  quantity: number
  product:
    | {
        __typename?: 'BundleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'ConfigurableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'DownloadableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GiftCardProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GroupedProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'SimpleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'VirtualProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
  prices?: {
    __typename?: 'CartItemPrices'
    price_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
    row_total_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
  } | null
}

type CartItem_VirtualCartItem_Fragment = {
  __typename: 'VirtualCartItem'
  uid: string
  quantity: number
  product:
    | {
        __typename?: 'BundleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'ConfigurableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'DownloadableProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GiftCardProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'GroupedProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'SimpleProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
    | {
        __typename?: 'VirtualProduct'
        uid: string
        name?: string | null
        canonical_url?: string | null
        image?: {
          __typename?: 'ProductImage'
          label?: string | null
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            small?: string | null
          } | null
        } | null
      }
  prices?: {
    __typename?: 'CartItemPrices'
    price_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
    row_total_including_tax: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    }
  } | null
}

export type CartItemFragment =
  | CartItem_BundleCartItem_Fragment
  | CartItem_ConfigurableCartItem_Fragment
  | CartItem_DownloadableCartItem_Fragment
  | CartItem_GiftCardCartItem_Fragment
  | CartItem_SimpleCartItem_Fragment
  | CartItem_VirtualCartItem_Fragment

export type GiftCardAccountQueryVariables = Exact<{
  giftCardCode: Scalars['String']['input']
}>

export type GiftCardAccountQuery = {
  __typename?: 'Query'
  giftCardAccount?: {
    __typename?: 'GiftCardAccount'
    code?: string | null
    expiration_date?: string | null
    balance?: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    } | null
  } | null
}

export type CategoriesByGoalQueryVariables = Exact<{ [key: string]: never }>

export type CategoriesByGoalQuery = {
  __typename?: 'Query'
  categoriesByGoal?: Array<{
    __typename?: 'CategoryByGoal'
    custom_name?: string | null
    category?: {
      __typename?: 'CategoryTree'
      uid: string
      thumbnail?: string | null
      url?: string | null
      url_key?: string | null
    } | null
  } | null> | null
}

export type CategoryListQueryVariables = Exact<{
  filters?: InputMaybe<CategoryFilterInput>
}>

export type CategoryListQuery = {
  __typename?: 'Query'
  categoryList?: Array<{
    __typename?: 'CategoryTree'
    children?: Array<{
      __typename?: 'CategoryTree'
      display_main_navigation?: number | null
      display_side_navigation?: number | null
      id?: number | null
      include_in_menu?: number | null
      level?: number | null
      name?: string | null
      path?: string | null
      staged: boolean
      uid: string
      url?: string | null
      custom_url?: string | null
      url_key?: string | null
      visible_tiktok?: boolean | null
      children?: Array<{
        __typename?: 'CategoryTree'
        display_main_navigation?: number | null
        display_side_navigation?: number | null
        id?: number | null
        include_in_menu?: number | null
        level?: number | null
        name?: string | null
        path?: string | null
        staged: boolean
        uid: string
        url?: string | null
        custom_url?: string | null
        url_key?: string | null
        visible_tiktok?: boolean | null
        children?: Array<{
          __typename?: 'CategoryTree'
          display_main_navigation?: number | null
          display_side_navigation?: number | null
          id?: number | null
          include_in_menu?: number | null
          level?: number | null
          name?: string | null
          path?: string | null
          staged: boolean
          uid: string
          url?: string | null
          custom_url?: string | null
          url_key?: string | null
          visible_tiktok?: boolean | null
          children?: Array<{
            __typename?: 'CategoryTree'
            display_main_navigation?: number | null
            display_side_navigation?: number | null
            id?: number | null
            include_in_menu?: number | null
            level?: number | null
            name?: string | null
            path?: string | null
            staged: boolean
            uid: string
            url?: string | null
            custom_url?: string | null
            url_key?: string | null
            visible_tiktok?: boolean | null
          } | null> | null
        } | null> | null
      } | null> | null
    } | null> | null
  } | null> | null
}

export type CategoryFieldsFragment = {
  __typename?: 'CategoryTree'
  display_main_navigation?: number | null
  display_side_navigation?: number | null
  id?: number | null
  include_in_menu?: number | null
  level?: number | null
  name?: string | null
  path?: string | null
  staged: boolean
  uid: string
  url?: string | null
  custom_url?: string | null
  url_key?: string | null
  visible_tiktok?: boolean | null
}

export type CategoryMagentoTagsQueryVariables = Exact<{
  categoryId: Scalars['String']['input']
}>

export type CategoryMagentoTagsQuery = {
  __typename?: 'Query'
  route?:
    | { __typename?: 'BundleProduct' }
    | {
        __typename: 'CategoryTree'
        name?: string | null
        breadcrumbs?: Array<{
          __typename?: 'BreadcrumbType'
          name?: string | null
        } | null> | null
      }
    | { __typename?: 'CmsPage' }
    | { __typename?: 'ConfigurableProduct' }
    | { __typename?: 'DownloadableProduct' }
    | { __typename?: 'GiftCardProduct' }
    | { __typename?: 'GroupedProduct' }
    | { __typename?: 'SimpleProduct' }
    | { __typename?: 'VirtualProduct' }
    | null
}

export type CategoryTagsFragment = {
  __typename: 'CategoryTree'
  name?: string | null
  breadcrumbs?: Array<{
    __typename?: 'BreadcrumbType'
    name?: string | null
  } | null> | null
}

export type ParentCategoriesFragment = {
  __typename?: 'BreadcrumbType'
  name?: string | null
}

export type CategoryMetadataQueryVariables = Exact<{
  categoryId: Scalars['String']['input']
}>

export type CategoryMetadataQuery = {
  __typename?: 'Query'
  route?:
    | { __typename?: 'BundleProduct' }
    | {
        __typename: 'CategoryTree'
        name?: string | null
        description?: string | null
        canonical_url?: string | null
        image?: string | null
        meta_description?: string | null
        meta_robots?: string | null
        meta_title?: string | null
        hreflangs?: Array<{
          __typename?: 'Hreflangs'
          href?: string | null
          hreflang?: string | null
        } | null> | null
      }
    | { __typename?: 'CmsPage' }
    | { __typename?: 'ConfigurableProduct' }
    | { __typename?: 'DownloadableProduct' }
    | { __typename?: 'GiftCardProduct' }
    | { __typename?: 'GroupedProduct' }
    | { __typename?: 'SimpleProduct' }
    | { __typename?: 'VirtualProduct' }
    | null
}

export type CategoryMetadataFragment = {
  __typename: 'CategoryTree'
  name?: string | null
  description?: string | null
  canonical_url?: string | null
  image?: string | null
  meta_description?: string | null
  meta_robots?: string | null
  meta_title?: string | null
  hreflangs?: Array<{
    __typename?: 'Hreflangs'
    href?: string | null
    hreflang?: string | null
  } | null> | null
}

export type CategoryPageProductsQueryVariables = Exact<{
  categoryId: Scalars['String']['input']
  pageSize: Scalars['Int']['input']
  currentPage: Scalars['Int']['input']
  sort?: InputMaybe<CategorySearchSort>
  from?: InputMaybe<Scalars['String']['input']>
  to?: InputMaybe<Scalars['String']['input']>
}>

export type CategoryPageProductsQuery = {
  __typename?: 'Query'
  route?:
    | { __typename: 'BundleProduct' }
    | {
        __typename: 'CategoryTree'
        categorySearch?: {
          __typename: 'CategorySearchProducts'
          total_count?: number | null
          page_info?: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          } | null
          availableFilters?: Array<{
            __typename?: 'CategorySearchFilter'
            range?: {
              __typename?: 'PriceRange'
              min?: number | null
              max?: number | null
            } | null
          } | null> | null
          items?: Array<{
            __typename?: 'CategorySearchProduct'
            breadcrumbs_en?: string | null
            formatted_price?: string | null
            id?: number | null
            image?: string | null
            name?: string | null
            price?: number | null
            product_url?: string | null
            rating_summary?: string | null
            reviews_count?: string | null
            skus?: Array<string | null> | null
            small_image?: string | null
            thumbnail?: string | null
            is_saleable?: string | null
            is_single_variant?: string | null
            is_price_range?: boolean | null
            single_variant_sku?: string | null
            product_labels?: Array<{
              __typename?: 'Label'
              bg?: string | null
              content?: string | null
              discount?: number | null
              label?: number | null
              src?: string | null
              type?: string | null
            } | null> | null
            manufacturer?: {
              __typename?: 'IndexedEntity'
              id?: number | null
              name?: string | null
            } | null
            default_category?: {
              __typename?: 'IndexedEntity'
              id?: number | null
              name?: string | null
            } | null
          } | null> | null
        } | null
      }
    | { __typename: 'CmsPage' }
    | { __typename: 'ConfigurableProduct' }
    | { __typename: 'DownloadableProduct' }
    | { __typename: 'GiftCardProduct' }
    | { __typename: 'GroupedProduct' }
    | { __typename: 'SimpleProduct' }
    | { __typename: 'VirtualProduct' }
    | null
}

export type CategoryPageProductsTreeFragment = {
  __typename?: 'CategoryTree'
  categorySearch?: {
    __typename: 'CategorySearchProducts'
    total_count?: number | null
    page_info?: {
      __typename?: 'SearchResultPageInfo'
      current_page?: number | null
      page_size?: number | null
      total_pages?: number | null
    } | null
    availableFilters?: Array<{
      __typename?: 'CategorySearchFilter'
      range?: {
        __typename?: 'PriceRange'
        min?: number | null
        max?: number | null
      } | null
    } | null> | null
    items?: Array<{
      __typename?: 'CategorySearchProduct'
      breadcrumbs_en?: string | null
      formatted_price?: string | null
      id?: number | null
      image?: string | null
      name?: string | null
      price?: number | null
      product_url?: string | null
      rating_summary?: string | null
      reviews_count?: string | null
      skus?: Array<string | null> | null
      small_image?: string | null
      thumbnail?: string | null
      is_saleable?: string | null
      is_single_variant?: string | null
      is_price_range?: boolean | null
      single_variant_sku?: string | null
      product_labels?: Array<{
        __typename?: 'Label'
        bg?: string | null
        content?: string | null
        discount?: number | null
        label?: number | null
        src?: string | null
        type?: string | null
      } | null> | null
      manufacturer?: {
        __typename?: 'IndexedEntity'
        id?: number | null
        name?: string | null
      } | null
      default_category?: {
        __typename?: 'IndexedEntity'
        id?: number | null
        name?: string | null
      } | null
    } | null> | null
  } | null
}

export type CategoryQueryVariables = Exact<{
  categoryId: Scalars['String']['input']
}>

export type CategoryQuery = {
  __typename?: 'Query'
  route?:
    | { __typename: 'BundleProduct' }
    | {
        __typename: 'CategoryTree'
        breadcrumbs?: Array<{
          __typename?: 'BreadcrumbType'
          name?: string | null
          url?: string | null
        } | null> | null
        categorySearch?: {
          __typename?: 'CategorySearchProducts'
          name?: string | null
          description?: string | null
          short_description?: string | null
          breadcrumbs?: string | null
        } | null
      }
    | { __typename: 'CmsPage' }
    | { __typename: 'ConfigurableProduct' }
    | { __typename: 'DownloadableProduct' }
    | { __typename: 'GiftCardProduct' }
    | { __typename: 'GroupedProduct' }
    | { __typename: 'SimpleProduct' }
    | { __typename: 'VirtualProduct' }
    | null
}

export type CategoryItemsFragment = {
  __typename?: 'CategoryTree'
  breadcrumbs?: Array<{
    __typename?: 'BreadcrumbType'
    name?: string | null
    url?: string | null
  } | null> | null
  categorySearch?: {
    __typename?: 'CategorySearchProducts'
    name?: string | null
    description?: string | null
    short_description?: string | null
    breadcrumbs?: string | null
  } | null
}

export type CategorySearchFiltersQueryVariables = Exact<{
  url: Scalars['String']['input']
  from?: InputMaybe<Scalars['String']['input']>
  to?: InputMaybe<Scalars['String']['input']>
}>

export type CategorySearchFiltersQuery = {
  __typename?: 'Query'
  route?:
    | { __typename?: 'BundleProduct' }
    | {
        __typename: 'CategoryTree'
        categorySearch?: {
          __typename?: 'CategorySearchProducts'
          total_count?: number | null
          availableFilters?: Array<{
            __typename?: 'CategorySearchFilter'
            code?: string | null
            display_mode?: number | null
            global_name?: string | null
            name?: string | null
            position?: number | null
            type?: string | null
            range?: {
              __typename?: 'PriceRange'
              max?: number | null
              min?: number | null
            } | null
            options?: Array<{
              __typename?: 'CategorySearchFilterOption'
              count?: number | null
              name?: string | null
              url?: string | null
              used?: boolean | null
            } | null> | null
          } | null> | null
        } | null
      }
    | { __typename?: 'CmsPage' }
    | { __typename?: 'ConfigurableProduct' }
    | { __typename?: 'DownloadableProduct' }
    | { __typename?: 'GiftCardProduct' }
    | { __typename?: 'GroupedProduct' }
    | { __typename?: 'SimpleProduct' }
    | { __typename?: 'VirtualProduct' }
    | null
}

export type CategorySearchFiltersFragmentFragment = {
  __typename: 'CategoryTree'
  categorySearch?: {
    __typename?: 'CategorySearchProducts'
    total_count?: number | null
    availableFilters?: Array<{
      __typename?: 'CategorySearchFilter'
      code?: string | null
      display_mode?: number | null
      global_name?: string | null
      name?: string | null
      position?: number | null
      type?: string | null
      range?: {
        __typename?: 'PriceRange'
        max?: number | null
        min?: number | null
      } | null
      options?: Array<{
        __typename?: 'CategorySearchFilterOption'
        count?: number | null
        name?: string | null
        url?: string | null
        used?: boolean | null
      } | null> | null
    } | null> | null
  } | null
}

export type BreadcrumbsFragment = {
  __typename?: 'BreadcrumbType'
  name?: string | null
  url?: string | null
}

export type CmsBlockQueryVariables = Exact<{
  identifiers?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type CmsBlockQuery = {
  __typename?: 'Query'
  cmsBlocks?: {
    __typename?: 'CmsBlocks'
    items?: Array<{
      __typename?: 'CmsBlock'
      content?: string | null
      identifier?: string | null
      title?: string | null
    } | null> | null
  } | null
}

export type CmsContentQueryVariables = Exact<{
  identifier: Scalars['String']['input']
}>

export type CmsContentQuery = {
  __typename?: 'Query'
  cmsPage?: {
    __typename?: 'CmsPage'
    identifier?: string | null
    url_key?: string | null
    title?: string | null
    content?: string | null
    content_heading?: string | null
  } | null
}

export type CmsMetaDataQueryVariables = Exact<{
  identifier: Scalars['String']['input']
}>

export type CmsMetaDataQuery = {
  __typename?: 'Query'
  cmsPage?: {
    __typename?: 'CmsPage'
    identifier?: string | null
    url_key?: string | null
    title?: string | null
    meta_description?: string | null
    hreflangs?: Array<{
      __typename?: 'Hreflangs'
      href?: string | null
      hreflang?: string | null
    } | null> | null
  } | null
}

export type CreateProductAnswerMutationVariables = Exact<{
  name: Scalars['String']['input']
  email: Scalars['String']['input']
  answer: Scalars['String']['input']
  questionUid: Scalars['String']['input']
}>

export type CreateProductAnswerMutation = {
  __typename?: 'Mutation'
  createProductAnswer?: {
    __typename?: 'CreateProductAnswerOutput'
    uid?: string | null
  } | null
}

export type CreateProductQuestionMutationVariables = Exact<{
  name: Scalars['String']['input']
  email: Scalars['String']['input']
  question: Scalars['String']['input']
  sku: Scalars['String']['input']
  notification: Scalars['Boolean']['input']
}>

export type CreateProductQuestionMutation = {
  __typename?: 'Mutation'
  createProductQuestion?: {
    __typename?: 'CreateProductQuestionOutput'
    uid?: string | null
  } | null
}

export type CustomerBaseInfoQueryVariables = Exact<{ [key: string]: never }>

export type CustomerBaseInfoQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    firstname?: string | null
    middlename?: string | null
    lastname?: string | null
    email?: string | null
    id?: number | null
    group_code?: string | null
    gender?: number | null
    date_of_birth?: string | null
    is_b2b?: boolean | null
    is_subscribed?: boolean | null
    reviews: {
      __typename?: 'ProductReviews'
      items: Array<{
        __typename?: 'ProductReview'
        review_id?: number | null
        summary_rating?: number | null
        product:
          | {
              __typename?: 'BundleProduct'
              name?: string | null
              url_rewrites?: Array<{
                __typename?: 'UrlRewrite'
                url?: string | null
              } | null> | null
            }
          | {
              __typename?: 'ConfigurableProduct'
              name?: string | null
              url_rewrites?: Array<{
                __typename?: 'UrlRewrite'
                url?: string | null
              } | null> | null
            }
          | {
              __typename?: 'DownloadableProduct'
              name?: string | null
              url_rewrites?: Array<{
                __typename?: 'UrlRewrite'
                url?: string | null
              } | null> | null
            }
          | {
              __typename?: 'GiftCardProduct'
              name?: string | null
              url_rewrites?: Array<{
                __typename?: 'UrlRewrite'
                url?: string | null
              } | null> | null
            }
          | {
              __typename?: 'GroupedProduct'
              name?: string | null
              url_rewrites?: Array<{
                __typename?: 'UrlRewrite'
                url?: string | null
              } | null> | null
            }
          | {
              __typename?: 'SimpleProduct'
              name?: string | null
              url_rewrites?: Array<{
                __typename?: 'UrlRewrite'
                url?: string | null
              } | null> | null
            }
          | {
              __typename?: 'VirtualProduct'
              name?: string | null
              url_rewrites?: Array<{
                __typename?: 'UrlRewrite'
                url?: string | null
              } | null> | null
            }
      } | null>
    }
    addresses?: Array<{
      __typename?: 'CustomerAddress'
      default_billing?: boolean | null
      default_shipping?: boolean | null
      city?: string | null
      firstname?: string | null
      lastname?: string | null
      middlename?: string | null
      company?: string | null
      vat_id?: string | null
      id?: number | null
      postcode?: string | null
      street?: Array<string | null> | null
      country_code?: CountryCodeEnum | null
      telephone?: string | null
    } | null> | null
  } | null
}

export type CustomerFragment = {
  __typename?: 'Customer'
  firstname?: string | null
  middlename?: string | null
  lastname?: string | null
  email?: string | null
  id?: number | null
  group_code?: string | null
  gender?: number | null
  date_of_birth?: string | null
  is_b2b?: boolean | null
  is_subscribed?: boolean | null
  reviews: {
    __typename?: 'ProductReviews'
    items: Array<{
      __typename?: 'ProductReview'
      review_id?: number | null
      summary_rating?: number | null
      product:
        | {
            __typename?: 'BundleProduct'
            name?: string | null
            url_rewrites?: Array<{
              __typename?: 'UrlRewrite'
              url?: string | null
            } | null> | null
          }
        | {
            __typename?: 'ConfigurableProduct'
            name?: string | null
            url_rewrites?: Array<{
              __typename?: 'UrlRewrite'
              url?: string | null
            } | null> | null
          }
        | {
            __typename?: 'DownloadableProduct'
            name?: string | null
            url_rewrites?: Array<{
              __typename?: 'UrlRewrite'
              url?: string | null
            } | null> | null
          }
        | {
            __typename?: 'GiftCardProduct'
            name?: string | null
            url_rewrites?: Array<{
              __typename?: 'UrlRewrite'
              url?: string | null
            } | null> | null
          }
        | {
            __typename?: 'GroupedProduct'
            name?: string | null
            url_rewrites?: Array<{
              __typename?: 'UrlRewrite'
              url?: string | null
            } | null> | null
          }
        | {
            __typename?: 'SimpleProduct'
            name?: string | null
            url_rewrites?: Array<{
              __typename?: 'UrlRewrite'
              url?: string | null
            } | null> | null
          }
        | {
            __typename?: 'VirtualProduct'
            name?: string | null
            url_rewrites?: Array<{
              __typename?: 'UrlRewrite'
              url?: string | null
            } | null> | null
          }
    } | null>
  }
  addresses?: Array<{
    __typename?: 'CustomerAddress'
    default_billing?: boolean | null
    default_shipping?: boolean | null
    city?: string | null
    firstname?: string | null
    lastname?: string | null
    middlename?: string | null
    company?: string | null
    vat_id?: string | null
    id?: number | null
    postcode?: string | null
    street?: Array<string | null> | null
    country_code?: CountryCodeEnum | null
    telephone?: string | null
  } | null> | null
}

export type CustomerIdQueryVariables = Exact<{ [key: string]: never }>

export type CustomerIdQuery = {
  __typename?: 'Query'
  customer?: { __typename?: 'Customer'; id?: number | null } | null
}

export type UpdateCustomerAddressMutationVariables = Exact<{
  id: Scalars['Int']['input']
  firstname: Scalars['String']['input']
  lastname: Scalars['String']['input']
  telephone: Scalars['String']['input']
  city: Scalars['String']['input']
  street: Array<Scalars['String']['input']> | Scalars['String']['input']
  postcode: Scalars['String']['input']
  company?: InputMaybe<Scalars['String']['input']>
  vat_id?: InputMaybe<Scalars['String']['input']>
  default_shipping: Scalars['Boolean']['input']
  default_billing: Scalars['Boolean']['input']
}>

export type UpdateCustomerAddressMutation = {
  __typename?: 'Mutation'
  updateCustomerAddress?: {
    __typename?: 'CustomerAddress'
    id?: number | null
  } | null
}

export type DeleteCustomerAddressMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DeleteCustomerAddressMutation = {
  __typename?: 'Mutation'
  deleteCustomerAddress?: boolean | null
}

export type UpdateCustomerMutationVariables = Exact<{
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Scalars['Int']['input']>
  date_of_birth?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateCustomerMutation = {
  __typename?: 'Mutation'
  updateCustomer?: {
    __typename?: 'CustomerOutput'
    customer: {
      __typename?: 'Customer'
      date_of_birth?: string | null
      firstname?: string | null
      lastname?: string | null
      gender?: number | null
      email?: string | null
    }
  } | null
}

export type CustomerCartIdQueryVariables = Exact<{ [key: string]: never }>

export type CustomerCartIdQuery = {
  __typename?: 'Query'
  customerCart: { __typename?: 'Cart'; id: string }
}

export type ChangeCustomerPasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}>

export type ChangeCustomerPasswordMutation = {
  __typename?: 'Mutation'
  changeCustomerPassword?: {
    __typename?: 'Customer'
    id?: number | null
  } | null
}

export type RevokeCustomerTokenMutationVariables = Exact<{
  [key: string]: never
}>

export type RevokeCustomerTokenMutation = {
  __typename?: 'Mutation'
  revokeCustomerToken?: {
    __typename?: 'RevokeCustomerTokenOutput'
    result: boolean
  } | null
}

export type FooterBlocksQueryVariables = Exact<{ [key: string]: never }>

export type FooterBlocksQuery = {
  __typename?: 'Query'
  cmsBlocks?: {
    __typename?: 'CmsBlocks'
    items?: Array<{
      __typename?: 'CmsBlock'
      content?: string | null
    } | null> | null
  } | null
}

export type HomepageBannerSliderQueryVariables = Exact<{ [key: string]: never }>

export type HomepageBannerSliderQuery = {
  __typename?: 'Query'
  homepageBannerSlider?: {
    __typename?: 'HomepageBannerSliderType'
    config?: {
      __typename?: 'BannerSliderConfig'
      enabled?: boolean | null
      autoplay?: boolean | null
      autoplay_speed?: number | null
      infinite_loop?: boolean | null
      show_dots?: boolean | null
    } | null
    items?: Array<{
      __typename?: 'BannerSliderItem'
      id?: string | null
      title?: string | null
      position?: number | null
      url?: string | null
      desktop_image?: string | null
      mobile_image?: string | null
      status?: boolean | null
      active_from?: string | null
      active_to?: string | null
      store_id?: number | null
    } | null> | null
  } | null
}

export type NewestBlogPostsQueryVariables = Exact<{
  pageSize: Scalars['Int']['input']
  currentPage: Scalars['Int']['input']
}>

export type NewestBlogPostsQuery = {
  __typename?: 'Query'
  NewestBlogPosts?: {
    __typename?: 'BlogPosts'
    items?: Array<{
      __typename?: 'BlogPostInterface'
      excerpt?: string | null
      img?: string | null
      title?: string | null
      url?: string | null
    } | null> | null
  } | null
}

export type CustomerOrderQueryVariables = Exact<{
  orderId: Scalars['Int']['input']
}>

export type CustomerOrderQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    orders?: {
      __typename?: 'CustomerOrders'
      items: Array<{
        __typename?: 'CustomerOrder'
        reorder_eligible?: boolean | null
        created_at?: string | null
        id: string
        increment_id?: string | null
        order_number: string
        order_id?: string | null
        internal_order_id?: string | null
        status: string
        status_en?: string | null
        tracking_url?: string | null
        shipping_method?: string | null
        items?: Array<
          | {
              __typename?: 'BundleOrderItem'
              product_name?: string | null
              parent_sku?: string | null
              product_sku: string
              quantity_ordered?: number | null
              product_image?: string | null
              image?: string | null
              is_saleable?: boolean | null
              product_url_key?: string | null
              selected_options?: Array<{
                __typename?: 'OrderItemOption'
                label: string
                value: string
              } | null> | null
              product_sale_price: {
                __typename?: 'Money'
                currency?: CurrencyEnum | null
                value?: number | null
              }
              entered_options?: Array<{
                __typename?: 'OrderItemOption'
                label: string
                value: string
              } | null> | null
              discounts?: Array<{
                __typename?: 'Discount'
                label: string
                amount: {
                  __typename?: 'Money'
                  currency?: CurrencyEnum | null
                  value?: number | null
                }
              } | null> | null
            }
          | {
              __typename?: 'DownloadableOrderItem'
              product_name?: string | null
              parent_sku?: string | null
              product_sku: string
              quantity_ordered?: number | null
              product_image?: string | null
              image?: string | null
              is_saleable?: boolean | null
              product_url_key?: string | null
              selected_options?: Array<{
                __typename?: 'OrderItemOption'
                label: string
                value: string
              } | null> | null
              product_sale_price: {
                __typename?: 'Money'
                currency?: CurrencyEnum | null
                value?: number | null
              }
              entered_options?: Array<{
                __typename?: 'OrderItemOption'
                label: string
                value: string
              } | null> | null
              discounts?: Array<{
                __typename?: 'Discount'
                label: string
                amount: {
                  __typename?: 'Money'
                  currency?: CurrencyEnum | null
                  value?: number | null
                }
              } | null> | null
            }
          | {
              __typename?: 'GiftCardOrderItem'
              product_name?: string | null
              parent_sku?: string | null
              product_sku: string
              quantity_ordered?: number | null
              product_image?: string | null
              image?: string | null
              is_saleable?: boolean | null
              product_url_key?: string | null
              selected_options?: Array<{
                __typename?: 'OrderItemOption'
                label: string
                value: string
              } | null> | null
              product_sale_price: {
                __typename?: 'Money'
                currency?: CurrencyEnum | null
                value?: number | null
              }
              entered_options?: Array<{
                __typename?: 'OrderItemOption'
                label: string
                value: string
              } | null> | null
              discounts?: Array<{
                __typename?: 'Discount'
                label: string
                amount: {
                  __typename?: 'Money'
                  currency?: CurrencyEnum | null
                  value?: number | null
                }
              } | null> | null
            }
          | {
              __typename?: 'OrderItem'
              product_name?: string | null
              parent_sku?: string | null
              product_sku: string
              quantity_ordered?: number | null
              product_image?: string | null
              image?: string | null
              is_saleable?: boolean | null
              product_url_key?: string | null
              selected_options?: Array<{
                __typename?: 'OrderItemOption'
                label: string
                value: string
              } | null> | null
              product_sale_price: {
                __typename?: 'Money'
                currency?: CurrencyEnum | null
                value?: number | null
              }
              entered_options?: Array<{
                __typename?: 'OrderItemOption'
                label: string
                value: string
              } | null> | null
              discounts?: Array<{
                __typename?: 'Discount'
                label: string
                amount: {
                  __typename?: 'Money'
                  currency?: CurrencyEnum | null
                  value?: number | null
                }
              } | null> | null
            }
          | null
        > | null
        payment_methods?: Array<{
          __typename?: 'OrderPaymentMethod'
          name: string
          instructions?: string | null
          type: string
          bank_account_details?: Array<{
            __typename?: 'BankAccountDetails'
            account_number?: string | null
            iban?: string | null
            swift?: string | null
          } | null> | null
        } | null> | null
        shipping_address?: {
          __typename?: 'OrderAddress'
          firstname: string
          lastname: string
          postcode?: string | null
          street: Array<string | null>
          city: string
          telephone: string
          country_code?: CountryCodeEnum | null
        } | null
        billing_address?: {
          __typename?: 'OrderAddress'
          firstname: string
          lastname: string
          postcode?: string | null
          street: Array<string | null>
          city: string
          telephone: string
          country_code?: CountryCodeEnum | null
        } | null
        total?: {
          __typename?: 'OrderTotal'
          discounts?: Array<{
            __typename?: 'Discount'
            label: string
            amount: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            }
          } | null> | null
          grand_total: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          shipping_handling?: {
            __typename?: 'ShippingHandling'
            amount_including_tax?: {
              __typename?: 'Money'
              currency?: CurrencyEnum | null
              value?: number | null
            } | null
          } | null
          payment_fee?: { __typename?: 'Money'; value?: number | null } | null
          products_price?: {
            __typename?: 'Money'
            value?: number | null
          } | null
          subtotal: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          total_giftcard?: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          } | null
          total_shipping: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
          total_tax: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
        } | null
        invoices: Array<{
          __typename?: 'Invoice'
          id: string
          url?: string | null
        } | null>
      } | null>
    } | null
  } | null
}

export type UpdateInternalOrderIdMutationVariables = Exact<{
  internalOrderId: Scalars['String']['input']
  orderId: Scalars['String']['input']
}>

export type UpdateInternalOrderIdMutation = {
  __typename?: 'Mutation'
  updateInternalOrderId?: {
    __typename?: 'InternalOrderResponse'
    internal_order_id?: string | null
  } | null
}

export type CustomerOrderStatusQueryVariables = Exact<{
  orderId: Scalars['Int']['input']
}>

export type CustomerOrderStatusQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    orders?: {
      __typename?: 'CustomerOrders'
      items: Array<{
        __typename?: 'CustomerOrder'
        status_en?: string | null
        payment_methods?: Array<{
          __typename?: 'OrderPaymentMethod'
          name: string
          type: string
        } | null> | null
        timestamps?: {
          __typename?: 'Timestamps'
          created_at?: string | null
          paid_at?: string | null
          processing_at?: string | null
          suspended_note_at?: string | null
          suspended_shipment_at?: string | null
          sent_at?: string | null
          completed_at?: string | null
          canceled_at?: string | null
        } | null
      } | null>
    } | null
  } | null
}

export type CustomerOrderStatusesQueryVariables = Exact<{
  [key: string]: never
}>

export type CustomerOrderStatusesQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    orders?: {
      __typename?: 'CustomerOrders'
      items: Array<{
        __typename?: 'CustomerOrder'
        id: string
        status_en?: string | null
        payment_methods?: Array<{
          __typename?: 'OrderPaymentMethod'
          name: string
          type: string
        } | null> | null
        timestamps?: {
          __typename?: 'Timestamps'
          created_at?: string | null
          paid_at?: string | null
          processing_at?: string | null
          suspended_note_at?: string | null
          suspended_shipment_at?: string | null
          sent_at?: string | null
          completed_at?: string | null
          canceled_at?: string | null
        } | null
      } | null>
    } | null
  } | null
}

export type ReorderItemsMutationVariables = Exact<{
  orderNumber: Scalars['String']['input']
}>

export type ReorderItemsMutation = {
  __typename?: 'Mutation'
  reorderItems?: {
    __typename?: 'ReorderItemsOutput'
    cart: {
      __typename?: 'Cart'
      total_quantity: number
      items?: Array<
        | { __typename?: 'BundleCartItem'; id: string; quantity: number }
        | { __typename?: 'ConfigurableCartItem'; id: string; quantity: number }
        | { __typename?: 'DownloadableCartItem'; id: string; quantity: number }
        | { __typename?: 'GiftCardCartItem'; id: string; quantity: number }
        | { __typename?: 'SimpleCartItem'; id: string; quantity: number }
        | { __typename?: 'VirtualCartItem'; id: string; quantity: number }
        | null
      > | null
    }
  } | null
}

export type CustomerOrdersListQueryVariables = Exact<{
  currentPage: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
  state?: InputMaybe<Scalars['String']['input']>
}>

export type CustomerOrdersListQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    orders?: {
      __typename?: 'CustomerOrders'
      total_count?: number | null
      items: Array<{
        __typename?: 'CustomerOrder'
        id: string
        increment_id?: string | null
        state: string
        order_number: string
        order_id?: string | null
        order_date: string
        tracking_url?: string | null
        status: string
        total?: {
          __typename?: 'OrderTotal'
          grand_total: {
            __typename?: 'Money'
            currency?: CurrencyEnum | null
            value?: number | null
          }
        } | null
      } | null>
      page_info?: {
        __typename?: 'SearchResultPageInfo'
        current_page?: number | null
        page_size?: number | null
        total_pages?: number | null
      } | null
    } | null
  } | null
}

export type ProductDataTabsQueryVariables = Exact<{
  url: Scalars['String']['input']
  questionsPageSize: Scalars['Int']['input']
  questionsCurrentPage: Scalars['Int']['input']
  reviewPageSize: Scalars['Int']['input']
  reviewCurrentPage: Scalars['Int']['input']
  reviewFilter: Scalars['String']['input']
}>

export type ProductDataTabsQuery = {
  __typename?: 'Query'
  route?:
    | { __typename?: 'BundleProduct' }
    | { __typename?: 'CategoryTree' }
    | { __typename?: 'CmsPage' }
    | {
        __typename: 'ConfigurableProduct'
        sku?: string | null
        review_count: number
        description?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        store_video_attribute?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        latest_related_blog_posts?: {
          __typename?: 'LatestRelatedBlogPosts'
          total_count?: number | null
          items?: Array<{
            __typename?: 'BlogPostInterface'
            excerpt?: string | null
            img?: string | null
            title?: string | null
            url?: string | null
          } | null> | null
        } | null
        productQuestions: {
          __typename?: 'ProductQuestions'
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
          list: Array<{
            __typename?: 'ProductQuestion'
            date?: string | null
            dislike?: number | null
            like?: number | null
            name?: string | null
            question_text?: string | null
            uid?: string | null
            user_avatar?: string | null
            user_name?: string | null
            user_uid?: string | null
            answer?: Array<{
              __typename?: 'ProductQuestionAnswer'
              answer_text?: string | null
              date?: string | null
              dislike?: number | null
              like?: number | null
              name?: string | null
              uid?: string | null
              user_avatar?: string | null
              user_name?: string | null
              user_uid?: string | null
            } | null> | null
          } | null>
        }
        reviews: {
          __typename?: 'ProductReviews'
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
          types?: {
            __typename?: 'ProductReviewsTypes'
            current_store?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
            negative?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
            other_stores?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
            positive?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
            verified?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
          } | null
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          summary?: {
            __typename?: 'ProductReviewsSummary'
            global_rating?: {
              __typename?: 'ProductReviewsSummaryGlobalRating'
              overall?: number | null
              total?: number | null
              verified_purchases_rating?: number | null
              segments?: Array<{
                __typename?: 'ProductReviewsSummaryGlobalRatingSegment'
                count?: number | null
                rating?: number | null
              } | null> | null
            } | null
            top_negative_review?: {
              __typename?: 'ProductReviewsSummaryTopReview'
              detail?: string | null
              rate_minus?: number | null
              rate_plus?: number | null
              review_id?: number | null
              summary_rating?: number | null
            } | null
            top_positive_review?: {
              __typename?: 'ProductReviewsSummaryTopReview'
              detail?: string | null
              rate_minus?: number | null
              rate_plus?: number | null
              review_id?: number | null
              summary_rating?: number | null
            } | null
          } | null
        }
      }
    | { __typename?: 'DownloadableProduct' }
    | {
        __typename: 'GiftCardProduct'
        sku?: string | null
        review_count: number
        description?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        visible_attributes?: Array<{
          __typename?: 'VisibleAttributes'
          label?: string | null
          code?: string | null
          values?: Array<{
            __typename?: 'VisibleAttributesValues'
            title?: string | null
            url?: string | null
            value?: string | null
          } | null> | null
        } | null> | null
        latest_related_blog_posts?: {
          __typename?: 'LatestRelatedBlogPosts'
          total_count?: number | null
          items?: Array<{
            __typename?: 'BlogPostInterface'
            excerpt?: string | null
            img?: string | null
            title?: string | null
            url?: string | null
          } | null> | null
        } | null
        productQuestions: {
          __typename?: 'ProductQuestions'
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
          list: Array<{
            __typename?: 'ProductQuestion'
            date?: string | null
            dislike?: number | null
            like?: number | null
            name?: string | null
            question_text?: string | null
            uid?: string | null
            user_avatar?: string | null
            user_name?: string | null
            user_uid?: string | null
            answer?: Array<{
              __typename?: 'ProductQuestionAnswer'
              answer_text?: string | null
              date?: string | null
              dislike?: number | null
              like?: number | null
              name?: string | null
              uid?: string | null
              user_avatar?: string | null
              user_name?: string | null
              user_uid?: string | null
            } | null> | null
          } | null>
        }
        reviews: {
          __typename?: 'ProductReviews'
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
          types?: {
            __typename?: 'ProductReviewsTypes'
            current_store?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
            negative?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
            other_stores?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
            positive?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
            verified?: {
              __typename?: 'ProductReviewsTypeSummary'
              total_count?: number | null
            } | null
          } | null
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          summary?: {
            __typename?: 'ProductReviewsSummary'
            global_rating?: {
              __typename?: 'ProductReviewsSummaryGlobalRating'
              overall?: number | null
              total?: number | null
              verified_purchases_rating?: number | null
              segments?: Array<{
                __typename?: 'ProductReviewsSummaryGlobalRatingSegment'
                count?: number | null
                rating?: number | null
              } | null> | null
            } | null
            top_negative_review?: {
              __typename?: 'ProductReviewsSummaryTopReview'
              detail?: string | null
              rate_minus?: number | null
              rate_plus?: number | null
              review_id?: number | null
              summary_rating?: number | null
            } | null
            top_positive_review?: {
              __typename?: 'ProductReviewsSummaryTopReview'
              detail?: string | null
              rate_minus?: number | null
              rate_plus?: number | null
              review_id?: number | null
              summary_rating?: number | null
            } | null
          } | null
        }
      }
    | { __typename?: 'GroupedProduct' }
    | { __typename?: 'SimpleProduct' }
    | { __typename?: 'VirtualProduct' }
    | null
}

export type ConfigurableProductTabsFragment = {
  __typename: 'ConfigurableProduct'
  sku?: string | null
  review_count: number
  description?: { __typename?: 'ComplexTextValue'; html?: string | null } | null
  store_video_attribute?: {
    __typename?: 'ComplexTextValue'
    html?: string | null
  } | null
  latest_related_blog_posts?: {
    __typename?: 'LatestRelatedBlogPosts'
    total_count?: number | null
    items?: Array<{
      __typename?: 'BlogPostInterface'
      excerpt?: string | null
      img?: string | null
      title?: string | null
      url?: string | null
    } | null> | null
  } | null
  productQuestions: {
    __typename?: 'ProductQuestions'
    page_info: {
      __typename?: 'SearchResultPageInfo'
      current_page?: number | null
      page_size?: number | null
      total_pages?: number | null
    }
    list: Array<{
      __typename?: 'ProductQuestion'
      date?: string | null
      dislike?: number | null
      like?: number | null
      name?: string | null
      question_text?: string | null
      uid?: string | null
      user_avatar?: string | null
      user_name?: string | null
      user_uid?: string | null
      answer?: Array<{
        __typename?: 'ProductQuestionAnswer'
        answer_text?: string | null
        date?: string | null
        dislike?: number | null
        like?: number | null
        name?: string | null
        uid?: string | null
        user_avatar?: string | null
        user_name?: string | null
        user_uid?: string | null
      } | null> | null
    } | null>
  }
  reviews: {
    __typename?: 'ProductReviews'
    page_info: {
      __typename?: 'SearchResultPageInfo'
      current_page?: number | null
      page_size?: number | null
      total_pages?: number | null
    }
    types?: {
      __typename?: 'ProductReviewsTypes'
      current_store?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
      negative?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
      other_stores?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
      positive?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
      verified?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
    } | null
    items: Array<{
      __typename?: 'ProductReview'
      average_rating: number
      created_at: string
      customer_avatar?: string | null
      customer_points?: number | null
      nickname: string
      rate_minus?: number | null
      rate_plus?: number | null
      review_id?: number | null
      summary: string
      summary_rating?: number | null
      text: string
      verified_purchase?: boolean | null
      ratings_breakdown: Array<{
        __typename?: 'ProductReviewRating'
        name: string
        value: string
      } | null>
      source?: {
        __typename?: 'ProductReviewSource'
        country_code?: string | null
        country_flag?: string | null
        id?: number | null
        summary?: string | null
        text?: string | null
      } | null
    } | null>
    summary?: {
      __typename?: 'ProductReviewsSummary'
      global_rating?: {
        __typename?: 'ProductReviewsSummaryGlobalRating'
        overall?: number | null
        total?: number | null
        verified_purchases_rating?: number | null
        segments?: Array<{
          __typename?: 'ProductReviewsSummaryGlobalRatingSegment'
          count?: number | null
          rating?: number | null
        } | null> | null
      } | null
      top_negative_review?: {
        __typename?: 'ProductReviewsSummaryTopReview'
        detail?: string | null
        rate_minus?: number | null
        rate_plus?: number | null
        review_id?: number | null
        summary_rating?: number | null
      } | null
      top_positive_review?: {
        __typename?: 'ProductReviewsSummaryTopReview'
        detail?: string | null
        rate_minus?: number | null
        rate_plus?: number | null
        review_id?: number | null
        summary_rating?: number | null
      } | null
    } | null
  }
}

export type GiftCardProductTabsFragment = {
  __typename: 'GiftCardProduct'
  sku?: string | null
  review_count: number
  description?: { __typename?: 'ComplexTextValue'; html?: string | null } | null
  visible_attributes?: Array<{
    __typename?: 'VisibleAttributes'
    label?: string | null
    code?: string | null
    values?: Array<{
      __typename?: 'VisibleAttributesValues'
      title?: string | null
      url?: string | null
      value?: string | null
    } | null> | null
  } | null> | null
  latest_related_blog_posts?: {
    __typename?: 'LatestRelatedBlogPosts'
    total_count?: number | null
    items?: Array<{
      __typename?: 'BlogPostInterface'
      excerpt?: string | null
      img?: string | null
      title?: string | null
      url?: string | null
    } | null> | null
  } | null
  productQuestions: {
    __typename?: 'ProductQuestions'
    page_info: {
      __typename?: 'SearchResultPageInfo'
      current_page?: number | null
      page_size?: number | null
      total_pages?: number | null
    }
    list: Array<{
      __typename?: 'ProductQuestion'
      date?: string | null
      dislike?: number | null
      like?: number | null
      name?: string | null
      question_text?: string | null
      uid?: string | null
      user_avatar?: string | null
      user_name?: string | null
      user_uid?: string | null
      answer?: Array<{
        __typename?: 'ProductQuestionAnswer'
        answer_text?: string | null
        date?: string | null
        dislike?: number | null
        like?: number | null
        name?: string | null
        uid?: string | null
        user_avatar?: string | null
        user_name?: string | null
        user_uid?: string | null
      } | null> | null
    } | null>
  }
  reviews: {
    __typename?: 'ProductReviews'
    page_info: {
      __typename?: 'SearchResultPageInfo'
      current_page?: number | null
      page_size?: number | null
      total_pages?: number | null
    }
    types?: {
      __typename?: 'ProductReviewsTypes'
      current_store?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
      negative?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
      other_stores?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
      positive?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
      verified?: {
        __typename?: 'ProductReviewsTypeSummary'
        total_count?: number | null
      } | null
    } | null
    items: Array<{
      __typename?: 'ProductReview'
      average_rating: number
      created_at: string
      customer_avatar?: string | null
      customer_points?: number | null
      nickname: string
      rate_minus?: number | null
      rate_plus?: number | null
      review_id?: number | null
      summary: string
      summary_rating?: number | null
      text: string
      verified_purchase?: boolean | null
      ratings_breakdown: Array<{
        __typename?: 'ProductReviewRating'
        name: string
        value: string
      } | null>
      source?: {
        __typename?: 'ProductReviewSource'
        country_code?: string | null
        country_flag?: string | null
        id?: number | null
        summary?: string | null
        text?: string | null
      } | null
    } | null>
    summary?: {
      __typename?: 'ProductReviewsSummary'
      global_rating?: {
        __typename?: 'ProductReviewsSummaryGlobalRating'
        overall?: number | null
        total?: number | null
        verified_purchases_rating?: number | null
        segments?: Array<{
          __typename?: 'ProductReviewsSummaryGlobalRatingSegment'
          count?: number | null
          rating?: number | null
        } | null> | null
      } | null
      top_negative_review?: {
        __typename?: 'ProductReviewsSummaryTopReview'
        detail?: string | null
        rate_minus?: number | null
        rate_plus?: number | null
        review_id?: number | null
        summary_rating?: number | null
      } | null
      top_positive_review?: {
        __typename?: 'ProductReviewsSummaryTopReview'
        detail?: string | null
        rate_minus?: number | null
        rate_plus?: number | null
        review_id?: number | null
        summary_rating?: number | null
      } | null
    } | null
  }
}

export type BlogPostFragment = {
  __typename?: 'BlogPostInterface'
  excerpt?: string | null
  img?: string | null
  title?: string | null
  url?: string | null
}

export type ProductQuestionsFragment = {
  __typename?: 'ProductQuestions'
  page_info: {
    __typename?: 'SearchResultPageInfo'
    current_page?: number | null
    page_size?: number | null
    total_pages?: number | null
  }
  list: Array<{
    __typename?: 'ProductQuestion'
    date?: string | null
    dislike?: number | null
    like?: number | null
    name?: string | null
    question_text?: string | null
    uid?: string | null
    user_avatar?: string | null
    user_name?: string | null
    user_uid?: string | null
    answer?: Array<{
      __typename?: 'ProductQuestionAnswer'
      answer_text?: string | null
      date?: string | null
      dislike?: number | null
      like?: number | null
      name?: string | null
      uid?: string | null
      user_avatar?: string | null
      user_name?: string | null
      user_uid?: string | null
    } | null> | null
  } | null>
}

export type ProductQuestionFragment = {
  __typename?: 'ProductQuestion'
  date?: string | null
  dislike?: number | null
  like?: number | null
  name?: string | null
  question_text?: string | null
  uid?: string | null
  user_avatar?: string | null
  user_name?: string | null
  user_uid?: string | null
  answer?: Array<{
    __typename?: 'ProductQuestionAnswer'
    answer_text?: string | null
    date?: string | null
    dislike?: number | null
    like?: number | null
    name?: string | null
    uid?: string | null
    user_avatar?: string | null
    user_name?: string | null
    user_uid?: string | null
  } | null> | null
}

export type ProductQuestionAnswerFragment = {
  __typename?: 'ProductQuestionAnswer'
  answer_text?: string | null
  date?: string | null
  dislike?: number | null
  like?: number | null
  name?: string | null
  uid?: string | null
  user_avatar?: string | null
  user_name?: string | null
  user_uid?: string | null
}

export type ProductReviewsFragment = {
  __typename?: 'ProductReviews'
  page_info: {
    __typename?: 'SearchResultPageInfo'
    current_page?: number | null
    page_size?: number | null
    total_pages?: number | null
  }
  types?: {
    __typename?: 'ProductReviewsTypes'
    current_store?: {
      __typename?: 'ProductReviewsTypeSummary'
      total_count?: number | null
    } | null
    negative?: {
      __typename?: 'ProductReviewsTypeSummary'
      total_count?: number | null
    } | null
    other_stores?: {
      __typename?: 'ProductReviewsTypeSummary'
      total_count?: number | null
    } | null
    positive?: {
      __typename?: 'ProductReviewsTypeSummary'
      total_count?: number | null
    } | null
    verified?: {
      __typename?: 'ProductReviewsTypeSummary'
      total_count?: number | null
    } | null
  } | null
  items: Array<{
    __typename?: 'ProductReview'
    average_rating: number
    created_at: string
    customer_avatar?: string | null
    customer_points?: number | null
    nickname: string
    rate_minus?: number | null
    rate_plus?: number | null
    review_id?: number | null
    summary: string
    summary_rating?: number | null
    text: string
    verified_purchase?: boolean | null
    ratings_breakdown: Array<{
      __typename?: 'ProductReviewRating'
      name: string
      value: string
    } | null>
    source?: {
      __typename?: 'ProductReviewSource'
      country_code?: string | null
      country_flag?: string | null
      id?: number | null
      summary?: string | null
      text?: string | null
    } | null
  } | null>
  summary?: {
    __typename?: 'ProductReviewsSummary'
    global_rating?: {
      __typename?: 'ProductReviewsSummaryGlobalRating'
      overall?: number | null
      total?: number | null
      verified_purchases_rating?: number | null
      segments?: Array<{
        __typename?: 'ProductReviewsSummaryGlobalRatingSegment'
        count?: number | null
        rating?: number | null
      } | null> | null
    } | null
    top_negative_review?: {
      __typename?: 'ProductReviewsSummaryTopReview'
      detail?: string | null
      rate_minus?: number | null
      rate_plus?: number | null
      review_id?: number | null
      summary_rating?: number | null
    } | null
    top_positive_review?: {
      __typename?: 'ProductReviewsSummaryTopReview'
      detail?: string | null
      rate_minus?: number | null
      rate_plus?: number | null
      review_id?: number | null
      summary_rating?: number | null
    } | null
  } | null
}

export type ProductReviewFragment = {
  __typename?: 'ProductReview'
  average_rating: number
  created_at: string
  customer_avatar?: string | null
  customer_points?: number | null
  nickname: string
  rate_minus?: number | null
  rate_plus?: number | null
  review_id?: number | null
  summary: string
  summary_rating?: number | null
  text: string
  verified_purchase?: boolean | null
  ratings_breakdown: Array<{
    __typename?: 'ProductReviewRating'
    name: string
    value: string
  } | null>
  source?: {
    __typename?: 'ProductReviewSource'
    country_code?: string | null
    country_flag?: string | null
    id?: number | null
    summary?: string | null
    text?: string | null
  } | null
}

export type ProductReviewsSummaryFragment = {
  __typename?: 'ProductReviewsSummary'
  global_rating?: {
    __typename?: 'ProductReviewsSummaryGlobalRating'
    overall?: number | null
    total?: number | null
    verified_purchases_rating?: number | null
    segments?: Array<{
      __typename?: 'ProductReviewsSummaryGlobalRatingSegment'
      count?: number | null
      rating?: number | null
    } | null> | null
  } | null
  top_negative_review?: {
    __typename?: 'ProductReviewsSummaryTopReview'
    detail?: string | null
    rate_minus?: number | null
    rate_plus?: number | null
    review_id?: number | null
    summary_rating?: number | null
  } | null
  top_positive_review?: {
    __typename?: 'ProductReviewsSummaryTopReview'
    detail?: string | null
    rate_minus?: number | null
    rate_plus?: number | null
    review_id?: number | null
    summary_rating?: number | null
  } | null
}

export type GlobalRatingFragment = {
  __typename?: 'ProductReviewsSummaryGlobalRating'
  overall?: number | null
  total?: number | null
  verified_purchases_rating?: number | null
  segments?: Array<{
    __typename?: 'ProductReviewsSummaryGlobalRatingSegment'
    count?: number | null
    rating?: number | null
  } | null> | null
}

export type TopReviewFragment = {
  __typename?: 'ProductReviewsSummaryTopReview'
  detail?: string | null
  rate_minus?: number | null
  rate_plus?: number | null
  review_id?: number | null
  summary_rating?: number | null
}

export type ConfigurableProductFragment = {
  __typename: 'ConfigurableProduct'
  default_category?: string | null
  enabled?: boolean | null
  id?: number | null
  uid: string
  long_term_unavailable?: number | null
  stock_status?: ProductStockStatus | null
  meta_description?: string | null
  name?: string | null
  options_container?: string | null
  rating_summary: number
  redirect_code: number
  relative_url?: string | null
  review_count: number
  sku?: string | null
  type_id?: string | null
  canonical_url?: string | null
  breadcrumb_en?: string | null
  configurable_options?: Array<{
    __typename?: 'ConfigurableProductOptions'
    attribute_code?: string | null
    label?: string | null
    position?: number | null
  } | null> | null
  configurable_variants?: Array<{
    __typename?: 'ConfigurableVariant'
    is_bestseller?: boolean | null
    attributes?: Array<{
      __typename?: 'ConfigurableAttributeOption'
      code?: string | null
      label?: string | null
      value_index?: number | null
    } | null> | null
    product?: {
      __typename?: 'SimpleProduct'
      enabled?: boolean | null
      expiration_date?: string | null
      id?: number | null
      msrp_price?: number | null
      name?: string | null
      rating_summary: number
      review_count: number
      sku?: string | null
      stock_status?: ProductStockStatus | null
      long_term_unavailable?: number | null
      allergens?: {
        __typename?: 'AllergensInfoInterface'
        allergens?: Array<string | null> | null
        traces?: Array<string | null> | null
      } | null
      estimated_date_of_arrival?: {
        __typename?: 'EstimatedDateOfArrivalType'
        timestamp?: string | null
        formatted_date?: string | null
      } | null
      price_tiers?: Array<{
        __typename?: 'TierPrice'
        final_price?: {
          __typename?: 'Money'
          currency?: CurrencyEnum | null
          value?: number | null
        } | null
      } | null> | null
      unit_price?: {
        __typename?: 'UnitPrice'
        value: number
        unit: string
        currency: string
      } | null
      image?: {
        __typename?: 'ProductImage'
        disabled?: boolean | null
        label?: string | null
        position?: number | null
        url?: {
          __typename?: 'MediaGalleryUrlInterface'
          full?: string | null
          medium?: string | null
          small?: string | null
        } | null
      } | null
      price_range: {
        __typename?: 'PriceRange'
        maximum_price?: {
          __typename?: 'ProductPrice'
          final_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
          regular_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
        } | null
        minimum_price: {
          __typename?: 'ProductPrice'
          final_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
        }
      }
      visible_attributes?: Array<{
        __typename?: 'VisibleAttributes'
        label?: string | null
        code?: string | null
        values?: Array<{
          __typename?: 'VisibleAttributesValues'
          title?: string | null
          url?: string | null
          value?: string | null
        } | null> | null
      } | null> | null
      gb_product_labels?: Array<{
        __typename?: 'Label'
        bg?: string | null
        content?: string | null
        discount?: number | null
        label?: number | null
        src?: string | null
        type?: string | null
      } | null> | null
    } | null
  } | null> | null
  hreflangs?: Array<{
    __typename?: 'Hreflangs'
    href?: string | null
    hreflang?: string | null
  } | null> | null
  image?: {
    __typename?: 'ProductImage'
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      full?: string | null
    } | null
  } | null
  manufacturer_info?: {
    __typename?: 'ManufacturerInfoInterface'
    name?: string | null
    pathname?: string | null
  } | null
  media_gallery?: Array<
    | {
        __typename?: 'ProductImage'
        disabled?: boolean | null
        label?: string | null
        position?: number | null
        url?: {
          __typename?: 'MediaGalleryUrlInterface'
          full?: string | null
          medium?: string | null
          small?: string | null
        } | null
      }
    | {
        __typename?: 'ProductVideo'
        disabled?: boolean | null
        label?: string | null
        position?: number | null
        url?: {
          __typename?: 'MediaGalleryUrlInterface'
          full?: string | null
          medium?: string | null
          small?: string | null
        } | null
      }
    | null
  > | null
  short_description?: {
    __typename?: 'ComplexTextValue'
    html?: string | null
  } | null
  sales_description?: {
    __typename?: 'ComplexTextValue'
    html?: string | null
  } | null
  breadcrumb?: Array<{
    __typename?: 'BreadcrumbType'
    name?: string | null
    url?: string | null
  } | null> | null
  gb_product_labels?: Array<{
    __typename?: 'Label'
    bg?: string | null
    content?: string | null
    discount?: number | null
    label?: number | null
    src?: string | null
    type?: string | null
  } | null> | null
}

export type GiftCardProductFragment = {
  __typename: 'GiftCardProduct'
  default_category?: string | null
  enabled?: boolean | null
  id?: number | null
  uid: string
  long_term_unavailable?: number | null
  stock_status?: ProductStockStatus | null
  meta_description?: string | null
  name?: string | null
  options_container?: string | null
  rating_summary: number
  redirect_code: number
  relative_url?: string | null
  review_count: number
  sku?: string | null
  type_id?: string | null
  canonical_url?: string | null
  breadcrumb_en?: string | null
  giftcard_amounts?: Array<{
    __typename?: 'GiftCardAmounts'
    uid: string
    website_value?: number | null
  } | null> | null
  hreflangs?: Array<{
    __typename?: 'Hreflangs'
    href?: string | null
    hreflang?: string | null
  } | null> | null
  image?: {
    __typename?: 'ProductImage'
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      full?: string | null
    } | null
  } | null
  media_gallery?: Array<
    | {
        __typename?: 'ProductImage'
        disabled?: boolean | null
        label?: string | null
        position?: number | null
        url?: {
          __typename?: 'MediaGalleryUrlInterface'
          full?: string | null
          medium?: string | null
          small?: string | null
        } | null
      }
    | {
        __typename?: 'ProductVideo'
        disabled?: boolean | null
        label?: string | null
        position?: number | null
        url?: {
          __typename?: 'MediaGalleryUrlInterface'
          full?: string | null
          medium?: string | null
          small?: string | null
        } | null
      }
    | null
  > | null
  short_description?: {
    __typename?: 'ComplexTextValue'
    html?: string | null
  } | null
  sales_description?: {
    __typename?: 'ComplexTextValue'
    html?: string | null
  } | null
  breadcrumb?: Array<{
    __typename?: 'BreadcrumbType'
    name?: string | null
    url?: string | null
  } | null> | null
  gb_product_labels?: Array<{
    __typename?: 'Label'
    bg?: string | null
    content?: string | null
    discount?: number | null
    label?: number | null
    src?: string | null
    type?: string | null
  } | null> | null
}

export type ConfigurableProductListItemFragment = {
  __typename: 'ConfigurableProduct'
  enabled?: boolean | null
  id?: number | null
  uid: string
  long_term_unavailable?: number | null
  stock_status?: ProductStockStatus | null
  name?: string | null
  rating_summary: number
  review_count: number
  sku?: string | null
  type_id?: string | null
  breadcrumb_en?: string | null
  msrp_price?: number | null
  canonical_url?: string | null
  image?: {
    __typename?: 'ProductImage'
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      full?: string | null
    } | null
  } | null
  manufacturer_info?: {
    __typename?: 'ManufacturerInfoInterface'
    name?: string | null
    pathname?: string | null
  } | null
  breadcrumb?: Array<{
    __typename?: 'BreadcrumbType'
    name?: string | null
    url?: string | null
  } | null> | null
  configurable_options?: Array<{
    __typename?: 'ConfigurableProductOptions'
    attribute_code?: string | null
    label?: string | null
    position?: number | null
  } | null> | null
  configurable_variants?: Array<{
    __typename?: 'ConfigurableVariant'
    is_bestseller?: boolean | null
    attributes?: Array<{
      __typename?: 'ConfigurableAttributeOption'
      code?: string | null
      label?: string | null
      value_index?: number | null
    } | null> | null
    product?: {
      __typename?: 'SimpleProduct'
      enabled?: boolean | null
      expiration_date?: string | null
      id?: number | null
      msrp_price?: number | null
      name?: string | null
      rating_summary: number
      review_count: number
      sku?: string | null
      stock_status?: ProductStockStatus | null
      long_term_unavailable?: number | null
      allergens?: {
        __typename?: 'AllergensInfoInterface'
        allergens?: Array<string | null> | null
        traces?: Array<string | null> | null
      } | null
      estimated_date_of_arrival?: {
        __typename?: 'EstimatedDateOfArrivalType'
        timestamp?: string | null
        formatted_date?: string | null
      } | null
      price_tiers?: Array<{
        __typename?: 'TierPrice'
        final_price?: {
          __typename?: 'Money'
          currency?: CurrencyEnum | null
          value?: number | null
        } | null
      } | null> | null
      unit_price?: {
        __typename?: 'UnitPrice'
        value: number
        unit: string
        currency: string
      } | null
      image?: {
        __typename?: 'ProductImage'
        disabled?: boolean | null
        label?: string | null
        position?: number | null
        url?: {
          __typename?: 'MediaGalleryUrlInterface'
          full?: string | null
          medium?: string | null
          small?: string | null
        } | null
      } | null
      price_range: {
        __typename?: 'PriceRange'
        maximum_price?: {
          __typename?: 'ProductPrice'
          final_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
          regular_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
        } | null
        minimum_price: {
          __typename?: 'ProductPrice'
          final_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
        }
      }
    } | null
  } | null> | null
  gb_product_labels?: Array<{
    __typename?: 'Label'
    bg?: string | null
    content?: string | null
    discount?: number | null
    label?: number | null
    src?: string | null
    type?: string | null
  } | null> | null
  thumbnail?: {
    __typename?: 'ProductImage'
    label?: string | null
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      medium?: string | null
    } | null
  } | null
  price_range: {
    __typename?: 'PriceRange'
    maximum_price?: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
      regular_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    } | null
    minimum_price: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    }
  }
}

export type GiftCardProductListItemFragment = {
  __typename: 'GiftCardProduct'
  enabled?: boolean | null
  id?: number | null
  uid: string
  long_term_unavailable?: number | null
  stock_status?: ProductStockStatus | null
  name?: string | null
  rating_summary: number
  review_count: number
  sku?: string | null
  type_id?: string | null
  breadcrumb_en?: string | null
  msrp_price?: number | null
  canonical_url?: string | null
  image?: {
    __typename?: 'ProductImage'
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      full?: string | null
    } | null
  } | null
  breadcrumb?: Array<{
    __typename?: 'BreadcrumbType'
    name?: string | null
    url?: string | null
  } | null> | null
  giftcard_amounts?: Array<{
    __typename?: 'GiftCardAmounts'
    uid: string
    website_value?: number | null
  } | null> | null
  gb_product_labels?: Array<{
    __typename?: 'Label'
    bg?: string | null
    content?: string | null
    discount?: number | null
    label?: number | null
    src?: string | null
    type?: string | null
  } | null> | null
  thumbnail?: {
    __typename?: 'ProductImage'
    label?: string | null
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      medium?: string | null
    } | null
  } | null
  price_range: {
    __typename?: 'PriceRange'
    maximum_price?: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
      regular_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    } | null
    minimum_price: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    }
  }
}

export type ConfigurableProductOptionFragment = {
  __typename?: 'ConfigurableProductOptions'
  attribute_code?: string | null
  label?: string | null
  position?: number | null
}

export type ConfigurableProductVariantFragment = {
  __typename?: 'ConfigurableVariant'
  is_bestseller?: boolean | null
  attributes?: Array<{
    __typename?: 'ConfigurableAttributeOption'
    code?: string | null
    label?: string | null
    value_index?: number | null
  } | null> | null
  product?: {
    __typename?: 'SimpleProduct'
    enabled?: boolean | null
    expiration_date?: string | null
    id?: number | null
    msrp_price?: number | null
    name?: string | null
    rating_summary: number
    review_count: number
    sku?: string | null
    stock_status?: ProductStockStatus | null
    long_term_unavailable?: number | null
    visible_attributes?: Array<{
      __typename?: 'VisibleAttributes'
      label?: string | null
      code?: string | null
      values?: Array<{
        __typename?: 'VisibleAttributesValues'
        title?: string | null
        url?: string | null
        value?: string | null
      } | null> | null
    } | null> | null
    gb_product_labels?: Array<{
      __typename?: 'Label'
      bg?: string | null
      content?: string | null
      discount?: number | null
      label?: number | null
      src?: string | null
      type?: string | null
    } | null> | null
    allergens?: {
      __typename?: 'AllergensInfoInterface'
      allergens?: Array<string | null> | null
      traces?: Array<string | null> | null
    } | null
    estimated_date_of_arrival?: {
      __typename?: 'EstimatedDateOfArrivalType'
      timestamp?: string | null
      formatted_date?: string | null
    } | null
    price_tiers?: Array<{
      __typename?: 'TierPrice'
      final_price?: {
        __typename?: 'Money'
        currency?: CurrencyEnum | null
        value?: number | null
      } | null
    } | null> | null
    unit_price?: {
      __typename?: 'UnitPrice'
      value: number
      unit: string
      currency: string
    } | null
    image?: {
      __typename?: 'ProductImage'
      disabled?: boolean | null
      label?: string | null
      position?: number | null
      url?: {
        __typename?: 'MediaGalleryUrlInterface'
        full?: string | null
        medium?: string | null
        small?: string | null
      } | null
    } | null
    price_range: {
      __typename?: 'PriceRange'
      maximum_price?: {
        __typename?: 'ProductPrice'
        final_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
        regular_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
      } | null
      minimum_price: {
        __typename?: 'ProductPrice'
        final_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
      }
    }
  } | null
}

export type ConfigurableProductVariantOptimizedFragment = {
  __typename?: 'ConfigurableVariant'
  is_bestseller?: boolean | null
  attributes?: Array<{
    __typename?: 'ConfigurableAttributeOption'
    code?: string | null
    label?: string | null
    value_index?: number | null
  } | null> | null
  product?: {
    __typename?: 'SimpleProduct'
    enabled?: boolean | null
    expiration_date?: string | null
    id?: number | null
    msrp_price?: number | null
    name?: string | null
    rating_summary: number
    review_count: number
    sku?: string | null
    stock_status?: ProductStockStatus | null
    long_term_unavailable?: number | null
    allergens?: {
      __typename?: 'AllergensInfoInterface'
      allergens?: Array<string | null> | null
      traces?: Array<string | null> | null
    } | null
    estimated_date_of_arrival?: {
      __typename?: 'EstimatedDateOfArrivalType'
      timestamp?: string | null
      formatted_date?: string | null
    } | null
    price_tiers?: Array<{
      __typename?: 'TierPrice'
      final_price?: {
        __typename?: 'Money'
        currency?: CurrencyEnum | null
        value?: number | null
      } | null
    } | null> | null
    unit_price?: {
      __typename?: 'UnitPrice'
      value: number
      unit: string
      currency: string
    } | null
    image?: {
      __typename?: 'ProductImage'
      disabled?: boolean | null
      label?: string | null
      position?: number | null
      url?: {
        __typename?: 'MediaGalleryUrlInterface'
        full?: string | null
        medium?: string | null
        small?: string | null
      } | null
    } | null
    price_range: {
      __typename?: 'PriceRange'
      maximum_price?: {
        __typename?: 'ProductPrice'
        final_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
        regular_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
      } | null
      minimum_price: {
        __typename?: 'ProductPrice'
        final_price: {
          __typename?: 'MoneyTaxExtended'
          currency?: CurrencyEnum | null
          value?: number | null
          value_excl_tax?: number | null
        }
      }
    }
  } | null
}

export type ConfigurableProductVariantAttributeFragment = {
  __typename?: 'ConfigurableAttributeOption'
  code?: string | null
  label?: string | null
  value_index?: number | null
}

export type SimpleProductOptimizedFragment = {
  __typename?: 'SimpleProduct'
  enabled?: boolean | null
  expiration_date?: string | null
  id?: number | null
  msrp_price?: number | null
  name?: string | null
  rating_summary: number
  review_count: number
  sku?: string | null
  stock_status?: ProductStockStatus | null
  long_term_unavailable?: number | null
  allergens?: {
    __typename?: 'AllergensInfoInterface'
    allergens?: Array<string | null> | null
    traces?: Array<string | null> | null
  } | null
  estimated_date_of_arrival?: {
    __typename?: 'EstimatedDateOfArrivalType'
    timestamp?: string | null
    formatted_date?: string | null
  } | null
  price_tiers?: Array<{
    __typename?: 'TierPrice'
    final_price?: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    } | null
  } | null> | null
  unit_price?: {
    __typename?: 'UnitPrice'
    value: number
    unit: string
    currency: string
  } | null
  image?: {
    __typename?: 'ProductImage'
    disabled?: boolean | null
    label?: string | null
    position?: number | null
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      full?: string | null
      medium?: string | null
      small?: string | null
    } | null
  } | null
  price_range: {
    __typename?: 'PriceRange'
    maximum_price?: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
      regular_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    } | null
    minimum_price: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    }
  }
}

export type SimpleProductFragment = {
  __typename?: 'SimpleProduct'
  enabled?: boolean | null
  expiration_date?: string | null
  id?: number | null
  msrp_price?: number | null
  name?: string | null
  rating_summary: number
  review_count: number
  sku?: string | null
  stock_status?: ProductStockStatus | null
  long_term_unavailable?: number | null
  visible_attributes?: Array<{
    __typename?: 'VisibleAttributes'
    label?: string | null
    code?: string | null
    values?: Array<{
      __typename?: 'VisibleAttributesValues'
      title?: string | null
      url?: string | null
      value?: string | null
    } | null> | null
  } | null> | null
  gb_product_labels?: Array<{
    __typename?: 'Label'
    bg?: string | null
    content?: string | null
    discount?: number | null
    label?: number | null
    src?: string | null
    type?: string | null
  } | null> | null
  allergens?: {
    __typename?: 'AllergensInfoInterface'
    allergens?: Array<string | null> | null
    traces?: Array<string | null> | null
  } | null
  estimated_date_of_arrival?: {
    __typename?: 'EstimatedDateOfArrivalType'
    timestamp?: string | null
    formatted_date?: string | null
  } | null
  price_tiers?: Array<{
    __typename?: 'TierPrice'
    final_price?: {
      __typename?: 'Money'
      currency?: CurrencyEnum | null
      value?: number | null
    } | null
  } | null> | null
  unit_price?: {
    __typename?: 'UnitPrice'
    value: number
    unit: string
    currency: string
  } | null
  image?: {
    __typename?: 'ProductImage'
    disabled?: boolean | null
    label?: string | null
    position?: number | null
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      full?: string | null
      medium?: string | null
      small?: string | null
    } | null
  } | null
  price_range: {
    __typename?: 'PriceRange'
    maximum_price?: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
      regular_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    } | null
    minimum_price: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    }
  }
}

export type ProductImageFragment = {
  __typename?: 'ProductImage'
  disabled?: boolean | null
  label?: string | null
  position?: number | null
  url?: {
    __typename?: 'MediaGalleryUrlInterface'
    full?: string | null
    medium?: string | null
    small?: string | null
  } | null
}

export type RecommendedProductFragment = {
  __typename?: 'SimpleProduct'
  id?: number | null
  canonical_url?: string | null
  name?: string | null
  parent_sku?: string | null
  rating_summary: number
  review_count: number
  sku?: string | null
  stock_status?: ProductStockStatus | null
  breadcrumb_en?: string | null
  manufacturer_info?: {
    __typename?: 'ManufacturerInfoInterface'
    name?: string | null
  } | null
  thumbnail?: {
    __typename?: 'ProductImage'
    label?: string | null
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      medium?: string | null
    } | null
  } | null
  gb_product_labels?: Array<{
    __typename?: 'Label'
    bg?: string | null
    content?: string | null
    discount?: number | null
    label?: number | null
    src?: string | null
    type?: string | null
  } | null> | null
  price_range: {
    __typename?: 'PriceRange'
    minimum_price: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    }
  }
}

export type GiftCardProductAmountFragment = {
  __typename?: 'GiftCardAmounts'
  uid: string
  website_value?: number | null
}

export type VisibleAttributeFragment = {
  __typename?: 'VisibleAttributes'
  label?: string | null
  code?: string | null
  values?: Array<{
    __typename?: 'VisibleAttributesValues'
    title?: string | null
    url?: string | null
    value?: string | null
  } | null> | null
}

export type BreadcrumbFragment = {
  __typename?: 'BreadcrumbType'
  name?: string | null
  url?: string | null
}

export type ProductLabelV2Fragment = {
  __typename?: 'Label'
  bg?: string | null
  content?: string | null
  discount?: number | null
  label?: number | null
  src?: string | null
  type?: string | null
}

export type ProductPriceFragment = {
  __typename?: 'MoneyTaxExtended'
  currency?: CurrencyEnum | null
  value?: number | null
  value_excl_tax?: number | null
}

export type ProductIdQueryVariables = Exact<{
  url: Scalars['String']['input']
}>

export type ProductIdQuery = {
  __typename?: 'Query'
  route?:
    | { __typename?: 'BundleProduct' }
    | { __typename?: 'CategoryTree' }
    | { __typename?: 'CmsPage' }
    | { __typename: 'ConfigurableProduct'; id?: number | null }
    | { __typename?: 'DownloadableProduct' }
    | { __typename: 'GiftCardProduct'; id?: number | null }
    | { __typename?: 'GroupedProduct' }
    | { __typename?: 'SimpleProduct' }
    | { __typename?: 'VirtualProduct' }
    | null
}

export type ConfigurableProductIdFragment = {
  __typename: 'ConfigurableProduct'
  id?: number | null
}

export type GiftCardProductIdFragment = {
  __typename: 'GiftCardProduct'
  id?: number | null
}

export type ProductModalDataQueryVariables = Exact<{
  url: Scalars['String']['input']
}>

export type ProductModalDataQuery = {
  __typename?: 'Query'
  route?:
    | { __typename?: 'BundleProduct' }
    | { __typename?: 'CategoryTree' }
    | { __typename?: 'CmsPage' }
    | {
        __typename: 'ConfigurableProduct'
        default_category?: string | null
        enabled?: boolean | null
        id?: number | null
        uid: string
        long_term_unavailable?: number | null
        stock_status?: ProductStockStatus | null
        name?: string | null
        options_container?: string | null
        rating_summary: number
        redirect_code: number
        relative_url?: string | null
        review_count: number
        sku?: string | null
        type_id?: string | null
        canonical_url?: string | null
        breadcrumb_en?: string | null
        msrp_price?: number | null
        configurable_options?: Array<{
          __typename?: 'ConfigurableProductOptions'
          attribute_code?: string | null
          label?: string | null
          position?: number | null
        } | null> | null
        configurable_variants?: Array<{
          __typename?: 'ConfigurableVariant'
          is_bestseller?: boolean | null
          attributes?: Array<{
            __typename?: 'ConfigurableAttributeOption'
            code?: string | null
            label?: string | null
            value_index?: number | null
          } | null> | null
          product?: {
            __typename?: 'SimpleProduct'
            enabled?: boolean | null
            expiration_date?: string | null
            id?: number | null
            msrp_price?: number | null
            name?: string | null
            rating_summary: number
            review_count: number
            sku?: string | null
            stock_status?: ProductStockStatus | null
            long_term_unavailable?: number | null
            visible_attributes?: Array<{
              __typename?: 'VisibleAttributes'
              label?: string | null
              code?: string | null
              values?: Array<{
                __typename?: 'VisibleAttributesValues'
                title?: string | null
                url?: string | null
                value?: string | null
              } | null> | null
            } | null> | null
            gb_product_labels?: Array<{
              __typename?: 'Label'
              bg?: string | null
              content?: string | null
              discount?: number | null
              label?: number | null
              src?: string | null
              type?: string | null
            } | null> | null
            allergens?: {
              __typename?: 'AllergensInfoInterface'
              allergens?: Array<string | null> | null
              traces?: Array<string | null> | null
            } | null
            estimated_date_of_arrival?: {
              __typename?: 'EstimatedDateOfArrivalType'
              timestamp?: string | null
              formatted_date?: string | null
            } | null
            price_tiers?: Array<{
              __typename?: 'TierPrice'
              final_price?: {
                __typename?: 'Money'
                currency?: CurrencyEnum | null
                value?: number | null
              } | null
            } | null> | null
            unit_price?: {
              __typename?: 'UnitPrice'
              value: number
              unit: string
              currency: string
            } | null
            image?: {
              __typename?: 'ProductImage'
              disabled?: boolean | null
              label?: string | null
              position?: number | null
              url?: {
                __typename?: 'MediaGalleryUrlInterface'
                full?: string | null
                medium?: string | null
                small?: string | null
              } | null
            } | null
            price_range: {
              __typename?: 'PriceRange'
              maximum_price?: {
                __typename?: 'ProductPrice'
                final_price: {
                  __typename?: 'MoneyTaxExtended'
                  currency?: CurrencyEnum | null
                  value?: number | null
                  value_excl_tax?: number | null
                }
                regular_price: {
                  __typename?: 'MoneyTaxExtended'
                  currency?: CurrencyEnum | null
                  value?: number | null
                  value_excl_tax?: number | null
                }
              } | null
              minimum_price: {
                __typename?: 'ProductPrice'
                final_price: {
                  __typename?: 'MoneyTaxExtended'
                  currency?: CurrencyEnum | null
                  value?: number | null
                  value_excl_tax?: number | null
                }
              }
            }
          } | null
        } | null> | null
        image?: {
          __typename?: 'ProductImage'
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            full?: string | null
          } | null
        } | null
        manufacturer_info?: {
          __typename?: 'ManufacturerInfoInterface'
          name?: string | null
          pathname?: string | null
        } | null
        sales_description?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        breadcrumb?: Array<{
          __typename?: 'BreadcrumbType'
          name?: string | null
          url?: string | null
        } | null> | null
        gb_product_labels?: Array<{
          __typename?: 'Label'
          bg?: string | null
          content?: string | null
          discount?: number | null
          label?: number | null
          src?: string | null
          type?: string | null
        } | null> | null
        price_range: {
          __typename?: 'PriceRange'
          maximum_price?: {
            __typename?: 'ProductPrice'
            final_price: {
              __typename?: 'MoneyTaxExtended'
              currency?: CurrencyEnum | null
              value?: number | null
              value_excl_tax?: number | null
            }
            regular_price: {
              __typename?: 'MoneyTaxExtended'
              currency?: CurrencyEnum | null
              value?: number | null
              value_excl_tax?: number | null
            }
          } | null
          minimum_price: {
            __typename?: 'ProductPrice'
            final_price: {
              __typename?: 'MoneyTaxExtended'
              currency?: CurrencyEnum | null
              value?: number | null
              value_excl_tax?: number | null
            }
          }
        }
      }
    | { __typename?: 'DownloadableProduct' }
    | {
        __typename: 'GiftCardProduct'
        default_category?: string | null
        enabled?: boolean | null
        id?: number | null
        uid: string
        long_term_unavailable?: number | null
        stock_status?: ProductStockStatus | null
        name?: string | null
        options_container?: string | null
        rating_summary: number
        redirect_code: number
        relative_url?: string | null
        review_count: number
        sku?: string | null
        type_id?: string | null
        canonical_url?: string | null
        breadcrumb_en?: string | null
        msrp_price?: number | null
        giftcard_amounts?: Array<{
          __typename?: 'GiftCardAmounts'
          uid: string
          website_value?: number | null
        } | null> | null
        hreflangs?: Array<{
          __typename?: 'Hreflangs'
          href?: string | null
          hreflang?: string | null
        } | null> | null
        image?: {
          __typename?: 'ProductImage'
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            full?: string | null
          } | null
        } | null
        sales_description?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        breadcrumb?: Array<{
          __typename?: 'BreadcrumbType'
          name?: string | null
          url?: string | null
        } | null> | null
        gb_product_labels?: Array<{
          __typename?: 'Label'
          bg?: string | null
          content?: string | null
          discount?: number | null
          label?: number | null
          src?: string | null
          type?: string | null
        } | null> | null
        price_range: {
          __typename?: 'PriceRange'
          maximum_price?: {
            __typename?: 'ProductPrice'
            final_price: {
              __typename?: 'MoneyTaxExtended'
              currency?: CurrencyEnum | null
              value?: number | null
              value_excl_tax?: number | null
            }
            regular_price: {
              __typename?: 'MoneyTaxExtended'
              currency?: CurrencyEnum | null
              value?: number | null
              value_excl_tax?: number | null
            }
          } | null
          minimum_price: {
            __typename?: 'ProductPrice'
            final_price: {
              __typename?: 'MoneyTaxExtended'
              currency?: CurrencyEnum | null
              value?: number | null
              value_excl_tax?: number | null
            }
          }
        }
      }
    | { __typename?: 'GroupedProduct' }
    | { __typename?: 'SimpleProduct' }
    | { __typename?: 'VirtualProduct' }
    | null
}

export type ConfigurableProductModalDataFragment = {
  __typename: 'ConfigurableProduct'
  default_category?: string | null
  enabled?: boolean | null
  id?: number | null
  uid: string
  long_term_unavailable?: number | null
  stock_status?: ProductStockStatus | null
  name?: string | null
  options_container?: string | null
  rating_summary: number
  redirect_code: number
  relative_url?: string | null
  review_count: number
  sku?: string | null
  type_id?: string | null
  canonical_url?: string | null
  breadcrumb_en?: string | null
  msrp_price?: number | null
  configurable_options?: Array<{
    __typename?: 'ConfigurableProductOptions'
    attribute_code?: string | null
    label?: string | null
    position?: number | null
  } | null> | null
  configurable_variants?: Array<{
    __typename?: 'ConfigurableVariant'
    is_bestseller?: boolean | null
    attributes?: Array<{
      __typename?: 'ConfigurableAttributeOption'
      code?: string | null
      label?: string | null
      value_index?: number | null
    } | null> | null
    product?: {
      __typename?: 'SimpleProduct'
      enabled?: boolean | null
      expiration_date?: string | null
      id?: number | null
      msrp_price?: number | null
      name?: string | null
      rating_summary: number
      review_count: number
      sku?: string | null
      stock_status?: ProductStockStatus | null
      long_term_unavailable?: number | null
      visible_attributes?: Array<{
        __typename?: 'VisibleAttributes'
        label?: string | null
        code?: string | null
        values?: Array<{
          __typename?: 'VisibleAttributesValues'
          title?: string | null
          url?: string | null
          value?: string | null
        } | null> | null
      } | null> | null
      gb_product_labels?: Array<{
        __typename?: 'Label'
        bg?: string | null
        content?: string | null
        discount?: number | null
        label?: number | null
        src?: string | null
        type?: string | null
      } | null> | null
      allergens?: {
        __typename?: 'AllergensInfoInterface'
        allergens?: Array<string | null> | null
        traces?: Array<string | null> | null
      } | null
      estimated_date_of_arrival?: {
        __typename?: 'EstimatedDateOfArrivalType'
        timestamp?: string | null
        formatted_date?: string | null
      } | null
      price_tiers?: Array<{
        __typename?: 'TierPrice'
        final_price?: {
          __typename?: 'Money'
          currency?: CurrencyEnum | null
          value?: number | null
        } | null
      } | null> | null
      unit_price?: {
        __typename?: 'UnitPrice'
        value: number
        unit: string
        currency: string
      } | null
      image?: {
        __typename?: 'ProductImage'
        disabled?: boolean | null
        label?: string | null
        position?: number | null
        url?: {
          __typename?: 'MediaGalleryUrlInterface'
          full?: string | null
          medium?: string | null
          small?: string | null
        } | null
      } | null
      price_range: {
        __typename?: 'PriceRange'
        maximum_price?: {
          __typename?: 'ProductPrice'
          final_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
          regular_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
        } | null
        minimum_price: {
          __typename?: 'ProductPrice'
          final_price: {
            __typename?: 'MoneyTaxExtended'
            currency?: CurrencyEnum | null
            value?: number | null
            value_excl_tax?: number | null
          }
        }
      }
    } | null
  } | null> | null
  image?: {
    __typename?: 'ProductImage'
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      full?: string | null
    } | null
  } | null
  manufacturer_info?: {
    __typename?: 'ManufacturerInfoInterface'
    name?: string | null
    pathname?: string | null
  } | null
  sales_description?: {
    __typename?: 'ComplexTextValue'
    html?: string | null
  } | null
  breadcrumb?: Array<{
    __typename?: 'BreadcrumbType'
    name?: string | null
    url?: string | null
  } | null> | null
  gb_product_labels?: Array<{
    __typename?: 'Label'
    bg?: string | null
    content?: string | null
    discount?: number | null
    label?: number | null
    src?: string | null
    type?: string | null
  } | null> | null
  price_range: {
    __typename?: 'PriceRange'
    maximum_price?: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
      regular_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    } | null
    minimum_price: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    }
  }
}

export type GiftCardProductModalDataFragment = {
  __typename: 'GiftCardProduct'
  default_category?: string | null
  enabled?: boolean | null
  id?: number | null
  uid: string
  long_term_unavailable?: number | null
  stock_status?: ProductStockStatus | null
  name?: string | null
  options_container?: string | null
  rating_summary: number
  redirect_code: number
  relative_url?: string | null
  review_count: number
  sku?: string | null
  type_id?: string | null
  canonical_url?: string | null
  breadcrumb_en?: string | null
  msrp_price?: number | null
  giftcard_amounts?: Array<{
    __typename?: 'GiftCardAmounts'
    uid: string
    website_value?: number | null
  } | null> | null
  hreflangs?: Array<{
    __typename?: 'Hreflangs'
    href?: string | null
    hreflang?: string | null
  } | null> | null
  image?: {
    __typename?: 'ProductImage'
    url?: {
      __typename?: 'MediaGalleryUrlInterface'
      full?: string | null
    } | null
  } | null
  sales_description?: {
    __typename?: 'ComplexTextValue'
    html?: string | null
  } | null
  breadcrumb?: Array<{
    __typename?: 'BreadcrumbType'
    name?: string | null
    url?: string | null
  } | null> | null
  gb_product_labels?: Array<{
    __typename?: 'Label'
    bg?: string | null
    content?: string | null
    discount?: number | null
    label?: number | null
    src?: string | null
    type?: string | null
  } | null> | null
  price_range: {
    __typename?: 'PriceRange'
    maximum_price?: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
      regular_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    } | null
    minimum_price: {
      __typename?: 'ProductPrice'
      final_price: {
        __typename?: 'MoneyTaxExtended'
        currency?: CurrencyEnum | null
        value?: number | null
        value_excl_tax?: number | null
      }
    }
  }
}

export type QuestionsFilterQueryVariables = Exact<{
  url: Scalars['String']['input']
  questionsPageSize: Scalars['Int']['input']
  questionsCurrentPage: Scalars['Int']['input']
}>

export type QuestionsFilterQuery = {
  __typename?: 'Query'
  route?:
    | { __typename?: 'BundleProduct' }
    | { __typename?: 'CategoryTree' }
    | { __typename?: 'CmsPage' }
    | {
        __typename: 'ConfigurableProduct'
        productQuestions: {
          __typename?: 'ProductQuestions'
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
          list: Array<{
            __typename?: 'ProductQuestion'
            date?: string | null
            dislike?: number | null
            like?: number | null
            name?: string | null
            question_text?: string | null
            uid?: string | null
            user_avatar?: string | null
            user_name?: string | null
            user_uid?: string | null
            answer?: Array<{
              __typename?: 'ProductQuestionAnswer'
              answer_text?: string | null
              date?: string | null
              dislike?: number | null
              like?: number | null
              name?: string | null
              uid?: string | null
              user_avatar?: string | null
              user_name?: string | null
              user_uid?: string | null
            } | null> | null
          } | null>
        }
      }
    | { __typename?: 'DownloadableProduct' }
    | {
        __typename: 'GiftCardProduct'
        productQuestions: {
          __typename?: 'ProductQuestions'
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
          list: Array<{
            __typename?: 'ProductQuestion'
            date?: string | null
            dislike?: number | null
            like?: number | null
            name?: string | null
            question_text?: string | null
            uid?: string | null
            user_avatar?: string | null
            user_name?: string | null
            user_uid?: string | null
            answer?: Array<{
              __typename?: 'ProductQuestionAnswer'
              answer_text?: string | null
              date?: string | null
              dislike?: number | null
              like?: number | null
              name?: string | null
              uid?: string | null
              user_avatar?: string | null
              user_name?: string | null
              user_uid?: string | null
            } | null> | null
          } | null>
        }
      }
    | { __typename?: 'GroupedProduct' }
    | { __typename?: 'SimpleProduct' }
    | { __typename?: 'VirtualProduct' }
    | null
}

export type RateProductQuestionMutationVariables = Exact<{
  action: QuestionAnswerRateActionEnum
  type: QuestionAnswerObjectTypeEnum
  uid: Scalars['String']['input']
}>

export type RateProductQuestionMutation = {
  __typename?: 'Mutation'
  rateProductQuestion?: {
    __typename?: 'RateProductQuestionOutput'
    uid: string
    type: QuestionAnswerObjectTypeEnum
    action: QuestionAnswerRateActionEnum
  } | null
}

export type CreateCustomerV2MutationVariables = Exact<{
  input: CustomerCreateInput
}>

export type CreateCustomerV2Mutation = {
  __typename?: 'Mutation'
  createCustomerV2?: {
    __typename?: 'CustomerOutput'
    customer: {
      __typename?: 'Customer'
      firstname?: string | null
      id?: number | null
    }
  } | null
}

export type ReportProductQuestionMutationVariables = Exact<{
  uid: Scalars['String']['input']
  type: QuestionAnswerObjectTypeEnum
}>

export type ReportProductQuestionMutation = {
  __typename?: 'Mutation'
  reportProductQuestion?: {
    __typename?: 'ReportProductQuestionOutput'
    uid: string
    type: QuestionAnswerObjectTypeEnum
  } | null
}

export type ReviewsFilterQueryVariables = Exact<{
  url: Scalars['String']['input']
  reviewPageSize: Scalars['Int']['input']
  reviewCurrentPage: Scalars['Int']['input']
  reviewFilter: Scalars['String']['input']
}>

export type ReviewsFilterQuery = {
  __typename?: 'Query'
  route?:
    | {
        __typename: 'BundleProduct'
        reviews: {
          __typename?: 'ProductReviews'
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
        }
      }
    | { __typename?: 'CategoryTree' }
    | { __typename?: 'CmsPage' }
    | {
        __typename: 'ConfigurableProduct'
        reviews: {
          __typename?: 'ProductReviews'
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
        }
      }
    | {
        __typename: 'DownloadableProduct'
        reviews: {
          __typename?: 'ProductReviews'
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
        }
      }
    | {
        __typename: 'GiftCardProduct'
        reviews: {
          __typename?: 'ProductReviews'
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
        }
      }
    | {
        __typename: 'GroupedProduct'
        reviews: {
          __typename?: 'ProductReviews'
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
        }
      }
    | {
        __typename: 'SimpleProduct'
        reviews: {
          __typename?: 'ProductReviews'
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
        }
      }
    | {
        __typename: 'VirtualProduct'
        reviews: {
          __typename?: 'ProductReviews'
          items: Array<{
            __typename?: 'ProductReview'
            average_rating: number
            created_at: string
            customer_avatar?: string | null
            customer_points?: number | null
            nickname: string
            rate_minus?: number | null
            rate_plus?: number | null
            review_id?: number | null
            summary: string
            summary_rating?: number | null
            text: string
            verified_purchase?: boolean | null
            ratings_breakdown: Array<{
              __typename?: 'ProductReviewRating'
              name: string
              value: string
            } | null>
            source?: {
              __typename?: 'ProductReviewSource'
              country_code?: string | null
              country_flag?: string | null
              id?: number | null
              summary?: string | null
              text?: string | null
            } | null
          } | null>
          page_info: {
            __typename?: 'SearchResultPageInfo'
            current_page?: number | null
            page_size?: number | null
            total_pages?: number | null
          }
        }
      }
    | null
}

export type StockSubscribeMutationVariables = Exact<{
  productId: Scalars['String']['input']
  email: Scalars['String']['input']
}>

export type StockSubscribeMutation = {
  __typename?: 'Mutation'
  AmxnotifStockSubscribe?: {
    __typename?: 'AmxnotifSubscribeOutput'
    message?: string | null
    success?: boolean | null
  } | null
}

export type StoreConfigQueryVariables = Exact<{ [key: string]: never }>

export type StoreConfigQuery = {
  __typename?: 'Query'
  storeConfig?: {
    __typename?: 'StoreConfig'
    cookie_restriction?: boolean | null
    date_format?: string | null
    gb_ribbon_enabled?: boolean | null
    gb_ribbon_message?: string | null
    gb_ribbon_date?: string | null
    gb_ribbon_font_color?: string | null
    gb_ribbon_background_color?: string | null
    default_title?: string | null
    base_url?: string | null
    facebook_app_id?: string | null
    head_includes?: string | null
    head_shortcut_icon?: string | null
    default_description?: string | null
    default_display_currency_symbol?: string | null
    default_display_currency_code?: string | null
    locale?: string | null
    checkout_currency?: {
      __typename?: 'CheckoutCurrency'
      rate: number
      format: string
    } | null
    dognet_s2s?: {
      __typename?: 'DognetS2SType'
      enabled?: boolean | null
      account_id?: string | null
      cookie_consent?: boolean | null
    } | null
    website_switcher: Array<{
      __typename?: 'WebsiteType'
      storeCodes: Array<string | null>
      icon: string
      url: string
      nameMobile: string
      nameDesktop: string
    } | null>
  } | null
}

export type WebsiteTypeFragment = {
  __typename?: 'WebsiteType'
  storeCodes: Array<string | null>
  icon: string
  url: string
  nameMobile: string
  nameDesktop: string
}

export type SubscribeEmailToNewsletterMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type SubscribeEmailToNewsletterMutation = {
  __typename?: 'Mutation'
  subscribeEmailToNewsletter?: {
    __typename?: 'SubscribeEmailToNewsletterOutput'
    status?: SubscriptionStatusesEnum | null
  } | null
}

export type TopProductsQueryVariables = Exact<{ [key: string]: never }>

export type TopProductsQuery = {
  __typename?: 'Query'
  categories?: {
    __typename?: 'CategoryResult'
    items?: Array<{
      __typename?: 'CategoryTree'
      products?: {
        __typename?: 'CategoryProducts'
        items?: Array<
          | { __typename?: 'BundleProduct' }
          | {
              __typename: 'ConfigurableProduct'
              enabled?: boolean | null
              id?: number | null
              uid: string
              long_term_unavailable?: number | null
              stock_status?: ProductStockStatus | null
              name?: string | null
              rating_summary: number
              review_count: number
              sku?: string | null
              type_id?: string | null
              breadcrumb_en?: string | null
              msrp_price?: number | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  full?: string | null
                } | null
              } | null
              manufacturer_info?: {
                __typename?: 'ManufacturerInfoInterface'
                name?: string | null
                pathname?: string | null
              } | null
              breadcrumb?: Array<{
                __typename?: 'BreadcrumbType'
                name?: string | null
                url?: string | null
              } | null> | null
              configurable_options?: Array<{
                __typename?: 'ConfigurableProductOptions'
                attribute_code?: string | null
                label?: string | null
                position?: number | null
              } | null> | null
              configurable_variants?: Array<{
                __typename?: 'ConfigurableVariant'
                is_bestseller?: boolean | null
                attributes?: Array<{
                  __typename?: 'ConfigurableAttributeOption'
                  code?: string | null
                  label?: string | null
                  value_index?: number | null
                } | null> | null
                product?: {
                  __typename?: 'SimpleProduct'
                  enabled?: boolean | null
                  expiration_date?: string | null
                  id?: number | null
                  msrp_price?: number | null
                  name?: string | null
                  rating_summary: number
                  review_count: number
                  sku?: string | null
                  stock_status?: ProductStockStatus | null
                  long_term_unavailable?: number | null
                  allergens?: {
                    __typename?: 'AllergensInfoInterface'
                    allergens?: Array<string | null> | null
                    traces?: Array<string | null> | null
                  } | null
                  estimated_date_of_arrival?: {
                    __typename?: 'EstimatedDateOfArrivalType'
                    timestamp?: string | null
                    formatted_date?: string | null
                  } | null
                  price_tiers?: Array<{
                    __typename?: 'TierPrice'
                    final_price?: {
                      __typename?: 'Money'
                      currency?: CurrencyEnum | null
                      value?: number | null
                    } | null
                  } | null> | null
                  unit_price?: {
                    __typename?: 'UnitPrice'
                    value: number
                    unit: string
                    currency: string
                  } | null
                  image?: {
                    __typename?: 'ProductImage'
                    disabled?: boolean | null
                    label?: string | null
                    position?: number | null
                    url?: {
                      __typename?: 'MediaGalleryUrlInterface'
                      full?: string | null
                      medium?: string | null
                      small?: string | null
                    } | null
                  } | null
                  price_range: {
                    __typename?: 'PriceRange'
                    maximum_price?: {
                      __typename?: 'ProductPrice'
                      final_price: {
                        __typename?: 'MoneyTaxExtended'
                        currency?: CurrencyEnum | null
                        value?: number | null
                        value_excl_tax?: number | null
                      }
                      regular_price: {
                        __typename?: 'MoneyTaxExtended'
                        currency?: CurrencyEnum | null
                        value?: number | null
                        value_excl_tax?: number | null
                      }
                    } | null
                    minimum_price: {
                      __typename?: 'ProductPrice'
                      final_price: {
                        __typename?: 'MoneyTaxExtended'
                        currency?: CurrencyEnum | null
                        value?: number | null
                        value_excl_tax?: number | null
                      }
                    }
                  }
                } | null
              } | null> | null
              gb_product_labels?: Array<{
                __typename?: 'Label'
                bg?: string | null
                content?: string | null
                discount?: number | null
                label?: number | null
                src?: string | null
                type?: string | null
              } | null> | null
              thumbnail?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  medium?: string | null
                } | null
              } | null
              price_range: {
                __typename?: 'PriceRange'
                maximum_price?: {
                  __typename?: 'ProductPrice'
                  final_price: {
                    __typename?: 'MoneyTaxExtended'
                    currency?: CurrencyEnum | null
                    value?: number | null
                    value_excl_tax?: number | null
                  }
                  regular_price: {
                    __typename?: 'MoneyTaxExtended'
                    currency?: CurrencyEnum | null
                    value?: number | null
                    value_excl_tax?: number | null
                  }
                } | null
                minimum_price: {
                  __typename?: 'ProductPrice'
                  final_price: {
                    __typename?: 'MoneyTaxExtended'
                    currency?: CurrencyEnum | null
                    value?: number | null
                    value_excl_tax?: number | null
                  }
                }
              }
            }
          | { __typename?: 'DownloadableProduct' }
          | {
              __typename: 'GiftCardProduct'
              enabled?: boolean | null
              id?: number | null
              uid: string
              long_term_unavailable?: number | null
              stock_status?: ProductStockStatus | null
              name?: string | null
              rating_summary: number
              review_count: number
              sku?: string | null
              type_id?: string | null
              breadcrumb_en?: string | null
              msrp_price?: number | null
              canonical_url?: string | null
              image?: {
                __typename?: 'ProductImage'
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  full?: string | null
                } | null
              } | null
              breadcrumb?: Array<{
                __typename?: 'BreadcrumbType'
                name?: string | null
                url?: string | null
              } | null> | null
              giftcard_amounts?: Array<{
                __typename?: 'GiftCardAmounts'
                uid: string
                website_value?: number | null
              } | null> | null
              gb_product_labels?: Array<{
                __typename?: 'Label'
                bg?: string | null
                content?: string | null
                discount?: number | null
                label?: number | null
                src?: string | null
                type?: string | null
              } | null> | null
              thumbnail?: {
                __typename?: 'ProductImage'
                label?: string | null
                url?: {
                  __typename?: 'MediaGalleryUrlInterface'
                  medium?: string | null
                } | null
              } | null
              price_range: {
                __typename?: 'PriceRange'
                maximum_price?: {
                  __typename?: 'ProductPrice'
                  final_price: {
                    __typename?: 'MoneyTaxExtended'
                    currency?: CurrencyEnum | null
                    value?: number | null
                    value_excl_tax?: number | null
                  }
                  regular_price: {
                    __typename?: 'MoneyTaxExtended'
                    currency?: CurrencyEnum | null
                    value?: number | null
                    value_excl_tax?: number | null
                  }
                } | null
                minimum_price: {
                  __typename?: 'ProductPrice'
                  final_price: {
                    __typename?: 'MoneyTaxExtended'
                    currency?: CurrencyEnum | null
                    value?: number | null
                    value_excl_tax?: number | null
                  }
                }
              }
            }
          | { __typename?: 'GroupedProduct' }
          | { __typename?: 'SimpleProduct' }
          | { __typename?: 'VirtualProduct' }
          | null
        > | null
      } | null
    } | null> | null
  } | null
}

export type TopProductsCategoryQueryVariables = Exact<{ [key: string]: never }>

export type TopProductsCategoryQuery = {
  __typename?: 'Query'
  category?: {
    __typename?: 'CategoryTree'
    products?: {
      __typename?: 'CategoryProducts'
      items?: Array<
        | { __typename?: 'BundleProduct'; sku?: string | null }
        | { __typename?: 'ConfigurableProduct'; sku?: string | null }
        | { __typename?: 'DownloadableProduct'; sku?: string | null }
        | { __typename?: 'GiftCardProduct'; sku?: string | null }
        | { __typename?: 'GroupedProduct'; sku?: string | null }
        | { __typename?: 'SimpleProduct'; sku?: string | null }
        | { __typename?: 'VirtualProduct'; sku?: string | null }
        | null
      > | null
    } | null
  } | null
}

export type XsearchPopularQueryVariables = Exact<{ [key: string]: never }>

export type XsearchPopularQuery = {
  __typename?: 'Query'
  xsearchPopular?: Array<{
    __typename?: 'SearchPopular'
    query?: string | null
  } | null> | null
}

export type XsearchQueryVariables = Exact<{
  text?: InputMaybe<Scalars['String']['input']>
}>

export type XsearchQuery = {
  __typename?: 'Query'
  xsearch?: {
    __typename?: 'SearchResults'
    brands?: Array<{
      __typename?: 'SearchResultType'
      id?: number | null
      image?: string | null
      name?: string | null
      url?: string | null
    } | null> | null
    categories?: Array<{
      __typename?: 'SearchResultType'
      id?: number | null
      image?: string | null
      name?: string | null
      url?: string | null
    } | null> | null
    products?: Array<{
      __typename?: 'SearchResultType'
      id?: number | null
      image?: string | null
      name?: string | null
      url?: string | null
    } | null> | null
  } | null
}

export type ProductDataQueryVariables = Exact<{
  url: Scalars['String']['input']
}>

export type ProductDataQuery = {
  __typename?: 'Query'
  route?:
    | { __typename?: 'BundleProduct' }
    | { __typename?: 'CategoryTree' }
    | { __typename?: 'CmsPage' }
    | {
        __typename: 'ConfigurableProduct'
        default_category?: string | null
        enabled?: boolean | null
        id?: number | null
        uid: string
        long_term_unavailable?: number | null
        stock_status?: ProductStockStatus | null
        meta_description?: string | null
        name?: string | null
        options_container?: string | null
        rating_summary: number
        redirect_code: number
        relative_url?: string | null
        review_count: number
        sku?: string | null
        type_id?: string | null
        canonical_url?: string | null
        breadcrumb_en?: string | null
        configurable_options?: Array<{
          __typename?: 'ConfigurableProductOptions'
          attribute_code?: string | null
          label?: string | null
          position?: number | null
        } | null> | null
        configurable_variants?: Array<{
          __typename?: 'ConfigurableVariant'
          is_bestseller?: boolean | null
          attributes?: Array<{
            __typename?: 'ConfigurableAttributeOption'
            code?: string | null
            label?: string | null
            value_index?: number | null
          } | null> | null
          product?: {
            __typename?: 'SimpleProduct'
            enabled?: boolean | null
            expiration_date?: string | null
            id?: number | null
            msrp_price?: number | null
            name?: string | null
            rating_summary: number
            review_count: number
            sku?: string | null
            stock_status?: ProductStockStatus | null
            long_term_unavailable?: number | null
            allergens?: {
              __typename?: 'AllergensInfoInterface'
              allergens?: Array<string | null> | null
              traces?: Array<string | null> | null
            } | null
            estimated_date_of_arrival?: {
              __typename?: 'EstimatedDateOfArrivalType'
              timestamp?: string | null
              formatted_date?: string | null
            } | null
            price_tiers?: Array<{
              __typename?: 'TierPrice'
              final_price?: {
                __typename?: 'Money'
                currency?: CurrencyEnum | null
                value?: number | null
              } | null
            } | null> | null
            unit_price?: {
              __typename?: 'UnitPrice'
              value: number
              unit: string
              currency: string
            } | null
            image?: {
              __typename?: 'ProductImage'
              disabled?: boolean | null
              label?: string | null
              position?: number | null
              url?: {
                __typename?: 'MediaGalleryUrlInterface'
                full?: string | null
                medium?: string | null
                small?: string | null
              } | null
            } | null
            price_range: {
              __typename?: 'PriceRange'
              maximum_price?: {
                __typename?: 'ProductPrice'
                final_price: {
                  __typename?: 'MoneyTaxExtended'
                  currency?: CurrencyEnum | null
                  value?: number | null
                  value_excl_tax?: number | null
                }
                regular_price: {
                  __typename?: 'MoneyTaxExtended'
                  currency?: CurrencyEnum | null
                  value?: number | null
                  value_excl_tax?: number | null
                }
              } | null
              minimum_price: {
                __typename?: 'ProductPrice'
                final_price: {
                  __typename?: 'MoneyTaxExtended'
                  currency?: CurrencyEnum | null
                  value?: number | null
                  value_excl_tax?: number | null
                }
              }
            }
            visible_attributes?: Array<{
              __typename?: 'VisibleAttributes'
              label?: string | null
              code?: string | null
              values?: Array<{
                __typename?: 'VisibleAttributesValues'
                title?: string | null
                url?: string | null
                value?: string | null
              } | null> | null
            } | null> | null
            gb_product_labels?: Array<{
              __typename?: 'Label'
              bg?: string | null
              content?: string | null
              discount?: number | null
              label?: number | null
              src?: string | null
              type?: string | null
            } | null> | null
          } | null
        } | null> | null
        hreflangs?: Array<{
          __typename?: 'Hreflangs'
          href?: string | null
          hreflang?: string | null
        } | null> | null
        image?: {
          __typename?: 'ProductImage'
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            full?: string | null
          } | null
        } | null
        manufacturer_info?: {
          __typename?: 'ManufacturerInfoInterface'
          name?: string | null
          pathname?: string | null
        } | null
        media_gallery?: Array<
          | {
              __typename?: 'ProductImage'
              disabled?: boolean | null
              label?: string | null
              position?: number | null
              url?: {
                __typename?: 'MediaGalleryUrlInterface'
                full?: string | null
                medium?: string | null
                small?: string | null
              } | null
            }
          | {
              __typename?: 'ProductVideo'
              disabled?: boolean | null
              label?: string | null
              position?: number | null
              url?: {
                __typename?: 'MediaGalleryUrlInterface'
                full?: string | null
                medium?: string | null
                small?: string | null
              } | null
            }
          | null
        > | null
        short_description?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        sales_description?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        breadcrumb?: Array<{
          __typename?: 'BreadcrumbType'
          name?: string | null
          url?: string | null
        } | null> | null
        gb_product_labels?: Array<{
          __typename?: 'Label'
          bg?: string | null
          content?: string | null
          discount?: number | null
          label?: number | null
          src?: string | null
          type?: string | null
        } | null> | null
      }
    | { __typename?: 'DownloadableProduct' }
    | {
        __typename: 'GiftCardProduct'
        default_category?: string | null
        enabled?: boolean | null
        id?: number | null
        uid: string
        long_term_unavailable?: number | null
        stock_status?: ProductStockStatus | null
        meta_description?: string | null
        name?: string | null
        options_container?: string | null
        rating_summary: number
        redirect_code: number
        relative_url?: string | null
        review_count: number
        sku?: string | null
        type_id?: string | null
        canonical_url?: string | null
        breadcrumb_en?: string | null
        giftcard_amounts?: Array<{
          __typename?: 'GiftCardAmounts'
          uid: string
          website_value?: number | null
        } | null> | null
        hreflangs?: Array<{
          __typename?: 'Hreflangs'
          href?: string | null
          hreflang?: string | null
        } | null> | null
        image?: {
          __typename?: 'ProductImage'
          url?: {
            __typename?: 'MediaGalleryUrlInterface'
            full?: string | null
          } | null
        } | null
        media_gallery?: Array<
          | {
              __typename?: 'ProductImage'
              disabled?: boolean | null
              label?: string | null
              position?: number | null
              url?: {
                __typename?: 'MediaGalleryUrlInterface'
                full?: string | null
                medium?: string | null
                small?: string | null
              } | null
            }
          | {
              __typename?: 'ProductVideo'
              disabled?: boolean | null
              label?: string | null
              position?: number | null
              url?: {
                __typename?: 'MediaGalleryUrlInterface'
                full?: string | null
                medium?: string | null
                small?: string | null
              } | null
            }
          | null
        > | null
        short_description?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        sales_description?: {
          __typename?: 'ComplexTextValue'
          html?: string | null
        } | null
        breadcrumb?: Array<{
          __typename?: 'BreadcrumbType'
          name?: string | null
          url?: string | null
        } | null> | null
        gb_product_labels?: Array<{
          __typename?: 'Label'
          bg?: string | null
          content?: string | null
          discount?: number | null
          label?: number | null
          src?: string | null
          type?: string | null
        } | null> | null
      }
    | { __typename?: 'GroupedProduct' }
    | { __typename?: 'SimpleProduct' }
    | { __typename?: 'VirtualProduct' }
    | null
}

export const CartItemFragmentDoc = `
    fragment CartItem on CartItemInterface {
  __typename
  uid
  quantity
  product {
    uid
    name
    canonical_url
    image {
      label
      url {
        small
      }
    }
  }
  ... on ConfigurableCartItem {
    configurable_options {
      id
      value_label
    }
    configured_variant {
      image {
        label
        url {
          small
          medium
          full
        }
      }
    }
  }
  prices {
    price_including_tax {
      currency
      value
    }
    row_total_including_tax {
      currency
      value
    }
  }
}
    `
export const CartFragmentDoc = `
    fragment Cart on Cart {
  id
  items {
    ...CartItem
  }
}
    `
export const CategoryFieldsFragmentDoc = `
    fragment CategoryFields on CategoryTree {
  display_main_navigation
  display_side_navigation
  id
  include_in_menu
  level
  name
  path
  staged
  uid
  url
  custom_url
  url_key
  visible_tiktok
}
    `
export const ParentCategoriesFragmentDoc = `
    fragment ParentCategories on BreadcrumbType {
  name
}
    `
export const CategoryTagsFragmentDoc = `
    fragment CategoryTags on CategoryTree {
  __typename
  name
  breadcrumbs {
    ...ParentCategories
  }
}
    `
export const CategoryMetadataFragmentDoc = `
    fragment CategoryMetadata on CategoryTree {
  __typename
  name
  description
  canonical_url
  image
  meta_description
  meta_robots
  meta_title
  hreflangs {
    href
    hreflang
  }
}
    `
export const CategoryPageProductsTreeFragmentDoc = `
    fragment CategoryPageProductsTree on CategoryTree {
  categorySearch(
    pageSize: $pageSize
    currentPage: $currentPage
    sort: $sort
    filter: {price: {from: $from, to: $to}}
  ) {
    __typename
    total_count
    page_info {
      current_page
      page_size
      total_pages
    }
    availableFilters {
      range {
        min
        max
      }
    }
    items {
      breadcrumbs_en
      formatted_price
      id
      image
      name
      price
      product_url
      rating_summary
      reviews_count
      skus
      small_image
      thumbnail
      is_saleable
      is_single_variant
      is_price_range
      product_labels {
        bg
        content
        discount
        label
        src
        type
      }
      single_variant_sku
      manufacturer {
        id
        name
      }
      default_category {
        id
        name
      }
    }
  }
}
    `
export const BreadcrumbsFragmentDoc = `
    fragment Breadcrumbs on BreadcrumbType {
  name
  url
}
    `
export const CategoryItemsFragmentDoc = `
    fragment CategoryItems on CategoryTree {
  breadcrumbs {
    ...Breadcrumbs
  }
  categorySearch {
    name
    description
    short_description
    breadcrumbs
  }
}
    `
export const CategorySearchFiltersFragmentFragmentDoc = `
    fragment CategorySearchFiltersFragment on CategoryInterface {
  __typename
  categorySearch(filter: {price: {from: $from, to: $to}}) {
    total_count
    availableFilters {
      code
      display_mode
      global_name
      name
      position
      type
      range {
        max
        min
      }
      options {
        count
        name
        url
        used
      }
    }
  }
}
    `
export const CustomerFragmentDoc = `
    fragment Customer on Customer {
  firstname
  middlename
  lastname
  email
  id
  group_code
  gender
  date_of_birth
  is_b2b
  is_subscribed
  reviews {
    items {
      review_id
      product {
        name
        url_rewrites {
          url
        }
      }
      summary_rating
    }
  }
  addresses {
    default_billing
    default_shipping
    city
    firstname
    lastname
    middlename
    company
    vat_id
    id
    postcode
    street
    country_code
    telephone
  }
}
    `
export const BlogPostFragmentDoc = `
    fragment BlogPost on BlogPostInterface {
  excerpt
  img
  title
  url
}
    `
export const ProductQuestionAnswerFragmentDoc = `
    fragment ProductQuestionAnswer on ProductQuestionAnswer {
  answer_text
  date
  dislike
  like
  name
  uid
  user_avatar
  user_name
  user_uid
}
    `
export const ProductQuestionFragmentDoc = `
    fragment ProductQuestion on ProductQuestion {
  answer {
    ...ProductQuestionAnswer
  }
  date
  dislike
  like
  name
  question_text
  uid
  user_avatar
  user_name
  user_uid
}
    `
export const ProductQuestionsFragmentDoc = `
    fragment ProductQuestions on ProductQuestions {
  page_info {
    current_page
    page_size
    total_pages
  }
  list {
    ...ProductQuestion
  }
}
    `
export const ProductReviewFragmentDoc = `
    fragment ProductReview on ProductReview {
  average_rating
  created_at
  customer_avatar
  customer_points
  nickname
  rate_minus
  rate_plus
  review_id
  summary
  summary_rating
  text
  verified_purchase
  ratings_breakdown {
    name
    value
  }
  source {
    country_code
    country_flag
    id
    summary
    text
  }
}
    `
export const GlobalRatingFragmentDoc = `
    fragment GlobalRating on ProductReviewsSummaryGlobalRating {
  overall
  total
  verified_purchases_rating
  segments {
    count
    rating
  }
}
    `
export const TopReviewFragmentDoc = `
    fragment TopReview on ProductReviewsSummaryTopReview {
  detail
  rate_minus
  rate_plus
  review_id
  summary_rating
}
    `
export const ProductReviewsSummaryFragmentDoc = `
    fragment ProductReviewsSummary on ProductReviewsSummary {
  global_rating {
    ...GlobalRating
  }
  top_negative_review {
    ...TopReview
  }
  top_positive_review {
    ...TopReview
  }
}
    `
export const ProductReviewsFragmentDoc = `
    fragment ProductReviews on ProductReviews {
  page_info {
    current_page
    page_size
    total_pages
  }
  types {
    current_store {
      total_count
    }
    negative {
      total_count
    }
    other_stores {
      total_count
    }
    positive {
      total_count
    }
    verified {
      total_count
    }
  }
  items {
    ...ProductReview
  }
  summary {
    ...ProductReviewsSummary
  }
}
    `
export const ConfigurableProductTabsFragmentDoc = `
    fragment ConfigurableProductTabs on ConfigurableProduct {
  __typename
  sku
  review_count
  description {
    html
  }
  store_video_attribute {
    html
  }
  latest_related_blog_posts {
    total_count
    items {
      ...BlogPost
    }
  }
  productQuestions(
    pageSize: $questionsPageSize
    currentPage: $questionsCurrentPage
  ) {
    ...ProductQuestions
  }
  reviews(
    pageSize: $reviewPageSize
    currentPage: $reviewCurrentPage
    filter: $reviewFilter
  ) {
    ...ProductReviews
  }
}
    `
export const GiftCardProductTabsFragmentDoc = `
    fragment GiftCardProductTabs on GiftCardProduct {
  __typename
  sku
  review_count
  description {
    html
  }
  visible_attributes {
    label
    code
    values {
      title
      url
      value
    }
  }
  latest_related_blog_posts {
    total_count
    items {
      ...BlogPost
    }
  }
  productQuestions(
    pageSize: $questionsPageSize
    currentPage: $questionsCurrentPage
  ) {
    ...ProductQuestions
  }
  reviews(
    pageSize: $reviewPageSize
    currentPage: $reviewCurrentPage
    filter: $reviewFilter
  ) {
    ...ProductReviews
  }
}
    `
export const ConfigurableProductOptionFragmentDoc = `
    fragment ConfigurableProductOption on ConfigurableProductOptions {
  attribute_code
  label
  position
}
    `
export const ConfigurableProductVariantAttributeFragmentDoc = `
    fragment ConfigurableProductVariantAttribute on ConfigurableAttributeOption {
  code
  label
  value_index
}
    `
export const ProductImageFragmentDoc = `
    fragment ProductImage on ProductImage {
  disabled
  label
  position
  url {
    full
    medium
    small
  }
}
    `
export const ProductPriceFragmentDoc = `
    fragment ProductPrice on MoneyTaxExtended {
  currency
  value
  value_excl_tax
}
    `
export const SimpleProductOptimizedFragmentDoc = `
    fragment SimpleProductOptimized on SimpleProduct {
  enabled
  expiration_date
  id
  msrp_price
  name
  rating_summary
  review_count
  sku
  stock_status
  long_term_unavailable
  allergens {
    allergens
    traces
  }
  estimated_date_of_arrival {
    timestamp
    formatted_date
  }
  price_tiers {
    final_price {
      currency
      value
    }
  }
  unit_price {
    value
    unit
    currency
  }
  image {
    ...ProductImage
  }
  price_range {
    maximum_price {
      final_price {
        ...ProductPrice
      }
      regular_price {
        ...ProductPrice
      }
    }
    minimum_price {
      final_price {
        ...ProductPrice
      }
    }
  }
}
    `
export const ConfigurableProductVariantOptimizedFragmentDoc = `
    fragment ConfigurableProductVariantOptimized on ConfigurableVariant {
  is_bestseller
  attributes {
    ...ConfigurableProductVariantAttribute
  }
  product {
    ...SimpleProductOptimized
  }
}
    `
export const BreadcrumbFragmentDoc = `
    fragment Breadcrumb on BreadcrumbType {
  name
  url
}
    `
export const ProductLabelV2FragmentDoc = `
    fragment ProductLabelV2 on Label {
  bg
  content
  discount
  label
  src
  type
}
    `
export const SimpleProductFragmentDoc = `
    fragment SimpleProduct on SimpleProduct {
  ...SimpleProductOptimized
  visible_attributes {
    label
    code
    values {
      title
      url
      value
    }
  }
  gb_product_labels {
    ...ProductLabelV2
  }
}
    `
export const ConfigurableProductVariantFragmentDoc = `
    fragment ConfigurableProductVariant on ConfigurableVariant {
  is_bestseller
  attributes {
    ...ConfigurableProductVariantAttribute
  }
  product {
    ...SimpleProduct
  }
}
    `
export const ConfigurableProductFragmentDoc = `
    fragment ConfigurableProduct on ConfigurableProduct {
  __typename
  default_category
  enabled
  id
  uid
  long_term_unavailable
  stock_status
  meta_description
  name
  options_container
  rating_summary
  redirect_code
  relative_url
  review_count
  sku
  type_id
  configurable_options {
    ...ConfigurableProductOption
  }
  configurable_variants {
    ...ConfigurableProductVariantOptimized
  }
  hreflangs {
    href
    hreflang
  }
  image {
    url {
      full
    }
  }
  manufacturer_info {
    name
    pathname
  }
  media_gallery {
    disabled
    label
    position
    url {
      full
      medium
      small
    }
  }
  short_description {
    html
  }
  sales_description {
    html
  }
  breadcrumb {
    ...Breadcrumb
  }
  configurable_options {
    ...ConfigurableProductOption
  }
  configurable_variants {
    ...ConfigurableProductVariant
  }
  gb_product_labels {
    ...ProductLabelV2
  }
  canonical_url
  breadcrumb_en
}
    `
export const GiftCardProductAmountFragmentDoc = `
    fragment GiftCardProductAmount on GiftCardAmounts {
  uid
  website_value
}
    `
export const GiftCardProductFragmentDoc = `
    fragment GiftCardProduct on GiftCardProduct {
  __typename
  default_category
  enabled
  id
  uid
  long_term_unavailable
  stock_status
  meta_description
  name
  options_container
  rating_summary
  redirect_code
  relative_url
  review_count
  sku
  type_id
  giftcard_amounts {
    ...GiftCardProductAmount
  }
  hreflangs {
    href
    hreflang
  }
  image {
    url {
      full
    }
  }
  media_gallery {
    disabled
    label
    position
    url {
      full
      medium
      small
    }
  }
  short_description {
    html
  }
  sales_description {
    html
  }
  breadcrumb {
    ...Breadcrumb
  }
  giftcard_amounts {
    ...GiftCardProductAmount
  }
  gb_product_labels {
    ...ProductLabelV2
  }
  canonical_url
  breadcrumb_en
}
    `
export const ConfigurableProductListItemFragmentDoc = `
    fragment ConfigurableProductListItem on ConfigurableProduct {
  __typename
  enabled
  id
  uid
  long_term_unavailable
  stock_status
  name
  rating_summary
  review_count
  sku
  type_id
  image {
    url {
      full
    }
  }
  manufacturer_info {
    name
    pathname
  }
  breadcrumb {
    ...Breadcrumb
  }
  configurable_options {
    ...ConfigurableProductOption
  }
  configurable_variants {
    ...ConfigurableProductVariantOptimized
  }
  gb_product_labels {
    ...ProductLabelV2
  }
  breadcrumb_en
  thumbnail {
    url {
      medium
    }
    label
  }
  price_range {
    maximum_price {
      final_price {
        ...ProductPrice
      }
      regular_price {
        ...ProductPrice
      }
    }
    minimum_price {
      final_price {
        ...ProductPrice
      }
    }
  }
  msrp_price
  canonical_url
}
    `
export const GiftCardProductListItemFragmentDoc = `
    fragment GiftCardProductListItem on GiftCardProduct {
  __typename
  enabled
  id
  uid
  long_term_unavailable
  stock_status
  name
  rating_summary
  review_count
  sku
  type_id
  image {
    url {
      full
    }
  }
  breadcrumb {
    ...Breadcrumb
  }
  giftcard_amounts {
    ...GiftCardProductAmount
  }
  gb_product_labels {
    ...ProductLabelV2
  }
  breadcrumb_en
  thumbnail {
    url {
      medium
    }
    label
  }
  price_range {
    maximum_price {
      final_price {
        ...ProductPrice
      }
      regular_price {
        ...ProductPrice
      }
    }
    minimum_price {
      final_price {
        ...ProductPrice
      }
    }
  }
  msrp_price
  canonical_url
}
    `
export const RecommendedProductFragmentDoc = `
    fragment RecommendedProduct on SimpleProduct {
  id
  canonical_url
  name
  parent_sku
  rating_summary
  review_count
  sku
  stock_status
  breadcrumb_en
  manufacturer_info {
    name
  }
  thumbnail {
    label
    url {
      medium
    }
  }
  gb_product_labels {
    ...ProductLabelV2
  }
  price_range {
    minimum_price {
      final_price {
        ...ProductPrice
      }
    }
  }
}
    `
export const VisibleAttributeFragmentDoc = `
    fragment VisibleAttribute on VisibleAttributes {
  label
  code
  values {
    title
    url
    value
  }
}
    `
export const ConfigurableProductIdFragmentDoc = `
    fragment ConfigurableProductId on ConfigurableProduct {
  id
  __typename
}
    `
export const GiftCardProductIdFragmentDoc = `
    fragment GiftCardProductId on GiftCardProduct {
  id
  __typename
}
    `
export const ConfigurableProductModalDataFragmentDoc = `
    fragment ConfigurableProductModalData on ConfigurableProduct {
  __typename
  default_category
  enabled
  id
  uid
  long_term_unavailable
  stock_status
  name
  options_container
  rating_summary
  redirect_code
  relative_url
  review_count
  sku
  type_id
  configurable_options {
    ...ConfigurableProductOption
  }
  configurable_variants {
    ...ConfigurableProductVariant
  }
  image {
    url {
      full
    }
  }
  manufacturer_info {
    name
    pathname
  }
  sales_description {
    html
  }
  breadcrumb {
    ...Breadcrumb
  }
  gb_product_labels {
    ...ProductLabelV2
  }
  canonical_url
  breadcrumb_en
  price_range {
    maximum_price {
      final_price {
        ...ProductPrice
      }
      regular_price {
        ...ProductPrice
      }
    }
    minimum_price {
      final_price {
        ...ProductPrice
      }
    }
  }
  msrp_price
}
    `
export const GiftCardProductModalDataFragmentDoc = `
    fragment GiftCardProductModalData on GiftCardProduct {
  __typename
  default_category
  enabled
  id
  uid
  long_term_unavailable
  stock_status
  name
  options_container
  rating_summary
  redirect_code
  relative_url
  review_count
  sku
  type_id
  giftcard_amounts {
    ...GiftCardProductAmount
  }
  hreflangs {
    href
    hreflang
  }
  image {
    url {
      full
    }
  }
  sales_description {
    html
  }
  breadcrumb {
    ...Breadcrumb
  }
  giftcard_amounts {
    ...GiftCardProductAmount
  }
  gb_product_labels {
    ...ProductLabelV2
  }
  canonical_url
  breadcrumb_en
  price_range {
    maximum_price {
      final_price {
        ...ProductPrice
      }
      regular_price {
        ...ProductPrice
      }
    }
    minimum_price {
      final_price {
        ...ProductPrice
      }
    }
  }
  msrp_price
}
    `
export const WebsiteTypeFragmentDoc = `
    fragment WebsiteType on WebsiteType {
  storeCodes
  icon
  url
  nameMobile
  nameDesktop
}
    `
export const LoginDocument = `
    mutation Login($email: String!, $password: String!) {
  generateCustomerToken(email: $email, password: $password) {
    token
  }
}
    `

export const useLoginMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LoginMutation,
    TError,
    LoginMutationVariables,
    TContext
  >,
) => {
  return useMutation<LoginMutation, TError, LoginMutationVariables, TContext>({
    mutationKey: ['Login'],
    mutationFn: (variables?: LoginMutationVariables) =>
      nextFetcher<LoginMutation, LoginMutationVariables>(
        LoginDocument,
        variables,
      )(),
    ...options,
  })
}

useLoginMutation.fetcher = (
  variables: LoginMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    variables,
    options,
  )

export const CreateProductReviewDocument = `
    mutation CreateProductReview($nickname: String!, $ratings: [ProductReviewRatingInput!]!, $sku: String!, $summary: String!, $text: String!) {
  createProductReview(
    input: {nickname: $nickname, ratings: $ratings, sku: $sku, summary: $summary, text: $text}
  ) {
    review {
      created_at
    }
  }
}
    `

export const useCreateProductReviewMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateProductReviewMutation,
    TError,
    CreateProductReviewMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateProductReviewMutation,
    TError,
    CreateProductReviewMutationVariables,
    TContext
  >({
    mutationKey: ['CreateProductReview'],
    mutationFn: (variables?: CreateProductReviewMutationVariables) =>
      nextFetcher<
        CreateProductReviewMutation,
        CreateProductReviewMutationVariables
      >(CreateProductReviewDocument, variables)(),
    ...options,
  })
}

useCreateProductReviewMutation.fetcher = (
  variables: CreateProductReviewMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    CreateProductReviewMutation,
    CreateProductReviewMutationVariables
  >(CreateProductReviewDocument, variables, options)

export const AddGiftCardToCartDocument = `
    mutation AddGiftCardToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
  addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
    cart {
      id
    }
  }
}
    `

export const useAddGiftCardToCartMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AddGiftCardToCartMutation,
    TError,
    AddGiftCardToCartMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    AddGiftCardToCartMutation,
    TError,
    AddGiftCardToCartMutationVariables,
    TContext
  >({
    mutationKey: ['AddGiftCardToCart'],
    mutationFn: (variables?: AddGiftCardToCartMutationVariables) =>
      nextFetcher<
        AddGiftCardToCartMutation,
        AddGiftCardToCartMutationVariables
      >(AddGiftCardToCartDocument, variables)(),
    ...options,
  })
}

useAddGiftCardToCartMutation.fetcher = (
  variables: AddGiftCardToCartMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<AddGiftCardToCartMutation, AddGiftCardToCartMutationVariables>(
    AddGiftCardToCartDocument,
    variables,
    options,
  )

export const AddConfigurableProductToCartDocument = `
    mutation AddConfigurableProductToCart($cartId: String!, $cartItems: [ConfigurableProductCartItemInput]!) {
  addConfigurableProductsToCart(input: {cart_id: $cartId, cart_items: $cartItems}) {
    cart {
      id
    }
    messages {
      type
      message
    }
  }
}
    `

export const useAddConfigurableProductToCartMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AddConfigurableProductToCartMutation,
    TError,
    AddConfigurableProductToCartMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    AddConfigurableProductToCartMutation,
    TError,
    AddConfigurableProductToCartMutationVariables,
    TContext
  >({
    mutationKey: ['AddConfigurableProductToCart'],
    mutationFn: (variables?: AddConfigurableProductToCartMutationVariables) =>
      nextFetcher<
        AddConfigurableProductToCartMutation,
        AddConfigurableProductToCartMutationVariables
      >(AddConfigurableProductToCartDocument, variables)(),
    ...options,
  })
}

useAddConfigurableProductToCartMutation.fetcher = (
  variables: AddConfigurableProductToCartMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    AddConfigurableProductToCartMutation,
    AddConfigurableProductToCartMutationVariables
  >(AddConfigurableProductToCartDocument, variables, options)

export const RelatedBlogPostsDocument = `
    query RelatedBlogPosts($sku: String!, $pageSize: Int!, $currentPage: Int!) {
  RelatedBlogPosts(
    filter: {sku: {eq: $sku}}
    pageSize: $pageSize
    currentPage: $currentPage
  ) {
    total_count
    items {
      excerpt
      img
      title
      url
    }
    page_info {
      current_page
      page_size
      total_pages
    }
  }
}
    `

export const useRelatedBlogPostsQuery = <
  TData = RelatedBlogPostsQuery,
  TError = unknown,
>(
  variables: RelatedBlogPostsQueryVariables,
  options?: Omit<
    UseQueryOptions<RelatedBlogPostsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<RelatedBlogPostsQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<RelatedBlogPostsQuery, TError, TData>({
    queryKey: ['RelatedBlogPosts', variables],
    queryFn: nextFetcher<RelatedBlogPostsQuery, RelatedBlogPostsQueryVariables>(
      RelatedBlogPostsDocument,
      variables,
    ),
    ...options,
  })
}

useRelatedBlogPostsQuery.document = RelatedBlogPostsDocument

useRelatedBlogPostsQuery.getKey = (
  variables: RelatedBlogPostsQueryVariables,
) => ['RelatedBlogPosts', variables]

export const useSuspenseRelatedBlogPostsQuery = <
  TData = RelatedBlogPostsQuery,
  TError = unknown,
>(
  variables: RelatedBlogPostsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<RelatedBlogPostsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      RelatedBlogPostsQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<RelatedBlogPostsQuery, TError, TData>({
    queryKey: ['RelatedBlogPostsSuspense', variables],
    queryFn: nextFetcher<RelatedBlogPostsQuery, RelatedBlogPostsQueryVariables>(
      RelatedBlogPostsDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseRelatedBlogPostsQuery.document = RelatedBlogPostsDocument

useSuspenseRelatedBlogPostsQuery.getKey = (
  variables: RelatedBlogPostsQueryVariables,
) => ['RelatedBlogPostsSuspense', variables]

useRelatedBlogPostsQuery.fetcher = (
  variables: RelatedBlogPostsQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<RelatedBlogPostsQuery, RelatedBlogPostsQueryVariables>(
    RelatedBlogPostsDocument,
    variables,
    options,
  )

export const CartDocument = `
    query Cart($cartId: String!) {
  cart(cart_id: $cartId) {
    ...Cart
  }
}
    ${CartFragmentDoc}
${CartItemFragmentDoc}`

export const useCartQuery = <TData = CartQuery, TError = unknown>(
  variables: CartQueryVariables,
  options?: Omit<UseQueryOptions<CartQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<CartQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CartQuery, TError, TData>({
    queryKey: ['Cart', variables],
    queryFn: nextFetcher<CartQuery, CartQueryVariables>(
      CartDocument,
      variables,
    ),
    ...options,
  })
}

useCartQuery.document = CartDocument

useCartQuery.getKey = (variables: CartQueryVariables) => ['Cart', variables]

export const useSuspenseCartQuery = <TData = CartQuery, TError = unknown>(
  variables: CartQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CartQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<CartQuery, TError, TData>['queryKey']
  },
) => {
  return useSuspenseQuery<CartQuery, TError, TData>({
    queryKey: ['CartSuspense', variables],
    queryFn: nextFetcher<CartQuery, CartQueryVariables>(
      CartDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCartQuery.document = CartDocument

useSuspenseCartQuery.getKey = (variables: CartQueryVariables) => [
  'CartSuspense',
  variables,
]

useCartQuery.fetcher = (
  variables: CartQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CartQuery, CartQueryVariables>(CartDocument, variables, options)

export const GiftCardAccountDocument = `
    query GiftCardAccount($giftCardCode: String!) {
  giftCardAccount(input: {gift_card_code: $giftCardCode}) {
    code
    expiration_date
    balance {
      currency
      value
    }
  }
}
    `

export const useGiftCardAccountQuery = <
  TData = GiftCardAccountQuery,
  TError = unknown,
>(
  variables: GiftCardAccountQueryVariables,
  options?: Omit<
    UseQueryOptions<GiftCardAccountQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GiftCardAccountQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<GiftCardAccountQuery, TError, TData>({
    queryKey: ['GiftCardAccount', variables],
    queryFn: nextFetcher<GiftCardAccountQuery, GiftCardAccountQueryVariables>(
      GiftCardAccountDocument,
      variables,
    ),
    ...options,
  })
}

useGiftCardAccountQuery.document = GiftCardAccountDocument

useGiftCardAccountQuery.getKey = (variables: GiftCardAccountQueryVariables) => [
  'GiftCardAccount',
  variables,
]

export const useSuspenseGiftCardAccountQuery = <
  TData = GiftCardAccountQuery,
  TError = unknown,
>(
  variables: GiftCardAccountQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<GiftCardAccountQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      GiftCardAccountQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<GiftCardAccountQuery, TError, TData>({
    queryKey: ['GiftCardAccountSuspense', variables],
    queryFn: nextFetcher<GiftCardAccountQuery, GiftCardAccountQueryVariables>(
      GiftCardAccountDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseGiftCardAccountQuery.document = GiftCardAccountDocument

useSuspenseGiftCardAccountQuery.getKey = (
  variables: GiftCardAccountQueryVariables,
) => ['GiftCardAccountSuspense', variables]

useGiftCardAccountQuery.fetcher = (
  variables: GiftCardAccountQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<GiftCardAccountQuery, GiftCardAccountQueryVariables>(
    GiftCardAccountDocument,
    variables,
    options,
  )

export const CategoriesByGoalDocument = `
    query CategoriesByGoal {
  categoriesByGoal {
    category {
      uid
      thumbnail
      url
      url_key
    }
    custom_name
  }
}
    `

export const useCategoriesByGoalQuery = <
  TData = CategoriesByGoalQuery,
  TError = unknown,
>(
  variables?: CategoriesByGoalQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoriesByGoalQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CategoriesByGoalQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CategoriesByGoalQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CategoriesByGoal']
        : ['CategoriesByGoal', variables],
    queryFn: nextFetcher<CategoriesByGoalQuery, CategoriesByGoalQueryVariables>(
      CategoriesByGoalDocument,
      variables,
    ),
    ...options,
  })
}

useCategoriesByGoalQuery.document = CategoriesByGoalDocument

useCategoriesByGoalQuery.getKey = (
  variables?: CategoriesByGoalQueryVariables,
) =>
  variables === undefined
    ? ['CategoriesByGoal']
    : ['CategoriesByGoal', variables]

export const useSuspenseCategoriesByGoalQuery = <
  TData = CategoriesByGoalQuery,
  TError = unknown,
>(
  variables?: CategoriesByGoalQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoriesByGoalQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoriesByGoalQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CategoriesByGoalQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CategoriesByGoalSuspense']
        : ['CategoriesByGoalSuspense', variables],
    queryFn: nextFetcher<CategoriesByGoalQuery, CategoriesByGoalQueryVariables>(
      CategoriesByGoalDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCategoriesByGoalQuery.document = CategoriesByGoalDocument

useSuspenseCategoriesByGoalQuery.getKey = (
  variables?: CategoriesByGoalQueryVariables,
) =>
  variables === undefined
    ? ['CategoriesByGoalSuspense']
    : ['CategoriesByGoalSuspense', variables]

useCategoriesByGoalQuery.fetcher = (
  variables?: CategoriesByGoalQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CategoriesByGoalQuery, CategoriesByGoalQueryVariables>(
    CategoriesByGoalDocument,
    variables,
    options,
  )

export const CategoryListDocument = `
    query CategoryList($filters: CategoryFilterInput) {
  categoryList(filters: $filters) {
    children {
      ...CategoryFields
      children {
        ...CategoryFields
        children {
          ...CategoryFields
          children {
            ...CategoryFields
          }
        }
      }
    }
  }
}
    ${CategoryFieldsFragmentDoc}`

export const useCategoryListQuery = <
  TData = CategoryListQuery,
  TError = unknown,
>(
  variables?: CategoryListQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoryListQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CategoryListQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CategoryListQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['CategoryList'] : ['CategoryList', variables],
    queryFn: nextFetcher<CategoryListQuery, CategoryListQueryVariables>(
      CategoryListDocument,
      variables,
    ),
    ...options,
  })
}

useCategoryListQuery.document = CategoryListDocument

useCategoryListQuery.getKey = (variables?: CategoryListQueryVariables) =>
  variables === undefined ? ['CategoryList'] : ['CategoryList', variables]

export const useSuspenseCategoryListQuery = <
  TData = CategoryListQuery,
  TError = unknown,
>(
  variables?: CategoryListQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoryListQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoryListQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CategoryListQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CategoryListSuspense']
        : ['CategoryListSuspense', variables],
    queryFn: nextFetcher<CategoryListQuery, CategoryListQueryVariables>(
      CategoryListDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCategoryListQuery.document = CategoryListDocument

useSuspenseCategoryListQuery.getKey = (
  variables?: CategoryListQueryVariables,
) =>
  variables === undefined
    ? ['CategoryListSuspense']
    : ['CategoryListSuspense', variables]

useCategoryListQuery.fetcher = (
  variables?: CategoryListQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CategoryListQuery, CategoryListQueryVariables>(
    CategoryListDocument,
    variables,
    options,
  )

export const CategoryMagentoTagsDocument = `
    query CategoryMagentoTags($categoryId: String!) {
  route(url: $categoryId) {
    ...CategoryTags
  }
}
    ${CategoryTagsFragmentDoc}
${ParentCategoriesFragmentDoc}`

export const useCategoryMagentoTagsQuery = <
  TData = CategoryMagentoTagsQuery,
  TError = unknown,
>(
  variables: CategoryMagentoTagsQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoryMagentoTagsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CategoryMagentoTagsQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useQuery<CategoryMagentoTagsQuery, TError, TData>({
    queryKey: ['CategoryMagentoTags', variables],
    queryFn: nextFetcher<
      CategoryMagentoTagsQuery,
      CategoryMagentoTagsQueryVariables
    >(CategoryMagentoTagsDocument, variables),
    ...options,
  })
}

useCategoryMagentoTagsQuery.document = CategoryMagentoTagsDocument

useCategoryMagentoTagsQuery.getKey = (
  variables: CategoryMagentoTagsQueryVariables,
) => ['CategoryMagentoTags', variables]

export const useSuspenseCategoryMagentoTagsQuery = <
  TData = CategoryMagentoTagsQuery,
  TError = unknown,
>(
  variables: CategoryMagentoTagsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoryMagentoTagsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoryMagentoTagsQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CategoryMagentoTagsQuery, TError, TData>({
    queryKey: ['CategoryMagentoTagsSuspense', variables],
    queryFn: nextFetcher<
      CategoryMagentoTagsQuery,
      CategoryMagentoTagsQueryVariables
    >(CategoryMagentoTagsDocument, variables),
    ...options,
  })
}

useSuspenseCategoryMagentoTagsQuery.document = CategoryMagentoTagsDocument

useSuspenseCategoryMagentoTagsQuery.getKey = (
  variables: CategoryMagentoTagsQueryVariables,
) => ['CategoryMagentoTagsSuspense', variables]

useCategoryMagentoTagsQuery.fetcher = (
  variables: CategoryMagentoTagsQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CategoryMagentoTagsQuery, CategoryMagentoTagsQueryVariables>(
    CategoryMagentoTagsDocument,
    variables,
    options,
  )

export const CategoryMetadataDocument = `
    query CategoryMetadata($categoryId: String!) {
  route(url: $categoryId) {
    ...CategoryMetadata
  }
}
    ${CategoryMetadataFragmentDoc}`

export const useCategoryMetadataQuery = <
  TData = CategoryMetadataQuery,
  TError = unknown,
>(
  variables: CategoryMetadataQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoryMetadataQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CategoryMetadataQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CategoryMetadataQuery, TError, TData>({
    queryKey: ['CategoryMetadata', variables],
    queryFn: nextFetcher<CategoryMetadataQuery, CategoryMetadataQueryVariables>(
      CategoryMetadataDocument,
      variables,
    ),
    ...options,
  })
}

useCategoryMetadataQuery.document = CategoryMetadataDocument

useCategoryMetadataQuery.getKey = (
  variables: CategoryMetadataQueryVariables,
) => ['CategoryMetadata', variables]

export const useSuspenseCategoryMetadataQuery = <
  TData = CategoryMetadataQuery,
  TError = unknown,
>(
  variables: CategoryMetadataQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoryMetadataQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoryMetadataQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CategoryMetadataQuery, TError, TData>({
    queryKey: ['CategoryMetadataSuspense', variables],
    queryFn: nextFetcher<CategoryMetadataQuery, CategoryMetadataQueryVariables>(
      CategoryMetadataDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCategoryMetadataQuery.document = CategoryMetadataDocument

useSuspenseCategoryMetadataQuery.getKey = (
  variables: CategoryMetadataQueryVariables,
) => ['CategoryMetadataSuspense', variables]

useCategoryMetadataQuery.fetcher = (
  variables: CategoryMetadataQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CategoryMetadataQuery, CategoryMetadataQueryVariables>(
    CategoryMetadataDocument,
    variables,
    options,
  )

export const CategoryPageProductsDocument = `
    query CategoryPageProducts($categoryId: String!, $pageSize: Int!, $currentPage: Int!, $sort: CategorySearchSort, $from: String, $to: String) {
  route(url: $categoryId) {
    __typename
    ...CategoryPageProductsTree
  }
}
    ${CategoryPageProductsTreeFragmentDoc}`

export const useCategoryPageProductsQuery = <
  TData = CategoryPageProductsQuery,
  TError = unknown,
>(
  variables: CategoryPageProductsQueryVariables,
  options?: Omit<
    UseQueryOptions<CategoryPageProductsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CategoryPageProductsQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useQuery<CategoryPageProductsQuery, TError, TData>({
    queryKey: ['CategoryPageProducts', variables],
    queryFn: nextFetcher<
      CategoryPageProductsQuery,
      CategoryPageProductsQueryVariables
    >(CategoryPageProductsDocument, variables),
    ...options,
  })
}

useCategoryPageProductsQuery.document = CategoryPageProductsDocument

useCategoryPageProductsQuery.getKey = (
  variables: CategoryPageProductsQueryVariables,
) => ['CategoryPageProducts', variables]

export const useSuspenseCategoryPageProductsQuery = <
  TData = CategoryPageProductsQuery,
  TError = unknown,
>(
  variables: CategoryPageProductsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoryPageProductsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategoryPageProductsQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CategoryPageProductsQuery, TError, TData>({
    queryKey: ['CategoryPageProductsSuspense', variables],
    queryFn: nextFetcher<
      CategoryPageProductsQuery,
      CategoryPageProductsQueryVariables
    >(CategoryPageProductsDocument, variables),
    ...options,
  })
}

useSuspenseCategoryPageProductsQuery.document = CategoryPageProductsDocument

useSuspenseCategoryPageProductsQuery.getKey = (
  variables: CategoryPageProductsQueryVariables,
) => ['CategoryPageProductsSuspense', variables]

useCategoryPageProductsQuery.fetcher = (
  variables: CategoryPageProductsQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CategoryPageProductsQuery, CategoryPageProductsQueryVariables>(
    CategoryPageProductsDocument,
    variables,
    options,
  )

export const CategoryDocument = `
    query Category($categoryId: String!) {
  route(url: $categoryId) {
    __typename
    ...CategoryItems
  }
}
    ${CategoryItemsFragmentDoc}
${BreadcrumbsFragmentDoc}`

export const useCategoryQuery = <TData = CategoryQuery, TError = unknown>(
  variables: CategoryQueryVariables,
  options?: Omit<UseQueryOptions<CategoryQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<CategoryQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CategoryQuery, TError, TData>({
    queryKey: ['Category', variables],
    queryFn: nextFetcher<CategoryQuery, CategoryQueryVariables>(
      CategoryDocument,
      variables,
    ),
    ...options,
  })
}

useCategoryQuery.document = CategoryDocument

useCategoryQuery.getKey = (variables: CategoryQueryVariables) => [
  'Category',
  variables,
]

export const useSuspenseCategoryQuery = <
  TData = CategoryQuery,
  TError = unknown,
>(
  variables: CategoryQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategoryQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<CategoryQuery, TError, TData>['queryKey']
  },
) => {
  return useSuspenseQuery<CategoryQuery, TError, TData>({
    queryKey: ['CategorySuspense', variables],
    queryFn: nextFetcher<CategoryQuery, CategoryQueryVariables>(
      CategoryDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCategoryQuery.document = CategoryDocument

useSuspenseCategoryQuery.getKey = (variables: CategoryQueryVariables) => [
  'CategorySuspense',
  variables,
]

useCategoryQuery.fetcher = (
  variables: CategoryQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CategoryQuery, CategoryQueryVariables>(
    CategoryDocument,
    variables,
    options,
  )

export const CategorySearchFiltersDocument = `
    query CategorySearchFilters($url: String!, $from: String, $to: String) {
  route(url: $url) {
    ...CategorySearchFiltersFragment
  }
}
    ${CategorySearchFiltersFragmentFragmentDoc}`

export const useCategorySearchFiltersQuery = <
  TData = CategorySearchFiltersQuery,
  TError = unknown,
>(
  variables: CategorySearchFiltersQueryVariables,
  options?: Omit<
    UseQueryOptions<CategorySearchFiltersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CategorySearchFiltersQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useQuery<CategorySearchFiltersQuery, TError, TData>({
    queryKey: ['CategorySearchFilters', variables],
    queryFn: nextFetcher<
      CategorySearchFiltersQuery,
      CategorySearchFiltersQueryVariables
    >(CategorySearchFiltersDocument, variables),
    ...options,
  })
}

useCategorySearchFiltersQuery.document = CategorySearchFiltersDocument

useCategorySearchFiltersQuery.getKey = (
  variables: CategorySearchFiltersQueryVariables,
) => ['CategorySearchFilters', variables]

export const useSuspenseCategorySearchFiltersQuery = <
  TData = CategorySearchFiltersQuery,
  TError = unknown,
>(
  variables: CategorySearchFiltersQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CategorySearchFiltersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CategorySearchFiltersQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CategorySearchFiltersQuery, TError, TData>({
    queryKey: ['CategorySearchFiltersSuspense', variables],
    queryFn: nextFetcher<
      CategorySearchFiltersQuery,
      CategorySearchFiltersQueryVariables
    >(CategorySearchFiltersDocument, variables),
    ...options,
  })
}

useSuspenseCategorySearchFiltersQuery.document = CategorySearchFiltersDocument

useSuspenseCategorySearchFiltersQuery.getKey = (
  variables: CategorySearchFiltersQueryVariables,
) => ['CategorySearchFiltersSuspense', variables]

useCategorySearchFiltersQuery.fetcher = (
  variables: CategorySearchFiltersQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CategorySearchFiltersQuery, CategorySearchFiltersQueryVariables>(
    CategorySearchFiltersDocument,
    variables,
    options,
  )

export const CmsBlockDocument = `
    query CmsBlock($identifiers: [String!]) {
  cmsBlocks(identifiers: $identifiers) {
    items {
      content
      identifier
      title
    }
  }
}
    `

export const useCmsBlockQuery = <TData = CmsBlockQuery, TError = unknown>(
  variables?: CmsBlockQueryVariables,
  options?: Omit<UseQueryOptions<CmsBlockQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<CmsBlockQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CmsBlockQuery, TError, TData>({
    queryKey: variables === undefined ? ['CmsBlock'] : ['CmsBlock', variables],
    queryFn: nextFetcher<CmsBlockQuery, CmsBlockQueryVariables>(
      CmsBlockDocument,
      variables,
    ),
    ...options,
  })
}

useCmsBlockQuery.document = CmsBlockDocument

useCmsBlockQuery.getKey = (variables?: CmsBlockQueryVariables) =>
  variables === undefined ? ['CmsBlock'] : ['CmsBlock', variables]

export const useSuspenseCmsBlockQuery = <
  TData = CmsBlockQuery,
  TError = unknown,
>(
  variables?: CmsBlockQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CmsBlockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<CmsBlockQuery, TError, TData>['queryKey']
  },
) => {
  return useSuspenseQuery<CmsBlockQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CmsBlockSuspense']
        : ['CmsBlockSuspense', variables],
    queryFn: nextFetcher<CmsBlockQuery, CmsBlockQueryVariables>(
      CmsBlockDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCmsBlockQuery.document = CmsBlockDocument

useSuspenseCmsBlockQuery.getKey = (variables?: CmsBlockQueryVariables) =>
  variables === undefined
    ? ['CmsBlockSuspense']
    : ['CmsBlockSuspense', variables]

useCmsBlockQuery.fetcher = (
  variables?: CmsBlockQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CmsBlockQuery, CmsBlockQueryVariables>(
    CmsBlockDocument,
    variables,
    options,
  )

export const CmsContentDocument = `
    query CmsContent($identifier: String!) {
  cmsPage(identifier: $identifier) {
    identifier
    url_key
    title
    content
    content_heading
  }
}
    `

export const useCmsContentQuery = <TData = CmsContentQuery, TError = unknown>(
  variables: CmsContentQueryVariables,
  options?: Omit<
    UseQueryOptions<CmsContentQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CmsContentQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CmsContentQuery, TError, TData>({
    queryKey: ['CmsContent', variables],
    queryFn: nextFetcher<CmsContentQuery, CmsContentQueryVariables>(
      CmsContentDocument,
      variables,
    ),
    ...options,
  })
}

useCmsContentQuery.document = CmsContentDocument

useCmsContentQuery.getKey = (variables: CmsContentQueryVariables) => [
  'CmsContent',
  variables,
]

export const useSuspenseCmsContentQuery = <
  TData = CmsContentQuery,
  TError = unknown,
>(
  variables: CmsContentQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CmsContentQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CmsContentQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CmsContentQuery, TError, TData>({
    queryKey: ['CmsContentSuspense', variables],
    queryFn: nextFetcher<CmsContentQuery, CmsContentQueryVariables>(
      CmsContentDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCmsContentQuery.document = CmsContentDocument

useSuspenseCmsContentQuery.getKey = (variables: CmsContentQueryVariables) => [
  'CmsContentSuspense',
  variables,
]

useCmsContentQuery.fetcher = (
  variables: CmsContentQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CmsContentQuery, CmsContentQueryVariables>(
    CmsContentDocument,
    variables,
    options,
  )

export const CmsMetaDataDocument = `
    query cmsMetaData($identifier: String!) {
  cmsPage(identifier: $identifier) {
    identifier
    url_key
    title
    meta_description
    hreflangs {
      href
      hreflang
    }
  }
}
    `

export const useCmsMetaDataQuery = <TData = CmsMetaDataQuery, TError = unknown>(
  variables: CmsMetaDataQueryVariables,
  options?: Omit<
    UseQueryOptions<CmsMetaDataQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CmsMetaDataQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CmsMetaDataQuery, TError, TData>({
    queryKey: ['cmsMetaData', variables],
    queryFn: nextFetcher<CmsMetaDataQuery, CmsMetaDataQueryVariables>(
      CmsMetaDataDocument,
      variables,
    ),
    ...options,
  })
}

useCmsMetaDataQuery.document = CmsMetaDataDocument

useCmsMetaDataQuery.getKey = (variables: CmsMetaDataQueryVariables) => [
  'cmsMetaData',
  variables,
]

export const useSuspenseCmsMetaDataQuery = <
  TData = CmsMetaDataQuery,
  TError = unknown,
>(
  variables: CmsMetaDataQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CmsMetaDataQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CmsMetaDataQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CmsMetaDataQuery, TError, TData>({
    queryKey: ['cmsMetaDataSuspense', variables],
    queryFn: nextFetcher<CmsMetaDataQuery, CmsMetaDataQueryVariables>(
      CmsMetaDataDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCmsMetaDataQuery.document = CmsMetaDataDocument

useSuspenseCmsMetaDataQuery.getKey = (variables: CmsMetaDataQueryVariables) => [
  'cmsMetaDataSuspense',
  variables,
]

useCmsMetaDataQuery.fetcher = (
  variables: CmsMetaDataQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CmsMetaDataQuery, CmsMetaDataQueryVariables>(
    CmsMetaDataDocument,
    variables,
    options,
  )

export const CreateProductAnswerDocument = `
    mutation CreateProductAnswer($name: String!, $email: String!, $answer: String!, $questionUid: String!) {
  createProductAnswer(
    input: {name: $name, answer_text: $answer, email: $email, question_uid: $questionUid}
  ) {
    uid
  }
}
    `

export const useCreateProductAnswerMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateProductAnswerMutation,
    TError,
    CreateProductAnswerMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateProductAnswerMutation,
    TError,
    CreateProductAnswerMutationVariables,
    TContext
  >({
    mutationKey: ['CreateProductAnswer'],
    mutationFn: (variables?: CreateProductAnswerMutationVariables) =>
      nextFetcher<
        CreateProductAnswerMutation,
        CreateProductAnswerMutationVariables
      >(CreateProductAnswerDocument, variables)(),
    ...options,
  })
}

useCreateProductAnswerMutation.fetcher = (
  variables: CreateProductAnswerMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    CreateProductAnswerMutation,
    CreateProductAnswerMutationVariables
  >(CreateProductAnswerDocument, variables, options)

export const CreateProductQuestionDocument = `
    mutation CreateProductQuestion($name: String!, $email: String!, $question: String!, $sku: String!, $notification: Boolean!) {
  createProductQuestion(
    input: {name: $name, question_text: $question, email: $email, sku: $sku, notification: $notification}
  ) {
    uid
  }
}
    `

export const useCreateProductQuestionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateProductQuestionMutation,
    TError,
    CreateProductQuestionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateProductQuestionMutation,
    TError,
    CreateProductQuestionMutationVariables,
    TContext
  >({
    mutationKey: ['CreateProductQuestion'],
    mutationFn: (variables?: CreateProductQuestionMutationVariables) =>
      nextFetcher<
        CreateProductQuestionMutation,
        CreateProductQuestionMutationVariables
      >(CreateProductQuestionDocument, variables)(),
    ...options,
  })
}

useCreateProductQuestionMutation.fetcher = (
  variables: CreateProductQuestionMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    CreateProductQuestionMutation,
    CreateProductQuestionMutationVariables
  >(CreateProductQuestionDocument, variables, options)

export const CustomerBaseInfoDocument = `
    query CustomerBaseInfo {
  customer {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`

export const useCustomerBaseInfoQuery = <
  TData = CustomerBaseInfoQuery,
  TError = unknown,
>(
  variables?: CustomerBaseInfoQueryVariables,
  options?: Omit<
    UseQueryOptions<CustomerBaseInfoQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CustomerBaseInfoQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CustomerBaseInfoQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CustomerBaseInfo']
        : ['CustomerBaseInfo', variables],
    queryFn: nextFetcher<CustomerBaseInfoQuery, CustomerBaseInfoQueryVariables>(
      CustomerBaseInfoDocument,
      variables,
    ),
    ...options,
  })
}

useCustomerBaseInfoQuery.document = CustomerBaseInfoDocument

useCustomerBaseInfoQuery.getKey = (
  variables?: CustomerBaseInfoQueryVariables,
) =>
  variables === undefined
    ? ['CustomerBaseInfo']
    : ['CustomerBaseInfo', variables]

export const useSuspenseCustomerBaseInfoQuery = <
  TData = CustomerBaseInfoQuery,
  TError = unknown,
>(
  variables?: CustomerBaseInfoQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CustomerBaseInfoQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CustomerBaseInfoQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CustomerBaseInfoQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CustomerBaseInfoSuspense']
        : ['CustomerBaseInfoSuspense', variables],
    queryFn: nextFetcher<CustomerBaseInfoQuery, CustomerBaseInfoQueryVariables>(
      CustomerBaseInfoDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCustomerBaseInfoQuery.document = CustomerBaseInfoDocument

useSuspenseCustomerBaseInfoQuery.getKey = (
  variables?: CustomerBaseInfoQueryVariables,
) =>
  variables === undefined
    ? ['CustomerBaseInfoSuspense']
    : ['CustomerBaseInfoSuspense', variables]

useCustomerBaseInfoQuery.fetcher = (
  variables?: CustomerBaseInfoQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CustomerBaseInfoQuery, CustomerBaseInfoQueryVariables>(
    CustomerBaseInfoDocument,
    variables,
    options,
  )

export const CustomerIdDocument = `
    query CustomerId {
  customer {
    id
  }
}
    `

export const useCustomerIdQuery = <TData = CustomerIdQuery, TError = unknown>(
  variables?: CustomerIdQueryVariables,
  options?: Omit<
    UseQueryOptions<CustomerIdQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CustomerIdQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CustomerIdQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['CustomerId'] : ['CustomerId', variables],
    queryFn: nextFetcher<CustomerIdQuery, CustomerIdQueryVariables>(
      CustomerIdDocument,
      variables,
    ),
    ...options,
  })
}

useCustomerIdQuery.document = CustomerIdDocument

useCustomerIdQuery.getKey = (variables?: CustomerIdQueryVariables) =>
  variables === undefined ? ['CustomerId'] : ['CustomerId', variables]

export const useSuspenseCustomerIdQuery = <
  TData = CustomerIdQuery,
  TError = unknown,
>(
  variables?: CustomerIdQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CustomerIdQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CustomerIdQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CustomerIdQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CustomerIdSuspense']
        : ['CustomerIdSuspense', variables],
    queryFn: nextFetcher<CustomerIdQuery, CustomerIdQueryVariables>(
      CustomerIdDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCustomerIdQuery.document = CustomerIdDocument

useSuspenseCustomerIdQuery.getKey = (variables?: CustomerIdQueryVariables) =>
  variables === undefined
    ? ['CustomerIdSuspense']
    : ['CustomerIdSuspense', variables]

useCustomerIdQuery.fetcher = (
  variables?: CustomerIdQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CustomerIdQuery, CustomerIdQueryVariables>(
    CustomerIdDocument,
    variables,
    options,
  )

export const UpdateCustomerAddressDocument = `
    mutation UpdateCustomerAddress($id: Int!, $firstname: String!, $lastname: String!, $telephone: String!, $city: String!, $street: [String!]!, $postcode: String!, $company: String, $vat_id: String, $default_shipping: Boolean!, $default_billing: Boolean!) {
  updateCustomerAddress(
    id: $id
    input: {firstname: $firstname, lastname: $lastname, telephone: $telephone, city: $city, street: $street, postcode: $postcode, company: $company, vat_id: $vat_id, default_shipping: $default_shipping, default_billing: $default_billing}
  ) {
    id
  }
}
    `

export const useUpdateCustomerAddressMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateCustomerAddressMutation,
    TError,
    UpdateCustomerAddressMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateCustomerAddressMutation,
    TError,
    UpdateCustomerAddressMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateCustomerAddress'],
    mutationFn: (variables?: UpdateCustomerAddressMutationVariables) =>
      nextFetcher<
        UpdateCustomerAddressMutation,
        UpdateCustomerAddressMutationVariables
      >(UpdateCustomerAddressDocument, variables)(),
    ...options,
  })
}

useUpdateCustomerAddressMutation.fetcher = (
  variables: UpdateCustomerAddressMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    UpdateCustomerAddressMutation,
    UpdateCustomerAddressMutationVariables
  >(UpdateCustomerAddressDocument, variables, options)

export const DeleteCustomerAddressDocument = `
    mutation DeleteCustomerAddress($id: Int!) {
  deleteCustomerAddress(id: $id)
}
    `

export const useDeleteCustomerAddressMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteCustomerAddressMutation,
    TError,
    DeleteCustomerAddressMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteCustomerAddressMutation,
    TError,
    DeleteCustomerAddressMutationVariables,
    TContext
  >({
    mutationKey: ['DeleteCustomerAddress'],
    mutationFn: (variables?: DeleteCustomerAddressMutationVariables) =>
      nextFetcher<
        DeleteCustomerAddressMutation,
        DeleteCustomerAddressMutationVariables
      >(DeleteCustomerAddressDocument, variables)(),
    ...options,
  })
}

useDeleteCustomerAddressMutation.fetcher = (
  variables: DeleteCustomerAddressMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    DeleteCustomerAddressMutation,
    DeleteCustomerAddressMutationVariables
  >(DeleteCustomerAddressDocument, variables, options)

export const UpdateCustomerDocument = `
    mutation UpdateCustomer($firstname: String, $lastname: String, $gender: Int, $date_of_birth: String, $email: String, $password: String) {
  updateCustomer(
    input: {firstname: $firstname, lastname: $lastname, gender: $gender, date_of_birth: $date_of_birth, email: $email, password: $password}
  ) {
    customer {
      date_of_birth
      firstname
      lastname
      gender
      email
    }
  }
}
    `

export const useUpdateCustomerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateCustomerMutation,
    TError,
    UpdateCustomerMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateCustomerMutation,
    TError,
    UpdateCustomerMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateCustomer'],
    mutationFn: (variables?: UpdateCustomerMutationVariables) =>
      nextFetcher<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
        UpdateCustomerDocument,
        variables,
      )(),
    ...options,
  })
}

useUpdateCustomerMutation.fetcher = (
  variables?: UpdateCustomerMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    UpdateCustomerDocument,
    variables,
    options,
  )

export const CustomerCartIdDocument = `
    query CustomerCartId {
  customerCart {
    id
  }
}
    `

export const useCustomerCartIdQuery = <
  TData = CustomerCartIdQuery,
  TError = unknown,
>(
  variables?: CustomerCartIdQueryVariables,
  options?: Omit<
    UseQueryOptions<CustomerCartIdQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CustomerCartIdQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CustomerCartIdQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CustomerCartId']
        : ['CustomerCartId', variables],
    queryFn: nextFetcher<CustomerCartIdQuery, CustomerCartIdQueryVariables>(
      CustomerCartIdDocument,
      variables,
    ),
    ...options,
  })
}

useCustomerCartIdQuery.document = CustomerCartIdDocument

useCustomerCartIdQuery.getKey = (variables?: CustomerCartIdQueryVariables) =>
  variables === undefined ? ['CustomerCartId'] : ['CustomerCartId', variables]

export const useSuspenseCustomerCartIdQuery = <
  TData = CustomerCartIdQuery,
  TError = unknown,
>(
  variables?: CustomerCartIdQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CustomerCartIdQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CustomerCartIdQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CustomerCartIdQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CustomerCartIdSuspense']
        : ['CustomerCartIdSuspense', variables],
    queryFn: nextFetcher<CustomerCartIdQuery, CustomerCartIdQueryVariables>(
      CustomerCartIdDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCustomerCartIdQuery.document = CustomerCartIdDocument

useSuspenseCustomerCartIdQuery.getKey = (
  variables?: CustomerCartIdQueryVariables,
) =>
  variables === undefined
    ? ['CustomerCartIdSuspense']
    : ['CustomerCartIdSuspense', variables]

useCustomerCartIdQuery.fetcher = (
  variables?: CustomerCartIdQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CustomerCartIdQuery, CustomerCartIdQueryVariables>(
    CustomerCartIdDocument,
    variables,
    options,
  )

export const ChangeCustomerPasswordDocument = `
    mutation ChangeCustomerPassword($currentPassword: String!, $newPassword: String!) {
  changeCustomerPassword(
    currentPassword: $currentPassword
    newPassword: $newPassword
  ) {
    id
  }
}
    `

export const useChangeCustomerPasswordMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ChangeCustomerPasswordMutation,
    TError,
    ChangeCustomerPasswordMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    ChangeCustomerPasswordMutation,
    TError,
    ChangeCustomerPasswordMutationVariables,
    TContext
  >({
    mutationKey: ['ChangeCustomerPassword'],
    mutationFn: (variables?: ChangeCustomerPasswordMutationVariables) =>
      nextFetcher<
        ChangeCustomerPasswordMutation,
        ChangeCustomerPasswordMutationVariables
      >(ChangeCustomerPasswordDocument, variables)(),
    ...options,
  })
}

useChangeCustomerPasswordMutation.fetcher = (
  variables: ChangeCustomerPasswordMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    ChangeCustomerPasswordMutation,
    ChangeCustomerPasswordMutationVariables
  >(ChangeCustomerPasswordDocument, variables, options)

export const RevokeCustomerTokenDocument = `
    mutation RevokeCustomerToken {
  revokeCustomerToken {
    result
  }
}
    `

export const useRevokeCustomerTokenMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RevokeCustomerTokenMutation,
    TError,
    RevokeCustomerTokenMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RevokeCustomerTokenMutation,
    TError,
    RevokeCustomerTokenMutationVariables,
    TContext
  >({
    mutationKey: ['RevokeCustomerToken'],
    mutationFn: (variables?: RevokeCustomerTokenMutationVariables) =>
      nextFetcher<
        RevokeCustomerTokenMutation,
        RevokeCustomerTokenMutationVariables
      >(RevokeCustomerTokenDocument, variables)(),
    ...options,
  })
}

useRevokeCustomerTokenMutation.fetcher = (
  variables?: RevokeCustomerTokenMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    RevokeCustomerTokenMutation,
    RevokeCustomerTokenMutationVariables
  >(RevokeCustomerTokenDocument, variables, options)

export const FooterBlocksDocument = `
    query FooterBlocks {
  cmsBlocks(
    identifiers: ["footer_block_link_customer_service", "footer_block_link_social_media", "footer_block_link_certifications", "footer_block_link_contact"]
  ) {
    items {
      content
    }
  }
}
    `

export const useFooterBlocksQuery = <
  TData = FooterBlocksQuery,
  TError = unknown,
>(
  variables?: FooterBlocksQueryVariables,
  options?: Omit<
    UseQueryOptions<FooterBlocksQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<FooterBlocksQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<FooterBlocksQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['FooterBlocks'] : ['FooterBlocks', variables],
    queryFn: nextFetcher<FooterBlocksQuery, FooterBlocksQueryVariables>(
      FooterBlocksDocument,
      variables,
    ),
    ...options,
  })
}

useFooterBlocksQuery.document = FooterBlocksDocument

useFooterBlocksQuery.getKey = (variables?: FooterBlocksQueryVariables) =>
  variables === undefined ? ['FooterBlocks'] : ['FooterBlocks', variables]

export const useSuspenseFooterBlocksQuery = <
  TData = FooterBlocksQuery,
  TError = unknown,
>(
  variables?: FooterBlocksQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<FooterBlocksQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      FooterBlocksQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<FooterBlocksQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['FooterBlocksSuspense']
        : ['FooterBlocksSuspense', variables],
    queryFn: nextFetcher<FooterBlocksQuery, FooterBlocksQueryVariables>(
      FooterBlocksDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseFooterBlocksQuery.document = FooterBlocksDocument

useSuspenseFooterBlocksQuery.getKey = (
  variables?: FooterBlocksQueryVariables,
) =>
  variables === undefined
    ? ['FooterBlocksSuspense']
    : ['FooterBlocksSuspense', variables]

useFooterBlocksQuery.fetcher = (
  variables?: FooterBlocksQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<FooterBlocksQuery, FooterBlocksQueryVariables>(
    FooterBlocksDocument,
    variables,
    options,
  )

export const HomepageBannerSliderDocument = `
    query HomepageBannerSlider {
  homepageBannerSlider {
    config {
      enabled
      autoplay
      autoplay_speed
      infinite_loop
      show_dots
    }
    items {
      id
      title
      position
      url
      desktop_image
      mobile_image
      status
      active_from
      active_to
      store_id
    }
  }
}
    `

export const useHomepageBannerSliderQuery = <
  TData = HomepageBannerSliderQuery,
  TError = unknown,
>(
  variables?: HomepageBannerSliderQueryVariables,
  options?: Omit<
    UseQueryOptions<HomepageBannerSliderQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      HomepageBannerSliderQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useQuery<HomepageBannerSliderQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['HomepageBannerSlider']
        : ['HomepageBannerSlider', variables],
    queryFn: nextFetcher<
      HomepageBannerSliderQuery,
      HomepageBannerSliderQueryVariables
    >(HomepageBannerSliderDocument, variables),
    ...options,
  })
}

useHomepageBannerSliderQuery.document = HomepageBannerSliderDocument

useHomepageBannerSliderQuery.getKey = (
  variables?: HomepageBannerSliderQueryVariables,
) =>
  variables === undefined
    ? ['HomepageBannerSlider']
    : ['HomepageBannerSlider', variables]

export const useSuspenseHomepageBannerSliderQuery = <
  TData = HomepageBannerSliderQuery,
  TError = unknown,
>(
  variables?: HomepageBannerSliderQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<HomepageBannerSliderQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      HomepageBannerSliderQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<HomepageBannerSliderQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['HomepageBannerSliderSuspense']
        : ['HomepageBannerSliderSuspense', variables],
    queryFn: nextFetcher<
      HomepageBannerSliderQuery,
      HomepageBannerSliderQueryVariables
    >(HomepageBannerSliderDocument, variables),
    ...options,
  })
}

useSuspenseHomepageBannerSliderQuery.document = HomepageBannerSliderDocument

useSuspenseHomepageBannerSliderQuery.getKey = (
  variables?: HomepageBannerSliderQueryVariables,
) =>
  variables === undefined
    ? ['HomepageBannerSliderSuspense']
    : ['HomepageBannerSliderSuspense', variables]

useHomepageBannerSliderQuery.fetcher = (
  variables?: HomepageBannerSliderQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<HomepageBannerSliderQuery, HomepageBannerSliderQueryVariables>(
    HomepageBannerSliderDocument,
    variables,
    options,
  )

export const NewestBlogPostsDocument = `
    query NewestBlogPosts($pageSize: Int!, $currentPage: Int!) {
  NewestBlogPosts(pageSize: $pageSize, currentPage: $currentPage) {
    items {
      excerpt
      img
      title
      url
    }
  }
}
    `

export const useNewestBlogPostsQuery = <
  TData = NewestBlogPostsQuery,
  TError = unknown,
>(
  variables: NewestBlogPostsQueryVariables,
  options?: Omit<
    UseQueryOptions<NewestBlogPostsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<NewestBlogPostsQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<NewestBlogPostsQuery, TError, TData>({
    queryKey: ['NewestBlogPosts', variables],
    queryFn: nextFetcher<NewestBlogPostsQuery, NewestBlogPostsQueryVariables>(
      NewestBlogPostsDocument,
      variables,
    ),
    ...options,
  })
}

useNewestBlogPostsQuery.document = NewestBlogPostsDocument

useNewestBlogPostsQuery.getKey = (variables: NewestBlogPostsQueryVariables) => [
  'NewestBlogPosts',
  variables,
]

export const useSuspenseNewestBlogPostsQuery = <
  TData = NewestBlogPostsQuery,
  TError = unknown,
>(
  variables: NewestBlogPostsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<NewestBlogPostsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      NewestBlogPostsQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<NewestBlogPostsQuery, TError, TData>({
    queryKey: ['NewestBlogPostsSuspense', variables],
    queryFn: nextFetcher<NewestBlogPostsQuery, NewestBlogPostsQueryVariables>(
      NewestBlogPostsDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseNewestBlogPostsQuery.document = NewestBlogPostsDocument

useSuspenseNewestBlogPostsQuery.getKey = (
  variables: NewestBlogPostsQueryVariables,
) => ['NewestBlogPostsSuspense', variables]

useNewestBlogPostsQuery.fetcher = (
  variables: NewestBlogPostsQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<NewestBlogPostsQuery, NewestBlogPostsQueryVariables>(
    NewestBlogPostsDocument,
    variables,
    options,
  )

export const CustomerOrderDocument = `
    query CustomerOrder($orderId: Int!) {
  customer {
    orders(filter: {order_id: {eq: $orderId}}) {
      items {
        reorder_eligible
        created_at
        id
        increment_id
        order_number
        order_id
        internal_order_id
        status
        status_en
        tracking_url
        items {
          product_name
          parent_sku
          product_sku
          quantity_ordered
          product_image
          image
          is_saleable
          product_url_key
          selected_options {
            label
            value
          }
          product_sale_price {
            currency
            value
          }
          entered_options {
            label
            value
          }
          discounts {
            label
            amount {
              currency
              value
            }
          }
        }
        payment_methods {
          name
          instructions
          type
          bank_account_details {
            account_number
            iban
            swift
          }
        }
        shipping_address {
          firstname
          lastname
          postcode
          street
          city
          telephone
          country_code
        }
        billing_address {
          firstname
          lastname
          postcode
          street
          city
          telephone
          country_code
        }
        shipping_method
        total {
          discounts {
            label
            amount {
              currency
              value
            }
          }
          grand_total {
            currency
            value
          }
          shipping_handling {
            amount_including_tax {
              currency
              value
            }
          }
          payment_fee {
            value
          }
          products_price {
            value
          }
          subtotal {
            currency
            value
          }
          total_giftcard {
            currency
            value
          }
          total_shipping {
            currency
            value
          }
          total_tax {
            currency
            value
          }
        }
        invoices {
          id
          url
        }
      }
    }
  }
}
    `

export const useCustomerOrderQuery = <
  TData = CustomerOrderQuery,
  TError = unknown,
>(
  variables: CustomerOrderQueryVariables,
  options?: Omit<
    UseQueryOptions<CustomerOrderQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CustomerOrderQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<CustomerOrderQuery, TError, TData>({
    queryKey: ['CustomerOrder', variables],
    queryFn: nextFetcher<CustomerOrderQuery, CustomerOrderQueryVariables>(
      CustomerOrderDocument,
      variables,
    ),
    ...options,
  })
}

useCustomerOrderQuery.document = CustomerOrderDocument

useCustomerOrderQuery.getKey = (variables: CustomerOrderQueryVariables) => [
  'CustomerOrder',
  variables,
]

export const useSuspenseCustomerOrderQuery = <
  TData = CustomerOrderQuery,
  TError = unknown,
>(
  variables: CustomerOrderQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CustomerOrderQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CustomerOrderQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CustomerOrderQuery, TError, TData>({
    queryKey: ['CustomerOrderSuspense', variables],
    queryFn: nextFetcher<CustomerOrderQuery, CustomerOrderQueryVariables>(
      CustomerOrderDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseCustomerOrderQuery.document = CustomerOrderDocument

useSuspenseCustomerOrderQuery.getKey = (
  variables: CustomerOrderQueryVariables,
) => ['CustomerOrderSuspense', variables]

useCustomerOrderQuery.fetcher = (
  variables: CustomerOrderQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CustomerOrderQuery, CustomerOrderQueryVariables>(
    CustomerOrderDocument,
    variables,
    options,
  )

export const UpdateInternalOrderIdDocument = `
    mutation UpdateInternalOrderId($internalOrderId: String!, $orderId: String!) {
  updateInternalOrderId(
    input: {internalOrderId: $internalOrderId}
    number: $orderId
  ) {
    internal_order_id
  }
}
    `

export const useUpdateInternalOrderIdMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateInternalOrderIdMutation,
    TError,
    UpdateInternalOrderIdMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateInternalOrderIdMutation,
    TError,
    UpdateInternalOrderIdMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateInternalOrderId'],
    mutationFn: (variables?: UpdateInternalOrderIdMutationVariables) =>
      nextFetcher<
        UpdateInternalOrderIdMutation,
        UpdateInternalOrderIdMutationVariables
      >(UpdateInternalOrderIdDocument, variables)(),
    ...options,
  })
}

useUpdateInternalOrderIdMutation.fetcher = (
  variables: UpdateInternalOrderIdMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    UpdateInternalOrderIdMutation,
    UpdateInternalOrderIdMutationVariables
  >(UpdateInternalOrderIdDocument, variables, options)

export const CustomerOrderStatusDocument = `
    query CustomerOrderStatus($orderId: Int!) {
  customer {
    orders(filter: {order_id: {eq: $orderId}}) {
      items {
        status_en
        payment_methods {
          name
          type
        }
        timestamps {
          created_at
          paid_at
          processing_at
          suspended_note_at
          suspended_shipment_at
          sent_at
          completed_at
          canceled_at
        }
      }
    }
  }
}
    `

export const useCustomerOrderStatusQuery = <
  TData = CustomerOrderStatusQuery,
  TError = unknown,
>(
  variables: CustomerOrderStatusQueryVariables,
  options?: Omit<
    UseQueryOptions<CustomerOrderStatusQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CustomerOrderStatusQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useQuery<CustomerOrderStatusQuery, TError, TData>({
    queryKey: ['CustomerOrderStatus', variables],
    queryFn: nextFetcher<
      CustomerOrderStatusQuery,
      CustomerOrderStatusQueryVariables
    >(CustomerOrderStatusDocument, variables),
    ...options,
  })
}

useCustomerOrderStatusQuery.document = CustomerOrderStatusDocument

useCustomerOrderStatusQuery.getKey = (
  variables: CustomerOrderStatusQueryVariables,
) => ['CustomerOrderStatus', variables]

export const useSuspenseCustomerOrderStatusQuery = <
  TData = CustomerOrderStatusQuery,
  TError = unknown,
>(
  variables: CustomerOrderStatusQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CustomerOrderStatusQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CustomerOrderStatusQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CustomerOrderStatusQuery, TError, TData>({
    queryKey: ['CustomerOrderStatusSuspense', variables],
    queryFn: nextFetcher<
      CustomerOrderStatusQuery,
      CustomerOrderStatusQueryVariables
    >(CustomerOrderStatusDocument, variables),
    ...options,
  })
}

useSuspenseCustomerOrderStatusQuery.document = CustomerOrderStatusDocument

useSuspenseCustomerOrderStatusQuery.getKey = (
  variables: CustomerOrderStatusQueryVariables,
) => ['CustomerOrderStatusSuspense', variables]

useCustomerOrderStatusQuery.fetcher = (
  variables: CustomerOrderStatusQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CustomerOrderStatusQuery, CustomerOrderStatusQueryVariables>(
    CustomerOrderStatusDocument,
    variables,
    options,
  )

export const CustomerOrderStatusesDocument = `
    query CustomerOrderStatuses {
  customer {
    orders {
      items {
        id
        status_en
        payment_methods {
          name
          type
        }
        timestamps {
          created_at
          paid_at
          processing_at
          suspended_note_at
          suspended_shipment_at
          sent_at
          completed_at
          canceled_at
        }
      }
    }
  }
}
    `

export const useCustomerOrderStatusesQuery = <
  TData = CustomerOrderStatusesQuery,
  TError = unknown,
>(
  variables?: CustomerOrderStatusesQueryVariables,
  options?: Omit<
    UseQueryOptions<CustomerOrderStatusesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CustomerOrderStatusesQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useQuery<CustomerOrderStatusesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CustomerOrderStatuses']
        : ['CustomerOrderStatuses', variables],
    queryFn: nextFetcher<
      CustomerOrderStatusesQuery,
      CustomerOrderStatusesQueryVariables
    >(CustomerOrderStatusesDocument, variables),
    ...options,
  })
}

useCustomerOrderStatusesQuery.document = CustomerOrderStatusesDocument

useCustomerOrderStatusesQuery.getKey = (
  variables?: CustomerOrderStatusesQueryVariables,
) =>
  variables === undefined
    ? ['CustomerOrderStatuses']
    : ['CustomerOrderStatuses', variables]

export const useSuspenseCustomerOrderStatusesQuery = <
  TData = CustomerOrderStatusesQuery,
  TError = unknown,
>(
  variables?: CustomerOrderStatusesQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CustomerOrderStatusesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CustomerOrderStatusesQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CustomerOrderStatusesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['CustomerOrderStatusesSuspense']
        : ['CustomerOrderStatusesSuspense', variables],
    queryFn: nextFetcher<
      CustomerOrderStatusesQuery,
      CustomerOrderStatusesQueryVariables
    >(CustomerOrderStatusesDocument, variables),
    ...options,
  })
}

useSuspenseCustomerOrderStatusesQuery.document = CustomerOrderStatusesDocument

useSuspenseCustomerOrderStatusesQuery.getKey = (
  variables?: CustomerOrderStatusesQueryVariables,
) =>
  variables === undefined
    ? ['CustomerOrderStatusesSuspense']
    : ['CustomerOrderStatusesSuspense', variables]

useCustomerOrderStatusesQuery.fetcher = (
  variables?: CustomerOrderStatusesQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CustomerOrderStatusesQuery, CustomerOrderStatusesQueryVariables>(
    CustomerOrderStatusesDocument,
    variables,
    options,
  )

export const ReorderItemsDocument = `
    mutation ReorderItems($orderNumber: String!) {
  reorderItems(orderNumber: $orderNumber) {
    cart {
      items {
        id
        quantity
      }
      total_quantity
    }
  }
}
    `

export const useReorderItemsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ReorderItemsMutation,
    TError,
    ReorderItemsMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    ReorderItemsMutation,
    TError,
    ReorderItemsMutationVariables,
    TContext
  >({
    mutationKey: ['ReorderItems'],
    mutationFn: (variables?: ReorderItemsMutationVariables) =>
      nextFetcher<ReorderItemsMutation, ReorderItemsMutationVariables>(
        ReorderItemsDocument,
        variables,
      )(),
    ...options,
  })
}

useReorderItemsMutation.fetcher = (
  variables: ReorderItemsMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<ReorderItemsMutation, ReorderItemsMutationVariables>(
    ReorderItemsDocument,
    variables,
    options,
  )

export const CustomerOrdersListDocument = `
    query CustomerOrdersList($currentPage: Int!, $pageSize: Int!, $state: String) {
  customer {
    orders(
      currentPage: $currentPage
      pageSize: $pageSize
      filter: {state: {eq: $state}}
    ) {
      total_count
      items {
        id
        increment_id
        state
        order_number
        order_id
        order_date
        tracking_url
        total {
          grand_total {
            currency
            value
          }
        }
        status
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
}
    `

export const useCustomerOrdersListQuery = <
  TData = CustomerOrdersListQuery,
  TError = unknown,
>(
  variables: CustomerOrdersListQueryVariables,
  options?: Omit<
    UseQueryOptions<CustomerOrdersListQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      CustomerOrdersListQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useQuery<CustomerOrdersListQuery, TError, TData>({
    queryKey: ['CustomerOrdersList', variables],
    queryFn: nextFetcher<
      CustomerOrdersListQuery,
      CustomerOrdersListQueryVariables
    >(CustomerOrdersListDocument, variables),
    ...options,
  })
}

useCustomerOrdersListQuery.document = CustomerOrdersListDocument

useCustomerOrdersListQuery.getKey = (
  variables: CustomerOrdersListQueryVariables,
) => ['CustomerOrdersList', variables]

export const useSuspenseCustomerOrdersListQuery = <
  TData = CustomerOrdersListQuery,
  TError = unknown,
>(
  variables: CustomerOrdersListQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CustomerOrdersListQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CustomerOrdersListQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<CustomerOrdersListQuery, TError, TData>({
    queryKey: ['CustomerOrdersListSuspense', variables],
    queryFn: nextFetcher<
      CustomerOrdersListQuery,
      CustomerOrdersListQueryVariables
    >(CustomerOrdersListDocument, variables),
    ...options,
  })
}

useSuspenseCustomerOrdersListQuery.document = CustomerOrdersListDocument

useSuspenseCustomerOrdersListQuery.getKey = (
  variables: CustomerOrdersListQueryVariables,
) => ['CustomerOrdersListSuspense', variables]

useCustomerOrdersListQuery.fetcher = (
  variables: CustomerOrdersListQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CustomerOrdersListQuery, CustomerOrdersListQueryVariables>(
    CustomerOrdersListDocument,
    variables,
    options,
  )

export const ProductDataTabsDocument = `
    query ProductDataTabs($url: String!, $questionsPageSize: Int!, $questionsCurrentPage: Int!, $reviewPageSize: Int!, $reviewCurrentPage: Int!, $reviewFilter: String!) {
  route(url: $url) {
    ...GiftCardProductTabs
    ...ConfigurableProductTabs
  }
}
    ${GiftCardProductTabsFragmentDoc}
${BlogPostFragmentDoc}
${ProductQuestionsFragmentDoc}
${ProductQuestionFragmentDoc}
${ProductQuestionAnswerFragmentDoc}
${ProductReviewsFragmentDoc}
${ProductReviewFragmentDoc}
${ProductReviewsSummaryFragmentDoc}
${GlobalRatingFragmentDoc}
${TopReviewFragmentDoc}
${ConfigurableProductTabsFragmentDoc}`

export const useProductDataTabsQuery = <
  TData = ProductDataTabsQuery,
  TError = unknown,
>(
  variables: ProductDataTabsQueryVariables,
  options?: Omit<
    UseQueryOptions<ProductDataTabsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ProductDataTabsQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<ProductDataTabsQuery, TError, TData>({
    queryKey: ['ProductDataTabs', variables],
    queryFn: nextFetcher<ProductDataTabsQuery, ProductDataTabsQueryVariables>(
      ProductDataTabsDocument,
      variables,
    ),
    ...options,
  })
}

useProductDataTabsQuery.document = ProductDataTabsDocument

useProductDataTabsQuery.getKey = (variables: ProductDataTabsQueryVariables) => [
  'ProductDataTabs',
  variables,
]

export const useSuspenseProductDataTabsQuery = <
  TData = ProductDataTabsQuery,
  TError = unknown,
>(
  variables: ProductDataTabsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ProductDataTabsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      ProductDataTabsQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<ProductDataTabsQuery, TError, TData>({
    queryKey: ['ProductDataTabsSuspense', variables],
    queryFn: nextFetcher<ProductDataTabsQuery, ProductDataTabsQueryVariables>(
      ProductDataTabsDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseProductDataTabsQuery.document = ProductDataTabsDocument

useSuspenseProductDataTabsQuery.getKey = (
  variables: ProductDataTabsQueryVariables,
) => ['ProductDataTabsSuspense', variables]

useProductDataTabsQuery.fetcher = (
  variables: ProductDataTabsQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<ProductDataTabsQuery, ProductDataTabsQueryVariables>(
    ProductDataTabsDocument,
    variables,
    options,
  )

export const ProductIdDocument = `
    query ProductId($url: String!) {
  route(url: $url) {
    ...ConfigurableProductId
    ...GiftCardProductId
  }
}
    ${ConfigurableProductIdFragmentDoc}
${GiftCardProductIdFragmentDoc}`

export const useProductIdQuery = <TData = ProductIdQuery, TError = unknown>(
  variables: ProductIdQueryVariables,
  options?: Omit<UseQueryOptions<ProductIdQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<ProductIdQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<ProductIdQuery, TError, TData>({
    queryKey: ['ProductId', variables],
    queryFn: nextFetcher<ProductIdQuery, ProductIdQueryVariables>(
      ProductIdDocument,
      variables,
    ),
    ...options,
  })
}

useProductIdQuery.document = ProductIdDocument

useProductIdQuery.getKey = (variables: ProductIdQueryVariables) => [
  'ProductId',
  variables,
]

export const useSuspenseProductIdQuery = <
  TData = ProductIdQuery,
  TError = unknown,
>(
  variables: ProductIdQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ProductIdQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      ProductIdQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<ProductIdQuery, TError, TData>({
    queryKey: ['ProductIdSuspense', variables],
    queryFn: nextFetcher<ProductIdQuery, ProductIdQueryVariables>(
      ProductIdDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseProductIdQuery.document = ProductIdDocument

useSuspenseProductIdQuery.getKey = (variables: ProductIdQueryVariables) => [
  'ProductIdSuspense',
  variables,
]

useProductIdQuery.fetcher = (
  variables: ProductIdQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<ProductIdQuery, ProductIdQueryVariables>(
    ProductIdDocument,
    variables,
    options,
  )

export const ProductModalDataDocument = `
    query ProductModalData($url: String!) {
  route(url: $url) {
    ...ConfigurableProductModalData
    ...GiftCardProductModalData
  }
}
    ${ConfigurableProductModalDataFragmentDoc}
${ConfigurableProductOptionFragmentDoc}
${ConfigurableProductVariantFragmentDoc}
${ConfigurableProductVariantAttributeFragmentDoc}
${SimpleProductFragmentDoc}
${SimpleProductOptimizedFragmentDoc}
${ProductImageFragmentDoc}
${ProductPriceFragmentDoc}
${ProductLabelV2FragmentDoc}
${BreadcrumbFragmentDoc}
${GiftCardProductModalDataFragmentDoc}
${GiftCardProductAmountFragmentDoc}`

export const useProductModalDataQuery = <
  TData = ProductModalDataQuery,
  TError = unknown,
>(
  variables: ProductModalDataQueryVariables,
  options?: Omit<
    UseQueryOptions<ProductModalDataQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ProductModalDataQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<ProductModalDataQuery, TError, TData>({
    queryKey: ['ProductModalData', variables],
    queryFn: nextFetcher<ProductModalDataQuery, ProductModalDataQueryVariables>(
      ProductModalDataDocument,
      variables,
    ),
    ...options,
  })
}

useProductModalDataQuery.document = ProductModalDataDocument

useProductModalDataQuery.getKey = (
  variables: ProductModalDataQueryVariables,
) => ['ProductModalData', variables]

export const useSuspenseProductModalDataQuery = <
  TData = ProductModalDataQuery,
  TError = unknown,
>(
  variables: ProductModalDataQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ProductModalDataQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      ProductModalDataQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<ProductModalDataQuery, TError, TData>({
    queryKey: ['ProductModalDataSuspense', variables],
    queryFn: nextFetcher<ProductModalDataQuery, ProductModalDataQueryVariables>(
      ProductModalDataDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseProductModalDataQuery.document = ProductModalDataDocument

useSuspenseProductModalDataQuery.getKey = (
  variables: ProductModalDataQueryVariables,
) => ['ProductModalDataSuspense', variables]

useProductModalDataQuery.fetcher = (
  variables: ProductModalDataQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<ProductModalDataQuery, ProductModalDataQueryVariables>(
    ProductModalDataDocument,
    variables,
    options,
  )

export const QuestionsFilterDocument = `
    query QuestionsFilter($url: String!, $questionsPageSize: Int!, $questionsCurrentPage: Int!) {
  route(url: $url) {
    ... on ConfigurableProduct {
      __typename
      productQuestions(
        pageSize: $questionsPageSize
        currentPage: $questionsCurrentPage
      ) {
        ...ProductQuestions
      }
    }
    ... on GiftCardProduct {
      __typename
      productQuestions(
        pageSize: $questionsPageSize
        currentPage: $questionsCurrentPage
      ) {
        ...ProductQuestions
      }
    }
  }
}
    ${ProductQuestionsFragmentDoc}
${ProductQuestionFragmentDoc}
${ProductQuestionAnswerFragmentDoc}`

export const useQuestionsFilterQuery = <
  TData = QuestionsFilterQuery,
  TError = unknown,
>(
  variables: QuestionsFilterQueryVariables,
  options?: Omit<
    UseQueryOptions<QuestionsFilterQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<QuestionsFilterQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<QuestionsFilterQuery, TError, TData>({
    queryKey: ['QuestionsFilter', variables],
    queryFn: nextFetcher<QuestionsFilterQuery, QuestionsFilterQueryVariables>(
      QuestionsFilterDocument,
      variables,
    ),
    ...options,
  })
}

useQuestionsFilterQuery.document = QuestionsFilterDocument

useQuestionsFilterQuery.getKey = (variables: QuestionsFilterQueryVariables) => [
  'QuestionsFilter',
  variables,
]

export const useSuspenseQuestionsFilterQuery = <
  TData = QuestionsFilterQuery,
  TError = unknown,
>(
  variables: QuestionsFilterQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<QuestionsFilterQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      QuestionsFilterQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<QuestionsFilterQuery, TError, TData>({
    queryKey: ['QuestionsFilterSuspense', variables],
    queryFn: nextFetcher<QuestionsFilterQuery, QuestionsFilterQueryVariables>(
      QuestionsFilterDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseQuestionsFilterQuery.document = QuestionsFilterDocument

useSuspenseQuestionsFilterQuery.getKey = (
  variables: QuestionsFilterQueryVariables,
) => ['QuestionsFilterSuspense', variables]

useQuestionsFilterQuery.fetcher = (
  variables: QuestionsFilterQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<QuestionsFilterQuery, QuestionsFilterQueryVariables>(
    QuestionsFilterDocument,
    variables,
    options,
  )

export const RateProductQuestionDocument = `
    mutation RateProductQuestion($action: QuestionAnswerRateActionEnum!, $type: QuestionAnswerObjectTypeEnum!, $uid: String!) {
  rateProductQuestion(input: {uid: $uid, type: $type, action: $action}) {
    uid
    type
    action
  }
}
    `

export const useRateProductQuestionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RateProductQuestionMutation,
    TError,
    RateProductQuestionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    RateProductQuestionMutation,
    TError,
    RateProductQuestionMutationVariables,
    TContext
  >({
    mutationKey: ['RateProductQuestion'],
    mutationFn: (variables?: RateProductQuestionMutationVariables) =>
      nextFetcher<
        RateProductQuestionMutation,
        RateProductQuestionMutationVariables
      >(RateProductQuestionDocument, variables)(),
    ...options,
  })
}

useRateProductQuestionMutation.fetcher = (
  variables: RateProductQuestionMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    RateProductQuestionMutation,
    RateProductQuestionMutationVariables
  >(RateProductQuestionDocument, variables, options)

export const CreateCustomerV2Document = `
    mutation CreateCustomerV2($input: CustomerCreateInput!) {
  createCustomerV2(input: $input) {
    customer {
      firstname
      id
    }
  }
}
    `

export const useCreateCustomerV2Mutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateCustomerV2Mutation,
    TError,
    CreateCustomerV2MutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateCustomerV2Mutation,
    TError,
    CreateCustomerV2MutationVariables,
    TContext
  >({
    mutationKey: ['CreateCustomerV2'],
    mutationFn: (variables?: CreateCustomerV2MutationVariables) =>
      nextFetcher<CreateCustomerV2Mutation, CreateCustomerV2MutationVariables>(
        CreateCustomerV2Document,
        variables,
      )(),
    ...options,
  })
}

useCreateCustomerV2Mutation.fetcher = (
  variables: CreateCustomerV2MutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<CreateCustomerV2Mutation, CreateCustomerV2MutationVariables>(
    CreateCustomerV2Document,
    variables,
    options,
  )

export const ReportProductQuestionDocument = `
    mutation ReportProductQuestion($uid: String!, $type: QuestionAnswerObjectTypeEnum!) {
  reportProductQuestion(input: {uid: $uid, type: $type}) {
    uid
    type
  }
}
    `

export const useReportProductQuestionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ReportProductQuestionMutation,
    TError,
    ReportProductQuestionMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    ReportProductQuestionMutation,
    TError,
    ReportProductQuestionMutationVariables,
    TContext
  >({
    mutationKey: ['ReportProductQuestion'],
    mutationFn: (variables?: ReportProductQuestionMutationVariables) =>
      nextFetcher<
        ReportProductQuestionMutation,
        ReportProductQuestionMutationVariables
      >(ReportProductQuestionDocument, variables)(),
    ...options,
  })
}

useReportProductQuestionMutation.fetcher = (
  variables: ReportProductQuestionMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    ReportProductQuestionMutation,
    ReportProductQuestionMutationVariables
  >(ReportProductQuestionDocument, variables, options)

export const ReviewsFilterDocument = `
    query ReviewsFilter($url: String!, $reviewPageSize: Int!, $reviewCurrentPage: Int!, $reviewFilter: String!) {
  route(url: $url) {
    ... on ProductInterface {
      __typename
      reviews(
        filter: $reviewFilter
        pageSize: $reviewPageSize
        currentPage: $reviewCurrentPage
      ) {
        items {
          average_rating
          created_at
          customer_avatar
          customer_points
          nickname
          rate_minus
          rate_plus
          review_id
          summary
          summary_rating
          text
          verified_purchase
          ratings_breakdown {
            name
            value
          }
          source {
            country_code
            country_flag
            id
            summary
            text
          }
        }
        page_info {
          current_page
          page_size
          total_pages
        }
      }
    }
  }
}
    `

export const useReviewsFilterQuery = <
  TData = ReviewsFilterQuery,
  TError = unknown,
>(
  variables: ReviewsFilterQueryVariables,
  options?: Omit<
    UseQueryOptions<ReviewsFilterQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ReviewsFilterQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<ReviewsFilterQuery, TError, TData>({
    queryKey: ['ReviewsFilter', variables],
    queryFn: nextFetcher<ReviewsFilterQuery, ReviewsFilterQueryVariables>(
      ReviewsFilterDocument,
      variables,
    ),
    ...options,
  })
}

useReviewsFilterQuery.document = ReviewsFilterDocument

useReviewsFilterQuery.getKey = (variables: ReviewsFilterQueryVariables) => [
  'ReviewsFilter',
  variables,
]

export const useSuspenseReviewsFilterQuery = <
  TData = ReviewsFilterQuery,
  TError = unknown,
>(
  variables: ReviewsFilterQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ReviewsFilterQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      ReviewsFilterQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<ReviewsFilterQuery, TError, TData>({
    queryKey: ['ReviewsFilterSuspense', variables],
    queryFn: nextFetcher<ReviewsFilterQuery, ReviewsFilterQueryVariables>(
      ReviewsFilterDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseReviewsFilterQuery.document = ReviewsFilterDocument

useSuspenseReviewsFilterQuery.getKey = (
  variables: ReviewsFilterQueryVariables,
) => ['ReviewsFilterSuspense', variables]

useReviewsFilterQuery.fetcher = (
  variables: ReviewsFilterQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<ReviewsFilterQuery, ReviewsFilterQueryVariables>(
    ReviewsFilterDocument,
    variables,
    options,
  )

export const StockSubscribeDocument = `
    mutation StockSubscribe($productId: String!, $email: String!) {
  AmxnotifStockSubscribe(input: {product_id: $productId, email: $email}) {
    message
    success
  }
}
    `

export const useStockSubscribeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    StockSubscribeMutation,
    TError,
    StockSubscribeMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    StockSubscribeMutation,
    TError,
    StockSubscribeMutationVariables,
    TContext
  >({
    mutationKey: ['StockSubscribe'],
    mutationFn: (variables?: StockSubscribeMutationVariables) =>
      nextFetcher<StockSubscribeMutation, StockSubscribeMutationVariables>(
        StockSubscribeDocument,
        variables,
      )(),
    ...options,
  })
}

useStockSubscribeMutation.fetcher = (
  variables: StockSubscribeMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<StockSubscribeMutation, StockSubscribeMutationVariables>(
    StockSubscribeDocument,
    variables,
    options,
  )

export const StoreConfigDocument = `
    query StoreConfig {
  storeConfig {
    cookie_restriction
    date_format
    gb_ribbon_enabled
    gb_ribbon_message
    gb_ribbon_date
    gb_ribbon_font_color
    gb_ribbon_background_color
    default_title
    base_url
    facebook_app_id
    head_includes
    head_shortcut_icon
    default_description
    default_display_currency_symbol
    default_display_currency_code
    locale
    checkout_currency {
      rate
      format
    }
    dognet_s2s {
      enabled
      account_id
      cookie_consent
    }
    website_switcher {
      ...WebsiteType
    }
  }
}
    ${WebsiteTypeFragmentDoc}`

export const useStoreConfigQuery = <TData = StoreConfigQuery, TError = unknown>(
  variables?: StoreConfigQueryVariables,
  options?: Omit<
    UseQueryOptions<StoreConfigQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<StoreConfigQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<StoreConfigQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['StoreConfig'] : ['StoreConfig', variables],
    queryFn: nextFetcher<StoreConfigQuery, StoreConfigQueryVariables>(
      StoreConfigDocument,
      variables,
    ),
    ...options,
  })
}

useStoreConfigQuery.document = StoreConfigDocument

useStoreConfigQuery.getKey = (variables?: StoreConfigQueryVariables) =>
  variables === undefined ? ['StoreConfig'] : ['StoreConfig', variables]

export const useSuspenseStoreConfigQuery = <
  TData = StoreConfigQuery,
  TError = unknown,
>(
  variables?: StoreConfigQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<StoreConfigQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      StoreConfigQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<StoreConfigQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['StoreConfigSuspense']
        : ['StoreConfigSuspense', variables],
    queryFn: nextFetcher<StoreConfigQuery, StoreConfigQueryVariables>(
      StoreConfigDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseStoreConfigQuery.document = StoreConfigDocument

useSuspenseStoreConfigQuery.getKey = (variables?: StoreConfigQueryVariables) =>
  variables === undefined
    ? ['StoreConfigSuspense']
    : ['StoreConfigSuspense', variables]

useStoreConfigQuery.fetcher = (
  variables?: StoreConfigQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<StoreConfigQuery, StoreConfigQueryVariables>(
    StoreConfigDocument,
    variables,
    options,
  )

export const SubscribeEmailToNewsletterDocument = `
    mutation SubscribeEmailToNewsletter($email: String!) {
  subscribeEmailToNewsletter(email: $email) {
    status
  }
}
    `

export const useSubscribeEmailToNewsletterMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SubscribeEmailToNewsletterMutation,
    TError,
    SubscribeEmailToNewsletterMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    SubscribeEmailToNewsletterMutation,
    TError,
    SubscribeEmailToNewsletterMutationVariables,
    TContext
  >({
    mutationKey: ['SubscribeEmailToNewsletter'],
    mutationFn: (variables?: SubscribeEmailToNewsletterMutationVariables) =>
      nextFetcher<
        SubscribeEmailToNewsletterMutation,
        SubscribeEmailToNewsletterMutationVariables
      >(SubscribeEmailToNewsletterDocument, variables)(),
    ...options,
  })
}

useSubscribeEmailToNewsletterMutation.fetcher = (
  variables: SubscribeEmailToNewsletterMutationVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<
    SubscribeEmailToNewsletterMutation,
    SubscribeEmailToNewsletterMutationVariables
  >(SubscribeEmailToNewsletterDocument, variables, options)

export const TopProductsDocument = `
    query TopProducts {
  categories(filters: {ids: {eq: "1865"}}) {
    items {
      products(sort: {bestsellers: DESC}) {
        items {
          ...ConfigurableProductListItem
          ...GiftCardProductListItem
        }
      }
    }
  }
}
    ${ConfigurableProductListItemFragmentDoc}
${BreadcrumbFragmentDoc}
${ConfigurableProductOptionFragmentDoc}
${ConfigurableProductVariantOptimizedFragmentDoc}
${ConfigurableProductVariantAttributeFragmentDoc}
${SimpleProductOptimizedFragmentDoc}
${ProductImageFragmentDoc}
${ProductPriceFragmentDoc}
${ProductLabelV2FragmentDoc}
${GiftCardProductListItemFragmentDoc}
${GiftCardProductAmountFragmentDoc}`

export const useTopProductsQuery = <TData = TopProductsQuery, TError = unknown>(
  variables?: TopProductsQueryVariables,
  options?: Omit<
    UseQueryOptions<TopProductsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<TopProductsQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<TopProductsQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['TopProducts'] : ['TopProducts', variables],
    queryFn: nextFetcher<TopProductsQuery, TopProductsQueryVariables>(
      TopProductsDocument,
      variables,
    ),
    ...options,
  })
}

useTopProductsQuery.document = TopProductsDocument

useTopProductsQuery.getKey = (variables?: TopProductsQueryVariables) =>
  variables === undefined ? ['TopProducts'] : ['TopProducts', variables]

export const useSuspenseTopProductsQuery = <
  TData = TopProductsQuery,
  TError = unknown,
>(
  variables?: TopProductsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<TopProductsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      TopProductsQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<TopProductsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['TopProductsSuspense']
        : ['TopProductsSuspense', variables],
    queryFn: nextFetcher<TopProductsQuery, TopProductsQueryVariables>(
      TopProductsDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseTopProductsQuery.document = TopProductsDocument

useSuspenseTopProductsQuery.getKey = (variables?: TopProductsQueryVariables) =>
  variables === undefined
    ? ['TopProductsSuspense']
    : ['TopProductsSuspense', variables]

useTopProductsQuery.fetcher = (
  variables?: TopProductsQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<TopProductsQuery, TopProductsQueryVariables>(
    TopProductsDocument,
    variables,
    options,
  )

export const TopProductsCategoryDocument = `
    query TopProductsCategory {
  category(id: 1865) {
    products {
      items {
        sku
      }
    }
  }
}
    `

export const useTopProductsCategoryQuery = <
  TData = TopProductsCategoryQuery,
  TError = unknown,
>(
  variables?: TopProductsCategoryQueryVariables,
  options?: Omit<
    UseQueryOptions<TopProductsCategoryQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      TopProductsCategoryQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useQuery<TopProductsCategoryQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['TopProductsCategory']
        : ['TopProductsCategory', variables],
    queryFn: nextFetcher<
      TopProductsCategoryQuery,
      TopProductsCategoryQueryVariables
    >(TopProductsCategoryDocument, variables),
    ...options,
  })
}

useTopProductsCategoryQuery.document = TopProductsCategoryDocument

useTopProductsCategoryQuery.getKey = (
  variables?: TopProductsCategoryQueryVariables,
) =>
  variables === undefined
    ? ['TopProductsCategory']
    : ['TopProductsCategory', variables]

export const useSuspenseTopProductsCategoryQuery = <
  TData = TopProductsCategoryQuery,
  TError = unknown,
>(
  variables?: TopProductsCategoryQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<TopProductsCategoryQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      TopProductsCategoryQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<TopProductsCategoryQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['TopProductsCategorySuspense']
        : ['TopProductsCategorySuspense', variables],
    queryFn: nextFetcher<
      TopProductsCategoryQuery,
      TopProductsCategoryQueryVariables
    >(TopProductsCategoryDocument, variables),
    ...options,
  })
}

useSuspenseTopProductsCategoryQuery.document = TopProductsCategoryDocument

useSuspenseTopProductsCategoryQuery.getKey = (
  variables?: TopProductsCategoryQueryVariables,
) =>
  variables === undefined
    ? ['TopProductsCategorySuspense']
    : ['TopProductsCategorySuspense', variables]

useTopProductsCategoryQuery.fetcher = (
  variables?: TopProductsCategoryQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<TopProductsCategoryQuery, TopProductsCategoryQueryVariables>(
    TopProductsCategoryDocument,
    variables,
    options,
  )

export const XsearchPopularDocument = `
    query XsearchPopular {
  xsearchPopular {
    query
  }
}
    `

export const useXsearchPopularQuery = <
  TData = XsearchPopularQuery,
  TError = unknown,
>(
  variables?: XsearchPopularQueryVariables,
  options?: Omit<
    UseQueryOptions<XsearchPopularQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<XsearchPopularQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<XsearchPopularQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['XsearchPopular']
        : ['XsearchPopular', variables],
    queryFn: nextFetcher<XsearchPopularQuery, XsearchPopularQueryVariables>(
      XsearchPopularDocument,
      variables,
    ),
    ...options,
  })
}

useXsearchPopularQuery.document = XsearchPopularDocument

useXsearchPopularQuery.getKey = (variables?: XsearchPopularQueryVariables) =>
  variables === undefined ? ['XsearchPopular'] : ['XsearchPopular', variables]

export const useSuspenseXsearchPopularQuery = <
  TData = XsearchPopularQuery,
  TError = unknown,
>(
  variables?: XsearchPopularQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<XsearchPopularQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      XsearchPopularQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<XsearchPopularQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['XsearchPopularSuspense']
        : ['XsearchPopularSuspense', variables],
    queryFn: nextFetcher<XsearchPopularQuery, XsearchPopularQueryVariables>(
      XsearchPopularDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseXsearchPopularQuery.document = XsearchPopularDocument

useSuspenseXsearchPopularQuery.getKey = (
  variables?: XsearchPopularQueryVariables,
) =>
  variables === undefined
    ? ['XsearchPopularSuspense']
    : ['XsearchPopularSuspense', variables]

useXsearchPopularQuery.fetcher = (
  variables?: XsearchPopularQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<XsearchPopularQuery, XsearchPopularQueryVariables>(
    XsearchPopularDocument,
    variables,
    options,
  )

export const XsearchDocument = `
    query Xsearch($text: String) {
  xsearch(search: $text) {
    brands {
      id
      image
      name
      url
    }
    categories {
      id
      image
      name
      url
    }
    products {
      id
      image
      name
      url
    }
  }
}
    `

export const useXsearchQuery = <TData = XsearchQuery, TError = unknown>(
  variables?: XsearchQueryVariables,
  options?: Omit<UseQueryOptions<XsearchQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<XsearchQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<XsearchQuery, TError, TData>({
    queryKey: variables === undefined ? ['Xsearch'] : ['Xsearch', variables],
    queryFn: nextFetcher<XsearchQuery, XsearchQueryVariables>(
      XsearchDocument,
      variables,
    ),
    ...options,
  })
}

useXsearchQuery.document = XsearchDocument

useXsearchQuery.getKey = (variables?: XsearchQueryVariables) =>
  variables === undefined ? ['Xsearch'] : ['Xsearch', variables]

export const useSuspenseXsearchQuery = <TData = XsearchQuery, TError = unknown>(
  variables?: XsearchQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<XsearchQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<XsearchQuery, TError, TData>['queryKey']
  },
) => {
  return useSuspenseQuery<XsearchQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['XsearchSuspense']
        : ['XsearchSuspense', variables],
    queryFn: nextFetcher<XsearchQuery, XsearchQueryVariables>(
      XsearchDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseXsearchQuery.document = XsearchDocument

useSuspenseXsearchQuery.getKey = (variables?: XsearchQueryVariables) =>
  variables === undefined ? ['XsearchSuspense'] : ['XsearchSuspense', variables]

useXsearchQuery.fetcher = (
  variables?: XsearchQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<XsearchQuery, XsearchQueryVariables>(
    XsearchDocument,
    variables,
    options,
  )

export const ProductDataDocument = `
    query ProductData($url: String!) {
  route(url: $url) {
    ...ConfigurableProduct
    ...GiftCardProduct
  }
}
    ${ConfigurableProductFragmentDoc}
${ConfigurableProductOptionFragmentDoc}
${ConfigurableProductVariantOptimizedFragmentDoc}
${ConfigurableProductVariantAttributeFragmentDoc}
${SimpleProductOptimizedFragmentDoc}
${ProductImageFragmentDoc}
${ProductPriceFragmentDoc}
${BreadcrumbFragmentDoc}
${ConfigurableProductVariantFragmentDoc}
${SimpleProductFragmentDoc}
${ProductLabelV2FragmentDoc}
${GiftCardProductFragmentDoc}
${GiftCardProductAmountFragmentDoc}`

export const useProductDataQuery = <TData = ProductDataQuery, TError = unknown>(
  variables: ProductDataQueryVariables,
  options?: Omit<
    UseQueryOptions<ProductDataQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ProductDataQuery, TError, TData>['queryKey']
  },
) => {
  return useQuery<ProductDataQuery, TError, TData>({
    queryKey: ['ProductData', variables],
    queryFn: nextFetcher<ProductDataQuery, ProductDataQueryVariables>(
      ProductDataDocument,
      variables,
    ),
    ...options,
  })
}

useProductDataQuery.document = ProductDataDocument

useProductDataQuery.getKey = (variables: ProductDataQueryVariables) => [
  'ProductData',
  variables,
]

export const useSuspenseProductDataQuery = <
  TData = ProductDataQuery,
  TError = unknown,
>(
  variables: ProductDataQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ProductDataQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      ProductDataQuery,
      TError,
      TData
    >['queryKey']
  },
) => {
  return useSuspenseQuery<ProductDataQuery, TError, TData>({
    queryKey: ['ProductDataSuspense', variables],
    queryFn: nextFetcher<ProductDataQuery, ProductDataQueryVariables>(
      ProductDataDocument,
      variables,
    ),
    ...options,
  })
}

useSuspenseProductDataQuery.document = ProductDataDocument

useSuspenseProductDataQuery.getKey = (variables: ProductDataQueryVariables) => [
  'ProductDataSuspense',
  variables,
]

useProductDataQuery.fetcher = (
  variables: ProductDataQueryVariables,
  options?: RequestInit['headers'],
) =>
  nextFetcher<ProductDataQuery, ProductDataQueryVariables>(
    ProductDataDocument,
    variables,
    options,
  )
