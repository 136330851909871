import { useEffect, useState } from 'react'
import md5 from 'md5'

import { CustomerBaseInfoQuery, useCustomerBaseInfoQuery } from '@/api'
import { consoleError } from '@/common/utils/console'
import { useRouter } from '@/common/hooks/use-router'
import { preserveCustomerSegment } from '../utils'
import { hasGraphqlAuthorizationError } from '@/providers/cart/cart.utils'

interface HandleCustomerDataProps {
  customerToken: string | undefined
  guestToken: string | undefined
}

const isAuthPage = () => {
  const pathname = window.location.pathname

  if (pathname.includes('/sales')) {
    return true
  }

  if (pathname === '/customer/account/edit') {
    return true
  }

  if (pathname === '/giftcard/customer') {
    return true
  }

  if (pathname === '/customer/account') {
    return true
  }

  if (pathname.includes('/customer/address')) {
    return true
  }

  if (pathname === '/newsletter/manage') {
    return true
  }

  return false
}

/**
 * hook that handles the customer data, it also works as a signal for auth load to be completed.
 * @param customerToken
 * @param guestToken
 */
export const useHandleCustomerData = ({
  customerToken,
  guestToken,
}: HandleCustomerDataProps) => {
  const [loaded, setLoaded] = useState(false)
  const [customerData, setCustomerData] =
    useState<CustomerBaseInfoQuery['customer']>()
  const { navigate } = useRouter()

  const { isFetched, data, error } = useCustomerBaseInfoQuery(
    {},
    {
      enabled: !!customerToken,
    },
  )

  useEffect(() => {
    if (guestToken) {
      setLoaded(true)
    }

    if (customerToken && isFetched) {
      if (data?.['errors'] ?? error) {
        consoleError('useHandleCustomerData', { data, error, customerToken })

        // TODO: remove once the auth page is fully migrated to Next.js
        if (!process.env.NEXT_PUBLIC_AUTH) {
          if (isAuthPage() && hasGraphqlAuthorizationError(data?.['errors'])) {
            window.location.reload() // will hit the middleware, reset auth and redirect to login
          }
        }
      } else {
        const customerData = data?.customer ?? undefined

        // for caching purposes, store md5 hash of customer group name as cookie customerSegment
        if (customerData?.group_code) {
          preserveCustomerSegment(md5(customerData.group_code))
        }

        setCustomerData(customerData)
        setLoaded(true)
      }
    }
  }, [customerToken, data, error, guestToken, isFetched, navigate])

  return { loaded, customerData }
}
