import { NextRequest, NextResponse } from 'next/server'

import { defaultCustomerSegment } from './middleware-constants'
import { getStoreCode, STORE_CODES } from '@/common/utils'
import { STORE_CODE_RECORD } from '@/common/constants'
import { Domain, StoreCodeType } from '@/common/types'
import { MiddlewareHeader } from '@/common/types/header-types'
import { Language } from '@/common/types/language-types'
import {
  getURLObjectSafely,
  handleMultipleSlashInPath,
  replaceFirstUaRuLocaleInUrl,
} from '@/common/utils/url-utils'
import { CookieKeys } from '@/common/utils/cookie-utils'

type SetCommonResponseHeadersProps = {
  href: string
  baseUrl: string
  pathname: string
  customerSegment: string
  xForwardedUrl?: URL
  response: NextResponse
  storeCode: StoreCodeType
}

export function setCommonResponseHeaders({
  href,
  baseUrl,
  response,
  pathname,
  xForwardedUrl,
  customerSegment,
  storeCode,
}: SetCommonResponseHeadersProps) {
  response.headers.set('BaseUrl', baseUrl)
  response.headers.set(MiddlewareHeader.Pathname, pathname)
  response.headers.set(
    MiddlewareHeader.OriginUrl,
    xForwardedUrl?.toString() ?? href,
  )
  response.headers.set(MiddlewareHeader.CustomerSegment, customerSegment)
  response.headers.set(MiddlewareHeader.RequestUrl, href)
  response.headers.set(
    MiddlewareHeader.XForwardedUrl,
    xForwardedUrl?.toString() ?? href,
  )
  response.headers.set(MiddlewareHeader.Store, storeCode)
}

export function resolveForwardedUrlWithFallback(
  forwardedUrl?: URL | string | undefined | null,
): string {
  return forwardedUrl
    ? forwardedUrl.toString().replace('http://', 'https://')
    : `${process.env.STORE_PROXY_URL}`
}

export function processMiddlewareHeaders(request: NextRequest) {
  // Get xForwardedUrl from the header, because request.url is always localhost
  // this information is needed to correctly set the storeCode of the application
  const xForwardedUrl = request.headers.get(MiddlewareHeader.XForwardedUrl)

  // Get url from xForwardedUrl, if xForwardedUrl is not specified (feature-env) use env url
  const forwardedUrl = getURLObjectSafely(
    resolveForwardedUrlWithFallback(xForwardedUrl),
  )

  const storeCode =
    (request.headers.get(MiddlewareHeader.Store) as StoreCodeType) ??
    getStoreCode(forwardedUrl?.toString())

  if (!STORE_CODE_RECORD[storeCode]) {
    throw new Error('Invalid storeCode')
  }

  const token =
    request.cookies.get(CookieKeys.NEXT_TOKEN)?.value ??
    request.cookies.get(CookieKeys.CUSTOMER_TOKEN)?.value
  const customerToken = token ? `Bearer ${token}` : undefined

  // if customer is logged in, but we do not have the segment yet, cache it under its token
  const customerSegment =
    request.cookies.get('customerSegment')?.value ??
    customerToken ??
    defaultCustomerSegment

  const { domain, lang: language, locale } = STORE_CODE_RECORD[storeCode]

  // prepare custom headers
  request.headers.set(MiddlewareHeader.XForwardedUrl, forwardedUrl.toString())
  request.headers.set(MiddlewareHeader.Store, storeCode)
  request.headers.set(MiddlewareHeader.RequestUrl, request.url)
  customerToken &&
    request.headers.set(MiddlewareHeader.Authorization, customerToken)

  return {
    forwardedUrl,
    storeCode,
    customerSegment,
    customerToken,
    locale,
    language,
    domain,
  }
}

export const isUA = (storeCode: StoreCodeType) =>
  storeCode === STORE_CODES[Domain.UA]

export const isRU = (storeCode: StoreCodeType) =>
  storeCode === STORE_CODES[Domain.RU]

export const isUaDomain = (storeCode: StoreCodeType): boolean => {
  return storeCode === STORE_CODES.ua || storeCode === STORE_CODES.ru
}

export const getUrlLocale = (language: Language) => {
  switch (language) {
    case Language.Uk:
      return Domain.UA
    case Language.Ru:
      return Domain.RU

    default:
      return language
  }
}

/**
 * @param url - request url to be rewritten in NextResponse
 * @param urlLocale - locale, e.g: `ua`, `sk`, etc.
 * @param subPath - optional part of the path - can be added both with `/`
 * symbol or without it. If passed `/category` or `category`, the url for
 * the category page is modified from `en/weight-loss-supplements` to
 * `en/category/weight-loss-supplements`.
 * @returns URL
 */
export const getRouteUrl = ({
  url,
  urlLocale,
  subPath = '',
}: {
  url: URL
  urlLocale: string
  subPath?: string
}) => {
  const newUrl = new URL(url)

  newUrl.pathname = handleMultipleSlashInPath(
    `${urlLocale}/${subPath}${url.pathname}`,
  )

  if (urlLocale === Domain.UA || urlLocale === Domain.RU) {
    const formattedUrl = new URL(replaceFirstUaRuLocaleInUrl(url.toString()))

    newUrl.pathname = handleMultipleSlashInPath(
      `${urlLocale}/${subPath}${formattedUrl.pathname}`,
    )
  }

  return newUrl
}

export const getCommonRequestHeaders = (
  requestHeaders: Headers,
): { [key: string]: string } => {
  const ip = requestHeaders.get(MiddlewareHeader.XRealIp)
  const ua = requestHeaders.get(MiddlewareHeader.UserAgent)
  const url = requestHeaders.get(MiddlewareHeader.XForwardedUrl)
  const origin = requestHeaders.get(MiddlewareHeader.OriginUrl)
  const store = requestHeaders.get(MiddlewareHeader.Store)
  const cookie = requestHeaders.get(MiddlewareHeader.Cookie)
  const auth = requestHeaders.get(MiddlewareHeader.Authorization)
  const segment = requestHeaders.get(MiddlewareHeader.CustomerSegment)
  const group = requestHeaders.get(MiddlewareHeader.Group)
  const cache = requestHeaders.get(MiddlewareHeader.Cache)
  const responseHeaders = {}

  if (ip) responseHeaders[MiddlewareHeader.XRealIp] = ip
  if (ua) responseHeaders[MiddlewareHeader.UserAgent] = ua
  if (url) responseHeaders[MiddlewareHeader.XForwardedUrl] = url
  if (origin) responseHeaders[MiddlewareHeader.OriginUrl] = origin
  if (store) responseHeaders[MiddlewareHeader.Store] = store
  if (cookie) responseHeaders[MiddlewareHeader.Cookie] = cookie
  if (auth) responseHeaders[MiddlewareHeader.Authorization] = auth
  if (segment) responseHeaders[MiddlewareHeader.CustomerSegment] = segment
  if (group) responseHeaders[MiddlewareHeader.Group] = group
  if (cache) responseHeaders[MiddlewareHeader.Cache] = cache

  return responseHeaders
}
