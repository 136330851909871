'use client'

import { useRouter as useNextRouter } from 'next/navigation'

import { useNavigation } from './use-navigation'
import { SkeletonLoader } from '@/providers/skeleton/skeleton-context'

type RouterProps = {
  type?: 'push' | 'replace'
  keepUrl?: boolean
  skeletonLoader?: SkeletonLoader
  scroll?: boolean
}

export const useRouter = () => {
  const { push, replace } = useNextRouter()
  const { getUrl, triggerSkeletonLoader } = useNavigation()

  return {
    navigate: (href: URL | string, props?: RouterProps) => {
      const {
        type = 'push',
        keepUrl = false,
        skeletonLoader,
        ...options
      } = props ?? {}
      const url = getUrl(href, keepUrl)

      if (skeletonLoader) {
        triggerSkeletonLoader(skeletonLoader)
      }

      if (type === 'replace') {
        replace(url, options)
      } else {
        push(url, options)
      }
    },
  }
}
