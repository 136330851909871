'use client'

import { createContext, useContext, useState, useRef, useEffect } from 'react'

export interface SkeletonContextType {
  target: SkeletonTargetType
  isSkeletonOpen: boolean
  handleCloseSkeleton: () => void
  productSkeletonName: string
  handleOpenSkeleton: ({ target, data }: SkeletonLoader) => void
  setProductSkeletonName: (productName: string) => void
}

export const SkeletonContext = createContext<SkeletonContextType>({
  target: null,
  isSkeletonOpen: false,
  productSkeletonName: '',
  handleOpenSkeleton: () => {},
  handleCloseSkeleton: () => {},
  setProductSkeletonName: () => {},
} as SkeletonContextType)

export const useSkeletonContext = () => useContext(SkeletonContext)

type SkeletonTargetType = 'product' | 'homepage' | null

type SkeletonDataType = { productName: string }

export type SkeletonLoader = {
  target: SkeletonTargetType
  data?: SkeletonDataType
}

const SKELETON_DELAY = 300

export const SkeletonContextProvider = ({ children }) => {
  const [isSkeletonOpen, setIsSkeletonOpen] = useState(false)
  const [productSkeletonName, setProductSkeletonName] = useState('')
  const [target, setTarget] = useState<SkeletonTargetType>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleOpenSkeleton = ({ target, data }: SkeletonLoader) => {
    if (data && target === 'product' && 'productName' in data) {
      setProductSkeletonName(data.productName)
    }
    setTarget(target)
    timeoutRef.current = setTimeout(() => {
      setIsSkeletonOpen(true)
    }, SKELETON_DELAY)
  }

  const handleCloseSkeleton = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    setIsSkeletonOpen(false)
  }

  useEffect(() => {
    return () => {
      setTarget(null)
      setIsSkeletonOpen(false)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <SkeletonContext.Provider
      value={{
        target,
        isSkeletonOpen,
        handleCloseSkeleton,
        productSkeletonName,
        handleOpenSkeleton,
        setProductSkeletonName,
      }}
    >
      {children}
    </SkeletonContext.Provider>
  )
}
