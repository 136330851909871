import { isServer } from '@tanstack/react-query'

import { getStoreCode, IS_DEV } from '@/utils'
import { GraphQlErrorsResponse, GraphQlResponse } from './types'
import { consoleError, consoleLog } from '@/common/utils/console'
import { minifyGraphQLOperation } from '@/common/utils/gqlmin'
import { getURLObjectSafely } from '@/common/utils/url-utils'
import { MiddlewareHeader } from '@/common/types/header-types'
import { CookieKeys } from '@/common/utils/cookie-utils'
import { getCommonRequestHeaders } from '@/middleware/middleware-utils'

/**
 * queries that cannot be cached - cart and customer related data
 */
const POST_QUERIES = [
  'query Cart($cartId: String!)',
  'query CustomerBaseInfo',
  'query CustomerCartId',
  'query CustomerOrder($orderId: Int!)',
  'query CustomerOrdersList',
  'query Xsearch($text: String)',
  'query CategoryPageProducts',
  'query GiftCardAccount($giftCardCode: String!)',
]

/**
 * this functions works as a custom fetch for every graphql request, either by directly using the hook generate by codegen
 * or by calling the exposed fetcher on the hook:
 *    const { data } = useXsearchPopularQuery()
 *    const data = await useXsearchPopularQuery.fetcher()
 *
 * The function handles every possible edge cases related to api calls, because this is the only place, that is called for every endpoint
 * There are 3 main use case, how is this function called:
 *  1. Client - React component - function is called from the browser of a client,
 *              it has the relative path specified and that is enough, nothing special has to happen for this call.
 *
 *  2. Server - Server component - function is called during the initial render of the page on the server side,
 *              server calls has to be called with the absolute path so this function append the LOCAL_URL which is the url of the middleware.
 *              Another thing, that needs to be embedded is the information in the headers, using the next/headers.
 *
 *  3. Server - middleware call - the last type of call is very similar to the call from the server component,
 *              the main difference, is that because the call starts directly from a middleware, the request is not processed by the nextjs yet, and we cannot use the next/headers.
 *              Because of that, it is necessary to pass this information into the nextFetcher via the option props.
 *
 * Caching - every graphql call is modified into the GET method by a custom function, this way the cloudflare is able to cache it.
 *           Exceptions: mutations and queries listed in POST_QUERIES are excluded from caching.
 *
 *
 *
 * @param query
 * @param variables
 * @param options
 */
export const nextFetcher =
  <TData, TVariables>(
    query: string,
    variables?: TVariables,
    options?: Record<string, string>,
  ): (() => Promise<TData & GraphQlErrorsResponse>) =>
  async () => {
    let url = getURLObjectSafely('')
    url.pathname = IS_DEV ? '/api/graphql' : '/graphql'
    let storeCode = ''
    let serverHeaders: Record<string, string> = {}
    const { ...queryOptions } = options ?? {}
    let xUrl = ''

    if (isServer) {
      /**
       *  This is one of the most unconventional parts in the project. You cannot call, or even import next/headers outside the server components.
       *  because of this, and the fact, that next fetcher is called everywhere across the app, it is necessary to lazy load headers, inside the (isServer) condition.
       *  Otherwise, the project cannot run correctly.
       *  This is a potential source of memory leaks, and it has to be investigated.
       *  If the memory leak is valid, there is an option to rewrite the logic by sending the headers via the options, the same way as for middleware calls.
       */
      const { headers, cookies } = await import('next/headers')

      serverHeaders = getCommonRequestHeaders(headers())
      xUrl = headers().get(MiddlewareHeader.XForwardedUrl) ?? ''

      const token =
        cookies().get(CookieKeys.NEXT_TOKEN)?.value ??
        cookies().get(CookieKeys.CUSTOMER_TOKEN)?.value

      const phpSessionId = cookies().get(CookieKeys.PHP_SESSION_ID)?.value

      if (token)
        serverHeaders[MiddlewareHeader.Authorization] = `Bearer ${token}`

      if (phpSessionId)
        serverHeaders[
          MiddlewareHeader.Cookie
        ] = `${CookieKeys.PHP_SESSION_ID}=${phpSessionId};`

      url = new URL(xUrl ?? 'http://localhost:3000')
      url.pathname = IS_DEV ? '/api/graphql' : '/graphql'
      storeCode = getStoreCode(xUrl ?? '')
    } else {
      if (window && window.location.href) {
        storeCode = getStoreCode(window.location.href)
      }
    }

    const isPOST =
      query.includes('mutation ') ||
      POST_QUERIES.some((postQuery) => query.includes(postQuery))

    if (!isPOST) {
      const minifiedQuery = minifyGraphQLOperation(query)
      const gqlParams = new URLSearchParams()

      gqlParams.append('query', minifiedQuery)

      if (variables) {
        gqlParams.append(
          'variables',
          minifyGraphQLOperation(JSON.stringify(variables)),
        )
      }

      url.search = gqlParams.toString()
    }

    const fetchUrl = isServer ? url : `${url.pathname}?${url.search.toString()}`

    const start = Date.now()
    let response
    try {
      response = await fetch(fetchUrl, {
        method: isPOST ? 'POST' : 'GET',
        headers: {
          ...(isPOST ? { 'Content-Type': 'application/json' } : {}),
          [MiddlewareHeader.Store]: storeCode,
          ...serverHeaders,
          ...queryOptions,
        },
        body: isPOST
          ? JSON.stringify({
              query,
              variables,
            })
          : undefined,
      })

      const end = Date.now()
      const time = end - start

      if (time > 5000) {
        consoleLog('next-fetcher - time', {
          status: response?.status,
          time,
          storeCode,
          serverHeaders,
          queryOptions,
          fetchUrl,
          body: isPOST
            ? JSON.stringify({
                query,
                variables,
              })
            : undefined,
        })
      }
    } catch (e) {
      const end = Date.now()
      const time = end - start

      consoleError('next-fetcher - fetch', {
        status: response?.status,
        time,
        storeCode,
        serverHeaders,
        queryOptions,
        fetchUrl,
        body: isPOST
          ? JSON.stringify({
              query,
              variables,
            })
          : undefined,
        e,
      })

      return {
        errors: e,
      }
    }

    /**
     * Process the json value according to what hooks from codegen want
     * This is another interesting part, because the codegen generates types of hooks that require as a response direct TData.
     * It is necessary to handle the response directly inside the nextFetcher.
     * Happy case is that the nextFetcher just returns the json.data
     * The problem is with error handling, sometimes response returns the errors array that has to be somehow processed
     * because of this, the code throws an error with stringified errors as a message.
     * With this approach it is possible to catch this error and handle the json.errors according to the specific case.
     * For example: processFormErrors, processAddToCartErrors
     */
    let json: GraphQlResponse<TData> | any = {}
    try {
      json = await response.json()
    } catch (error) {
      const end = Date.now()
      const time = end - start
      const errors = {
        status: response?.status,
        time,
        storeCode,
        serverHeaders,
        queryOptions,
        fetchUrl,
        body: isPOST
          ? JSON.stringify({
              query,
              variables,
            })
          : undefined,
        error,
      }
      consoleError('next-fetcher.ts', errors)

      return {
        errors: errors,
      }
    }

    if (json.errors || !json.data) {
      const end = Date.now()
      const time = end - start
      const errors = {
        status: response?.status,
        time,
        storeCode,
        serverHeaders,
        queryOptions,
        fetchUrl,
        body: isPOST
          ? JSON.stringify({
              query,
              variables,
            })
          : undefined,
        error: json.errors,
      }

      consoleError('next-fetcher.ts', errors)

      return {
        ...json.data,
        errors: json.errors,
      }
    }

    return json.data
  }
