'use client'

import {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
  useEffect,
  useState,
  useCallback,
} from 'react'

import { CustomerBaseInfoQuery, fetchGuestToken } from '@/api'
import { useHandleCustomerData } from './hooks'
import { getCustomerToken } from './utils'
import { StoreCodeType } from '@/common/types'
import { HttpMethod } from '@/common/api/rest/fetch'
import { consoleError } from '@/common/utils/console'

interface AuthContext {
  isLoggedIn: boolean
  loaded: boolean
  guestToken: string | undefined
  customerToken: string | undefined
  customerData: CustomerBaseInfoQuery['customer']
}

export const AuthContext = createContext<AuthContext>({
  isLoggedIn: false,
  loaded: false,
  guestToken: undefined,
  customerToken: undefined,
  customerData: undefined,
})

/**
 * The Provider handles users token and customer data
 * @param children
 * @constructor
 */
export const AuthContextProvider = ({
  storeCode,
  children,
}: { storeCode: StoreCodeType } & PropsWithChildren) => {
  const [guestToken, setGuestToken] = useState<string | undefined>(undefined)
  const customerToken = getCustomerToken()

  const fetchNewGuestToken = useCallback(async () => {
    try {
      const res = await fetchGuestToken()

      if (res.error || !res.data) {
        fetch(`/api/v1/add-logs`, {
          method: HttpMethod.POST,
          body: JSON.stringify({
            place: 'auth-context.tsx [fetchNewGuestToken][try]',
            context: {
              message: 'Failed to fetch guest token',
              data: res.data,
              storeCode,
              customerToken,
              guestToken,
              cause: res.error,
            },
          }),
        })
      } else {
        consoleError('auth-context.tsx [fetchNewGuestToken]', {
          res,
        })
        setGuestToken(res.data)
      }
    } catch (e) {
      fetch(`/api/v1/add-logs`, {
        method: HttpMethod.POST,
        body: JSON.stringify({
          place: 'auth-context.tsx [fetchNewGuestToken][catch]',
          context: {
            message: 'Failed to fetch guest token',
            storeCode,
            customerToken,
            guestToken,
            cause: {
              message: e?.message,
              cause: e?.cause,
            },
          },
        }),
      })
    }
  }, [customerToken, guestToken, storeCode])

  useEffect(() => {
    if (!guestToken && !customerToken) {
      fetchNewGuestToken()
    }
  }, [customerToken, fetchNewGuestToken, guestToken])

  const { loaded, customerData } = useHandleCustomerData({
    customerToken,
    guestToken: guestToken || undefined,
  })

  const contextValue: AuthContext = useMemo(
    () => ({
      isLoggedIn: !!customerData,
      loaded,
      guestToken,
      customerToken,
      customerData,
    }),
    [loaded, guestToken, customerToken, customerData],
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
