import { isUA } from '@/middleware/middleware-utils'
import { StoreCodeType } from '../types/store-code'

/**
 * Adds a slash to the URL if the storecode is `ua`.
 * This is needed for the `ua` homepage.
 */
export const addSlashToUaHomepagePathname = (
  url: string,
  storeCode: StoreCodeType,
): string => {
  if (isUA(storeCode)) {
    return `${url}/`
  }

  return url
}

/**
 * Adds the `uk` locale to the URL if the storecode is `ua`.
 * This is needed for the `ua` blog. Ru is /blog
 */
export const addUaBlogLocale = (
  url: string,
  storeCode: StoreCodeType,
): string => {
  if (isUA(storeCode)) {
    return url + '/uk'
  }

  return url
}
