import { isServer } from '@tanstack/react-query'

import { MiddlewareHeader } from '@/common/types/header-types'
import { consoleError } from '@/common/utils/console'
import { CookieKeys } from '@/common/utils/cookie-utils'
import { getCommonRequestHeaders } from '@/middleware/middleware-utils'

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export type RestHeaders = {
  storeCode?: string
  token?: string
  xApiKey?: string
  [key: string]: string | undefined
}

type Error = {
  message: string
}

export type GraphqlPayload<T> = {
  query: string
  variables?: T
}

const getUrl = (url: string, xUrl: string, isServer: boolean) => {
  if (isServer) {
    if (url?.startsWith('http')) {
      return url
    } else {
      try {
        if (!xUrl) {
          throw new Error('xUrl is not defined')
        }

        return new URL(url, xUrl).toString()
      } catch (error) {
        consoleError('rest-fetcher - getUrl', {
          url,
          xUrl,
        })
      }

      return url
    }
  }

  return url
}

export const restFetch = async <RESPONSE, PROPS = void>(
  url: string,
  method: HttpMethod = HttpMethod.GET,
  headers: RestHeaders = {},
  data?: PROPS,
  next?: NextFetchRequestConfig,
  isCache: boolean = false,
): Promise<any> => {
  let response: any
  const { storeCode, token, xApiKey, ...restHeaders } = headers
  let serverHeaders: Record<string, string> = {}
  let xUrl = ''

  try {
    if (isServer) {
      const { headers, cookies } = await import('next/headers')

      serverHeaders = getCommonRequestHeaders(headers())

      const token =
        cookies().get(CookieKeys.NEXT_TOKEN)?.value ??
        cookies().get(CookieKeys.CUSTOMER_TOKEN)?.value

      const phpSessionId = cookies().get(CookieKeys.PHP_SESSION_ID)?.value

      xUrl = serverHeaders[MiddlewareHeader.XForwardedUrl]

      if (token)
        serverHeaders[MiddlewareHeader.Authorization] = `Bearer ${token}`

      if (phpSessionId)
        serverHeaders[
          MiddlewareHeader.Cookie
        ] = `${CookieKeys.PHP_SESSION_ID}=${phpSessionId};`
    }

    const fetchUrl = getUrl(url, xUrl, isServer)

    response = await fetch(fetchUrl, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(storeCode ? { [MiddlewareHeader.Store]: storeCode } : {}),
        ...(xApiKey ? { 'x-api-key': xApiKey } : {}),
        ...(token
          ? { [MiddlewareHeader.Authorization]: `Bearer ${token}` }
          : {}),
        ...serverHeaders,
        ...restHeaders,
      },
      body: data ? JSON.stringify(data) : undefined,
      next,
      ...(!isCache && { cache: 'no-store' }),
    })

    if (!response.ok) {
      const error = (await response.json()) as Error
      return { error }
    }

    const responseData = (await response.json()) as RESPONSE
    return { data: responseData }
  } catch (error) {
    consoleError('rest-fetcher - fetch', {
      status: response?.status,
      url,
      headers,
    })
    throw new Error(`Network error: ${(error as Error).message}`)
  }
}
