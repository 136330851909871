import { useState, useEffect, useCallback } from 'react'
import { useTranslations } from 'next-intl'

import {
  AddConfigurableProductToCartMutation,
  AddGiftCardToCartMutation,
  GraphQlErrorsResponse,
  useAddConfigurableProductToCartMutation,
  useAddGiftCardToCartMutation,
} from '@/api'
import { FlashMessage, useFlashMessagesContext } from '@/providers'
import {
  CartErrors,
  ExtensionErrors,
  processAddToCartErrors,
  processAddToCartRawErrors,
} from './process-add-to-cart-errors'
import { preserveMagentoStorageInvalidation } from '../utils'
import {
  containsGraphqlErrorResponseSpecificCategory,
  handleCartGraphqlError,
} from '../cart.utils'
import { AddToCartData } from '../cart.types'
import { StoreCodeType } from '@/common/types'
import { HttpMethod } from '@/common/api/rest/fetch'

export const useAddToCart = (storeCode: StoreCodeType) => {
  const t = useTranslations('FlashMessage')
  const [isCartFetchError, setIsCartFetchError] = useState(false)
  const [isAddingToCart, setIsAddingToCart] = useState(false)

  const { addFlashMessage } = useFlashMessagesContext()

  const { mutateAsync: addConfigurableProduct } =
    useAddConfigurableProductToCartMutation({
      retry: 2,
      retryDelay: 1000,
    })

  const { mutateAsync: addGiftCard } = useAddGiftCardToCartMutation({
    retry: 2,
    retryDelay: 1000,
  })

  const handleOnAddGiftCardToCart = useCallback(
    (
      {
        cartId,
        quantity,
        sku,
        selectedOptions,
        refreshTokens,
      }: {
        cartId: string
        quantity: number
        sku: string
        selectedOptions?: string[]
        refreshTokens: (newToken: string) => void
      },
      retries: number,
      internalErrorTranslation?: string,
    ) => {
      if (retries <= 0) {
        throw new Error(t('errorAddCardToCart'))
      } else {
        return addGiftCard({
          cartId,
          cartItems: [{ quantity, sku, selected_options: selectedOptions }],
        })
          .then((res: AddGiftCardToCartMutation & GraphQlErrorsResponse) => {
            if (
              containsGraphqlErrorResponseSpecificCategory(
                [
                  ExtensionErrors.Authorization,
                  ExtensionErrors.NoSuchEntity,
                  ExtensionErrors.Internal,
                ],
                res.errors,
              )
            ) {
              return handleCartGraphqlError(
                { cartId, quantity, sku, selectedOptions, refreshTokens },
                ({ cartId: newCartId, ...rest }) => {
                  refreshTokens(newCartId)
                  return handleOnAddGiftCardToCart(
                    {
                      ...rest,
                      cartId: newCartId,
                    },
                    retries - 1,
                  )
                },
              )
            } else if (res.errors) {
              fetch(`/api/v1/add-logs`, {
                method: HttpMethod.POST,
                body: JSON.stringify({
                  place: 'use-add-to-cart.tsx [addGiftCard]',
                  context: {
                    message: 'Failed at handleOnAddGiftCardToCart',
                    storeCode,
                    cartId,
                    cartItems: {
                      quantity,
                      sku,
                      selectedOptions,
                    },
                    errors: res.errors,
                  },
                }),
              })

              return processAddToCartErrors(
                res.errors as CartErrors[],
                internalErrorTranslation,
              )
            } else {
              return [] as FlashMessage[]
            }
          })
          .catch((error) => {
            fetch(`/api/v1/add-logs`, {
              method: HttpMethod.POST,
              body: JSON.stringify({
                place: 'use-add-to-cart.tsx [addGiftCard][catch]',
                context: {
                  message: 'Failed at handleOnAddGiftCardToCart',
                  storeCode,
                  cartId,
                  cartItems: {
                    quantity,
                    sku,
                    selectedOptions,
                  },
                  errors: error,
                },
              }),
            })
            throw new Error(error)
          })
      }
    },
    [addGiftCard, storeCode, t],
  )

  const handleOnAddConfigurableVariantToCart = useCallback(
    (
      {
        cartId,
        quantity,
        sku,
        parentSku,
        refreshTokens,
      }: {
        cartId: string
        quantity: number
        sku: string
        parentSku?: string
        refreshTokens: (newToken: string) => void
      },
      retries: number,
      internalErrorTranslation?: string,
    ) => {
      if (retries <= 0) {
        throw new Error(t('errorAddProductToCart'))
      } else {
        return addConfigurableProduct({
          cartId,
          cartItems: [{ parent_sku: parentSku, data: { quantity, sku } }],
        })
          .then(
            (
              res: AddConfigurableProductToCartMutation & GraphQlErrorsResponse,
            ) => {
              if (
                containsGraphqlErrorResponseSpecificCategory(
                  ['graphql-authorization', 'graphql-no-such-entity'],
                  res.errors,
                )
              ) {
                return handleCartGraphqlError(
                  { cartId, quantity, sku, parentSku, refreshTokens },
                  ({ cartId: newCartId, ...rest }) => {
                    refreshTokens(newCartId)
                    return handleOnAddConfigurableVariantToCart(
                      {
                        ...rest,
                        cartId: newCartId,
                      },
                      retries - 1,
                    )
                  },
                )
              } else if (res.errors) {
                fetch(`/api/v1/add-logs`, {
                  method: HttpMethod.POST,
                  body: JSON.stringify({
                    place: 'use-add-to-cart.tsx [addConfigurableProduct]',
                    context: {
                      message: 'Failed at handleOnAddConfigurableVariantToCart',
                      storeCode,
                      cartId,
                      cartItems: {
                        parent_sku: parentSku,
                        data: { quantity, sku },
                      },
                      errors: res.errors,
                    },
                  }),
                })

                return processAddToCartErrors(
                  res.errors as CartErrors[],
                  internalErrorTranslation,
                )
              } else {
                return (res.addConfigurableProductsToCart?.messages ?? []).map(
                  (message) => ({
                    severity: message?.type as FlashMessage['severity'],
                    text: message?.message as string,
                  }),
                )
              }
            },
          )
          .catch((error) => {
            fetch(`/api/v1/add-logs`, {
              method: HttpMethod.POST,
              body: JSON.stringify({
                place: 'use-add-to-cart.tsx [addConfigurableProduct][catch]',
                context: {
                  message: 'Failed at handleOnAddConfigurableVariantToCart',
                  storeCode,
                  cartId,
                  cartItems: {
                    parent_sku: parentSku,
                    data: { quantity, sku },
                  },
                  cause: {
                    message: error?.message,
                    cause: error?.cause,
                  },
                },
              }),
            })
            throw new Error(error)
          })
      }
    },
    [addConfigurableProduct, storeCode, t],
  )

  const addToCart = async ({
    sku,
    cartId,
    quantity,
    parentSku,
    isGiftCard,
    productName,
    selectedOptions,
    onSuccess,
    refreshTokens,
  }: AddToCartData) => {
    try {
      setIsCartFetchError(false)
      setIsAddingToCart(true)
      const messages: FlashMessage[] = await (isGiftCard
        ? handleOnAddGiftCardToCart(
            {
              cartId,
              quantity,
              sku,
              selectedOptions,
              refreshTokens,
            },
            1,
            t('errorAddProductToCart'),
          )
        : handleOnAddConfigurableVariantToCart(
            {
              cartId,
              quantity,
              sku,
              parentSku,
              refreshTokens,
            },
            1,
            t('errorAddProductToCart'),
          ))

      // prepare first message that is custom
      const [text1, link, text2] = t('addToCart')
        .replace('@productName', productName)
        .split('#')

      const firstMessage = [
        text1,
        `<a href="/checkout/cart/">${link}</a>`,
        text2,
      ].join('')

      const isError = messages.some((message) => message.severity === 'error')

      if (isError) {
        setIsCartFetchError(true)
        messages.forEach((message) => addFlashMessage(message))
        fetch(`/api/v1/add-logs`, {
          method: HttpMethod.POST,
          body: JSON.stringify({
            place: 'use-add-to-cart.tsx [addToCart]',
            context: {
              message: `Failed at ${
                isGiftCard
                  ? 'handleOnAddGiftCardToCart'
                  : 'handleOnAddConfigurableVariantToCart'
              }`,
              storeCode,
              sku,
              cartId,
              quantity,
              parentSku,
              isGiftCard,
              productName,
              selectedOptions,
              cause: messages,
            },
          }),
        })
      } else {
        // show messages
        addFlashMessage({
          severity: 'success',
          text: firstMessage,
        })
        onSuccess?.()
      }
      setIsAddingToCart(false)
    } catch (error) {
      fetch(`/api/v1/add-logs`, {
        method: HttpMethod.POST,
        body: JSON.stringify({
          place: 'use-add-to-cart.tsx [addToCart][catch]',
          context: {
            message: `Failed at ${
              isGiftCard
                ? 'handleOnAddGiftCardToCart'
                : 'handleOnAddConfigurableVariantToCart'
            }`,
            storeCode,
            sku,
            cartId,
            quantity,
            parentSku,
            isGiftCard,
            productName,
            selectedOptions,
            cause: {
              message: error?.message,
              cause: error?.cause,
            },
          },
        }),
      })

      const errorMessages = processAddToCartRawErrors(
        error.message,
        t('errorAddProductToCart'),
      )
      errorMessages.forEach((message) => addFlashMessage(message))
      setIsCartFetchError(true)
    } finally {
      setIsAddingToCart(false)
    }
  }

  /**
   * add info for magneto cart reload into the LocalStorage with structure:
   * mage-cache-storage-section-invalidation: {"cart": ${currentTimestamp}}
   */
  useEffect(() => {
    const now = new Date().getTime()
    preserveMagentoStorageInvalidation(now)
  }, [])

  return {
    addToCart,
    isAddingToCart,
    isCartFetchError,
  }
}
