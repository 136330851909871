'use client'

import { useStoreContext } from '@/providers/store/store-context'
import {
  SkeletonLoader,
  useSkeletonContext,
} from '@/providers/skeleton/skeleton-context'
import { StoreCodeType } from '../types'
import {
  isBlog,
  isHomepage,
  getUrl as getUrlWithoutLocale,
} from '../utils/url-utils'
import {
  addSlashToUaHomepagePathname,
  addUaBlogLocale,
} from '../utils/store.exceptions'

const getUrl = (
  storeCode: StoreCodeType,
  baseUrl: string,
  href?: string,
  keepUrl?: boolean,
): string => {
  if (!href) {
    return baseUrl
  }

  if (keepUrl) {
    return href
  }

  const url = getUrlWithoutLocale(baseUrl, href)

  if (isHomepage(url)) {
    return addSlashToUaHomepagePathname(url, storeCode)
  }

  if (isBlog(url)) {
    return addUaBlogLocale(url, storeCode)
  }

  return url
}

export type NavigationProps = {
  href?: URL | string
  keepUrl?: boolean
}

export const useNavigation = (): {
  getUrl: (href: URL | string, keepUrl?: boolean) => string
  triggerSkeletonLoader: (skeletonLoader: SkeletonLoader) => void
} => {
  const {
    storeCode,
    storeConfig: { baseUrl },
  } = useStoreContext()
  const { handleOpenSkeleton } = useSkeletonContext()

  return {
    getUrl: (href, keepUrl) =>
      getUrl(storeCode, baseUrl, href?.toString(), keepUrl),
    triggerSkeletonLoader: (skeletonLoader) => {
      handleOpenSkeleton(skeletonLoader)
    },
  }
}
