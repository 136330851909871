'use client'

import { PropsWithChildren, useState } from 'react'
import { QueryClientProvider, HydrationBoundary } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { queryClient as client } from '@/services'
import { StoreCodeType, StoreConfigType } from '@/common/types'
import { AuthContextProvider } from './auth'
import { CartContextProvider } from './cart'
import { ReCaptchaProvider } from './re-captcha'
import { FlashMessagesProvider } from './flash-messages'
import { FullScreenSpinnerProvider } from './full-screen-spinner'
import { UiContextProvider } from './ui/ui-context'
import { SkeletonContextProvider } from './skeleton/skeleton-context'
import { StoreContextProvider } from './store'

interface ProvidersProps {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

//page data context
export function Providers({
  children,
  storeCode,
  storeConfig,
}: PropsWithChildren<ProvidersProps>) {
  const [queryClient] = useState(() => client)

  return (
    <StoreContextProvider storeCode={storeCode} storeConfig={storeConfig}>
      <FlashMessagesProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <HydrationBoundary>
            <ReCaptchaProvider>
              <SkeletonContextProvider>
                <FullScreenSpinnerProvider>
                  <AuthContextProvider storeCode={storeCode}>
                    <CartContextProvider
                      storeCode={storeCode}
                      storeConfig={storeConfig}
                    >
                      <UiContextProvider>{children}</UiContextProvider>
                    </CartContextProvider>
                  </AuthContextProvider>
                </FullScreenSpinnerProvider>
              </SkeletonContextProvider>
            </ReCaptchaProvider>
          </HydrationBoundary>
        </QueryClientProvider>
      </FlashMessagesProvider>
    </StoreContextProvider>
  )
}
