'use client'

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { CartItemFragment, CartQuery } from '@/api'
import { StoreCodeType, StoreConfigType } from '@/common/types'
import { useAddToCart } from './hooks'
import { useAuthContext } from '../auth'
import { AddToCartParams, AddToCartTrackingConfig } from './cart.types'
import { fetchCartData, fetchCartId, trackAddToCartEvent } from './cart.utils'
import { HttpMethod } from '@/common/api/rest/fetch'

type CartContext = {
  hasItems: boolean
  cartItems: CartItemFragment[]
  isCartFetchError: boolean
  isCartRefetching: boolean
  isAddingToCart: boolean
  addToCart?: (
    params: AddToCartParams,
    trackingConfig?: AddToCartTrackingConfig,
  ) => Promise<void>
  refetchCartData: () => void
}

export const CartContext = createContext<CartContext>({} as CartContext)

type CartContextProviderProps = {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

/**
 * Provider that handles the cart
 * @param storeConfig
 * @param children
 * @constructor
 */
export const CartContextProvider = ({
  storeCode,
  storeConfig,
  children,
}: PropsWithChildren<CartContextProviderProps>) => {
  const { customerToken, guestToken } = useAuthContext()
  const { isAddingToCart, addToCart, isCartFetchError } =
    useAddToCart(storeCode)

  const defaultCartId = guestToken ?? ''
  const [currCartId, setCurrCartId] = useState(defaultCartId)
  const [cartData, setCartData] = useState<CartQuery>()
  const [isCartRefetching, setIsCartRefetching] = useState(false)

  useEffect(() => {
    fetchCartId({
      customerToken,
      defaultCartId,
      onNewCartIdSet: setCurrCartId,
      storeCode,
    })
  }, [customerToken, defaultCartId, storeCode])

  // TODO: implement notification for user to know that there was a problem to load cart data from the backend
  useEffect(() => {
    if (currCartId) {
      fetchCartData(currCartId, setCartData)
    }
  }, [currCartId])

  const refetchCartData = useCallback(() => {
    setIsCartRefetching(true)

    if (currCartId.trim().length <= 0) {
      fetch(`/api/v1/add-logs`, {
        method: HttpMethod.POST,
        body: JSON.stringify({
          place: 'cart-context.ts [refetchCartData]',
          context: {
            message: 'Empty cart id',
            customerToken,
            guestToken,
            storeCode,
            defaultCartId,
            currCartId,
          },
        }),
      })
    }

    fetchCartData(currCartId, (data) => {
      setIsCartRefetching(false)
      setCartData(data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currCartId])

  const addToCartWithCartId = useCallback(
    async (
      {
        brand,
        category,
        parentSku,
        productName,
        sku,
        price,
        quantity,
        isGiftCard,
        selectedOptions,
        prodlistPlacement,
        productIds,
        onSuccess,
        configId,
        simpleId,
        productPosition,
      }: AddToCartParams,
      trackingConfig?: AddToCartTrackingConfig,
    ) => {
      let latestCartId = currCartId
      const productInfo: AddToCartParams = {
        productName: productName ?? '',
        parentSku,
        sku: sku ?? '',
        quantity: quantity ?? 1,
        brand,
        price,
        category,
        selectedOptions,
        isGiftCard,
        configId,
        simpleId,
      }

      await addToCart({
        cartId: currCartId,
        refreshTokens: (token) => {
          latestCartId = token
          setCurrCartId(token)
        },
        onSuccess: () => {
          onSuccess?.()

          if (trackingConfig?.skipTracking) {
            return
          }

          trackAddToCartEvent(
            {
              ...productInfo,
              prodlistPlacement,
              productIds,
              productPosition,
              currency: storeConfig.currency,
            },
            Boolean(trackingConfig?.addingRecommendedProduct),
          )
        },
        ...productInfo,
      })

      /*
       * this statement is because when in refreshToken we change cartId
       * we do not want to refetch cart data again
       */
      setIsCartRefetching(true)

      if (latestCartId.trim().length <= 0) {
        fetch(`/api/v1/add-logs`, {
          method: HttpMethod.POST,
          body: JSON.stringify({
            place: 'cart-context.ts [refetchCartData]',
            context: {
              message: 'Empty cart id',
              customerToken,
              guestToken,
              storeCode,
              defaultCartId,
              latestCartId,
              currCartId,
              brand,
              category,
              parentSku,
              productName,
            },
          }),
        })
      }

      fetchCartData(latestCartId, (cart) => {
        setCartData(cart)
        setIsCartRefetching(false)
      })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currCartId, addToCart, storeConfig.currency],
  )

  const cartItems = useMemo(
    () =>
      ((cartData?.cart?.items || []).filter(Boolean) ??
        []) as CartItemFragment[],
    [cartData?.cart?.items],
  )

  const contextValue = useMemo(
    () => ({
      cartItems,
      isAddingToCart,
      isCartRefetching,
      isCartFetchError,
      hasItems: cartItems.length > 0,
      addToCart: addToCartWithCartId,
      refetchCartData,
    }),
    [
      cartItems,
      isAddingToCart,
      isCartFetchError,
      addToCartWithCartId,
      isCartRefetching,
      refetchCartData,
    ],
  )

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

export const useCartContext = () => useContext(CartContext)
