import { z } from 'zod'

import { gtmTracker } from '../services/tracking/google/gtm-tracker'
import { CookieKeys, getCookie, setCookie } from './cookie-utils'

export const isOfType = <T>(
  varToBeChecked: any | undefined | null,
  propertyToCheckFor: keyof T,
): varToBeChecked is T => {
  if (varToBeChecked) {
    return varToBeChecked.hasOwnProperty(propertyToCheckFor)
  }

  return false
}

export const trackABTesting = () => {
  const testCookies = getABTestingCookie(
    getCookie(CookieKeys.TEST_NAME),
    getCookie(CookieKeys.TEST_VARIANT),
  )

  gtmTracker.trackAbTestSetup(
    testCookies.map(({ name, variant }) => ({
      testName: name,
      testVariant: variant,
    })) ?? [],
  )
}

export const delay = (time: number) =>
  new Promise<boolean>((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })

// can be used only in the browser
export function setABTestingCookie(
  testing: { name: string; variant: string }[],
) {
  if (testing.length >= 1) {
    setCookie(
      CookieKeys.TEST_NAME,
      testing.map((test) => test.name).join(';'),
      {
        expires: 30,
      },
    )
    setCookie(
      CookieKeys.TEST_VARIANT,
      testing.map((test) => test.variant).join(';'),
      {
        expires: 30,
      },
    )
  }
}

export function getABTestingCookie(
  testNames?: string,
  testVariants?: string,
): { name: string; variant: string }[] {
  if (testNames && testVariants) {
    return testNames.split(';').map((name, index) => ({
      name,
      variant: testVariants.split(';')[index],
    }))
  }

  return []
}

export function convertToJsonString(inputString: string) {
  // Add double quotes to keys
  let validJsonString = decodeURIComponent(inputString).replace(
    /([{,]\s*)([a-zA-Z0-9_]+)(\s*:)/g,
    '$1"$2"$3',
  )

  // Add double quotes to string values (but not to booleans, numbers, or nulls)
  validJsonString = validJsonString.replace(/:\s*'([^']+)'/g, ': "$1"')

  return validJsonString
}

export function getCookieMktConsent(mktConsent?: string): boolean {
  if (mktConsent) {
    const cookieConsent = convertToJsonString(mktConsent)

    try {
      return JSON.parse(cookieConsent).marketing
    } catch {
      return false
    }
  }

  return false
}

export function formatGAUID(gaUID: string) {
  if (gaUID) {
    return gaUID.split('.').slice(-2).join('.')
  }

  return ''
}

export function parseValueFromZodSchema<T extends z.ZodType>(
  obj: unknown,
  schema: T,
): z.infer<T> {
  const parsedResult = schema.safeParse(obj)

  if (!parsedResult.success) {
    throw new Error(`Parsing failed: ${parsedResult.error.message}`)
  }

  return parsedResult.data
}

export const IS_LOCAL = process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
export const IS_BRANCH = process.env.NEXT_PUBLIC_ENVIRONMENT === 'branch'
export const IS_DEV = IS_LOCAL || IS_BRANCH
