import { CookieKeys, getCookie } from '@/common/utils/cookie-utils'
import { HttpMethod, restFetch } from '../fetch'

const GET_GUEST_TOKEN_URL = '/rest/V1/guest-carts-session'

export const fetchGuestToken = async () => {
  const persistentQuote = getCookie(CookieKeys.PERSISTENT_QUOTE)

  if (persistentQuote) {
    return {
      data: persistentQuote,
    }
  }

  return restFetch<string>(
    GET_GUEST_TOKEN_URL,
    HttpMethod.POST,
    {},
    undefined,
    undefined,
    false,
  )
}
