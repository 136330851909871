import {
  useCustomerCartIdQuery,
  fetchGuestToken,
  GraphQlErrorsResponse,
  CurrencyEnum,
  CartQuery,
  useCartQuery,
} from '@/api'
import { queryClient } from '@/common/services'
import { getCustomerToken } from '@/providers/auth/utils'
import { FlashMessage } from '@/providers/flash-messages'
import { consoleError } from '@/common/utils/console'
import { AddToCartTrackingParams } from './cart.types'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { StoreCodeType } from '@/common/types'
import { HttpMethod } from '@/common/api/rest/fetch'
import { ExtensionErrors } from './hooks/process-add-to-cart-errors'

export const fetchCustomerCartId = async () => {
  const response = await queryClient.fetchQuery({
    queryKey: useCustomerCartIdQuery.getKey(),
    queryFn: useCustomerCartIdQuery.fetcher(),
    retry: 1,
    retryDelay: 1000,
  })

  if (!response.customerCart || response.errors) {
    consoleError('add-to-cart.utils.ts [fetchCustomerCartId]', {
      response,
    })
    return undefined
  }

  return response.customerCart.id
}

export const handleCartGraphqlError = async <T extends Record<string, unknown>>(
  data: T,
  handlerFn: (
    data: T & { cartId: string },
  ) => Promise<FlashMessage[] | undefined>,
) => {
  const response = await fetchGuestToken()

  if (response.error || !response.data) {
    const message = JSON.stringify(response.error)
    consoleError(
      'cart/*/add-to-cart.utils.ts [handleCartGraphqlError]',
      message,
    )
    throw new Error(`handleCartGraphqlError: ${message}`)
  } else {
    if (!getCustomerToken()) {
      return handlerFn({ ...data, cartId: response.data })
    } else {
      const customerCartId = await fetchCustomerCartId()
      const newCartId = customerCartId || response.data

      return handlerFn({ ...data, cartId: newCartId })
    }
  }
}

export const containsGraphqlErrorResponseSpecificCategory = (
  categories: string[],
  errs?: GraphQlErrorsResponse['errors'],
) => {
  try {
    return (
      errs &&
      errs?.some(
        (err) =>
          err.extensions?.category &&
          categories.includes(err.extensions.category),
      )
    )
  } catch (error) {
    return true
  }
}

export function hasGraphqlAuthorizationError(
  errors: GraphQlErrorsResponse['errors'],
): boolean {
  if (typeof errors !== 'object') {
    return false
  }

  const isAuthorizationError = errors?.some(
    (error) => error?.extensions?.category === ExtensionErrors.Authorization,
  )

  return !!isAuthorizationError
}

export const trackAddToCartEvent = (
  {
    brand,
    quantity,
    productName,
    price,
    category,
    prodlistPlacement,
    productIds,
    productPosition,
    simpleId,
    configId,
    currency,
  }: AddToCartTrackingParams,
  addingRecommendedProduct: boolean,
) => {
  if (addingRecommendedProduct) {
    gtmTracker.trackRecommendedProductAddToCart()
  } else {
    gtmTracker.trackAddProductToCart({
      productData: {
        id: configId,
        brand: brand ?? '',
        name: productName,
        category: category ?? '',
      },
      quantity,
      price: price ?? 0,
      currency: currency ?? CurrencyEnum?.Eur,
      recommendedOptions: {
        prodlistPlacement,
        productIds,
        productPosition,
      },
      simpleId,
    })
  }
}

export const fetchCartData = async (
  cartId: string,
  setFn: (data: CartQuery) => void,
) => {
  if (cartId.trim().length <= 0) {
    return
  }

  try {
    const data = await queryClient.fetchQuery({
      queryKey: [useCartQuery.getKey({ cartId: `${cartId}-${Date.now()}` })],
      queryFn: useCartQuery.fetcher({ cartId }),
      retry: 1,
      retryDelay: 1000,
    })

    if (data.errors && !data.cart) {
      consoleError('cart-context.tsx [fetchCartData]', {
        error: data.errors,
        data: null,
      })
      return
    }

    if (data.cart) {
      setFn(data)
    }
  } catch (err) {
    consoleError('cart-context.tsx [fetchCartData][catch]', err)
  }
}

export const fetchCartId = async ({
  defaultCartId,
  onNewCartIdSet,
  customerToken,
  storeCode,
}: {
  customerToken?: string
  defaultCartId: string
  onNewCartIdSet: (value: string) => void
  storeCode: StoreCodeType
}) => {
  try {
    if (!customerToken) {
      onNewCartIdSet(defaultCartId)
      return
    }

    const response = await queryClient.fetchQuery({
      queryKey: [...useCustomerCartIdQuery.getKey(), customerToken, storeCode],
      queryFn: useCustomerCartIdQuery.fetcher(),
      retry: 1,
      retryDelay: 1000,
    })

    if (!response.customerCart || response.errors) {
      throw new Error('Error while fetching cart id', {
        cause: response.errors,
      })
    }

    onNewCartIdSet(response.customerCart?.id ?? defaultCartId)
  } catch (err) {
    fetch(`/api/v1/add-logs`, {
      method: HttpMethod.POST,
      body: JSON.stringify({
        place: 'cart-utils.ts [fetchCartId][catch]',
        context: {
          message: 'Failed to fetch guest token',
          storeCode,
          customerToken,
          defaultCartId,
          cause: {
            message: err?.message,
            cause: err?.cause,
          },
        },
      }),
    })
  }
}
